import { Box, Grid } from '@mui/material';
import GenericInput from '../UI/GenericInput';
import GenericSelectInput from '../UI/GenericSelectInput';
import usePhonesHelperMethods from '../../../../hooks/UI/usePhonesHelperMethods';

export default function DepartmentPhones({
  form,
  formKeyName,
  departmentIndex,
  phoneTypes,
}: {
  form: any;
  formKeyName: string;
  departmentIndex: number;
  phoneTypes: any[];
}) {
  const arrayFieldHasError = (
    arrayName: string,
    itemIndex: number,
    fieldName: string,
  ) => {
    return form.errors &&
      form.errors[formKeyName] &&
      form.errors[formKeyName][departmentIndex] &&
      form.errors[formKeyName][departmentIndex][arrayName] &&
      form.errors[formKeyName][departmentIndex][arrayName][itemIndex] &&
      form.errors[formKeyName][departmentIndex][arrayName][itemIndex][fieldName]
      ? true
      : false;
  };
  const arrayFieldErrorMessage = (
    arrayName: string,
    itemIndex: number,
    fieldName: string,
  ) => {
    return form.errors &&
      form.errors[formKeyName] &&
      form.errors[formKeyName][departmentIndex] &&
      form.errors[formKeyName][departmentIndex][arrayName] &&
      form.errors[formKeyName][departmentIndex][arrayName][itemIndex] &&
      form.errors[formKeyName][departmentIndex][arrayName][itemIndex][fieldName]
      ? form.errors[formKeyName][departmentIndex][arrayName][itemIndex][
          fieldName
        ]
      : '';
  };
  const { formatPhoneNumberOnChange } = usePhonesHelperMethods();

  return (
    <Box pt={2}>
      {form.values[formKeyName][departmentIndex].phones &&
        form.values[formKeyName][departmentIndex].phones.length > 0 &&
        form.values[formKeyName][departmentIndex].phones.map(
          (phone: any, phoneIndex: number) => (
            <Grid spacing={2} mx={1} px={1} container key={phoneIndex}>
              <Grid item xs={4} px={2}>
                <GenericInput
                  name={`${formKeyName}.${departmentIndex}.phones.${phoneIndex}.phoneNumber`}
                  label="Phone"
                  maxLength={12}
                  placeholder="xxx-xxx-xxxx"
                  inputMode="tel"
                  value={phone.phoneNumber}
                  onChange={(e: any) => {
                    formatPhoneNumberOnChange(e);
                    form.setFieldValue(
                      `${formKeyName}.${departmentIndex}.phones.${phoneIndex}.phoneNumber`,
                      e.target.value,
                    );
                  }}
                  errorMsg={arrayFieldErrorMessage(
                    'phones',
                    phoneIndex,
                    'phoneNumber',
                  )}
                  hasError={arrayFieldHasError(
                    'phones',
                    phoneIndex,
                    'phoneNumber',
                  )}
                  onBlur={() => {
                    form.setFieldTouched(
                      `${formKeyName}[${departmentIndex}].phones[${phoneIndex}].phoneNumber`,
                      true,
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4} px={2}>
                <GenericSelectInput
                  name={`${formKeyName}.${departmentIndex}.phones.${phoneIndex}.phoneTypeId`}
                  label="Phone Type"
                  value={phone.phoneTypeId}
                  onChange={(value: string) => {
                    form.setFieldValue(
                      `${formKeyName}.${departmentIndex}.phones.${phoneIndex}.phoneTypeId`,
                      value,
                    );
                  }}
                  errorMsg={arrayFieldErrorMessage(
                    'phones',
                    phoneIndex,
                    'phoneTypeId',
                  )}
                  hasError={arrayFieldHasError(
                    'phones',
                    phoneIndex,
                    'phoneTypeId',
                  )}
                  onBlur={() => {
                    form.setFieldTouched(
                      `${formKeyName}[${departmentIndex}].phones[${phoneIndex}].phoneTypeId`,
                      true,
                    );
                  }}
                  options={phoneTypes}
                />
              </Grid>
              <Grid item xs={4} px={2}>
                <GenericInput
                  name={`${formKeyName}.${departmentIndex}.phones.${phoneIndex}.phoneNotes`}
                  label="Notes"
                  maxLength={40}
                  placeholder="Notes"
                  value={phone.phoneNotes}
                  onChange={(e: any) => {
                    form.setFieldValue(
                      `${formKeyName}.${departmentIndex}.phones.${phoneIndex}.phoneNotes`,
                      e.target.value,
                    );
                  }}
                  errorMsg={arrayFieldErrorMessage(
                    'phones',
                    phoneIndex,
                    'phoneNotes',
                  )}
                  hasError={arrayFieldHasError(
                    'phones',
                    phoneIndex,
                    'phoneNotes',
                  )}
                  onBlur={() => {
                    form.setFieldTouched(
                      `${formKeyName}[${departmentIndex}].phones[${phoneIndex}].phoneNotes`,
                      true,
                    );
                  }}
                />
              </Grid>
            </Grid>
          ),
        )}
    </Box>
  );
}
