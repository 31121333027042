import { Alert, AlertColor, Box, Snackbar } from '@mui/material';
import { useState, useEffect } from 'react';
import UIContext from '../context/UIContext';
import { alertStyle } from '../assets/styles/appStyles';
import useLocalStorage from '../hooks/storage/useLocalStorage';

const UIProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const [messages, setMessages] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [color, setColor] = useState<AlertColor | undefined>();

  useEffect(() => {
    if (messages.length > 0) {
      setIsOpen(true);
    }
  }, [messages]);

  const showSnackbar = (msgs: string[], color: AlertColor | undefined) => {
    setMessages([...msgs]);
    setColor(color);
  };
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    setIsOpen(false);

    if (reason === 'clickaway') {
      return;
    }
  };

  const [isSideBarOpened, openSideBar] = useLocalStorage(
    'isSideBarOpened',
    false,
  );
  return (
    <UIContext.Provider
      value={{
        showSnackbar: (msgs: string[], color: AlertColor | undefined) =>
          showSnackbar(msgs, color),
        isSideBarOpened,
        openSideBar,
      }}
    >
      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          <>
            {messages.map((message, index) => (
              <Alert
                key={index}
                onClose={handleClose}
                severity={color}
                sx={alertStyle}
              >
                <Box>
                  {message} <br />
                </Box>
              </Alert>
            ))}
          </>
        }
      />
      {children}
    </UIContext.Provider>
  );
};

export default UIProvider;
