import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';

export default function ResearchCustodiansSelectedSearch({
  search,
  onClearFilter,
}: {
  search: { name: string; value: string }[];
  onClearFilter: () => void;
}) {
  const clearFilters = () => {
    onClearFilter();
  };

  return (
    <>
      {search.length > 0 && (
        <Box ml={1}>
          <Grid container alignItems={'center'}>
            <Grid item>
              <Typography>Filters</Typography>
            </Grid>
            <Grid item pl={1}>
              <Chip
                label="Clear Filters"
                onClick={clearFilters}
                color="primary"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Grid container>
        {search.map((field) => (
          <Grid item p={1} key={field.name}>
            {field.name}:
            <Box ml={1} component={'span'}>
              <Chip label={field.value} color="primary" />
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
