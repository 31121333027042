import { SidebarItemModel } from '../../../Models/Layout/sidebarItemModel';
import SideBarItem from './SideBarItem';

function SideBarList({
  items,
  sideBarItemClicked,
}: {
  items: SidebarItemModel[];
  sideBarItemClicked: (item: SidebarItemModel) => void;
}) {
  return (
    <>
      {items.map((item) => (
        <SideBarItem
          key={item.title}
          item={item}
          handleClick={(link: SidebarItemModel) => sideBarItemClicked(link)}
        />
      ))}
    </>
  );
}
export default SideBarList;
