import { useEffect } from 'react';
import useDepartmentForm from '../addLocation/useDepartmentForm';
import useFetchDays from '../useFetchDays';
import useFetchDepartment from '../useFetchDepartment';
import useFetchDepartmentFileTypes from '../useFetchDepartmentFileTypes';
import useFetchOrderTypes from '../useFetchOrderTypes';
import useFetchPhoneTypes from '../useFetchPhoneTypes';
import useFetchRecordTypes from '../useFetchRecordTypes';
import useFetchServeMethods from '../useFetchServeMethods';
import useFetchStates from '../useFetchStates';
import useFetchTimeZones from '../useFetchTimeZones';
import moment from 'moment';

export default function useEditDepartment(id: string) {
  const { days } = useFetchDays();
  const { serveMethods } = useFetchServeMethods();
  const { departmentFileTypes } = useFetchDepartmentFileTypes();
  const { timeZones } = useFetchTimeZones();
  const { recordTypes } = useFetchRecordTypes();
  const { states } = useFetchStates();
  const { phoneTypes } = useFetchPhoneTypes();
  const { orderTypes } = useFetchOrderTypes();

  const { isLoading, department } = useFetchDepartment(id);

  useEffect(() => {
    if (department && department.id) {
      departmentsForm.setValues({
        departments: [
          {
            name: department.name,
            email: department.email,
            cId: department.custodianId,
            id: department.id,
            active: true,
            orderTypeId: department.orderTypeId,
            daysAvailable: department?.departmentDaysAvailable?.map(
              (d: any) => ({
                id: d.id,
                serveHours: {
                  from: d.serveHours.from
                    ? moment(d.serveHours.from, 'HH:mm')
                    : d.serveHours.from,
                  to: d.serveHours.to
                    ? moment(d.serveHours.to, 'HH:mm')
                    : d.serveHours.to,
                },
                pickupAndCopyHours: {
                  from: d.pickupAndCopyHours.from
                    ? moment(d.pickupAndCopyHours.from, 'HH:mm')
                    : d.pickupAndCopyHours.from,
                  to: d.pickupAndCopyHours.to
                    ? moment(d.pickupAndCopyHours.to, 'HH:mm')
                    : d.pickupAndCopyHours.to,
                },
                bestTimeToCall: {
                  from: d.bestTimeToCall.from
                    ? moment(d.bestTimeToCall.from, 'HH:mm')
                    : d.bestTimeToCall.from,
                  to: d.bestTimeToCall.to
                    ? moment(d.bestTimeToCall.to, 'HH:mm')
                    : d.bestTimeToCall.to,
                },
                dayOfWeek: d.dayId,
                timeZoneId: d.timeZoneId,
              }),
            ),
            portalNotes: department.portalNotes ?? '',
            serveMethods: department.departmentServeMethods?.map((d: any) => ({
              id: d.id,
              serveMethodId: d.serveMethodId,
            })),
            addressInfo: {
              id: department.address?.id,
              stateId: department.address?.stateId,
              city: department.address?.city,
              postCode: department.address?.postCode,
              addressDetails: department.address?.addressDetails,
              timeZoneId: department.address?.timeZoneId,
            },
            servePreferences: {
              ...department.servePreferences,
              files: [],
              oldFilesToBeDeleted: [],
              oldFiles: department.files?.map((d: any) => ({ ...d })) ?? [],
            },
            recordTypes: department.recordTypes?.map(
              (d: any) => d.recordTypeId,
            ),
            phones:
              department.phones && department.phones.length > 0
                ? department.phones?.map((d: any) => ({
                    id: d.id,
                    phoneNumber: d.phoneNumber,
                    phoneTypeId: d.phoneTypeId,
                    phoneNotes: d.phoneNotes ?? '',
                  }))
                : [
                    {
                      phoneNumber: '',
                      phoneTypeId: '',
                      phoneNotes: '',
                    },
                  ],
            contacts:
              department.contacts && department.contacts.length > 0
                ? department.contacts?.map((d: any) => ({
                    id: d.id,
                    email: d.email,
                    name: d.name,
                    phoneNumber: d.phoneNumber,
                    title: d.title,
                  }))
                : [
                    {
                      email: '',
                      name: '',
                      phoneNumber: '',
                      title: '',
                    },
                  ],
          },
        ],
      });
    }
  }, [department]);

  const { departmentsForm } = useDepartmentForm({
    departmentFileTypes,
    orderTypes,
    phoneTypes,
    recordTypes,
    states,
  });

  return {
    departmentsForm,
    department,
    isLoading,
    orderTypes,
    phoneTypes,
    recordTypes,
    states,
    timeZones,
    departmentFileTypes,
    serveMethods,
    days,
  };
}
