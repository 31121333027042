import { useCallback, useEffect, useState } from 'react';
import useHttp from '../http/useHttp';
import CustodianDetailsViewModel from '../../Models/custodian/custodian-details-viewmodel';

export default function useFetchCustodianDetails(id: string): {
  custodian: CustodianDetailsViewModel;
  isLoading: boolean;
  setAssociationHasUpdate?: (_: boolean) => void;
} {
  const { getDataResponse, getData } = useHttp();
  const [isLoading, setIsLoading] = useState(false);

  const init = useCallback(async (id: string) => {
    try {
      setIsLoading(true);
      await getData(`custodian?id=${id}`);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }, []);

  let custodian: CustodianDetailsViewModel = getDataResponse?.data ?? [];

  useEffect(() => {
    if (id) init(id);
  }, [id, init]);

  const [associationHasUpdate, setAssociationHasUpdate] =
    useState<boolean>(false);

  useEffect(() => {
    if (associationHasUpdate && id) init(id);
  }, [associationHasUpdate]);

  return { custodian, isLoading, setAssociationHasUpdate };
}
