import { SxProps, Theme } from '@mui/material';
import { wrapText } from '../../../assets/styles/appStyles';

export const headerContainer: SxProps<Theme> = (theme: Theme) => ({
  padding: '20px',
  backgroundColor: theme.palette.neutral['50'],
  borderRadius: '10px',
  marginBottom: '20px',
  boxShadow: 'none',
});

export const flexRow: SxProps = {
  display: 'flex',
  flexFlow: 'row',
};

export const flexColumn: SxProps = {
  display: 'flex',
  flexFlow: 'column',
};

export const headerInfoBoxText: SxProps = {
  ...flexColumn,
  maxWidth: '90%',
  paddingLeft: '1em',
};

export const flexRowSpaceBetween: SxProps = {
  ...flexRow,
  justifyContent: 'space-between',
};

export const creationDateContainer: SxProps = {
  ...flexRowSpaceBetween,
  alignItems: 'center',
};

export const headerInfoBox: SxProps = {
  ...flexRow,
  width: '25%',
  maxWidth: '50%',
  alignItems: 'center',
};

export const addressBox: SxProps = {
  ...headerInfoBox,
  flexGrow: '1',
};

export const phoneBox: SxProps = {
  ...headerInfoBox,
  flexGrow: '.25',
};

export const portalURLBox: SxProps = {
  ...headerInfoBox,
  flexGrow: '.25',
};

export const boldLabel: SxProps = {
  fontWeight: 'bold',
};

export const paddedIcon: SxProps<Theme> = (theme: Theme) => ({
  fontSize: '2em',
  padding: '5px',
  borderRadius: '5px',
  backgroundColor: theme.palette.primary['50'],
});

export const iconLabel: SxProps<Theme> = (theme: Theme) => ({
  fontWeight: '400',
  fontSize: '15px',
  color: theme.palette.neutral['400'],
});

export const vAlignBaseline: SxProps = {
  alignSelf: 'baseline',
};

export const iconText: SxProps = {
  ...vAlignBaseline,
  fontWeight: '600',
  maxWidth: '100%',
  ...wrapText,
};

export const custodianNameWrapper: SxProps = {
  maxWidth: '70%',
  overflow: 'hidden',
};

export const custodianName: SxProps = {
  fontSize: '34px',
  fontWeight: '800',
  ...wrapText,
};
