// Styles.ts
import { SxProps } from '@mui/material';

const submitButton: SxProps = {
  fontSize: '16px',
  fontWeight: '700',
  marginTop: '40px',
  height: '48px',
  width: '150px',
  color: (theme: any) => theme.palette.common.white,
};

const submitButtonContainer: SxProps = {
  textAlign: 'right',
};

const fromContainer: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
};

const errorMessage: SxProps = {
  fontSize: '13px',
  color: (theme: any) => theme.palette.error[500],
};

const classes = {
  submitButton,
  submitButtonContainer,
  fromContainer,
  errorMessage,
};

export default classes;
