import { Box, Button, Grid } from '@mui/material';
import RolesTable from './Components/RoleTable/roleTable';
import Styles from './manageRoleStyles';
import { buttonLink, cardContainer } from '../../assets/styles/appStyles';
import { appRoutesPaths } from '../../settings/appConfig';
import { Link } from 'react-router-dom';

export default function ManageRoles() {
  return (
    <Box sx={cardContainer} p={2}>
      <Grid container>
        <Grid item xs={12} sx={Styles.card}>
          <Link to={appRoutesPaths.addRole}>
            <Button sx={buttonLink}>Add New Role</Button>
          </Link>
        </Grid>
        <RolesTable></RolesTable>
      </Grid>
    </Box>
  );
}
