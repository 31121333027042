import { Grid } from '@mui/material';
import InsertDepartmentModel from '../../../../Models/Departments/InsertDepartmentModel';
import GenericInput from '../UI/GenericInput';
import GenericSelectInput from '../UI/GenericSelectInput';

export default function DepartmentBasicDetails({
  form,
  department,
  formKeyName,
  departmentIndex,
  orderTypes,
}: {
  department: InsertDepartmentModel;
  form: any;
  formKeyName: string;
  departmentIndex: number;
  orderTypes: any[];
}) {
  return (
    <Grid spacing={2} mx={1} px={1} container>
      <Grid item xs={4} px={2}>
        <GenericInput
          name={`${formKeyName}.${departmentIndex}.name`}
          label="Name"
          maxLength={120}
          placeholder="Name"
          value={department.name}
          onChange={(e: any) => {
            form.setFieldValue(
              `${formKeyName}.${departmentIndex}.name`,
              e.target.value,
            );
          }}
          errorMsg={
            form.touched[formKeyName] &&
            form.touched[formKeyName][departmentIndex] &&
            form.touched[formKeyName][departmentIndex]['name'] &&
            form.errors[formKeyName] &&
            form.errors[formKeyName][departmentIndex] &&
            form.errors[formKeyName][departmentIndex]['name']
              ? form.errors[formKeyName][departmentIndex]['name']
              : ''
          }
          hasError={
            form.touched[formKeyName] &&
            form.touched[formKeyName][departmentIndex] &&
            form.touched[formKeyName][departmentIndex]['name'] &&
            form.errors[formKeyName] &&
            form.errors[formKeyName][departmentIndex] &&
            form.errors[formKeyName][departmentIndex]['name']
              ? true
              : false
          }
          onBlur={() => {
            form.setFieldTouched(
              `${formKeyName}.${departmentIndex}.name`,
              true,
            );
          }}
          required={true}
        />
      </Grid>
      <Grid item xs={4} px={2}>
        <GenericSelectInput
          name={`${formKeyName}.${departmentIndex}.orderTypeId`}
          label="Order Type"
          value={department.orderTypeId ?? ''}
          onChange={(value: string) => {
            form.setFieldValue(
              `${formKeyName}.${departmentIndex}.orderTypeId`,
              value,
            );
          }}
          errorMsg={
            form.touched[formKeyName] &&
            form.touched[formKeyName][departmentIndex] &&
            form.touched[formKeyName][departmentIndex]['orderTypeId'] &&
            form.errors[formKeyName] &&
            form.errors[formKeyName][departmentIndex] &&
            form.errors[formKeyName][departmentIndex]['orderTypeId']
              ? form.errors[formKeyName][departmentIndex]['orderTypeId']
              : ''
          }
          hasError={
            form.touched[formKeyName] &&
            form.touched[formKeyName][departmentIndex] &&
            form.touched[formKeyName][departmentIndex]['orderTypeId'] &&
            form.errors[formKeyName] &&
            form.errors[formKeyName][departmentIndex] &&
            form.errors[formKeyName][departmentIndex]['orderTypeId']
              ? true
              : false
          }
          onBlur={() => {
            form.setFieldTouched(
              `${formKeyName}[${departmentIndex}].orderTypeId`,
              true,
            );
          }}
          options={orderTypes}
          required={true}
        />
      </Grid>
      <Grid item xs={4} px={2}>
        <GenericInput
          name={`${formKeyName}.${departmentIndex}.email`}
          label="Email"
          maxLength={80}
          placeholder="Email"
          value={department.email}
          onChange={(e: any) => {
            form.setFieldValue(
              `${formKeyName}.${departmentIndex}.email`,
              e.target.value,
            );
          }}
          errorMsg={
            form.touched[formKeyName] &&
            form.touched[formKeyName][departmentIndex] &&
            form.touched[formKeyName][departmentIndex]['email'] &&
            form.errors[formKeyName] &&
            form.errors[formKeyName][departmentIndex] &&
            form.errors[formKeyName][departmentIndex]['email']
              ? form.errors[formKeyName][departmentIndex]['email']
              : ''
          }
          hasError={
            form.touched[formKeyName] &&
            form.touched[formKeyName][departmentIndex] &&
            form.touched[formKeyName][departmentIndex]['email'] &&
            form.errors[formKeyName] &&
            form.errors[formKeyName][departmentIndex] &&
            form.errors[formKeyName][departmentIndex]['email']
              ? true
              : false
          }
          onBlur={() => {
            form.setFieldTouched(
              `${formKeyName}.${departmentIndex}.email`,
              true,
            );
          }}
        />
      </Grid>
    </Grid>
  );
}
