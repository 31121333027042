import { SxProps } from '@mui/material';
import { wrapText } from '../../../../assets/styles/appStyles';

const title: SxProps = {
  color: (theme: any) => theme.palette.neutral['400'],
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '19px',
  marginBottom: '5px',
};

const value: SxProps = {
  color: (theme: any) => theme.palette.neutral['800'],
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '22px',
  marginBottom: '5px',
  ...wrapText,
};

const divider: SxProps = {
  border: '1px solid',
  borderColor: (theme: any) => theme.palette.neutral['200'],
  marginBottom: '14px',
  marginTop: '14px',
};

const recordType: SxProps = {
  ...value,
  background: (theme: any) => theme.palette.neutral['300'],
  padding: '8px',
  borderRadius: '6px',
};

const days: SxProps = {
  background: (theme: any) => theme.palette.neutral['100'],
  borderRadius: '8px',
  marginTop: '20px',
  width: '100%',
  padding: '28px 12px',
};

const paddingBottom: SxProps = {
  paddingBottom: '20px',
};

const file: SxProps = {
  background: (theme: any) => theme.palette.neutral['100'],
  borderRadius: '8px',
  width: '100%',
  paddingTop: '3px ',
  paddingLeft: '20px',
};

const fileGroup: SxProps = {
  color: (theme: any) => theme.palette.neutral['800'],
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '22px',
  marginBottom: '5px',
};

const fileSize: SxProps = {
  color: (theme: any) => theme.palette.neutral['400'],
  fontSize: '10px',
  lineHeight: '22px',
  marginBottom: '5px',
};

const fileName: SxProps = {
  color: (theme: any) => theme.palette.neutral['800'],
  fontWeight: 600,
  fontSize: '14px',
};

const addDepartmentBtn: SxProps = {
  padding: '10px',
};

const editDepartment: SxProps = {
  padding: '8px',
};

let classes = {
  title,
  value,
  divider,
  recordType,
  days,
  paddingBottom,
  file,
  fileGroup,
  fileSize,
  fileName,
  addDepartmentBtn,
  editDepartment,
};

export default classes;
