import { IPublicClientApplication } from '@azure/msal-browser';
import { azureConfiguration } from '../settings/authConfig';

export const logoutUser = async (instance: IPublicClientApplication) => {
  instance.logoutRedirect({
    account: instance.getAllAccounts()[0],
    postLogoutRedirectUri: azureConfiguration.redirectId,
    onRedirectNavigate: () => {
      return true;
    },
  });
};
