import { Box } from '@mui/material';
import { ServePreferences } from '../../../../../../Models/Departments/Details/ServePreferences';
import { DepartmentFile } from '../../../../../../Models/Departments/Details/file';
import PatientInfo from './Components/PatientInfo';
import CaseInfo from './Components/CaseInfo';
import DocumentInfo from './Components/DocumentInfo';
import FilesInfo from './Components/FilesInfo';
import { useState } from 'react';

export default function ServePreferencesInfo({
  servePreferences,
  files,
}: {
  servePreferences: ServePreferences | undefined;
  files: DepartmentFile[];
}) {
  const [expandedPanel, setExpandedPanel] = useState<string>(
    'Patient'
  );

  const isExpanded = (panel: string) =>{
    return expandedPanel === panel;
  };
  
  const handleAccordionChange =
  (panel: string) => (event: React.SyntheticEvent, isExpanding: boolean) => {
    if(isExpanding)
    setExpandedPanel(panel);
    else
    setExpandedPanel('');
  };

  return (
    <Box mt={2}>
      <PatientInfo servePreferences={servePreferences} 
      isExpanded={isExpanded}
       handleAccordionChange={handleAccordionChange}/>
      <CaseInfo servePreferences={servePreferences} 
      isExpanded={isExpanded}
      handleAccordionChange={handleAccordionChange}/>
      <DocumentInfo servePreferences={servePreferences} 
      isExpanded={isExpanded}
      handleAccordionChange={handleAccordionChange}/>
      <FilesInfo files={files} 
      isExpanded={isExpanded}
      handleAccordionChange={handleAccordionChange}/>
    </Box>
  );
}
