import { useEffect, useCallback } from 'react';
import useHttp from '../http/useHttp';

export default function useFetchPaymentMethods() {
  const { getDataResponse, getData } = useHttp();

  const init = useCallback(async () => {
    await getData('lookup/getpaymentmethods');
  }, []);

  let paymentMethods: any[] = getDataResponse?.data ?? [];

  useEffect(() => {
    init();
  }, [init]);

  return { paymentMethods };
}
