import { useCallback, useState } from 'react';
import useUI from '../../context/useUI';
import useHttp from '../../http/useHttp';
import useModal from '../../UI/useModal';

export default function useDeleteDepartment(
  onDeleteSuccess: (id: number) => void,
) {
  const { showSnackbar } = useUI();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [deletedId, setId] = useState<number>();
  const { postDataResponse, postData } = useHttp();

  const { handleCloseModal, handleOpenModal, isModalOpened } = useModal();

  const deleteDepartment = useCallback(async (id: number) => {
    try {
      setIsSaving(true);
      const result = await postData(`department/delete?id=${id}`, { });
      setIsSaving(false);
      if (result && result.data && result.data == true) {
        showSnackbar(['department deleted successfully!'], 'success');
        onDeleteSuccess(id);
      }
    } catch (error) {
      setIsSaving(false);
    }
  }, []);

  const onDeleteClicked = (id: number) => {
    setId(id);
    handleOpenModal();
  };
  const onConfirmDelete = () => {
    deleteDepartment(deletedId ?? 0);
    handleCloseModal();
  };

  const onCloseModal = () => {
    setId(undefined);
    handleCloseModal();
  };

  return {
    isSaving,
    isDeleteModalOpened: isModalOpened,
    onCloseModal,
    onDeleteClicked,
    onConfirmDelete,
  };
}
