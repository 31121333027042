import { useCallback, useState } from 'react';
import useHttp from '../http/useHttp';
import InsertRole from '../../Models/Roles/insertRole';
import useUI from '../context/useUI';
import { useNavigate } from 'react-router';
import { appRoutesPaths } from '../../settings/appConfig';

export default function useInsertRole() {
  const { postDataResponse, postData } = useHttp();
  const { showSnackbar } = useUI();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const nav = useNavigate();

  const insertRole = useCallback(async (role: InsertRole) => {
    setIsSubmitting(true);
    try {
      await postData('role', role);
      setIsSubmitting(false);
      showSnackbar(['added Successfully!'], 'success');
      nav(appRoutesPaths.roles);
    } catch (err) {
      setIsSubmitting(false);
    }
    return postDataResponse;
  }, []);

  return { insertRole, isSubmitting };
}
