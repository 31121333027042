import { useCallback, useState } from 'react';
import useHttp from '../http/useHttp';
import useUI from '../context/useUI';
import { useNavigate } from 'react-router';
import { appRoutesPaths } from '../../settings/appConfig';

export default function usePostUserRole(id?: string) {
  const { postDataResponse, postData } = useHttp();
  const { showSnackbar } = useUI();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const nav = useNavigate();

  const save = useCallback(async (data: any) => {
    setIsSubmitting(true);
    try {
      if (id && +id > 0) {
        await postData('user/edituserrole', {
          ...data,
          roleId: data.roleId || null,
          userId: id,
        });
        showSnackbar(['updated Successfully!'], 'success');
      } else {
        await postData('user/assignuserrole', {
          ...data,
          roleId: data.roleId || null,
        });
        showSnackbar(['added Successfully!'], 'success');
      }
      setIsSubmitting(false);
      nav(appRoutesPaths.users);
    } catch (err) {
      setIsSubmitting(false);
    }
    return postDataResponse;
  }, []);

  return { save, isSubmitting };
}
