const tableContainer = {
  overflowX: 'scroll',
  padding: '20px',
};

const menuItemContainer = {
  width: '300px',
};

const cellContainer = {
  wordWrap: 'break-word',
  maxHeight: '100px',
};

const filterBoxStyles = {
  float: 'right',
  width: '45px',
};

export { tableContainer, menuItemContainer, cellContainer, filterBoxStyles };
