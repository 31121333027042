import { useCallback } from "react";

export default function useCheckDuplicatePhonesAndEmails() {

	const hasDuplicatePhoneOrEmailError = useCallback((form : any) => {
		if (form && form.values &&
		  (
			(form.values.phones &&
				form.values.phones.some((p: any) => p.hasDuplicateError))
			||
			(form.values.emails &&
				form.values.emails.some((p: any) => p.hasDuplicateError))
		  )
		) {
		  return true;
		}
		else {
		  return false;
		}
	  },[])

  return {
	hasDuplicatePhoneOrEmailError,
  }
}
