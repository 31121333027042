
import { SxProps } from '@mui/material';

const duplicateCustodiansTableContainer: SxProps = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 800,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

const scrollableTable: SxProps = {
	display: 'block',
	height: 600,
	overflowY: 'scroll'
}

const viewBtnStyles: SxProps = {
	padding: '0px',
	textDecoration: 'underline',
	justifyContent: 'start',
	color: 'black'
}

const modalHeaderStyles : SxProps = {
	display: 'flex',
	justifyContent: 'space-between',
}

const closeModalBtnStyles: SxProps = {
	paddingRight:'0px',
}

const styleClasses = {
	duplicateCustodiansTableContainer,
	scrollableTable,
	viewBtnStyles,
	modalHeaderStyles,
	closeModalBtnStyles,
};

export default styleClasses;