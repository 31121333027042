import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import SelectOption from '../../../../Models/Forms/selectOption';
import {
  fileTypeError,
  error,
  menuItem,
  requiredStar,
} from './../../../../assets/styles/appStyles';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';

export default function GenericSelectInput({
  name,
  label,
  options,
  useNative, //for unit testing
  onChange,
  onBlur,
  value,
  hasError,
  errorMsg,
  multiple,
  disabled,
  hideErrorMessage,
  required,
  small,
}: {
  name: string;
  label: string;
  options: SelectOption[] | any[];
  useNative?: boolean | undefined;
  onChange: (e: string) => void;
  onBlur: () => void;
  value: string | string[] | null;
  hasError: boolean;
  errorMsg: string;
  multiple?: boolean;
  disabled?: boolean;
  hideErrorMessage?: boolean;
  required?: boolean;
  small?: boolean;
}) {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${name}-label`}>
        {label}
        {required && (
          <Typography component={'span'} sx={requiredStar}>
            *
          </Typography>
        )}
      </InputLabel>
      <Select
        size={small ? 'small' : 'medium'}
        multiple={multiple}
        native={useNative ?? false}
        labelId={`${name}-label`}
        id={name}
        value={value ?? ''}
        renderValue={(v) =>
          multiple
            ? value?.length?.toString() + ' selected'
            : options?.find((o) => o.id == v)?.name
        }
        name={name}
        variant="outlined"
        label={label}
        onChange={(e: any) => onChange(e.target.value)}
        onBlur={() => onBlur()}
        error={hasError}
        disabled={disabled}
      >
        {options?.map((item, index) => {
          return useNative ? (
            <option key={item.id + index} value={item.id}>
              {item.name}
            </option>
          ) : (
            <MenuItem key={item.id + index} value={item.id} sx={menuItem}>
              <Grid
                pl={1}
                container
                key={item.id + index}
                alignItems={'center'}
                spacing={1}
              >
                <Grid item m={0}>
                  {(multiple && value?.includes(item.id)) ||
                  (!multiple && item?.id?.toString() == value) ? (
                    <Box mt={1}>
                      <CheckCircle fontSize="small" />
                    </Box>
                  ) : (
                    <Box mt={1}>
                      <RadioButtonUnchecked fontSize="small" />
                    </Box>
                  )}
                </Grid>
                <Grid item>{item.name}</Grid>
              </Grid>
            </MenuItem>
          );
        })}
      </Select>
      {errorMsg && !hideErrorMessage ? (
        <FormHelperText sx={fileTypeError}>{errorMsg}</FormHelperText>
      ) : null}
    </FormControl>
  );
}