import { AlertColor } from '@mui/material';
import { createContext } from 'react';

interface IUIContext {
  showSnackbar: (msgs: string[], color: AlertColor | undefined) => void;
  openSideBar: (_: boolean) => void;
  isSideBarOpened: boolean;
}

const UIContext = createContext<IUIContext>({
  showSnackbar: (msgs: string[], color: AlertColor | undefined) => {},
  isSideBarOpened: false,
  openSideBar: () => {},
});

export default UIContext;
