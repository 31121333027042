import { useEffect, useCallback, useState } from 'react';
import ServeMethod from '../../Models/Locations/serveMethod';
import useHttp from '../http/useHttp';

export default function useFetchServeMethods() {
  const { getDataResponse, getData } = useHttp();
  const [serveMethodLoading, setServeMethodLoading] = useState<boolean>(false);

  const init = useCallback(async () => {
    setServeMethodLoading(true);
    await getData('lookup/getservemethods');
    setServeMethodLoading(false);
  }, []);

  let serveMethods: ServeMethod[] = getDataResponse?.data ?? [];

  useEffect(() => {
    init();
  }, [init]);

  return { serveMethods, serveMethodLoading };
}
