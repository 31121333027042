import { useState, useCallback } from 'react';
import useHttp from '../http/useHttp';
export default function useSearchAssociations() {
  const { postDataResponse, postData } = useHttp();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const search = useCallback(async (value: string) => {
    setIsLoading(true);
    await postData('custodian/search', {
      searchText: value,
    });

    setIsLoading(false);
  }, []);
  return {
    data: postDataResponse?.data ?? { count: 0, custodians: [] },
    search,
    isLoading,
  };
}
