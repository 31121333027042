import { Box, Divider, Grid, Typography } from '@mui/material';
import Department from '../../../../../Models/Departments/Details/department';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../../../AddLocation/Components/UI/AccordionHelper';
import style from '../departmentStyle';
import { custodianDetailsContainer } from '../../../CustodianDetailsStyles';
import { emptyValue } from '../../../../../settings/appConfig';
import { accordionSummaryText } from '../../../Styles/MainInformationTabStyles';

export default function DepartmentInfo({
  department,
}: {
  department: Department | undefined;
}) {
  return (
    <Box mt={2}>
      <Accordion expanded={true}>
        <AccordionSummary
          sx={accordionSummaryText}
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={null}
        >
          {department?.name}
        </AccordionSummary>
        <AccordionDetails sx={custodianDetailsContainer}>
          <Grid container sx={style.paddingBottom} spacing={2}>
            <Grid item xs={3}>
              <Typography sx={style.title}>Department Address</Typography>
              <Box sx={style.value}>
                {!!department?.address?.addressDetails
                  ? department?.address?.addressDetails
                  : emptyValue}
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Typography sx={style.title}>State</Typography>
              <Box sx={style.value}>
                {!!department?.address?.stateName
                  ? department?.address?.stateName
                  : emptyValue}
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Typography sx={style.title}>City</Typography>
              <Box sx={style.value}>
                {!!department?.address?.city
                  ? department?.address?.city
                  : emptyValue}
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Typography sx={style.title}>Zip/Postal Code</Typography>
              <Box sx={style.value}>
                {!!department?.address?.postCode
                  ? department?.address?.postCode
                  : emptyValue}
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Typography sx={style.title}>TimeZone</Typography>
              <Box sx={style.value}>
                {!!department?.address?.timeZone
                  ? department?.address?.timeZone
                  : emptyValue}
              </Box>
            </Grid>
          </Grid>

          <Divider sx={style.divider} />

          <Grid container sx={style.paddingBottom} spacing={2}>
            <Grid item xs={3}>
              <Typography sx={style.title}>Order Type</Typography>
              <Box sx={style.value}>
                {!!department?.orderTypeName
                  ? department?.orderTypeName
                  : emptyValue}
              </Box>
            </Grid>

            <Grid item xs={7} sx={style.paddingBottom}>
              <Typography sx={style.title}>Department Email Address</Typography>
              <Box sx={style.value}>
                {!!department?.email ? department?.email : emptyValue}
              </Box>
            </Grid>
          </Grid>

          <Grid container sx={style.paddingBottom} spacing={2}>
            <Grid item xs={3}>
              <Typography sx={style.title}>Fax/Phone number</Typography>
              <Box sx={style.value}>
                {!!department?.phones &&
                department.phones.length > 0 &&
                department?.phones[0].phoneNumber
                  ? department?.phones[0].phoneNumber
                  : emptyValue}
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Typography sx={style.title}>Number Type</Typography>
              <Box sx={style.value}>
                {!!department?.phones &&
                department.phones.length > 0 &&
                department?.phones[0].phoneType
                  ? department?.phones[0].phoneType
                  : emptyValue}
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={style.title}>Notes</Typography>
              <Box sx={style.value}>
                {!!department?.phones &&
                department.phones.length > 0 &&
                department?.phones[0].phoneNotes
                  ? department?.phones[0].phoneNotes
                  : emptyValue}
              </Box>
            </Grid>
          </Grid>

          <Grid container sx={style.paddingBottom} spacing={2}>
            <Grid item xs={3}>
              <Typography sx={style.title}>Contact Name</Typography>
              <Box sx={style.value}>
                {!!department?.contacts &&
                department.contacts.length > 0 &&
                department?.contacts[0].name
                  ? department?.contacts[0]?.name
                  : emptyValue}
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Typography sx={style.title}>Contact Email</Typography>
              <Box sx={style.value}>
                {!!department?.contacts &&
                department.contacts.length > 0 &&
                department?.contacts[0].email
                  ? department?.contacts[0]?.email
                  : emptyValue}
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Typography sx={style.title}>Contact Number</Typography>
              <Box sx={style.value}>
                {!!department?.contacts &&
                department.contacts.length > 0 &&
                department?.contacts[0].phoneNumber
                  ? department?.contacts[0]?.phoneNumber
                  : emptyValue}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Typography sx={style.title}>Contact Title</Typography>
              <Box sx={style.value}>
                {!!department?.contacts &&
                department.contacts.length > 0 &&
                department?.contacts[0].title
                  ? department?.contacts[0]?.title
                  : emptyValue}
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={style.title}>Record Types</Typography>
            </Grid>
            {department?.recordTypes.map((p) => (
              <Grid item key={p.id}>
                <Typography sx={style.recordType}>{p.name}</Typography>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
