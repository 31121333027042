import useUserRole from '../../../../hooks/Users/useUserRole';
import UserRoleForm from '../UserRoleForm.tsx/UserRoleForm';

export default function AssignUserRole() {
  const { userRoleForm, roles, isSubmitting, isEditing } = useUserRole();
  return (
    <>
      <UserRoleForm
        formik={userRoleForm}
        roles={roles}
        isSubmitting={isSubmitting}
        isEditing={isEditing}
      />
    </>
  );
}
