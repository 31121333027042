import WizardFooter from './WizardFooter';
import WizardHeader from './WizardHeader';
import WizardStepTitle from '../../Models/Layout/wizard/WizardStepTitle';
import { Box, Divider } from '@mui/material';
import { cardContainer } from '../../assets/styles/appStyles';

export default function Wizard({
  isSaving,
  steps,
  saveLabel,
  onStepClicked,
  onNextClicked,
  onPreviousClicked,
  onSaveClicked,
  onCancelClicked,

  children,
}: {
  isSaving:boolean;
  steps: WizardStepTitle[];
  saveLabel?: string;
  onStepClicked: (step: WizardStepTitle) => void;
  onNextClicked: () => void;
  onPreviousClicked: () => void;
  onSaveClicked: () => void;
  onCancelClicked: () => void;
  children: any;
}) {
  return (
    <Box sx={cardContainer}>
      <Box>
        <WizardHeader steps={steps} stepClicked={onStepClicked} />
      </Box>
      <Divider />
      <Box p={2}>{children}</Box>
      <Divider />
      <WizardFooter
        isSaving={isSaving}
        isFirstStep={steps.length > 0 && steps[0].isCurrentStep}
        isLastStep={steps.length > 0 && steps[steps.length - 1].isCurrentStep}
        saveLabel={saveLabel || 'Submit'}
        nextClicked={onNextClicked}
        previousClicked={onPreviousClicked}
        saveClicked={onSaveClicked}
        cancelClicked={onCancelClicked}
      />
    </Box>
  );
}
