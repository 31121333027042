import { SxProps } from '@mui/material';

const panelHeader: SxProps = {
  bgcolor: '#F5F5F5',
  borderRadius: '8px 8px 0px 0px',
  fontWeight: '550',
  fontSize: '20px',
  fontStyle: 'normal',
  padding: '15px',
};

const panelContent: SxProps = {
  boxShadow:
    '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
  bgcolor: '#FFFFFF',
  borderRadius: '8px 8px 0px 0px',
  padding: '15px',
};

const styleClasses = {
  panelHeader,
  panelContent,
};

export default styleClasses;
