import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { buttonLink } from '../../../../assets/styles/appStyles';
import { cardContainer } from '../../../../assets/styles/appStyles';
import GenericInput from '../../../AddLocation/Components/UI/GenericInput';
import GenericSelectInput from '../../../AddLocation/Components/UI/GenericSelectInput';

export default function UserRoleForm({
  formik,
  roles,
  isSubmitting,
  isEditing,
}: {
  formik: any;
  roles: any[];
  isSubmitting: boolean;
  isEditing: boolean;
}) {
  return (
    <Box sx={cardContainer} p={2}>
      <form name="assign-user" onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={5} p={2}>
            <GenericInput
              name="email"
              label="Email"
              placeholder="Email"
              maxLength={80}
              inputMode="email"
              disabled={isEditing}
              value={formik.values.email}
              onChange={(e: any) => {
                formik.setFieldValue(`email`, e.target.value);
              }}
              errorMsg={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ''
              }
              hasError={
                formik.touched.email && formik.errors.email ? true : false
              }
              onBlur={() => {
                formik.setFieldTouched(`email`, true);
              }}
              required={true}
            />
          </Grid>
          <Grid item xs={5} p={2}>
            <GenericInput
              name="name"
              label="Name"
              placeholder="Name"
              maxLength={120}
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue(`name`, e.target.value);
              }}
              errorMsg={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : ''
              }
              hasError={
                formik.touched.name && formik.errors.name ? true : false
              }
              onBlur={() => {
                formik.setFieldTouched(`name`, true);
              }}
              required={true}
            />
          </Grid>
          <Grid item xs={5} p={2}>
            <GenericSelectInput
              name={`typeId`}
              label="Role"
              value={formik.values.roleId}
              onChange={(e: any) => {
                formik.setFieldValue(`roleId`, e);
              }}
              errorMsg={
                formik.touched.roleId && formik.errors.roleId
                  ? formik.errors.roleId
                  : ''
              }
              hasError={
                formik.touched.roleId && formik.errors.roleId ? true : false
              }
              onBlur={() => {
                formik.setFieldTouched(`roleId`, true);
              }}
              options={[{ id: '', name: 'None' }, ...roles]}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent={'end'}>
          <Grid item my={1}>
            <Button type="submit" variant="contained" sx={buttonLink}>
              {isSubmitting ? <CircularProgress size={28} /> : 'Save'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
