import { Grid, IconButton, ListItem, ListItemButton, ListItemText } from '@mui/material';
import Styles from './SideBarStyles';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { maxSideBarCharLength } from '../../../settings/appConfig';
import { SidebarItemModel } from '../../../Models/Layout/sidebarItemModel';
import { textPrimary } from '../../../assets/styles/appStyles';

export default function SideBarItem({
  item,
  handleClick,
}: {
  item: SidebarItemModel;
  handleClick: (item: SidebarItemModel) => void;
}) {
  const location = useLocation();
  const isActive = !!matchPath(location.pathname, item.navigateTo ?? '');
  const activeStyle = isActive ? Styles.activeSideBarButton : {};

  return (
    <ListItem key={item.title} disablePadding>
      <ListItemButton
        onClick={() => handleClick(item)}
        sx={{ ...Styles.sideBarButton, ...activeStyle }}
      >
        <Grid width={'500px'} container justifyContent="flex-end">
          <Grid item xs={12}>
            <Grid container alignItems={'center'}>
              {item.icon && (
                <IconButton color={isActive ? 'primary' : 'default'}>
                  {item.icon}
                </IconButton>
              )}
              <ListItemText
                data-testid="list-title"
                sx={isActive ? textPrimary : {}}
                primary={
                  (item.title?.length ?? 0) > maxSideBarCharLength
                    ? item.title
                        ?.substring(0, maxSideBarCharLength)
                        .concat('...')
                    : item.title
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </ListItemButton>
    </ListItem>
  );
}
