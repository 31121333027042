import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@mui/material';
import { ServePreferences } from '../../../../../../../Models/Departments/Details/ServePreferences';
import CheckboxInput from '../../../../../../AddLocation/Components/UI/CheckboxInput';
import { ExpandMore } from '@mui/icons-material';
import GenericInput from '../../../../../../AddLocation/Components/UI/GenericInput';
import { accordionSummaryText } from '../../../../../Styles/MainInformationTabStyles';

export default function DocumentInfo({
  servePreferences,
  isExpanded,
  handleAccordionChange,
}: {
  servePreferences: ServePreferences | undefined;
  isExpanded: (panel: string) => boolean;
  handleAccordionChange: (panel: string) => (event: React.SyntheticEvent, isExpanding: boolean) => void
}) {
  return (
    <Accordion
    expanded={isExpanded('DocumentInfo')}
    onChange={handleAccordionChange('DocumentInfo')}>
      <AccordionSummary
        sx={accordionSummaryText}
        aria-controls="Documents-content"
        id="Documents-header"
        expandIcon={<ExpandMore />}
      >
        Documents
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <CheckboxInput
                label="Authorization with Subpoena Required"
                keyName={`servePreferences.isAuthorizationWithSubpoenaRequired`}
                checked={servePreferences?.isAuthorizationWithSubpoenaRequired}
                onChange={(e: any) => {}}
                onBlur={() => {}}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12}>
              <CheckboxInput
                label="POA for Subpoena"
                keyName={`servePreferences.isPOAForSubpoena`}
                checked={servePreferences?.isPOAForSubpoena}
                onChange={(e: any) => {}}
                onBlur={() => {}}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12}>
              <CheckboxInput
                label="HIPPA Authorization Original"
                keyName={`servePreferences.isHIPPAAuthorizationOriginal`}
                checked={servePreferences?.isHIPPAAuthorizationOriginal}
                onChange={(e: any) => {}}
                onBlur={() => {}}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12}>
              <CheckboxInput
                label="Facility Specific Authorization"
                keyName={`servePreferences.isFacilitySpecificAuthorization`}
                checked={servePreferences?.isFacilitySpecificAuthorization}
                onChange={(e: any) => {}}
                onBlur={() => {}}
                disabled={true}
              />

              {servePreferences?.isFacilitySpecificAuthorization && (
                <GenericInput
                  name="facilitySpecificAuthorizationNotes"
                  label="Facility Specific Authorization Notes"
                  placeholder="Notes"
                  key={`servePreferences.facilitySpecificAuthorizationNotes`}
                  maxLength={150}
                  value={servePreferences?.facilitySpecificAuthorizationNotes}
                  onChange={(e: any) => {}}
                  onBlur={() => {}}
                  disabled={true}
                />
              )}
            </Grid>
          </Grid>

          <Grid item xs={6} pl={3}>
            <Grid item xs={12}>
              <CheckboxInput
                label="Accept LOR"
                keyName={`servePreferences.isAcceptLOR`}
                checked={servePreferences?.isAcceptLOR}
                onChange={(e: any) => {}}
                onBlur={() => {}}
                disabled={true}
              />

              {servePreferences?.isAcceptLOR ? (
                <GenericInput
                  name="acceptLORNotes"
                  label="Accept LOR Notes"
                  placeholder="Notes"
                  key={`servePreferences.acceptLORNotes`}
                  maxLength={150}
                  value={servePreferences?.acceptLORNotes}
                  onChange={(e: any) => {}}
                  onBlur={() => {}}
                  disabled={true}
                />
              ) : (
                ''
              )}
            </Grid>

            <Grid item xs={12}>
              <CheckboxInput
                label="SOA for Subpoena"
                keyName={`servePreferences.isSOAForSubpoena`}
                checked={servePreferences?.isSOAForSubpoena}
                onChange={(e: any) => {}}
                onBlur={() => {}}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12}>
              <CheckboxInput
                label="Copy Service"
                keyName={`servePreferences.isCopyService`}
                checked={servePreferences?.isCopyService}
                onChange={(e: any) => {}}
                onBlur={() => {}}
                disabled={true}
              />
              {servePreferences?.isCopyService ? (
                <GenericInput
                  name="CopyServiceNotes"
                  label="Copy Service Notes"
                  placeholder="Copy Service Notes"
                  maxLength={150}
                  value={servePreferences?.copyServiceNotes}
                  onChange={(e: any) => {}}
                  onBlur={() => {}}
                  disabled={true}
                />
              ) : (
                ''
              )}
            </Grid>

            <Grid item xs={12}>
              <CheckboxInput
                label="Additional Facility Specific Documentation"
                keyName={`servePreferences.isAdditionalFacilitySpecificDocumentation`}
                checked={
                  servePreferences?.isAdditionalFacilitySpecificDocumentation
                }
                onChange={(e: any) => {}}
                onBlur={() => {}}
                disabled={true}
              />
              {servePreferences?.isAdditionalFacilitySpecificDocumentation ? (
                <GenericInput
                  name="additionalFacilitySpecificDocumentationNotes"
                  label="Additional Facility Specific Documentation Notes"
                  placeholder="Additional Facility Specific Documentation Notes"
                  maxLength={150}
                  value={
                    servePreferences?.additionalFacilitySpecificDocumentationNotes
                  }
                  onChange={(e: any) => {}}
                  onBlur={() => {}}
                  disabled={true}
                />
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
