import { SxProps } from '@mui/material';
import { textWhite } from './appStyles';
import rectActive from '../images/rect-active.png';
import rectDisabled from '../images/rect-disabled.png';
import rectSuccess from '../images/rect-success.png';
import rectFirstActive from '../images/rect-first-active.png';
import rectFirstSuccess from '../images/rect-first-success.png';
import rectLastDisabled from '../images/rect-last-disabled.png';
import rectLastActive from '../images/rect-last-active.png';

const wizardTitle = (isCurrentStep?: boolean, isValid?: boolean) => {
  return isValid && !isCurrentStep
    ? { color: (theme: any) => theme.palette.success['600'] }
    : isCurrentStep
    ? { color: (theme: any) => theme.palette.primary['600'] }
    : textWhite;
};

const wizardTitleIndex = (isCurrentStep?: boolean) => {
  return {
    width: '20px',
    height: '20px',
    borderRadius: '20px',
    color: (theme: any) =>
      isCurrentStep ? theme.palette.common.white : theme.palette.neutral['300'],
    backgroundColor: (theme: any) =>
      isCurrentStep ? theme.palette.primary['400'] : theme.palette.common.white,
  };
};

const wizardTitleItem = (
  isCurrentStep?: boolean,
  isValid?: boolean,
  isFirstStep?: boolean,
  isLastStep?: boolean,
) => {
  let bgImage: any = '';
  if (isCurrentStep) {
    if (isFirstStep) {
      bgImage = rectFirstActive;
    } else if (isLastStep) {
      bgImage = rectLastActive;
    } else {
      bgImage = rectActive;
    }
  } else {
    if (isFirstStep) {
      if (isValid) {
        bgImage = rectFirstSuccess;
      }
    } else if (isLastStep) {
      bgImage = rectLastDisabled;
    } else {
      if (isValid) {
        bgImage = rectSuccess;
      } else {
        bgImage = rectDisabled;
      }
    }
  }
  return {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: '100% 100%',
    height: '42px',
    borderRadius: '0',
    margin: '0 -6px',
  };
};
export { wizardTitle, wizardTitleIndex, wizardTitleItem };
