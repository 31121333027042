import { FormControl, Grid, Typography } from '@mui/material';
import DepartmentDaysAvailable from '../../../../../Models/Departments/Details/departmentDaysAvailable';
import DepartmentServeMethodModel from '../../../../../Models/Departments/Details/departmentServeMethod';
import useFetchServeMethods from '../../../../../hooks/Locations/useFetchServeMethods';
import useFetchDays from '../../../../../hooks/Locations/useFetchDays';
import AppTimePicker from '../../../../AddLocation/Components/AppTimePicker';
import MultiSelectView from '../../../../AddLocation/Components/UI/MultiSelectView';
import {
  box,
  selectedBox,
  title,
} from '../../../../../assets/styles/appStyles';
import { useMemo } from 'react';
import style from '../departmentStyle';
import MultipleCheckBoxes from '../../../../AddLocation/Components/UI/MultipleCheckBoxes';
import { emptyValue } from '../../../../../settings/appConfig';
import ComponentLoader from '../../../../../common/ComponentLoader/componentLoader';

export default function ServeInformation({
  departmentDaysAvailable,
  departmentServeMethods,
  portalNote,
}: {
  departmentDaysAvailable: DepartmentDaysAvailable[];
  departmentServeMethods: DepartmentServeMethodModel[];
  portalNote?: string;
}) {
  const { serveMethods, serveMethodLoading } = useFetchServeMethods();
  const { days, daysLoading } = useFetchDays();

  const selectedDays = useMemo(
    () =>
      days
        .filter((o) => departmentDaysAvailable.some((day) => day.dayId == o.id))
        .map((obj) => ({
          name: obj.name,
          departmentDayAvailable: departmentDaysAvailable?.find(
            (day) => day.dayId === obj.id,
          ),
        })),
    [departmentDaysAvailable, days],
  );

  return (
    <ComponentLoader isOpen={serveMethodLoading || daysLoading}>
      {departmentDaysAvailable.length > 0 ? (
        <Grid container sx={style.days}>
          <Grid xs={12} item>
            <Typography sx={title}>Selected days</Typography>
            <Grid item>
              <MultiSelectView
                options={days}
                values={departmentDaysAvailable?.map((o: any) => o.dayId)}
              />
            </Grid>
          </Grid>
          {selectedDays.map((day, index) => {
            return (
              <Grid
                container
                spacing={1}
                key={day.departmentDayAvailable?.dayId ?? '' + index}
                alignItems={'center'}
                ml={1}
                mt={1}
              >
                <Grid item xs={1} alignSelf={'end'} sx={selectedBox}>
                  {day.name}
                </Grid>

                <Grid item xs={3}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography align="left">Serve Hours</Typography>
                    </Grid>
                    <Grid item xs={12} pt={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <AppTimePicker
                            disabled
                            label="Serve Hours"
                            fieldLabel="From"
                            value={
                              new Date(
                                `2000-01-01T${day?.departmentDayAvailable?.serveHours.from}`,
                              )
                            }
                            keyName={''}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <AppTimePicker
                            disabled
                            label="Serve Hours"
                            fieldLabel="To"
                            value={
                              new Date(
                                `2000-01-01T${day?.departmentDayAvailable?.serveHours.to}`,
                              )
                            }
                            keyName={''}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={3}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography align="left">
                        Pick up and copy hours
                      </Typography>
                    </Grid>
                    <Grid item xs={12} pt={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <AppTimePicker
                            disabled
                            label="Pick up and copy hours"
                            fieldLabel="From"
                            value={
                              new Date(
                                `2000-01-01T${day?.departmentDayAvailable?.pickupAndCopyHours.from}`,
                              )
                            }
                            keyName={''}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <AppTimePicker
                            disabled
                            label="Pick up and copy hours"
                            fieldLabel="To"
                            value={
                              new Date(
                                `2000-01-01T${day?.departmentDayAvailable?.pickupAndCopyHours.to}`,
                              )
                            }
                            keyName={''}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={3}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography align="left">Best time to call</Typography>
                    </Grid>
                    <Grid item xs={12} pt={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <AppTimePicker
                            disabled
                            label="Best time to call"
                            fieldLabel="From"
                            value={
                              new Date(
                                `2000-01-01T${day?.departmentDayAvailable?.bestTimeToCall.from}`,
                              )
                            }
                            keyName={''}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <AppTimePicker
                            disabled
                            label="Best time to call"
                            fieldLabel="To"
                            value={
                              new Date(
                                `2000-01-01T${day?.departmentDayAvailable?.bestTimeToCall.to}`,
                              )
                            }
                            keyName={''}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={2}>
                  <Typography align="left">Timezone</Typography>
                  <Typography sx={box} mt={1} align="left">
                    {day?.departmentDayAvailable?.timeZone ?? emptyValue}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <></>
      )}
      {serveMethods.length > 0 ? (
        <Grid container spacing={2} mt={2}>
          <Grid xs={12} item>
            <MultipleCheckBoxes
              options={serveMethods}
              keyName={''}
              label="Serve Method"
              selectedValues={departmentServeMethods?.map(
                (o: any) => o.serveMethodId,
              )}
              toggleChange={() => {}}
              disable={true}
            />
          </Grid>
          {portalNote && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography align="left">Portal Note</Typography>

                <Typography sx={box} mt={1} align="left">
                  {portalNote ?? emptyValue}
                </Typography>
              </FormControl>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container spacing={2} mt={2} p={2}>
          No serve Methods
        </Grid>
      )}
    </ComponentLoader>
  );
}
