import { Grid } from '@mui/material';
import CustodianType from '../../../Models/Locations/custodianType';
import Phones from './Phones';
import PhoneType from '../../../Models/Locations/phoneType';
import Panel from '../../../components/UI/Panel';
import Emails from './Emails';
import GenericInput from './UI/GenericInput';
import GenericSelectInput from './UI/GenericSelectInput';
import CustodianFieldSearchInput from './CustodianFieldSearchInput';

export default function CustodianBasicDetails({
  formik,
  custodianTypes,
  phoneTypes,
  showPanel,
}: {
  formik: any;
  custodianTypes: CustodianType[];
  phoneTypes: PhoneType[];
  showPanel: boolean;
}) {
  const content = (
    <>
      <Grid spacing={2} mx={1} px={1} container>
        <Grid item xs={4} px={2}>
          <CustodianFieldSearchInput
            duplicateErrorMessage="The name you have entered is slightly similar to some of the name of existing custodians. This could indicates that the custodian you are trying to create may already exist."
            label="Name"
            searchPropertyName="name"
            name="name"
            placeholder="Custodian Name"
            maxLength={120}
            value={formik.values.name}
            formErrorMessage={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : ''
            }
            hasFormError={
              formik.touched.name && formik.errors.name ? true : false
            }
            onBlur={() => {
              formik.setFieldTouched(`name`, true);
            }}
            onChange={(e: any) => {
              formik.setFieldValue(`name`, e.target.value);
            }}
            required={true}
          />
        </Grid>

        <Grid item xs={4} px={2}>
          <GenericSelectInput
            name={`typeId`}
            label="Custodian Type"
            value={formik.values.typeId}
            onChange={(e: any) => {
              formik.setFieldValue(`typeId`, e);
            }}
            errorMsg={
              formik.touched.typeId && formik.errors.typeId
                ? formik.errors.typeId
                : ''
            }
            hasError={
              formik.touched.typeId && formik.errors.typeId ? true : false
            }
            onBlur={() => {
              formik.setFieldTouched(`typeId`, true);
            }}
            options={custodianTypes}
            required={true}
          />
        </Grid>

        <Grid item xs={4} px={2}>
          <GenericInput
            name="portalUrl"
            label="Portal Url"
            placeholder="Portal Url"
            maxLength={150}
            inputMode="url"
            value={formik.values.portalUrl}
            onChange={(e: any) => {
              formik.setFieldValue(`portalUrl`, e.target.value);
            }}
            errorMsg={
              formik.touched.portalUrl && formik.errors.portalUrl
                ? formik.errors.portalUrl
                : ''
            }
            hasError={
              formik.touched.portalUrl && formik.errors.portalUrl ? true : false
            }
            onBlur={() => {
              formik.setFieldTouched(`portalUrl`, true);
            }}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} my={2} mx={1} px={1} container>
        <Emails formik={formik} />
      </Grid>
      <Grid spacing={2} mx={1} px={1} container>
        <Phones phoneTypes={phoneTypes} formik={formik} />
      </Grid>
      <Grid spacing={2} mx={1} px={1} container></Grid>
    </>
  );
  return (
    <>
      {showPanel === true && (
        <Panel headerText="Basic Details">{content}</Panel>
      )}
      {!showPanel && content}
    </>
  );
}

CustodianBasicDetails.defaultProps = {
  showPanel: true,
};
