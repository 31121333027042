import PhoneType from '../../Models/Locations/phoneType';
const phoneDashSymbol = '-';

const usePhonesHelperMethods = () => {
  function formatPhoneNumberOnChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const phoneNumber = input.value;
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    input.value = formattedPhoneNumber;
  }

  function formatPhoneNumber(phoneNumber: string): string {
    // Remove any existing dashes or spaces from the phone number string
    phoneNumber = phoneNumber.replace(/[-\s]/g, '');

    // Split the phone number string into groups of 3 digits
    const groups = phoneNumber.match(/.{1,3}/g);

    if (groups && groups.length > 2) {
      var firstTwoParts = groups.slice(0, 2);
      var restOfNumbers = groups.slice(2);
      var thirdPart = restOfNumbers.join('');
      var newarray = firstTwoParts.concat(thirdPart);
      return newarray ? newarray.join(phoneDashSymbol) : phoneNumber;
    }
    // Join the groups with dashes to create the formatted phone number string
    return groups ? groups.join(phoneDashSymbol) : phoneNumber;
  }

  const isMainPhoneDisabled = function (
    currentPhone: { isMainPhone: boolean; phoneTypeId: number },
    currentIndex: number,
    phoneTypes: PhoneType[],
    formik: any,
  ) {
    var faxType = phoneTypes.find((t) => t.name.toLowerCase() === 'fax');
    var isFax = faxType && faxType.id === currentPhone.phoneTypeId;

    if (currentPhone.isMainPhone) {
      if (isFax) {
        const newItems = [...formik.values.phones];
        newItems[currentIndex].isMainPhone = false;
        formik.setFieldValue('phones', [...newItems]);
        return true;
      }
      return false;
    } else {
      var mainPhoneIndex = formik.values.phones.findIndex(
        (p: { isMainPhone: boolean }) => p.isMainPhone,
      );
      if (mainPhoneIndex !== -1 || isFax) {
        return true;
      }
      return false;
    }
  };

  return {
    formatPhoneNumberOnChange,
    isMainPhoneDisabled,
  };
};
export default usePhonesHelperMethods;
