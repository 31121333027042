import { Box, TableCell, TableHead, TableRow } from '@mui/material';
import Styles from '../Styles/ListCustodiansStyles';
import ResearchCustodianListColumn from '../../../Models/custodian/research-custodian-list-column';
import GenericInput from '../../AddLocation/Components/UI/GenericInput';
import activeUpIcon from '../../../assets//Icons/ActiveUp.svg';
import activeDownIcon from '../../../assets//Icons/ActiveDown.svg';

import upIcon from '../../../assets/Icons/Up.svg';
import downIcon from '../../../assets/Icons/Down.svg';

export default function ResearchCustodiansListTableHeader({
  columns,
  searchValues,
  updateSearchValue,
  exclusionFilters,
  updateSortValue,
}: {
  columns: ResearchCustodianListColumn[];
  searchValues: any;
  updateSearchValue: (key: string, value: string) => void;
  exclusionFilters: string[];
  updateSortValue: (key: string, id: number) => void;
}) {
  return (
    <>
      <TableHead>
        <TableRow>
          {columns
            .sort((a, b) => a.id - b.id)
            .map((col) => (
              <TableCell
                key={col.name}
                sx={{
                  ...Styles.tableHeaderStyles,
                  minWidth: col.width || 100,
                }}
              >
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  sx={{ cursor: col.hasSort ? 'pointer' : 'auto' }}
                  onClick={() =>
                    updateSortValue(col.sortPropertyName ?? '', col.id)
                  }
                >
                  {col.name}
                  {col.hasSort && (
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      marginLeft={'5px'}
                      data-testid={`sort-${col.name}`}
                    >
                      <img
                        src={
                          searchValues.sortProperties.find(
                            (p: any) => p.propertyName === col.sortPropertyName,
                          )?.sortDirection === 'asc'
                            ? activeUpIcon
                            : upIcon
                        }
                        alt="sort-up-icon"
                      />
                      <img
                        src={
                          searchValues.sortProperties.find(
                            (p: any) => p.propertyName === col.sortPropertyName,
                          )?.sortDirection === 'desc'
                            ? activeDownIcon
                            : downIcon
                        }
                        alt="sort-down-icon"
                      />
                    </Box>
                  )}
                </Box>
                {!exclusionFilters.includes(col.fieldName) ? (
                  <Box pt={1}>
                    <GenericInput
                      name={col.fieldName}
                      label={`Search by ${col.name}`}
                      maxLength={120}
                      placeholder=""
                      value={searchValues[col.fieldName] || ''}
                      onChange={(e: any) => {
                        updateSearchValue(col.fieldName, e.target.value);
                      }}
                      errorMsg=""
                      hasError={false}
                      onBlur={() => {}}
                    />
                  </Box>
                ) : (
                  <Box mt={7.5}></Box>
                )}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    </>
  );
}
