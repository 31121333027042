import {
  TableCell,
  TableRow,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import Styles from '../Styles/ListCustodiansStyles';
import ResearchCustodianListColumn from '../../../Models/custodian/research-custodian-list-column';
import { cellContainer } from './ResearchStyles';

export default function ResearchCustodiansListTableRow({
  row,
  columns,
}: {
  row: any;
  columns: ResearchCustodianListColumn[];
}) {
  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 1200,
    },
  });

  return (
    <TableRow sx={Styles.tableRowStyles}>
      {columns.map((col) => (
        <TableCell
          key={col.id}
          sx={{
            ...cellContainer,
            minWidth: col.width,
          }}
        >
          {col.maxDisplayLength ? (
            <CustomWidthTooltip
              title={
                <Typography style={{ whiteSpace: 'pre-line' }}>
                  {row ? row[col.fieldName] : ''}
                </Typography>
              }
            >
              <Typography sx={Styles.custodianTableCellStyles}>
                {row ? row[col.fieldName]?.slice(0, col.maxDisplayLength) : ''}
                {row[col.fieldName]?.length > col.maxDisplayLength ? '...' : ''}
              </Typography>
            </CustomWidthTooltip>
          ) : (
            <Typography
              sx={Styles.custodianTableCellStyles}
              dangerouslySetInnerHTML={{
                __html: row
                  ? row[col.fieldName]?.replace(/\r?\n/g, '<br>')
                  : '',
              }}
            />
          )}
        </TableCell>
      ))}
      <TableCell />
    </TableRow>
  );
}
