import UsersList from './Components/UsersList/UsersList';
import { Box, Button, Grid } from '@mui/material';
import { buttonLink } from '../../assets/styles/appStyles';
import useFetchUsers from '../../hooks/Users/useFetchUsers';
import { cardContainer } from '../../assets/styles/appStyles';
import { appRoutesPaths } from '../../settings/appConfig';
import { Link } from 'react-router-dom';

export default function ManageUsers() {
  const { users, isLoading, getAll } = useFetchUsers();

  return (
    <Box sx={cardContainer} p={2}>
      <Grid container justifyContent={'end'}>
        <Grid item xs={12} my={1} mb={'10px'}>
          <Link to={appRoutesPaths.addUser}>
            <Button sx={buttonLink}>Assign User Role</Button>
          </Link>
        </Grid>
      </Grid>
      <UsersList isLoading={isLoading} users={users} getAll={getAll} />
    </Box>
  );
}
