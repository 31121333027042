import { Grid } from '@mui/material';
import InsertDepartmentModel from '../../../../Models/Departments/InsertDepartmentModel';
import GenericInput from '../UI/GenericInput';
import GenericSelectInput from '../UI/GenericSelectInput';

export default function DepartmentAddressDetails({
  form,
  department,
  formKeyName,
  departmentIndex,
  states,
  timeZones,
  asCustodianAddress,
}: {
  department: InsertDepartmentModel;
  form: any;
  formKeyName: string;
  departmentIndex: number;
  states: any[];
  timeZones: any[];
  asCustodianAddress?: boolean;
}) {
  return (
    <Grid spacing={2} mx={1} px={1} container>
      <Grid item xs={4} px={2}>
        <GenericInput
          disabled={asCustodianAddress}
          name={`${formKeyName}.${departmentIndex}.addressInfo.addressDetails`}
          label="Address"
          maxLength={120}
          placeholder="Address"
          value={department.addressInfo.addressDetails}
          onChange={(e: any) => {
            form.setFieldValue(
              `${formKeyName}.${departmentIndex}.addressInfo.addressDetails`,
              e.target.value,
            );
          }}
          errorMsg={
            form.touched[formKeyName] &&
            form.touched[formKeyName][departmentIndex] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'][
              'addressDetails'
            ] &&
            form.errors[formKeyName] &&
            form.errors[formKeyName][departmentIndex] &&
            form.errors[formKeyName][departmentIndex]['addressInfo'] &&
            form.errors[formKeyName][departmentIndex]['addressInfo'][
              'addressDetails'
            ]
              ? form.errors[formKeyName][departmentIndex]['addressInfo'][
                  'addressDetails'
                ]
              : ''
          }
          hasError={
            form.touched[formKeyName] &&
            form.touched[formKeyName][departmentIndex] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'][
              'addressDetails'
            ] &&
            form.errors[formKeyName] &&
            form.errors[formKeyName][departmentIndex] &&
            form.errors[formKeyName][departmentIndex]['addressInfo'] &&
            form.errors[formKeyName][departmentIndex]['addressInfo'][
              'addressDetails'
            ]
              ? true
              : false
          }
          onBlur={() => {
            form.setFieldTouched(
              `${formKeyName}.${departmentIndex}.addressInfo.addressDetails`,
              true,
            );
          }}
          required={true}
        />
      </Grid>
      <Grid item xs={4} px={2}>
        <GenericInput
          disabled={asCustodianAddress}
          name={`${formKeyName}.${departmentIndex}.addressInfo.city`}
          label="City"
          placeholder="City"
          maxLength={40}
          value={department.addressInfo.city}
          onChange={(e: any) => {
            form.setFieldValue(
              `${formKeyName}.${departmentIndex}.addressInfo.city`,
              e.target.value,
            );
          }}
          errorMsg={
            form.touched[formKeyName] &&
            form.touched[formKeyName][departmentIndex] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'] &&
            form.touched[formKeyName][departmentIndex]['addressInfo']['city'] &&
            form.errors[formKeyName] &&
            form.errors[formKeyName][departmentIndex] &&
            form.errors[formKeyName][departmentIndex]['addressInfo'] &&
            form.errors[formKeyName][departmentIndex]['addressInfo']['city']
              ? form.errors[formKeyName][departmentIndex]['addressInfo']['city']
              : ''
          }
          hasError={
            form.touched[formKeyName] &&
            form.touched[formKeyName][departmentIndex] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'] &&
            form.touched[formKeyName][departmentIndex]['addressInfo']['city'] &&
            form.errors[formKeyName] &&
            form.errors[formKeyName][departmentIndex] &&
            form.errors[formKeyName][departmentIndex]['addressInfo'] &&
            form.errors[formKeyName][departmentIndex]['addressInfo']['city']
              ? true
              : false
          }
          onBlur={() => {
            form.setFieldTouched(
              `${formKeyName}.${departmentIndex}.addressInfo.city`,
              true,
            );
          }}
          required={true}
        />
      </Grid>
      <Grid item xs={4} px={2}>
        <GenericSelectInput
          disabled={asCustodianAddress}
          name={`${formKeyName}.${departmentIndex}.addressInfo.stateId`}
          label="State"
          value={department.addressInfo.stateId ?? ''}
          onChange={(value: string) => {
            form.setFieldValue(
              `${formKeyName}.${departmentIndex}.addressInfo.stateId`,
              value,
            );
          }}
          errorMsg={
            form.touched[formKeyName] &&
            form.touched[formKeyName][departmentIndex] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'][
              'stateId'
            ] &&
            form.errors[formKeyName] &&
            form.errors[formKeyName][departmentIndex] &&
            form.errors[formKeyName][departmentIndex]['addressInfo'] &&
            form.errors[formKeyName][departmentIndex]['addressInfo']['stateId']
              ? form.errors[formKeyName][departmentIndex]['addressInfo'][
                  'stateId'
                ]
              : ''
          }
          hasError={
            form.touched[formKeyName] &&
            form.touched[formKeyName][departmentIndex] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'][
              'stateId'
            ] &&
            form.errors[formKeyName] &&
            form.errors[formKeyName][departmentIndex] &&
            form.errors[formKeyName][departmentIndex]['addressInfo'] &&
            form.errors[formKeyName][departmentIndex]['addressInfo']['stateId']
              ? true
              : false
          }
          onBlur={() => {
            form.setFieldTouched(
              `${formKeyName}[${departmentIndex}].addressInfo.stateId`,
              true,
            );
          }}
          options={states}
          required={true}
        />
      </Grid>
      <Grid item xs={4} px={2}>
        <GenericInput
          disabled={asCustodianAddress}
          name={`${formKeyName}.${departmentIndex}.addressInfo.postCode`}
          label="Zip / Postal Code"
          placeholder="Zip / Postal Code"
          maxLength={5}
          inputMode="numeric"
          value={department.addressInfo.postCode}
          onChange={(e: any) => {
            form.setFieldValue(
              `${formKeyName}.${departmentIndex}.addressInfo.postCode`,
              e.target.value,
            );
          }}
          errorMsg={
            form.touched[formKeyName] &&
            form.touched[formKeyName][departmentIndex] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'][
              'postCode'
            ] &&
            form.errors[formKeyName] &&
            form.errors[formKeyName][departmentIndex] &&
            form.errors[formKeyName][departmentIndex]['addressInfo'] &&
            form.errors[formKeyName][departmentIndex]['addressInfo']['postCode']
              ? form.errors[formKeyName][departmentIndex]['addressInfo'][
                  'postCode'
                ]
              : ''
          }
          hasError={
            form.touched[formKeyName] &&
            form.touched[formKeyName][departmentIndex] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'][
              'postCode'
            ] &&
            form.errors[formKeyName] &&
            form.errors[formKeyName][departmentIndex] &&
            form.errors[formKeyName][departmentIndex]['addressInfo'] &&
            form.errors[formKeyName][departmentIndex]['addressInfo']['postCode']
              ? true
              : false
          }
          onBlur={() => {
            form.setFieldTouched(
              `${formKeyName}.${departmentIndex}.addressInfo.postCode`,
              true,
            );
          }}
          required={true}
        />
      </Grid>

      <Grid item xs={4} px={2}>
        <GenericSelectInput
          disabled={asCustodianAddress}
          name={`${formKeyName}.${departmentIndex}.addressInfo.timeZoneId`}
          label="Timezone"
          value={department.addressInfo.timeZoneId ?? ''}
          onChange={(value: string) => {
            form.setFieldValue(
              `${formKeyName}.${departmentIndex}.addressInfo.timeZoneId`,
              value,
            );
          }}
          errorMsg={
            form.touched[formKeyName] &&
            form.touched[formKeyName][departmentIndex] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'][
              'timeZoneId'
            ] &&
            form.errors[formKeyName] &&
            form.errors[formKeyName][departmentIndex] &&
            form.errors[formKeyName][departmentIndex]['addressInfo'] &&
            form.errors[formKeyName][departmentIndex]['addressInfo'][
              'timeZoneId'
            ]
              ? form.errors[formKeyName][departmentIndex]['addressInfo'][
                  'timeZoneId'
                ]
              : ''
          }
          hasError={
            form.touched[formKeyName] &&
            form.touched[formKeyName][departmentIndex] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'] &&
            form.touched[formKeyName][departmentIndex]['addressInfo'][
              'timeZoneId'
            ] &&
            form.errors[formKeyName] &&
            form.errors[formKeyName][departmentIndex] &&
            form.errors[formKeyName][departmentIndex]['addressInfo'] &&
            form.errors[formKeyName][departmentIndex]['addressInfo'][
              'timeZoneId'
            ]
              ? true
              : false
          }
          onBlur={() => {
            form.setFieldTouched(
              `${formKeyName}[${departmentIndex}].addressInfo.timeZoneId`,
              true,
            );
          }}
          options={timeZones}
        />
      </Grid>
    </Grid>
  );
}
