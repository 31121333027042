import { useEffect, useCallback } from 'react';
import PhoneType from '../../Models/Locations/phoneType';
import useHttp from '../http/useHttp';

export default function useFetchPhoneTypes() {
  const { getDataResponse, getData } = useHttp();

  const init = useCallback(async () => {
    await getData('lookup/getphonetypes');
  }, []);

  let phoneTypes: PhoneType[] = getDataResponse?.data ?? [];

  useEffect(() => {
    init();
  }, [init]);

  return { phoneTypes };
}
