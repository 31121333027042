import useFetchRoles from '../Roles/useFetchRoles';
import useUserRoleForm from './form/useUserRoleForm';
import useFetchUserRole from './useFetchUserRole';
import usePostUserRole from './usePostUserRole';
import { useParams } from 'react-router';

export default function useUserRole() {
  const { id } = useParams();

  const { roles } = useFetchRoles();
  const { userRole } = useFetchUserRole(id);
  const { save, isSubmitting } = usePostUserRole(id);
  const { userRoleForm } = useUserRoleForm({ roles, onSubmit: save, userRole });

  return { userRoleForm, roles, isSubmitting, isEditing: Boolean(id) };
}
