import { Box, Grid, Typography } from '@mui/material';
import MultipleCheckBoxes from '../../../AddLocation/Components/UI/MultipleCheckBoxes';
import {
  detailsTitle,
  detailsValue,
} from '../../../../assets/styles/appStyles';
import CheckboxInput from '../../../AddLocation/Components/UI/CheckboxInput';
import { emptyValue } from '../../../../settings/appConfig';

export default function BillingInfoInvoicingDetails({
  invoiceBeforeRecords,
  billingInfoInvoicingPaymentMethods,
  paymentMethods,
}: {
  paymentMethods: any[];
  invoiceBeforeRecords: boolean;
  billingInfoInvoicingPaymentMethods: string[];
}) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <MultipleCheckBoxes
          options={paymentMethods}
          keyName={''}
          label="Invoicing payment method"
          selectedValues={billingInfoInvoicingPaymentMethods}
          toggleChange={() => {}}
          labelStyle={detailsTitle}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography sx={detailsTitle}>Invoicing</Typography>
        <Box sx={detailsValue}>
          <CheckboxInput
            keyName=""
            label="Invoice before records"
            onBlur={() => {}}
            onChange={() => {}}
            checked={invoiceBeforeRecords}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
