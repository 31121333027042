import { createTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

export const colors = {
  primary: {
    '50': '#fff7ed',
    '75': '#faf0e8',
    '100': '#ffedd5',
    '200': '#fed7aa',
    '300': '#fdba74',
    '400': '#f5831f',
    '500': '#f97316',
    '600': '#ea580c',
    '700': '#c2410c',
    '800': '#9a3412',
    '900': '#7c2d12',
  },
  error: {
    '50': '#fef2f2',
    '100': '#fee2e2',
    '200': '#fecaca',
    '300': '#fca5a5',
    '400': '#f87171',
    '500': '#ef4444',
    '600': '#dc2626',
    '700': '#b91c1c',
    '800': '#991b1b',
    '900': '#7f1d1d',
  },
  success: {
    '50': '#f0fdf4',
    '100': '#dcfce7',
    '200': '#bbf7d0',
    '300': '#86efac',
    '400': '#4ade80',
    '500': '#22c55e',
    '600': '#16a34a',
    '700': '#15803d',
    '800': '#166534',
    '900': '#14532d',
  },
  warning: {
    '50': '#fefce8',
    '100': '#fef9c3',
    '200': '#fef08a',
    '300': '#fde047',
    '400': '#facc15',
    '500': '#eab308',
    '600': '#ca8a04',
    '700': '#a16207',
    '800': '#854d0e',
    '900': '#713f12',
  },
  background: {
    paper: '#ffffff',
  },
  common: {
    black: '#000000',
    white: '#ffffff',
  },
  neutral: {
    '50': '#fafafa',
    '100': '#f5f5f5',
    '200': '#e5e5e5',
    '300': '#d4d4d4',
    '400': '#a3a3a3',
    '500': '#737373',
    '600': '#525252',
    '700': '#404040',
    '800': '#262626',
    '900': '#171717',
  },
};
export const lightTheme = createTheme({
  components: {
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        message: {
          padding: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          margin: '0px !important',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: colors.primary[400],
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.neutral[300],
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: 'none',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '50px',
          borderRadius: '6px',
          '&.Mui-disabled': {
            color: colors.neutral[400],
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled .MuiSvgIcon-root': {
            color: colors.neutral[400],
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-disabled .MuiSvgIcon-root': {
            color: colors.neutral[300],
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          marginBottom: '.5em',
          border: `1px solid ${colors.neutral[100]} !important`,
          borderRadius: '15px !important',
          ':before': {
            display: 'none !important',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '6px',
          padding: '12px 20px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 10,
          borderColor: '#fff',
          borderStyle: 'solid',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: ({ ownerState }) => ({
          ...(ownerState &&
            ownerState['data-style'] === 'outlined' && {
              display: 'none',
            }),
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState &&
            ownerState['data-style'] === 'outlined' && {
              border: `2px solid ${colors.neutral[200]}`,
              borderRadius: '5px',
              marginRight: '10px',
              fontWeight: 600,
              textTransform: 'none',
              fontSize: '16px',
              padding: '0px 25px',
              ...(ownerState.selected === true && {
                backgroundColor: colors.primary[400],
                borderColor: colors.primary[400],
                color: `${colors.common.white} !important`,
              }),
            }),
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: colors.neutral[100],
          borderRadius: '10px',
          minHeight: 'auto !important',
          '& .MuiAccordionSummary-content': {
            margin: '10px 0px !important',
          },
          '& .Mui-expanded': {
            margin: '15px 0px !important',
          },
        }),
      },
    },
  },
  typography: {
    fontFamily: `"Proxima Nova"`,
  },
  palette: {
    mode: 'light',
    ...colors,
    tonalOffset: 0.2,
  },
});
export const drawerWidth = 250;

declare module '@mui/material/styles' {
  interface Palette {
    neutral: ColorPartial;
  }
  interface PaletteColor extends ColorPartial {}
}
