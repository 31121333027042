import { useMemo } from 'react';
import { DepartmentFile } from '../../../../../../../Models/Departments/Details/file';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

export default function DepartmentFilePreview({
  file,
  openDialog,
  closeDialog,
  fileUrl,
}: {
  file: DepartmentFile;
  openDialog: boolean;
  closeDialog: () => void;
  fileUrl: string;
}) {
  const fileExtension = useMemo(
    () => file.fileName?.split('.').pop()?.toLowerCase(),
    [file.fileName],
  );

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      maxWidth="xl"
      fullWidth
      PaperProps={{ sx: { height: '100%' } }}
    >
      <DialogContent>
        {fileExtension?.toLowerCase() == 'pdf' ? (
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={[{ uri: fileUrl, fileType: fileExtension }]}
            prefetchMethod="GET"
          />
        ) : (
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={[{ uri: fileUrl }]}
            prefetchMethod="GET"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
