import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import {
  ExpandMore,
  CancelOutlined,
  SaveOutlined,
  Edit,
} from '@mui/icons-material';
import CustodianBillingInfo from '../../../../Models/custodian/custodian-billingInfo';
import BillingInfoInvoicingDetails from './BillingInfoInvoicingDetails';
import BillingInfoFeesDetails from './BillingInfoFeesDetails';
import BillingInfoPayeeDetails from './BillingInfoPayeeDetails';
import useFetchPaymentMethods from '../../../../hooks/Locations/useFetchPaymentMethods';
import { useState, MouseEvent, useEffect, useMemo } from 'react';

import {
  accordionSummaryText,
  cancelIcon,
  cancelIconText,
  iconContainer,
  saveIcon,
  saveIconText,
} from '../../Styles/MainInformationTabStyles';
import useBillingInfoForm from '../../../../hooks/Locations/addLocation/useBillingInfoForm';
import useFetchStates from '../../../../hooks/Locations/useFetchStates';
import { FormikProps } from 'formik';
import CustodianDetailsViewModel from '../../../../Models/custodian/custodian-details-viewmodel';
import useEditCustodianBillingInfo from '../../../../hooks/Locations/edit/useEditCustodianBillingInfo';
import useAuth from '../../../../hooks/context/useAuth';
import { appActions } from '../../../../settings/appConfig';
import BillingInfo from '../../../AddLocation/Components/BillingInfo';
import useUI from '../../../../hooks/context/useUI';

export default function BillingInfoDetails({
  custodian,
  setCustodianData,
}: {
  custodian?: CustodianDetailsViewModel;
  setCustodianData: any;
}) {
  const billingInfoSections: string[] = ['Invoicing', 'Fees', 'Payee'];
  const [editModes, setEditMode] = useState<boolean>(false);
  const { showSnackbar } = useUI();
  const [currentBillingInfo, setCurrentBillingInfo] = useState<
    CustodianBillingInfo | undefined
  >(custodian?.billingInformation);

  const [editLoading, setEditLoading] = useState(false);

  const { paymentMethods } = useFetchPaymentMethods();
  const [expandedPanel, setExpandedPanel] =
    useState<string>(billingInfoSections[0]);

  const { states } = useFetchStates();

  const { billingInfoForm } = useBillingInfoForm({
    states: states,
  });
  const { edit } = useEditCustodianBillingInfo();
  const { auth } = useAuth();
  const canEdit = useMemo(() => {
    return auth?.actions.includes(appActions.editBillingInfo);
  }, [auth?.actions]);

  const billingFormValue = useMemo(() => {
    if (currentBillingInfo && states.length > 0) {
      const values = {
        payeeName: currentBillingInfo?.payeeName,
        payeeAddress: currentBillingInfo?.payeeAddress,
        payeeCity: currentBillingInfo?.payeeCity,
        payeeContact: currentBillingInfo?.payeeContact,
        payeePostCode: currentBillingInfo?.payeePostCode,
        payeeStateId: currentBillingInfo?.payeeStateId,
        processingFeesSubpoena: currentBillingInfo?.processingFeesSubpoena,
        processingFeesAuthorization:
          currentBillingInfo?.processingFeesAuthorization,
        initialProcessingFeeNotes:
          currentBillingInfo?.initialProcessingFeeNotes,
        invoiceBeforeRecords: currentBillingInfo?.invoiceBeforeRecords,
        processingFeesMethods:
          currentBillingInfo?.billingInfoProcessingFeesMethods ?? [],
        invoicePaymentMethods:
          currentBillingInfo.billingInfoInvoicingPaymentMethods ?? [],
        id: currentBillingInfo.id,
      };
      return values;
    } else return null;
  }, [currentBillingInfo, states]);

  useEffect(() => {
    if (billingFormValue != null) {
      (billingInfoForm as FormikProps<any>).setValues({ ...billingFormValue });
    }
  }, [billingFormValue]);

  const togglePanel = (panel: string) => (_: any, isExpanding: boolean) => {
    if(isExpanding)
    setExpandedPanel(panel);
    else
    setExpandedPanel('');
  };

  const isExpanded = (panel: string) => 
  {
    return expandedPanel === panel;
  };

  const handelEditMode = (event: any) => {
    event.stopPropagation();
    setEditMode(true);
  };

  const handelCancelMode = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setEditMode(false);

    billingInfoForm.setValues(billingFormValue);
  };

  const handleSaveMode = async (event: MouseEvent<HTMLButtonElement>) => {
    billingInfoForm.submitForm();
    const errors = await billingInfoForm.validateForm(billingInfoForm.values);
    if (Object.keys(errors).length > 0) {
      showSnackbar(['please fix validation errors first'], 'error');
      return;
    }
    event.stopPropagation();
    if ((billingInfoForm as FormikProps<any>).isValid) {
      const billingInfo: CustodianBillingInfo = {
        ...billingInfoForm.values,
        billingInfoProcessingFeesMethods:
          billingInfoForm.values.processingFeesMethods,
        billingInfoInvoicingPaymentMethods: [
          ...billingInfoForm.values.invoicePaymentMethods,
        ],
        custodianId: currentBillingInfo?.custodianId,
        payeeStateName: states.find(
          (p) => p.id == billingInfoForm.values.payeeStateId,
        )?.name,
      };

      try {
        setEditLoading(true);
        await edit(billingInfo);
        setCurrentBillingInfo(billingInfo);
        if (currentBillingInfo?.id && custodian) {
          setCustodianData({ ...custodian, billingInformation: billingInfo });
        }
      } catch {
        billingInfoForm.setValues(billingFormValue);
      } finally {
        setEditLoading(false);
      }

      setEditMode(false);
    } else {
      showSnackbar(['please fix validation errors first'], 'error');
    }
  };

  return (
    <>
      <Grid container mb={2} justifyContent={'end'}>
        {editModes ? (
          <>
            <Button
              sx={iconContainer}
              onClick={(e) => {
                handelCancelMode(e);
              }}
            >
              <CancelOutlined sx={cancelIcon} />
              <Typography sx={cancelIconText}>Cancel</Typography>
            </Button>
            <Button
              sx={iconContainer}
              onClick={(e) => {
                handleSaveMode(e);
              }}
            >
              <SaveOutlined sx={saveIcon} />
              <Typography sx={saveIconText}>
                {editLoading ? <CircularProgress size={16} /> : 'Save'}
              </Typography>
            </Button>
          </>
        ) : (
          canEdit && (
            <Button
              variant="outlined"
              sx={{ marginRight: '12px' }}
              onClick={(e) => handelEditMode(e)}
            >
              <Edit />
              <Typography>Edit Billing</Typography>
            </Button>
          )
        )}
      </Grid>
      {!editModes ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Accordion
              expanded={isExpanded('Invoicing')}
              onChange={togglePanel('Invoicing')}
            >
              <AccordionSummary
                aria-controls={`Invoicing-content`}
                id={`Invoicing-header`}
                expandIcon={<ExpandMore />}
              >
                <Typography sx={accordionSummaryText}>
                  Invoicing Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <BillingInfoInvoicingDetails
                  paymentMethods={paymentMethods}
                  billingInfoInvoicingPaymentMethods={
                    currentBillingInfo?.billingInfoInvoicingPaymentMethods ?? []
                  }
                  invoiceBeforeRecords={
                    currentBillingInfo?.invoiceBeforeRecords ?? false
                  }
                />
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={isExpanded('Fees')}
              onChange={togglePanel('Fees')}
            >
              <AccordionSummary
                aria-controls={`Fees-content`}
                id={`Fees-header`}
                expandIcon={<ExpandMore />}
              >
                <Typography sx={accordionSummaryText}>Fees Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <BillingInfoFeesDetails
                  paymentMethods={paymentMethods}
                  billingInfoProcessingFeesMethods={
                    currentBillingInfo?.billingInfoProcessingFeesMethods ?? []
                  }
                  processingFeesAuthorization={
                    currentBillingInfo?.processingFeesAuthorization ?? 0
                  }
                  processingFeesSubpoena={
                    currentBillingInfo?.processingFeesSubpoena ?? 0
                  }
                  initialProcessingFee={
                    currentBillingInfo?.initialProcessingFee ?? false
                  }
                  initialProcessingFeeNotes={
                    currentBillingInfo?.initialProcessingFeeNotes ?? ''
                  }
                />
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={isExpanded('Payee')}
              onChange={togglePanel('Payee')}
            >
              <AccordionSummary
                aria-controls={`Payee-content`}
                id={`Payee-header`}
                expandIcon={<ExpandMore />}
              >
                <Typography sx={accordionSummaryText}>Payee Details</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <BillingInfoPayeeDetails
                  payeeAddress={currentBillingInfo?.payeeAddress ?? ''}
                  payeeCity={currentBillingInfo?.payeeCity ?? ''}
                  payeeContact={currentBillingInfo?.payeeContact ?? ''}
                  payeeName={currentBillingInfo?.payeeName ?? ''}
                  payeePostCode={currentBillingInfo?.payeePostCode ?? ''}
                  payeeStateName={currentBillingInfo?.payeeStateName ?? ''}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      ) : (
        <BillingInfo
          form={billingInfoForm}
          states={states}
          paymentMethods={paymentMethods}
        />
      )}
    </>
  );
}
