import { useRef, useState } from 'react';
import GenericInput from './GenericInput';
import { CircularProgress } from '@mui/material';
import useConstructAzureSearchParams from '../../../../hooks/Locations/useConstructAzureSearchParams';
import CustodianAzureSearchResult from '../../../../Models/Locations/CustodianAzureSearchResult';
import { debounceDelay } from '../../../../settings/appConfig';
import useSearchCustodians from '../../../../hooks/Locations/useSearchCustodians';

export default function FieldSearchInput({
  searchPropertyName,
  name,
  value,
  label,
  placeholder,
  maxLength,
  formErrorMessage,
  hasFormError,
  onChange,
  onBlur,
  setHasDuplicateError,
  isExactSearch = false,
  inputMode,
  isValid,
  setShowDuplicateRecordsMessage,
  setAzureSearchResult,
  required,
}: {
  searchPropertyName: string;
  name: string;
  value: string;
  label: string;
  placeholder: string;
  maxLength: number;
  formErrorMessage: any;
  hasFormError: boolean;
  onChange: (e: any) => void;
  onBlur: () => void;
  setHasDuplicateError?: (duplicateFound: boolean) => void;
  isExactSearch?: boolean;
  inputMode?:
    | 'search'
    | 'text'
    | 'email'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal'
    | undefined;
  isValid?: (value: any) => boolean;
  setShowDuplicateRecordsMessage: any;
  setAzureSearchResult: any;
  required?: boolean;
}) {
  const debounceTimeoutId = useRef<any>(null);
  const { constructFieldedSearchParamsObject } =
    useConstructAzureSearchParams();

  const { searchExactProperty, isLoading, fieldedSearchChanged } =
    useSearchCustodians(false, '', '');
  const handleSearchPropertyChanged = async (
    event: any,
    value: string,
    propertyName: string,
  ) => {
    if (debounceTimeoutId.current) {
      clearTimeout(debounceTimeoutId.current);
    }
    setShowDuplicateRecordsMessage(false);

    debounceTimeoutId.current = await setTimeout(async () => {
      if (value.length >= 3) {
        if (propertyName) {
          var searchParams = constructFieldedSearchParamsObject(
            0,
            propertyName,
            value,
          );
          var result: CustodianAzureSearchResult = await fieldedSearchChanged(
            searchParams,
          );

          if (result?.totalCount > 0) {
            setShowDuplicateRecordsMessage(true);
          } else {
            setShowDuplicateRecordsMessage(false);
          }
        }
      }
    }, debounceDelay);
  };
  const handleSearchExactPropertyChanged = async (
    event: any,
    value: string,
    propertyName: string,
  ) => {
    if (debounceTimeoutId.current) {
      clearTimeout(debounceTimeoutId.current);
    }
    let valid = false;
    setShowDuplicateRecordsMessage(false);

    debounceTimeoutId.current = await setTimeout(async () => {
      try {
        if (isValid) valid = isValid(value);
      } catch {
        valid = false;
      }
      if (valid) {
        const result = await searchExactProperty(value, propertyName);
        if (result && result?.totalCount > 0) {
          setShowDuplicateRecordsMessage(true);
          setAzureSearchResult(result);
          setHasDuplicateSearchResult(true);
        } else {
          setShowDuplicateRecordsMessage(false);
          setAzureSearchResult(null);
          setHasDuplicateSearchResult(false);
        }
      }
    }, debounceDelay);
  };

  const setHasDuplicateSearchResult = (hasDuplicates : boolean) => {
    if(setHasDuplicateError)
    setHasDuplicateError(hasDuplicates);
  }

  return (
    <>
      <GenericInput
        name={name}
        label={label}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        inputMode={inputMode}
        onChange={(e: any) => {
          onChange(e);
          if (!isExactSearch)
            handleSearchPropertyChanged(e, e.target.value, searchPropertyName);
          else if (isExactSearch)
            handleSearchExactPropertyChanged(
              e,
              e.target.value,
              searchPropertyName,
            );
        }}
        errorMsg={formErrorMessage}
        hasError={hasFormError}
        onBlur={(e: any) => {
          onBlur();
        }}
        required={required}
      />
      {isLoading ? <CircularProgress /> : ''}
    </>
  );
}
