import { useCallback, useEffect, useState } from 'react';

import useFetchActions from '../../../../hooks/Roles/useFetchActions';
import useRoleForm from '../../../../hooks/Roles/UseRoleForm/useRoleForm';
import useUpdateRole from '../../../../hooks/Roles/useUpdateRole';
import { useParams } from 'react-router';
import useFetchRole from '../../../../hooks/Roles/useFetchRole';
import RoleForm from '../RoleForm/roleForm';
import ComponentLoader from '../../../../common/ComponentLoader/componentLoader';
import { Box } from '@mui/material';
import { cardContainer } from '../../../../assets/styles/appStyles';

const EditRole = () => {
  const { actions, isLoading: isActionsLoading } = useFetchActions();
  const { updateRole, isSubmitting } = useUpdateRole();
  const { id } = useParams();

  const { role, isLoading: isRoleLoading } = useFetchRole(+(id ?? ''));

  const editRole = useCallback(
    async (newRole: any) => {
      await updateRole({ ...newRole, id: id });
    },
    [updateRole, id],
  );

  const { formik, changeActionIds } = useRoleForm({ saveRole: editRole });

  const getRole = useCallback(async () => {
    if (role) {
      formik.setValues({
        actionIds: role.actions.map((p) => p.id),
        name: role.name,
        description: role.description,
      });
    }
  }, [formik, role]);

  useEffect(() => {
    getRole();
  }, [role]);

  return (
    <Box sx={cardContainer} p={2}>
      <ComponentLoader isOpen={isActionsLoading && isRoleLoading}>
        <RoleForm
          actions={actions}
          changeActionIds={changeActionIds}
          formik={formik}
          isSubmitting={isSubmitting}
        />
      </ComponentLoader>
    </Box>
  );
};
export default EditRole;
