import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react';
import RecordType from '../../../../Models/Locations/recordType';
import AssignAssociationDialog from './AssignAssociationDialog';
import AssociationsListToBeAdded from './AssociationsListToBeAdded';
export default function Associations({
  supportedRecordTypes,
  orderTypes,
  providers,
  form,
}: {
  supportedRecordTypes: RecordType[];
  providers: any[];
  orderTypes: any[];
  form: any;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const removeAssociation = (id: any) => {
    const newItems = [...form.values.associations];
    const index = newItems.findIndex((i) => i.custodianAssociationId === id);
    newItems.splice(index, 1);
    form.setFieldValue('associations', [...newItems]);
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <AssociationsListToBeAdded
          removeAssociation={removeAssociation}
          recordTypes={supportedRecordTypes}
          orderTypes={orderTypes}
          providers={providers}
          form={form}
          formKeyName="associations"
        />

        <AssignAssociationDialog
          onItemSelected={(item) => {
            const newItems = [
              ...form.values.associations,
              {
                custodianAssociationId: item.id,
                name: item.name,
                recordTypes: [
                  {
                    recordTypeId: null,
                    orderTypeId: null,
                    from: null,
                    to: null,
                    associationProviderId: null,
                  },
                ],
              },
            ];
            form.setFieldValue('associations', newItems);
          }}
          associatedCustodians={[
            ...form.values.associations.map((o: any) => ({
              id: o.custodianAssociationId,
            })),
          ]}
          open={open}
          handleClose={handleCloseDialog}
        />
      </Grid>

      <Grid item >
        <Button onClick={handleClickOpen}>Add new associate custodian</Button>
      </Grid>
    </Grid>
  );
}
