import {
  Box,
  CircularProgress,
  Grid,
  Switch,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import ApplicationUser from '../../../../Models/Users/ApplicationUser';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Styles from '../../../../assets/styles/tableStyles';
import usePostUserRole from '../../../../hooks/Users/usePostUserRole';
import { Link } from 'react-router-dom';

export default function UsersListItem({
  user,
  statusChanged,
}: {
  user: ApplicationUser;
  statusChanged: () => Promise<void>;
}) {
  const { save, isSubmitting } = usePostUserRole(user.id.toString());
  const toggleUserStatus = async (isEnabled: boolean) => {
    await save({
      ...user,
      userId: user.id,
      disabled: isEnabled ? false : true,
    });
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (user.disabled === true) await toggleUserStatus(true);
    else await toggleUserStatus(false);
    await statusChanged();
  };
  return (
    <TableRow key={user.id} sx={Styles.tableRow}>
      <TableCell>
        <Typography sx={Styles.tableCell}>{user.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography sx={Styles.tableCell}>{user.roleName}</Typography>
      </TableCell>
      <TableCell>
        <Box sx={Styles.tableCell}>
          <Grid container alignItems={'center'}>
            <Grid item>
              <Link to={`/users/edit/${user.id}`}>
                <ModeEditIcon data-testid={user.id}></ModeEditIcon>
              </Link>
            </Grid>
            <Grid item>
              {!isSubmitting && (
                <Switch
                  checked={user.disabled !== true}
                  onChange={handleChange}
                />
              )}
              {isSubmitting && <CircularProgress />}
            </Grid>
          </Grid>
        </Box>
      </TableCell>
    </TableRow>
  );
}
