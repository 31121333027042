import { useEffect, useCallback, useState } from 'react';
import useHttp from '../http/useHttp';
import Action from '../../Models/Roles/action';

export default function useFetchActions() {
  const { getDataResponse, getData } = useHttp();
  const [isLoading, setIsLoading] = useState(false);

  const init = useCallback(async () => {
    try {
      setIsLoading(true);
      await getData('role/getallactions');
    } finally {
      setIsLoading(false);
    }
  }, []);

  let actions: Action[] = getDataResponse?.data ?? [];

  useEffect(() => {
    init();
  }, [init]);

  return { actions, isLoading };
}
