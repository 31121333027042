import { FormControl, Grid, Typography } from '@mui/material';
import Panel from '../../../components/UI/Panel';
import State from '../../../Models/Locations/state';
import CheckboxInput from './UI/CheckboxInput';
import MultipleCheckBoxes from './UI/MultipleCheckBoxes';
import YesNoInput from './YesNoInput';
import GenericInput from './UI/GenericInput';
import usePhonesHelperMethods from '../../../hooks/UI/usePhonesHelperMethods';
import GenericSelectInput from './UI/GenericSelectInput';
import { title } from '../../../assets/styles/appStyles';

export default function BillingInfo({
  form,
  states,
  paymentMethods,
}: {
  form: any;
  states: State[];
  paymentMethods?: any[];
}) {
  const { formatPhoneNumberOnChange } = usePhonesHelperMethods();
  const checkboxClickHandler = (id: string, keyName: string) => {
    const newItems = [...form.values[keyName]];
    const itemIndex = form.values[keyName].findIndex((i: string) => i === id);
    if (itemIndex > -1) {
      newItems.splice(itemIndex, 1);
    } else {
      newItems.push(id);
    }
    form.setFieldValue(keyName, newItems);
  };
  return (
    <>
      <Panel headerText="Invoicing Details">
        <Grid spacing={2} mx={1} px={1} container>
          <Grid item xs={6} p={2}>
            <MultipleCheckBoxes
              label="Invoicing Payment method"
              selectedValues={form.values.invoicePaymentMethods}
              toggleChange={(id: string) => {
                checkboxClickHandler(id, 'invoicePaymentMethods');
              }}
              keyName="invoicePaymentMethods"
              options={paymentMethods ?? []}
            />
          </Grid>
          <Grid item xs={6} p={2}>
            <FormControl fullWidth>
              <Typography sx={title}>Invoicing</Typography>

              <CheckboxInput
                keyName={`invoiceBeforeRecords`}
                label="Invoice before records"
                checked={form.values.invoiceBeforeRecords}
                onChange={(e: any) => {
                  form.setFieldValue(`invoiceBeforeRecords`, e.target.checked);
                }}
                onBlur={() => {
                  form.setFieldTouched(`invoiceBeforeRecords`, true);
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Panel>
      <Panel headerText="Fees Details">
        <Grid spacing={2} mx={1} px={1} container>
          <Grid item xs={6} p={2}>
            <GenericInput
              name="processingFeesAuthorization"
              maxLength={16}
              inputMode="numeric"
              label="Processing Fees (Authorization)"
              placeholder="Processing Fees (Authorization)"
              value={form.values.processingFeesAuthorization}
              onChange={(e: any) => {
                form.setFieldValue(
                  `processingFeesAuthorization`,
                  e.target.value,
                );
              }}
              errorMsg={
                form.touched.processingFeesAuthorization &&
                form.errors.processingFeesAuthorization
                  ? form.errors.processingFeesAuthorization
                  : ''
              }
              hasError={
                form.touched.processingFeesAuthorization &&
                form.errors.processingFeesAuthorization
                  ? true
                  : false
              }
              onBlur={() => {
                form.setFieldTouched(`processingFeesAuthorization`, true);
              }}
            />
          </Grid>
          <Grid item xs={6} p={2}>
            <GenericInput
              name="processingFeesSubpoena"
              label="Processing Fees (Subpoena)"
              placeholder="Processing Fees (Subpoena)"
              maxLength={16}
              inputMode="numeric"
              value={form.values.processingFeesSubpoena}
              onChange={(e: any) => {
                form.setFieldValue(`processingFeesSubpoena`, e.target.value);
              }}
              errorMsg={
                form.touched.processingFeesSubpoena &&
                form.errors.processingFeesSubpoena
                  ? form.errors.processingFeesSubpoena
                  : ''
              }
              hasError={
                form.touched.processingFeesSubpoena &&
                form.errors.processingFeesSubpoena
                  ? true
                  : false
              }
              onBlur={() => {
                form.setFieldTouched(`processingFeesSubpoena`, true);
              }}
            />
          </Grid>
          <Grid item xs={12} p={2}>
            <MultipleCheckBoxes
              label="Processing fees method"
              keyName="processingFeesMethods"
              selectedValues={form.values.processingFeesMethods}
              toggleChange={(id: string) => {
                checkboxClickHandler(id, 'processingFeesMethods');
              }}
              options={paymentMethods ?? []}
            />
          </Grid>

          <Grid item xs={6} px={2}>
            <YesNoInput
              formik={form}
              keyname="initialProcessingFee"
              label="Initial Processing Fee"
            />
            {form.values.initialProcessingFee && (
              <GenericInput
                name="initialProcessingFeeNotes"
                label="Notes"
                placeholder="Notes"
                maxLength={150}
                value={form.values.initialProcessingFeeNotes}
                onChange={(e: any) => {
                  form.setFieldValue(
                    `initialProcessingFeeNotes`,
                    e.target.value,
                  );
                }}
                errorMsg={
                  form.touched.initialProcessingFeeNotes &&
                  form.errors.initialProcessingFeeNotes
                    ? form.errors.initialProcessingFeeNotes
                    : ''
                }
                hasError={
                  form.touched.initialProcessingFeeNotes &&
                  form.errors.initialProcessingFeeNotes
                    ? true
                    : false
                }
                onBlur={() => {
                  form.setFieldTouched(`initialProcessingFeeNotes`, true);
                }}
              />
            )}
          </Grid>
        </Grid>
      </Panel>
      <Panel headerText="Payee Details">
        <Grid spacing={2} mx={1} px={1} container>
          <Grid item xs={4} px={2}>
            <GenericInput
              name="payeeName"
              label="Payee Name"
              placeholder="Payee Name"
              maxLength={120}
              value={form.values.payeeName}
              onChange={(e: any) => {
                form.setFieldValue(`payeeName`, e.target.value);
              }}
              errorMsg={
                form.touched.payeeName && form.errors.payeeName
                  ? form.errors.payeeName
                  : ''
              }
              hasError={
                form.touched.payeeName && form.errors.payeeName ? true : false
              }
              onBlur={() => {
                form.setFieldTouched(`payeeName`, true);
              }}
              required={true}
            />
          </Grid>
          <Grid item xs={4} px={2}>
            <GenericInput
              name="payeeContact"
              label="Payee Phone"
              maxLength={12}
              placeholder="xxx-xxx-xxxx"
              inputMode="tel"
              value={form.values.payeeContact}
              onChange={(e: any) => {
                formatPhoneNumberOnChange(e);
                form.setFieldValue(`payeeContact`, e.target.value);
              }}
              errorMsg={
                form.touched.payeeContact && form.errors.payeeContact
                  ? form.errors.payeeContact
                  : ''
              }
              hasError={
                form.touched.payeeContact && form.errors.payeeContact
                  ? true
                  : false
              }
              onBlur={() => {
                form.setFieldTouched(`payeeContact`, true);
              }}
              required={true}
            />
          </Grid>
          <Grid item xs={4} px={2}>
            <GenericInput
              name="payeeAddress"
              label="Payee Address"
              placeholder="Payee Address"
              maxLength={120}
              value={form.values.payeeAddress}
              onChange={(e: any) => {
                form.setFieldValue(`payeeAddress`, e.target.value);
              }}
              errorMsg={
                form.touched.payeeAddress && form.errors.payeeAddress
                  ? form.errors.payeeAddress
                  : ''
              }
              hasError={
                form.touched.payeeAddress && form.errors.payeeAddress
                  ? true
                  : false
              }
              onBlur={() => {
                form.setFieldTouched(`payeeAddress`, true);
              }}
              required={true}
            />
          </Grid>
          <Grid item xs={4} px={2}>
            <GenericInput
              name="payeeCity"
              label="Payee City"
              placeholder="Payee City"
              maxLength={40}
              value={form.values.payeeCity}
              onChange={(e: any) => {
                form.setFieldValue(`payeeCity`, e.target.value);
              }}
              errorMsg={
                form.touched.payeeCity && form.errors.payeeCity
                  ? form.errors.payeeCity
                  : ''
              }
              hasError={
                form.touched.payeeCity && form.errors.payeeCity ? true : false
              }
              onBlur={() => {
                form.setFieldTouched(`payeeCity`, true);
              }}
              required={true}
            />
          </Grid>
          <Grid item xs={4} px={2}>
            <GenericSelectInput
              name={`payeeStateId`}
              label="Payee State"
              value={form.values.payeeStateId}
              onChange={(e: any) => {
                form.setFieldValue(`payeeStateId`, e);
              }}
              errorMsg={
                form.touched.payeeStateId && form.errors.payeeStateId
                  ? form.errors.payeeStateId
                  : ''
              }
              hasError={
                form.touched.payeeStateId && form.errors.payeeStateId
                  ? true
                  : false
              }
              onBlur={() => {
                form.setFieldTouched(`payeeStateId`, true);
              }}
              options={states}
              required={true}
            />
          </Grid>
          <Grid item xs={4} px={2}>
            <GenericInput
              name="payeePostCode"
              label="Zip / Postal Code"
              placeholder="Zip / Postal Code"
              maxLength={5}
              value={form.values.payeePostCode}
              onChange={(e: any) => {
                form.setFieldValue(`payeePostCode`, e.target.value);
              }}
              errorMsg={
                form.touched.payeePostCode && form.errors.payeePostCode
                  ? form.errors.payeePostCode
                  : ''
              }
              hasError={
                form.touched.payeePostCode && form.errors.payeePostCode
                  ? true
                  : false
              }
              onBlur={() => {
                form.setFieldTouched(`payeePostCode`, true);
              }}
              required={true}
            />
          </Grid>
        </Grid>
      </Panel>
    </>
  );
}
