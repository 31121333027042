import { useCallback, useEffect, useState } from 'react';
import useHttp from '../http/useHttp';

export default function useFetchDepartment(id: string) {
  const { getDataResponse, getData } = useHttp();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const init = useCallback(async (id: string) => {
    try {
      setIsLoading(true);
      await getData(`department/getbyid?id=${id}`);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  let department: any = getDataResponse?.data ?? {};

  useEffect(() => {
    if (id) init(id);
  }, [id, init]);

  return { isLoading, department };
}
