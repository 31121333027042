import CustodianBasicDetails from './CustodianBasicDetails';
import CustodianAddress from './CustodianAddress';
import CustodianContactInfo from './CustodianContactInfo';

export default function CustodianMainInfo({
  formik,
  custodianTypes,
  states,
  phoneTypes,
  timeZones,
}: {
  formik: any;
  custodianTypes: any[];
  states: any[];
  phoneTypes: any[];
  timeZones: any[];
}) {
  return (
    <>
      <CustodianBasicDetails
        custodianTypes={custodianTypes}
        phoneTypes={phoneTypes}
        formik={formik}
      />

      <CustodianAddress states={states} timeZones={timeZones} formik={formik} />

      <CustodianContactInfo formik={formik} />
    </>
  );
}
