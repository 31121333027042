import { Box, Card, Grid, Typography } from '@mui/material';
import CustodianDetailsHeaderModel from '../../../Models/custodian/custodian-details-header';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';

import {
  addressBox,
  boldLabel,
  creationDateContainer,
  custodianName,
  custodianNameWrapper,
  flexRow,
  flexRowSpaceBetween,
  headerContainer,
  headerInfoBoxText,
  iconLabel,
  iconText,
  paddedIcon,
  phoneBox,
  portalURLBox,
} from '../Styles/CustodianDetailsHeaderStyles';

export default function CustodianDetailsHeader({
  headerInfo,
}: {
  headerInfo: CustodianDetailsHeaderModel;
}) {
  return (
    <Card sx={headerContainer}>
      <Box sx={flexRowSpaceBetween}>
        <Box sx={custodianNameWrapper}>
          <Typography align="left" sx={custodianName}>
            {headerInfo.name}
          </Typography>
        </Box>
        <Box sx={creationDateContainer}>
          <Typography align="right" sx={boldLabel}>
            Creation Date:&nbsp;
          </Typography>
          <Typography>
            {headerInfo.creationDate.toLocaleString('US-en', {
              dateStyle: 'medium',
              timeStyle: 'short',
            })}
          </Typography>
        </Box>
      </Box>
      <Grid container sx={flexRow} spacing={3}>
        <Grid item xs={6} sx={addressBox}>
          <HomeOutlinedIcon color="warning" sx={paddedIcon}></HomeOutlinedIcon>
          <Box sx={headerInfoBoxText}>
            <Typography sx={iconLabel}>Address</Typography>
            <Typography sx={iconText}>{headerInfo.address || '-'}</Typography>
          </Box>
        </Grid>
        <Grid item xs={3} sx={phoneBox}>
          <PhoneOutlinedIcon
            color="warning"
            sx={paddedIcon}
          ></PhoneOutlinedIcon>
          <Box sx={headerInfoBoxText}>
            <Typography sx={iconLabel}>Phone</Typography>
            <Typography sx={iconText} noWrap={true}>
              {headerInfo.phone || '-'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} sx={portalURLBox}>
          <LanguageOutlinedIcon
            color="warning"
            sx={paddedIcon}
          ></LanguageOutlinedIcon>
          <Box sx={headerInfoBoxText}>
            <Typography sx={iconLabel}>Portal URL</Typography>
            <Typography sx={iconText} noWrap={true}>
              {headerInfo.portalURL || '-'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
