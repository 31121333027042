import * as React from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import dropIcon from '../../../assets/images/expand.svg';
import logo from '../../../assets/images/Logo.svg';
import { useMemo } from 'react';
import NavBarStyles from './NavBarStyles';
import { logoutUser } from '../../../services/authService';
import NavBarMenu from './NavBarMenu';
import { Link } from 'react-router-dom';

export const NavBar = () => {
  const { instance, accounts } = useMsal();
  const theme = useTheme();
  const isAuthenticated = useIsAuthenticated();
  const name = useMemo(
    () => accounts && accounts[0] && accounts[0].name,
    [accounts],
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  function handleLogout() {
    logoutUser(instance);
  }
  const handleNameClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar position="fixed" elevation={1} sx={NavBarStyles.nav}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={NavBarStyles.logo}>
              <Link to={'/'}>
                <img src={logo} alt="Custodian" />
              </Link>
            </Box>
          </Box>
          {isAuthenticated && (
            <Box
              sx={{
                cursor: 'pointer',
              }}
              onClick={handleNameClick}
            >
              <Typography color={theme.palette.neutral['400']}>
                Hello,{' '}
                <Typography
                  sx={{ mr: '3px' }}
                  component="span"
                  color={theme.palette?.primary['400']}
                >
                  {name}
                </Typography>
                <img src={dropIcon} alt="Drop icon" />
              </Typography>
            </Box>
          )}
          <NavBarMenu
            anchorEl={anchorEl}
            open={open}
            handleDropClose={handleDropClose}
            handleLogout={handleLogout}
          />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavBar;
