import { Box, Button, Grid } from '@mui/material';
import RecordType from '../../../../Models/Locations/recordType';
import AssociationForm from './AssociationForm';
import GenericInput from '../UI/GenericInput';
import { greyLightContainer } from '../../../../assets/styles/appStyles';

export default function AssociationsListToBeAdded({
  recordTypes,
  orderTypes,
  providers,
  removeAssociation,
  formKeyName,
  form,
}: {
  recordTypes: RecordType[];
  providers: any[];
  orderTypes: any[];
  removeAssociation: (id: number) => void;
  formKeyName: string;
  form: any;
}) {
  return (
    <Box>
      {form.values[formKeyName]?.map((item: any, index: number) => (
        <Box key={index} sx={greyLightContainer} mb={2} p={2}>
          <Grid container>
            <Grid item xs={2} px={1}>
              <GenericInput
                name="name"
                label="Custodian Name"
                placeholder="Custodian Name"
                maxLength={120}
                value={item.name}
                onChange={() => {}}
                errorMsg={''}
                hasError={false}
                onBlur={() => {}}
                disabled={true}
              />
              <Button
                onClick={() => {
                  removeAssociation(item.custodianAssociationId);
                }}
              >
                Remove
              </Button>
            </Grid>
            <AssociationForm
              recordTypes={recordTypes}
              orderTypes={orderTypes}
              providers={providers}
              associationIndex={index}
              form={form}
              formKeyName={formKeyName}
            />
          </Grid>
        </Box>
      ))}
    </Box>
  );
}
