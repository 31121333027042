import useHttp from '../http/useHttp'
import { useCallback, useState } from 'react';
import CustodiansFullTextAzureSearchParams from '../../Models/Locations/CustodiansFullTextAzureSearchParams';
import CustodianAzureSearchResult from '../../Models/Locations/CustodianAzureSearchResult';
import CustodiansFieldedAzureSearchParams from '../../Models/Locations/CustodiansFieldedAzureSearchParams';

export default function useFetchAzureSearchCustodians() {
  const { postDataResponse, postData } = useHttp();
  const [isLoading, setIsLoading] = useState(false);


  const fullTextSearchChanged = useCallback(async (searchModel: CustodiansFullTextAzureSearchParams) => {
    try {
      setIsLoading(true);
      await postData(`azuresearch/fulltext/custodians`, searchModel);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }

  }, []);

  const fieldedSearchChanged = useCallback(async (searchModel: CustodiansFieldedAzureSearchParams) => {
    try {
      setIsLoading(true);
      var response = await postData(`azuresearch/fieldedsearch/custodians`, searchModel);
      setIsLoading(false);
      return response?.data;
    } catch (error) {
      setIsLoading(false);
    }
  }, []);


  let result: CustodianAzureSearchResult = postDataResponse?.data ?? { totalCount: 0, locations: [] };


  return {
    data: result,
    isLoading,
    fullTextSearchChanged,
    fieldedSearchChanged
  }
}
