import { Box, Checkbox, Grid, Typography } from '@mui/material';
import styleClasses from '../../../../assets/styles/checkBoxInputStyles';
import { pointerStyle } from '../../../../assets/styles/appStyles';

export default function CheckboxInput({
  label,
  keyName,
  checked,
  disabled,
  onChange,
  onBlur,
}: {
  label: string;
  keyName: string;
  checked?: boolean;
  disabled?: boolean;

  onChange: (e: any) => void;
  onBlur: () => void;
}) {
  return (
    <Grid container sx={styleClasses.revertedStyles}>
      <Grid item>
        <Box
          sx={pointerStyle}
          onClick={() => {
            onChange({ target: { checked: !checked } });
          }}
        >
          <Typography>{label}</Typography>
        </Box>
      </Grid>
      <Grid item>
        <Checkbox
          checked={checked}
          name={keyName}
          sx={styleClasses.checkBoxStyles}
          data-testid={keyName}
          disabled={disabled}
          onChange={(e: any) => onChange(e)}
          onBlur={() => onBlur()}
        />
      </Grid>
    </Grid>
  );
}
