import CustodiansFullTextAzureSearchParams from '../../Models/Locations/CustodiansFullTextAzureSearchParams';
import CustodiansFieldedAzureSearchParams, {
  CustodianSearchPropertiesMapping,
} from '../../Models/Locations/CustodiansFieldedAzureSearchParams';
import { duplicateLocationRowsPerPage } from '../../settings/appConfig';
import { useCallback } from 'react';
import useFetchAzureSearchCustodians from './useFetchAzureSearchCustodians';
import CustodianAzureSearchResult from '../../Models/Locations/CustodianAzureSearchResult';

export default function useConstructAzureSearchParams() {
  const { isLoading, fieldedSearchChanged } = useFetchAzureSearchCustodians();

  const constructFullTextSearchParamsObject = useCallback(
    (
      pageNumber: number,
      name: string,
      address: string,
      city: string,
      postCode: string,
      stateId: number,
    ) => {
      const searchText = `${name ?? ''} ${address ?? ''} ${city ?? ''} ${
        postCode ?? ''
      }`;
      const searchParams: CustodiansFullTextAzureSearchParams = {
        searchText: searchText,
        take: duplicateLocationRowsPerPage,
        skip: pageNumber * duplicateLocationRowsPerPage,
      };

      if (stateId) {
        searchParams.stateId = stateId;
      }
      return searchParams;
    },
    [],
  );

  const constructFieldedSearchParamsObject = useCallback(
    (
      pageNumber: number,
      propertyName: string,
      value: string,
      stateId: number = 0,
      isExact: boolean = false,
    ) => {
      let searchParams: CustodiansFieldedAzureSearchParams =
        {} as CustodiansFieldedAzureSearchParams;

      if (propertyName && propertyName in CustodianSearchPropertiesMapping) {
        const searchProp =
          CustodianSearchPropertiesMapping[
            propertyName as keyof typeof CustodianSearchPropertiesMapping
          ];
        searchParams = {
          ...searchParams,
          stateId,
          isExact,
          take: duplicateLocationRowsPerPage,
          skip: pageNumber * duplicateLocationRowsPerPage,
          [searchProp as keyof CustodiansFieldedAzureSearchParams]: value,
        };
      }
      return searchParams;
    },
    [],
  );

  return {
    constructFullTextSearchParamsObject,
    constructFieldedSearchParamsObject,
    isLoading,
  };
}
