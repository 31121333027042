import { Box, Grid, IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

export default function DepartmentNavigation({
  onClickNext,
  onClickPrevious,
  enableNext,
  enablePrevious,
}: {
  onClickNext: () => void;
  onClickPrevious: () => void;
  enableNext: boolean;
  enablePrevious: boolean;
}) {
  return (
    <Box>
      <Grid container justifyContent={'flex-end'}>
        <Grid item>
          <IconButton disabled={!enablePrevious} onClick={onClickPrevious}>
            <ChevronLeft />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton disabled={!enableNext} onClick={onClickNext}>
            <ChevronRight />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}
