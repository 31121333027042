import { useEffect, useCallback, useState } from 'react';
import useHttp from '../http/useHttp';

export default function useFetchDays() {
  const { getDataResponse, getData } = useHttp();
  const [daysLoading, setDaysLoading] = useState<boolean>(false);

  const init = useCallback(async () => {
    setDaysLoading(true);
    await getData('lookup/getdays');
    setDaysLoading(false);
  }, []);

  let days: any[] = getDataResponse?.data ?? [];

  useEffect(() => {
    init();
  }, [init]);

  return { days, daysLoading };
}
