import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { bgGrey, bgWhite, textWhite } from '../../assets/styles/appStyles';

export default function WizardFooter({
  isSaving,
  isFirstStep,
  isLastStep,
  saveLabel,
  previousClicked,
  nextClicked,
  saveClicked,
  cancelClicked,
}: {
  isSaving?: boolean;
  isFirstStep?: boolean;
  isLastStep?: boolean;
  saveLabel?: string;
  previousClicked: () => void;
  nextClicked: () => void;
  saveClicked: () => void;
  cancelClicked: () => void;
}) {
  return (
    <Grid p={2} m={1} container justifyContent={'space-around'}>
      <Grid item md={3}>
        <Button variant="contained" sx={bgGrey} onClick={cancelClicked}>
          Cancel
        </Button>
      </Grid>
      <Grid item md={3}></Grid>
      <Grid item md={6}>
        <Grid container justifyContent={'flex-end'}>
          <Grid item ml={1}>
            {!isFirstStep && (
              <Box
                component={Button}
                variant="contained"
                sx={bgWhite}
                onClick={previousClicked}
              >
                Previous
              </Box>
            )}
          </Grid>
          <Grid item ml={1}>
            {!isLastStep && (
              <Box
                component={Button}
                sx={textWhite}
                variant="contained"
                onClick={nextClicked}
              >
                Next Step
              </Box>
            )}
          </Grid>
          <Grid item ml={1}>
            {!isSaving && isLastStep && (
              <Box
                component={Button}
                variant="contained"
                sx={textWhite}
                onClick={saveClicked}
              >
                {saveLabel || 'Save'}
              </Box>
            )}
            {isSaving && <CircularProgress />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
