import { useMemo } from 'react';
import Wizard from '../../components/wizard/Wizard';
import CustodiansWizardContent from './CustodiansWizardContent';
import useAddCustodianWizard from '../../hooks/Locations/addLocation/useAddCustodianWizard';
import useAddCustodianLookups from '../../hooks/Locations/addLocation/useAddCustodianLookups';
import DiscardWizardModal from '../../Modals/DiscardWizardModal';

export default function CustodianWizard() {
  const {
    states,
    custodianTypes,
    phoneTypes,
    recordTypes,
    serveMethods,
    supportedOrderTypes,
    paymentMethods,
    timeZones,
    departmentFileTypes,
    days,
    providers,
  } = useAddCustodianLookups();

  const {
    steps,
    stepClickHandler,
    cancelClickHandler,
    nextClickHandler,
    previousClickHandler,
    saveClickHandler,
    mainInfoForm,
    billingInfoForm,
    cancelDiscard,
    discard,
    isDiscardModalOpened,
    addDepartment,
    departmentsForm,
    associationsForm,
    handleChangeAccordion,
    removeDepartment,
    isConfirmationOpened,
    onCloseConfirmationClicked,
    discardDelete,
    isSaving,
    copyCustodianAddress,
  } = useAddCustodianWizard({
    states,
    custodianTypes,
    phoneTypes,
    recordTypes,
    supportedOrderTypes,
    departmentFileTypes,
  });

  const activeStep = useMemo(() => steps.find((s) => s.isCurrentStep), [steps]);

  return (
    <>
      <DiscardWizardModal
        isModalOpened={isDiscardModalOpened}
        handleClose={cancelDiscard}
        handleAgree={discard}
      />
      <Wizard
        isSaving={isSaving}
        steps={steps}
        onSaveClicked={saveClickHandler}
        onStepClicked={stepClickHandler}
        onCancelClicked={cancelClickHandler}
        onPreviousClicked={previousClickHandler}
        onNextClicked={nextClickHandler}
      >
        <>
          <CustodiansWizardContent
            activeStep={activeStep}
            mainInfoForm={mainInfoForm}
            custodianTypes={custodianTypes}
            billingInfoForm={billingInfoForm}
            paymentMethods={paymentMethods}
            phoneTypes={phoneTypes}
            recordTypes={recordTypes}
            serveMethods={serveMethods}
            states={states}
            timeZones={timeZones}
            departmentFileTypes={departmentFileTypes}
            days={days}
            supportedOrderTypes={supportedOrderTypes}
            addDepartment={addDepartment}
            departmentsForm={departmentsForm}
            handleChangeAccordion={handleChangeAccordion}
            removeDepartment={removeDepartment}
            isConfirmationOpened={isConfirmationOpened}
            onCloseConfirmationClicked={onCloseConfirmationClicked}
            discardDelete={discardDelete}
            associationsForm={associationsForm}
            providers={providers}
            copyCusodianAddress={copyCustodianAddress}
          />
        </>
      </Wizard>
    </>
  );
}
