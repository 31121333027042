import logo from '../../assets/images/Logo.png';
import squares from '../../assets/images/squares.svg';
import Box from '@mui/material/Box';
import styles from '../Login/LoginStyles';
import {
  Button,
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { loginRequest } from '../../settings/authConfig';
import { useMsal } from '@azure/msal-react';
import { lightTheme } from '../../layout/theme';

const Login = () => {
  const { instance } = useMsal();

  const theme = createTheme(lightTheme);

  const handleLogin = async () => {
    await instance.loginRedirect(loginRequest);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={styles.LoginPage}
        alignItems="center"
        justifyContent={'space-between'}
      >
        <Grid item xs={6} sx={styles.leftPart}>
          <Grid container justifyContent={'flex-end'}>
            <Grid
              sx={{
                position: 'absolute',
              }}
              item
            >
              <Box sx={styles.image}>
                <img src={squares} alt="squares" />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={'center'}
            alignItems="center"
            height={'100%'}
          >
            <Grid item sx={styles.infoContainer}>
              <Box sx={styles.infoContent}>
                <Typography sx={styles.title}>
                  Manage Custodians Workload!
                </Typography>
                <Typography sx={styles.desc}>
                  A tool built for operations to help them manage their workload
                  by manage locations.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sx={styles.rightPart}>
          <Grid
            container
            justifyContent={'center'}
            alignItems="center"
            height="100%"
          >
            <Grid item>
              <Grid
                container
                flexDirection={'column'}
                justifyContent="center"
                alignItems={'center'}
                height="400px"
              >
                <Grid item>
                  <Box sx={styles.logo}>
                    <img src={logo} alt="logo" />
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={styles.form}>
                    <Typography sx={styles.formTitle}>
                      Login using Ontellus credentials
                    </Typography>
                    <Button
                      variant="contained"
                      sx={styles.loginBtn}
                      onClick={() => handleLogin()}
                    >
                      Login
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
export default Login;
