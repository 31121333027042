import { SxProps } from '@mui/material';

const cardStyles: SxProps = {
  marginBottom: '10px',
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  color: (theme: any) => theme.palette.common.black,
  gap: '7px',
};

const headerTitleStyles: SxProps = {
  fontSize: '24px',
  fontWeight: '700',
  lineHeight: '20px',
  letterSpacing: '0px',
  margin: '24px 0 0 0',
  color: (theme: any) => theme.palette.common.black,
};

const viewCustodianLinkStyles = {
  textDecoration: 'none',
  cursor: 'pointer',
  fontWeight: '600',
  fontSize: '15px',
  lineHeight: '20px',
  color: '#f97316',
};

const loaderStyles: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
};

const tableRowStyles: SxProps = {
  '&:last-child td, &:last-child th': { border: 0 },
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  '&:hover': {
    opacity: '0.6',
  },
  borderRadius: '2px',
};

const tableHeaderStyles: SxProps = {
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '20px',
  color: (theme: any) => theme.palette.common.black,
};

const tableSecondaryHeaderStyles: SxProps = {
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '20px',
  color: (theme: any) => theme.palette.common.black,
};

const tableContainerStyles: SxProps = {
  border: '1px solid',
  borderRadius: '8px',
  borderColor: (theme: any) => theme.palette.neutral['200'],
};

const custodianTableCellStyles: SxProps = {
  color: (theme: any) => theme.palette.common.black,
  fontWeight: '600',
  fontSize: '15px',
  lineHeight: '20px',
  wordWrap: 'anywhere !important',
};

const departmentTableCellStyles: SxProps = {
  color: (theme: any) => theme.palette.common.black,
  fontWeight: '550',
  fontSize: '14px',
  lineHeight: '20px',
};

const progressStyles: SxProps = {
  display: 'flex',
};

const paginationStyles: SxProps = {
  borderTop: '1px solid',
  borderTopColor: (theme: any) => theme.palette.neutral['200'],
};

const expandedDepartmentCellStyles: SxProps = {
  paddingBottom: 0,
  paddingTop: 0,
};

const classes = {
  tableContainerStyles,
  tableHeaderStyles,
  tableRowStyles,
  custodianTableCellStyles,
  progressStyles,
  paginationStyles,
  headerTitleStyles,
  cardStyles,
  loaderStyles,
  departmentTableCellStyles,
  viewCustodianLinkStyles,
  expandedDepartmentCellStyles,
  tableSecondaryHeaderStyles,
};

export default classes;
