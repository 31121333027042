import { useEffect, useCallback } from 'react';
import useHttp from '../http/useHttp';
import OrderType from '../../Models/Locations/orderType';

export default function useFetchOrderTypes() {
  const { getDataResponse, getData } = useHttp();

  const init = useCallback(async () => {
    await getData('lookup/getordertypes');
  }, []);

  let orderTypes: OrderType[] = getDataResponse?.data ?? [];

  useEffect(() => {
    init();
  }, [init]);

  return { orderTypes };
}
