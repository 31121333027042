import { SxProps, Theme } from '@mui/material';

export const accordionSummaryText: SxProps = {
  fontWeight: '550',
  fontSize: '20px',
  lineHeight: '30px',
};

export const iconContainer: SxProps = {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  cursor: 'pointer',
};

export const editIcon: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary[400],
  padding: '0px 5px 0px 15px',
});

export const editIconText: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary[400],
  fontWeight: 800,
});

export const editFormContainer: SxProps<Theme> = (theme: Theme) => ({
  padding: '30px 0px',
});

export const cancelIcon: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.error[500],
  padding: '0px 5px 0px 15px',
});

export const cancelIconText: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.error[500],
  fontWeight: 800,
});

export const saveIcon: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.success[500],
  padding: '0px 5px 0px 30px',
});

export const saveIconText: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.success[500],
  fontWeight: 800,
});
