import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@mui/material';
import { ServePreferences } from '../../../../../../../Models/Departments/Details/ServePreferences';
import CheckboxInput from '../../../../../../AddLocation/Components/UI/CheckboxInput';
import { ExpandMore } from '@mui/icons-material';
import { accordionSummaryText } from '../../../../../Styles/MainInformationTabStyles';

export default function CaseInfo({
  servePreferences,
  isExpanded,
  handleAccordionChange,
}: {
  servePreferences: ServePreferences | undefined;
  isExpanded: (panel: string) => boolean;
  handleAccordionChange: (panel: string) => (event: React.SyntheticEvent, isExpanding: boolean) => void
}) {
  return (
    <Accordion
    expanded={isExpanded('CaseInfo')}
    onChange={handleAccordionChange('CaseInfo')}>
      <AccordionSummary
        sx={accordionSummaryText}
        aria-controls="Case-content"
        id="Case-header"
        expandIcon={<ExpandMore />}
      >
        Case
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={6}>
            <CheckboxInput
              label="Require Date Of Loss"
              checked={servePreferences?.isRequireDateOfLoss}
              keyName={`servePreferences.isRequireDateOfLoss`}
              onChange={(e: any) => {}}
              onBlur={() => {}}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} pl={3}>
            <CheckboxInput
              label="Claim number"
              checked={servePreferences?.isClaimNumber}
              keyName={`servePreferences.isClaimNumber`}
              onChange={(e: any) => {}}
              onBlur={() => {}}
              disabled={true}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
