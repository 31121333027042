import * as Yup from 'yup';
import {
  maxContactsAllowed,
  maxEmailsAllowed,
  maxPhonesAllowed,
} from '../../settings/appConfig';
import moment from 'moment';
import useConstructAzureSearchParams from '../Locations/useConstructAzureSearchParams';

export default function useValidation() {
  const validateTimeFrom = (required?: boolean) => {
    let validationRule;
    if (required)
      validationRule = Yup.string().required('From time is required');
    else validationRule = Yup.string().notRequired();
    return validationRule.test(
      'is-less',
      'The "From" field should contain a value that is before the  "to" field',
      function (value) {
        if (value) {
          const to = this.parent.to;
          if (to) return moment(to).isSameOrAfter(moment(value));
        }
        return true;
      },
    );
  };
  const validateDateTo = (required?: boolean) => {
    let validationRule;
    if (required) validationRule = Yup.string().required('To date is required');
    else validationRule = Yup.string().notRequired();
    return validationRule.test(
      'is-greater',
      'The "From" field should contain a value that is before the  "to" field',
      function (value) {
        if (value) {
          const from = this.parent.from;
          if (from) return moment(from).isSameOrBefore(moment(value));
        }
        return true;
      },
    );
  };

  const validateDateFrom = (required?: boolean) => {
    let validationRule;
    if (required)
      validationRule = Yup.string().required('From date is required');
    else validationRule = Yup.string().notRequired();
    return validationRule.test(
      'is-greater',
      'The "From" field should contain a value that is before the  "to" field',
      function (value) {
        if (value) {
          const to = this.parent.to;
          if (to) return moment(to).isSameOrAfter(moment(value));
        }
        return true;
      },
    );
  };

  const validateTimeTo = (required?: boolean) => {
    let validationRule;
    if (required) validationRule = Yup.string().required('To time is required');
    else validationRule = Yup.string().notRequired();
    return validationRule.test(
      'is-greater',
      'The "From" field should contain a value that is before the  "to" field',
      function (value) {
        if (value) {
          const from = this.parent.from;
          if (from) return moment(from).isSameOrBefore(moment(value));
        }
        return true;
      },
    );
  };

  const validatePhoneNumber = (required?: boolean) => {
    let validationRule = Yup.string();
    if (required) validationRule = validationRule.required('Phone is required');
    return validationRule
      .matches(
        /^(\+\d{1,2})?(\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
        'Invalid phone',
      )
      .max(12, "Phone can't exceed 12 characters");
  };

  const validateEmail = (required?: boolean) => {
    let validationRule = Yup.string();

    if (required) validationRule = validationRule.required('Email is required');
    return validationRule
      .matches(
        /^$|^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        'Invalid email',
      )
      .max(80, "Email can't exceed 80 characters");
  };

  const validatePostalCode = (required?: boolean, maxLength: number = 5) => {
    let validationRule = Yup.string();
    if (required)
      validationRule = validationRule.required('Zip Code is required');
    return validationRule
      .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Invalid zip code')
      .max(maxLength, `Zip Code can't exceed ${maxLength} characters`);
  };

  const validateName = (
    required?: boolean,
    keyName: string = 'Name',
    maxLength: number = 120,
    minLength?: number,
  ) => {
    let validationRule = Yup.string();
    if (required)
      validationRule = validationRule.required(`${keyName} is required`);

    if (minLength)
      validationRule = validationRule.min(
        minLength,
        `${keyName} can't be less than ${minLength} characters`,
      );

    return validationRule
      .matches(
        /^[a-zA-Z0-9\s!@#$%^&*()_+=[\]{};':"\\|,.<>/?-]*$/,
        `Invalid ${keyName}`,
      )
      .max(maxLength, `${keyName} can't exceed ${maxLength} characters`);
  };

  const validateInt = (
    required?: boolean,
    keyName: string = 'Name',
    maxLength: number = 16,
  ) => {
    let validationRule = Yup.string();
    if (required)
      validationRule = validationRule.required(`${keyName} is required`);
    return validationRule
      .matches(/^(\d+|0)?(\.\d+)?$/, `Invalid ${keyName}`)
      .max(maxLength, `${keyName} can't exceed ${maxLength} characters`);
  };

  const validateURL = (required?: boolean, keyName: string = 'URL') => {
    let validationRule = Yup.string();
    if (required)
      validationRule = validationRule.required(`${keyName} is required`);

    return validationRule
      .matches(
        /^(https?:\/\/)?([\w.-]+)(\.[\w.-]+)(\/[\w%+\/-]*)*(\?[\w-]+=[\w%+\/-]+(&[\w-]+=[\w%+\/-]+)*)?(#[\w-]+)?$/i,
        `Invalid ${keyName}`,
      )
      .max(150, `${keyName} can't exceed 150 characters`);
  };

  const validateNotes = (required?: boolean, maxLength: number = 150) => {
    let validationRule = Yup.string();
    if (required) validationRule = validationRule.required(`Notes is required`);

    return validationRule.max(
      maxLength,
      `Notes can't exceed ${maxLength} characters`,
    );
  };

  const isPhoneValid = (value: any) => {
    const validationSchema = Yup.object().shape({
      phoneNumber: validatePhoneNumber(true),
      phoneTypeId: Yup.string().required('Phone type is required'),
      isMainPhone: Yup.boolean(),
    });
    try {
      validationSchema.validateSync(value);
      return true;
    } catch (error) {
      return false;
    }
  };

  const isPhoneNumberValid = (value: any) => {
    const phoneNumberValidationRule = validatePhoneNumber(true);
    try {
      phoneNumberValidationRule.validateSync(value);
      return true;
    } catch (error) {
      return false;
    }
  };

  const isEmailAddressValid = (value: any) => {
    const validationRule = validateEmail(true);
    try {
      validationRule.validateSync(value);
      return true;
    } catch (error) {
      return false;
    }
  };

  const isPhonesValid = (value: any[]) => {
    const validationSchema = Yup.array()
      .of(
        Yup.object().shape({
          phoneNumber: validatePhoneNumber(true),
          phoneTypeId: Yup.string().required('Phone type is required'),
          isMainPhone: Yup.boolean(),
        }),
      )
      .min(1, 'Phones must have at least 1 entry')
      .max(
        maxPhonesAllowed,
        `Phones can have at most ${maxPhonesAllowed} entries`,
      );
    try {
      validationSchema.validateSync(value);
      return true;
    } catch (error) {
      return false;
    }
  };
  const isContactValid = (value: any) => {
    const validationSchema = Yup.object().shape({
      name: validateName(true),
      email: validateEmail(true),
      phoneNumber: validatePhoneNumber(true),
      title: validateName(false, 'Title'),
      isDirectContact: Yup.boolean(),
    });

    try {
      validationSchema.validateSync(value);
      return true;
    } catch (error) {
      return false;
    }
  };

  const isContactsValid = (value: any) => {
    const validationSchema = Yup.array()
      .of(
        Yup.object().shape({
          name: validateName(true),
          email: validateEmail(true),
          phoneNumber: validatePhoneNumber(true),
          title: validateName(false, 'Title'),
          isDirectContact: Yup.boolean(),
        }),
      )
      .min(1)
      .max(
        maxContactsAllowed,
        `Contacts can have at most ${maxContactsAllowed} entries`,
      );

    try {
      validationSchema.validateSync(value);
      return true;
    } catch (error) {
      return false;
    }
  };

  const isAssociationRecordTypesValid = (value: any) => {
    const validationSchema = Yup.array().of(
      Yup.object().shape({
        recordTypeId: Yup.string().required('record type is required'),
        from: validateDateFrom(),
        to: validateDateTo(),
      }),
    );

    try {
      validationSchema.validateSync(value);
      return true;
    } catch (error) {
      return false;
    }
  };

  const isAssociationRecordTypeValid = (value: any) => {
    const validationSchema = Yup.object().shape({
      recordTypeId: Yup.string().required('record type is required'),
      from: validateDateFrom(),
      to: validateDateTo(),
    });

    try {
      validationSchema.validateSync(value);
      return true;
    } catch (error) {
      return false;
    }
  };

  const isEmailValid = (value: string) => {
    const validationSchema = Yup.object().shape({
      email: validateEmail(true),
    });
    try {
      validationSchema.validateSync(value);
      return true;
    } catch (error) {
      return false;
    }
  };

  const isEmailsValid = (value: string) => {
    const validationSchema = Yup.array()
      .of(
        Yup.object().shape({
          email: validateEmail(true),
        }),
      )
      .min(1)
      .max(
        maxEmailsAllowed,
        `Emails can have at most ${maxEmailsAllowed} entries`,
      );
    try {
      validationSchema.validateSync(value);
      return true;
    } catch (error) {
      return false;
    }
  };

  return {
    validateEmail,
    validateTimeFrom,
    validateTimeTo,
    validatePhoneNumber,
    validatePostalCode,
    validateName,
    validateInt,
    validateURL,
    validateNotes,
    isEmailValid,
    isEmailsValid,
    isContactValid,
    isContactsValid,
    isPhoneValid,
    isPhonesValid,
    isAssociationRecordTypeValid,
    isAssociationRecordTypesValid,
    validateDateFrom,
    validateDateTo,
    isPhoneNumberValid,
    isEmailAddressValid,
  };
}
