import { useEffect, useState } from 'react';
import useFetchOrderTypes from '../useFetchOrderTypes';
import useFetchRecordTypes from '../useFetchRecordTypes';
import moment from 'moment';
import useFetchProviders from '../useFetchProviders';
import useAssociationForm from '../addLocation/useAssociationForm';
import AssociationDetails from '../../../Models/Association/custodian-association-details';

export default function useEditAssociations({
  associations,
  editMode,
}: {
  associations: AssociationDetails[];
  editMode: boolean;
}) {
  const { recordTypes } = useFetchRecordTypes();
  const { orderTypes } = useFetchOrderTypes();
  const { providers } = useFetchProviders();

  const { associationsForm } = useAssociationForm();

  useEffect(() => {
    if (editMode) {
      associationsForm.setValues({
        associations:
          associations?.map((association) => ({
            id: association.id,
            custodianAssociationId: association?.associatedCustodian?.id,
            name: association?.associatedCustodian.name,
            recordTypes:
              association?.custodianAssociationRecordTypes?.map((record) => ({
                id: record.id,
                recordTypeId: record.recordTypeId,
                orderTypeId: record.orderTypeId,
                from: record.dateFrom ? moment(record.dateFrom) : null,
                to: record.dateTo ? moment(record.dateTo) : null,
                associationProviderId: record.custodianProviderId,
              })) ?? [],
          })) ?? [],
      });
    }
  }, [editMode]);

  return {
    associationsForm,
    recordTypes,
    providers,
    orderTypes,
  };
}
