import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import useValidation from '../../validation/useValidation';

export default function useUserRoleForm({
  roles,
  onSubmit,
  userRole,
}: {
  roles: any[];
  onSubmit: (values: any) => void;
  userRole: any;
}) {
  const { validateEmail, validateName } = useValidation();
  const validationSchema = Yup.object().shape({
    email: validateEmail(true),
    name: validateName(true),
    roleId: Yup.string(),
  });

  const initialValues = {
    email: userRole ? userRole.email : '',
    name: userRole ? userRole.name : '',
    roleId: userRole ? userRole.roleId : '',
  };

  const userRoleForm = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (userRoleForm.isValid) {
        onSubmit(values);
      }
    },
  });

  useEffect(() => {
    if (userRole && userRole.email && userRole.name) {
      userRoleForm.setValues({
        ...userRole,
      });
    }
  }, [userRole]);

  return { userRoleForm };
}
