import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import ApplicationUser from '../../../../Models/Users/ApplicationUser';
import ComponentLoader from '../../../../common/ComponentLoader/componentLoader';
import Styles from '../../../../assets/styles/tableStyles';
import UsersListItem from './UsersListItem';

export default function UsersList({
  users,
  isLoading,
  getAll,
}: {
  users: ApplicationUser[];
  isLoading: boolean;
  getAll: () => Promise<void>;
}) {
  return (
    <ComponentLoader isOpen={isLoading}>
      <TableContainer sx={Styles.tableContainer} component={Paper}>
        <Table aria-label="simple table">
          <TableHead sx={Styles.header}>
            <TableRow>
              <TableCell sx={Styles.tableHeader}>Name</TableCell>
              <TableCell sx={Styles.tableHeader}>Role</TableCell>
              <TableCell sx={Styles.tableHeader}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <UsersListItem
                key={user.id}
                user={user}
                statusChanged={async () => await getAll()}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ComponentLoader>
  );
}
