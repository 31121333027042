import { Box, FormControl, Grid } from '@mui/material';
import {
  selectedBox,
  unSelectedBox,
} from '../../../../assets/styles/appStyles';
export default function MultiSelectView({
  values,
  options,
  showOnlySelected,
}: {
  values: string[];
  options: any[];
  showOnlySelected?: boolean;
}) {
  return (
    <FormControl fullWidth>
      <Grid container>
        {options
          ?.filter((o) => (showOnlySelected ? values.includes(o.id) : true))
          .map((o) => {
            const selected = values.includes(o.id);
            return (
              <Grid item p={1} key={o.id}>
                <Box sx={selected ? selectedBox : unSelectedBox}>{o.name}</Box>
              </Grid>
            );
          })}
      </Grid>
    </FormControl>
  );
}
