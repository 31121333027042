import { useCallback } from 'react';
import useHttp from '../http/useHttp';
import useUI from '../context/useUI';

export default function usePatchCustodian() {
  const { showSnackbar } = useUI();
  const { patchDataResponse, patchData } = useHttp();

  const patchCustodian = useCallback(async (model: any) => {
    return await patchData('custodian', model)
      .then((result) => {
        if (result && result.status === 200) {
          showSnackbar(['custodian updated successfully!'], 'success');
          return result.data;
        }
      })
      .catch((err) => {
        showSnackbar(['custodian update failed!'], 'error');
        return null;
      });
  }, []);

  return { data: patchDataResponse?.data ?? null, patchCustodian };
}
