import { useEffect, useState, useCallback, useRef } from 'react';
import { CustodianSearchParams } from '../../Models/custodian/custodianSearchParams';
import { rowsPerPage } from '../../settings/appConfig';
import useHttp from '../http/useHttp';

export default function useFetchCustodiansSearchResult() {
  const { postDataResponse, postData } = useHttp();
  const [isLoading, setIsLoading] = useState(true);
  const totalCountRef = useRef(0);
  useEffect(() => {
    totalCountRef.current = postDataResponse?.data?.count ?? 0;
  }, [postDataResponse]);

  const [searchParams, setSearchParams] = useState<CustodianSearchParams>({
    pageSize: rowsPerPage,
    pageNo: 0
  });

  const init = async () => {
    try {
      setIsLoading(true);
      totalCountRef.current = 0;
      await postData('custodian/search', {
        pageNo: 0
      } as CustodianSearchParams);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }

  };
  useEffect(() => {
    init();
  }, []);

  const pageChanged = useCallback(
    async (pageNumber: number) => {
      try {
        setIsLoading(true);
        let newSearch: CustodianSearchParams = {
          searchText: searchParams.searchText,
          stateId: searchParams.stateId,
          pageSize: rowsPerPage,
          pageNo: pageNumber
        };
        await postData('custodian/search', newSearch);
        setSearchParams({ ...newSearch });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [searchParams],
  );

  const searchChanged = useCallback(async (searchText?: string, stateId?: number) => {
    try {
      setIsLoading(true);
      totalCountRef.current = 0;
      let newSearch: CustodianSearchParams = {
        searchText,
        stateId,
        pageSize: rowsPerPage,
        pageNo: 0
      };
      await postData('custodian/search', newSearch);
      setSearchParams({ ...newSearch });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }

  }, []);

  return {
    data: postDataResponse?.data ?? { count: totalCountRef.current, custodians: [] },
    pageChanged,
    searchChanged,
    isLoading
  };
}
