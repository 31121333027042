import { useEffect, useState, useCallback } from 'react';
import { rowsPerPage } from '../../settings/appConfig';
import useHttp from '../http/useHttp';

export default function useFetchResearchCustodiansSearchResult() {
  const { postDataResponse, postData } = useHttp();
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    setTotalCount(postDataResponse?.data?.totalCount ?? 0);
  }, [postDataResponse]);

  const [searchParams, setSearchParams] = useState<any>({
    locationName: '',
    phoneNumber: '',
    sourceLocation: '',
    address: '',
    city: '',
    state: '',
    postCode: '',
    altPhoneNumber: '',
    faxNumber: '',
    warnings: '',
    verifiedLocations: '',
    expeditors: '',
    attention: '',
    paymentForRecords: '',
    paymentTypeAccepted: '',
    receiveAuthByFax: '',
    rating: '',
    lastFulfillment: '',
    lastKnownCommunication: '',
    sourceId: '',
    pageSize: rowsPerPage,
    pageNo: 0,
    sortProperties: [],
  });

  const init = async () => {
    try {
      setIsLoading(true);
      setTotalCount(0);
      await postData('azureSearch/research-custodians', {
        ...searchParams,
        take: rowsPerPage,
        skip: 0,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    init();
  }, []);

  const pageChanged = async (pageNumber: number) => {
    try {
      setIsLoading(true);
      let newSearch = {
        ...searchParams,
        take: rowsPerPage,
        skip: pageNumber * rowsPerPage,
      };
      await postData('azureSearch/research-custodians', newSearch);
      setSearchParams({ ...newSearch });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const searchChanged = useCallback(async (search: any) => {
    try {
      setIsLoading(true);
      setTotalCount(0);
      let newSearch = {
        ...search,
        take: rowsPerPage,
        skip: 0,
      };
      setSearchParams({ ...newSearch });
      await postData('azureSearch/research-custodians', newSearch);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  return {
    data: postDataResponse?.data ?? { totalCount: totalCount, custodians: [] },
    pageChanged,
    searchChanged,
    isLoading,
    searchParams,
  };
}
