import { Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as Styles from '../../../assets/styles/appStyles';
import CustodianDetailsViewModel from '../../../Models/custodian/custodian-details-viewmodel';
import { horizontalLine } from '../../../assets/styles/appStyles';
import {
  gridContainer,
  infoGridItem,
} from '../Styles/CustodianBasicDetailsStyles';

export default function CustodianBasicDetails({
  custodian,
}: {
  custodian: CustodianDetailsViewModel;
}) {
  return (
    <>
      <Grid rowSpacing={3} container sx={gridContainer} spacing={2}>
        <Grid item xs={4} sx={infoGridItem}>
          <Typography align="left" sx={Styles.labelTitle}>
            Custodian Name
          </Typography>
          <Box>
            <Typography sx={Styles.labelText}>{custodian?.name}</Typography>
          </Box>
        </Grid>
        <Grid item xs={4} sx={infoGridItem}>
          <Typography align="left" sx={Styles.labelTitle}>
            Location Type
          </Typography>
          <Box>
            <Typography sx={Styles.labelText}>{custodian?.type}</Typography>
          </Box>
        </Grid>
        <Grid item xs={4} sx={infoGridItem}>
          <Typography align="left" sx={Styles.labelTitle}>
            Portal URL
          </Typography>
          <Box>
            <Typography sx={Styles.labelText}>
              {custodian?.portalURL}
            </Typography>
          </Box>
        </Grid>
        <Divider sx={horizontalLine} />
        {custodian.emailAddresses?.map((email, index) => (
          <Grid key={'email-' + index} item xs={4} sx={infoGridItem}>
            <Typography align="left" sx={Styles.labelTitle}>
              Email Address
            </Typography>
            <Box>
              <Typography sx={Styles.labelText}>{email}</Typography>
            </Box>
          </Grid>
        ))}
        {custodian.emailAddresses && custodian.emailAddresses.length > 0 && (
          <Divider sx={horizontalLine} />
        )}
        {custodian.phoneNumbers?.map((phoneNumber) => (
          <Grid container item rowSpacing={3} key={phoneNumber.id}>
            <Grid item xs={4} sx={infoGridItem}>
              <Typography align="left" sx={Styles.labelTitle}>
              Fax/Phone number
              </Typography>
              <Box>
                <Typography sx={Styles.labelText}>
                  {phoneNumber.phoneNumber}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4} sx={infoGridItem}>
              <Typography align="left" sx={Styles.labelTitle}>
                Number Type
              </Typography>
              <Box>
                <Typography sx={Styles.labelText}>
                  {phoneNumber.phoneType}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4} sx={infoGridItem}>
              <Typography align="left" sx={Styles.labelTitle}>
                Notes
              </Typography>
              <Box>
                <Typography sx={Styles.labelText}>-</Typography>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
