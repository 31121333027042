import { Box, Grid, Typography } from '@mui/material';
import CustodianAddressViewModel from '../../../Models/custodian/custodian-address-viewmodel';

import * as Styles from '../../../assets/styles/appStyles';
import { gridContainer, infoGridItem } from '../Styles/CustodianAddressDetailsStyles';

export default function CustodianAddressDetails({
  address,
}: {
  address: CustodianAddressViewModel | undefined;
}) {
  return (
    <Grid rowSpacing={3} container sx={gridContainer}>
      <Grid item xs={6} sx={infoGridItem}>
        <Typography align="left" sx={Styles.labelTitle}>
          Address
        </Typography>
        <Box>
          <Typography sx={Styles.labelText}>
            {address?.addressDetails}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={2} sx={infoGridItem}>
        <Typography align="left" sx={Styles.labelTitle}>
          City
        </Typography>
        <Box>
          <Typography sx={Styles.labelText}>{address?.city}</Typography>
        </Box>
      </Grid>
      <Grid item xs={2} sx={infoGridItem}>
        <Typography align="left" sx={Styles.labelTitle}>
          State
        </Typography>
        <Box>
          <Typography sx={Styles.labelText}>{address?.state}</Typography>
        </Box>
      </Grid>
      <Grid item xs={2} sx={infoGridItem}>
        <Typography align="left" sx={Styles.labelTitle}>
          Zip/Postal Code
        </Typography>
        <Box>
          <Typography sx={Styles.labelText}>{address?.postCode}</Typography>
        </Box>
      </Grid>
      <Grid item xs={3} sx={infoGridItem}>
        <Typography align="left" sx={Styles.labelTitle}>
          TimeZone
        </Typography>
        <Box>
          <Typography sx={Styles.labelText}>{address?.timeZone}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
