import { Grid, Typography } from '@mui/material';
import InsertDepartmentModel from '../../../../Models/Departments/InsertDepartmentModel';
import Panel from '../../../../components/UI/Panel';
import MultiSelectInput from '../UI/MultiSelectInput';
import DepartmentPhones from './DepartmentPhones';
import DepartmentBasicDetails from './DepartmentBasicDetails';
import DepartmentAddressDetails from './DepartmentAddressDetails';
import DepartmentContacts from './DepartmentContact';
import GenericSelectInput from '../UI/GenericSelectInput';
import CheckboxInput from '../UI/CheckboxInput';
import { useState } from 'react';
import styleClasses from '../../../../assets/styles/panelStyles';

export default function DepartmentMainInfo({
  form,
  department,
  formKeyName,
  departmentIndex,
  orderTypes,
  states,
  phoneTypes,
  recordTypes,
  timeZones,
  isWizard,
  copyCusodianAddress: copyCustodianAddress,
}: {
  department: InsertDepartmentModel;
  form: any;
  formKeyName: string;
  departmentIndex: number;
  orderTypes: any[];
  phoneTypes: any[];
  states: any[];
  recordTypes: any[];
  timeZones: any[];
  isWizard?: boolean;
  copyCusodianAddress?: (key: string) => void;
}) {
  const [asCustodianAddress, setAsCustodianAddress] = useState<boolean>(false);
  const togglePressRecordTypes = (r: string) => {
    let recordTypes = [
      ...form.values[formKeyName][departmentIndex].recordTypes,
    ];
    const recordIndex = recordTypes.findIndex((record) => record === r);
    if (recordIndex > -1) {
      recordTypes = [...recordTypes.filter((record) => record !== r)];
    } else recordTypes = [...recordTypes, r];
    form.setFieldValue(
      `${formKeyName}.${departmentIndex}.recordTypes`,
      recordTypes,
    );
  };
  const AddressHeader = () => {
    return (
      <>
        Address Details
        {isWizard && (
          <CheckboxInput
            label="Copy custodian address"
            checked={asCustodianAddress}
            keyName={''}
            onChange={(e: any) => {
              setAsCustodianAddress(e.target.checked);
              if (e.target.checked && copyCustodianAddress) {
                copyCustodianAddress(`${formKeyName}.${departmentIndex}`);
              }
            }}
            onBlur={() => {}}
          />
        )}
      </>
    );
  };
  return (
    <>
      <Panel headerText="Basic Details">
        <DepartmentBasicDetails
          departmentIndex={departmentIndex}
          form={form}
          formKeyName={formKeyName}
          department={department}
          orderTypes={orderTypes}
        />
        <DepartmentPhones
          departmentIndex={departmentIndex}
          form={form}
          formKeyName={formKeyName}
          phoneTypes={phoneTypes}
        />
      </Panel>

      <Panel headerText={isWizard ? AddressHeader() : 'Address Details'}>
        <DepartmentAddressDetails
          departmentIndex={departmentIndex}
          form={form}
          formKeyName={formKeyName}
          department={department}
          states={states}
          timeZones={timeZones}
          asCustodianAddress={asCustodianAddress}
        />
      </Panel>
      <Panel headerText="Contact Details">
        <DepartmentContacts
          departmentIndex={departmentIndex}
          form={form}
          formKeyName={formKeyName}
        />
      </Panel>
      <Panel headerText="Record Types">
        <Grid spacing={2} mx={1} px={1} container>
          <Grid item xs={4} px={2}>
            <GenericSelectInput
              hasError={false}
              errorMsg=""
              label="Select Record Type(s)"
              name="record-types"
              onBlur={() => {}}
              onChange={(r) => {
                form.setFieldValue(
                  `${formKeyName}.${departmentIndex}.recordTypes`,
                  r,
                );
              }}
              options={recordTypes}
              value={department.recordTypes}
              multiple
              required={true}
            />
          </Grid>
          <Grid item xs={12} px={2}>
            <MultiSelectInput
              options={recordTypes}
              errorMsg={
                form.errors[formKeyName] &&
                form.errors[formKeyName][departmentIndex] &&
                form.errors[formKeyName][departmentIndex].recordTypes
                  ? form.errors[formKeyName][departmentIndex].recordTypes
                  : ''
              }
              togglePress={togglePressRecordTypes}
              values={department.recordTypes}
              showOnlySelected
            />
          </Grid>
        </Grid>
      </Panel>
    </>
  );
}
