import './App.css';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import Login from './Pages/Login/Login';
import { PageLayout } from './layout/PageLayout';
import { BrowserRouter } from 'react-router-dom';
import AuthorizedRoutes from './Pages/Common/AuthorizedRoutes';
import UIProvider from './Providers/UIProvider';

function App() {
  return (
    <div>
      <AuthenticatedTemplate>
        <BrowserRouter>
          <UIProvider>
            <PageLayout>
              <AuthorizedRoutes />
            </PageLayout>
          </UIProvider>
        </BrowserRouter>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
