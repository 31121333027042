import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Box } from '@mui/system';
import Styles from '../Styles/SearchCustodiansStyles';
import { menuItem } from './../../../assets/styles/appStyles';


export default function CustodianSearchStates({
  onSearchChanged,
  states,
  searchText,
  setState,
  state,
  useNative, //for unit testing
}: {
  onSearchChanged: (searchText?: string, stateId?: number) => void;
  states: any[];
  searchText: string;
  state: number | undefined;
  setState: (s: number | undefined) => void;
  useNative: boolean | false;
}) {
  const handleChangeState = (value: string) => {
    setState(+value);
    onSearchChanged(searchText, +value);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="search-state-label">State</InputLabel>
        <Select
          native={useNative}
          labelId="search-state-label"
          id="search-state"
          value={(state ?? '').toString()}
          label={'State'}
          onChange={(e: SelectChangeEvent) => handleChangeState(e.target.value)}
        >
          {useNative ? (
            <option value={0}>all</option>
          ) : (
            <MenuItem key={'all'} value={0} sx={menuItem}>
              <Grid container sx={Styles.alignCenter}>
                <Grid item xs={10} sx={Styles.alignCenter}>
                  All
                </Grid>
              </Grid>
            </MenuItem>
          )}
          {states?.map((item, index) => {
            return useNative ? (
              <option key={item.id + index} value={item.id}>
                {item.name}
              </option>
            ) : (
              <MenuItem
                key={item.id + index}
                value={item.id}
                sx={menuItem}
              >
                <Grid container sx={Styles.alignCenter}>
                  <Grid item xs={10} sx={Styles.alignCenter}>
                    {item.name}
                  </Grid>
                </Grid>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
