import { useEffect, useCallback } from 'react';
import CustodianType from '../../Models/Locations/custodianType';
import useHttp from '../http/useHttp';

export default function useFetchCustodianTypes() {
  const { getDataResponse, getData } = useHttp();

  const init = useCallback(async () => {
    await getData('lookup/getcustodiantypes');
  }, []);

  let custodianTypes: CustodianType[] = getDataResponse?.data ?? [];

  useEffect(() => {
    init();
  }, [init]);

  return { custodianTypes };
}
