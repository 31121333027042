import {
  Tab,
  Tabs,
  MenuItem,
  Grid,
  FormControl,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import Department from '../../../../Models/Departments/Details/department';
import { useMemo, useState } from 'react';
import TabPanel from '../../../../components/UI/TabPanel';
import DepartmentInfo from './Components/DepartmentInfo';
import ServeInformation from './Components/ServeInformation';
import ServePreferencesInfo from './Components/ServePreference/ServePreferencesInfo';
import { Edit, Delete, Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { linkStyles, textWhite } from '../../../../assets/styles/appStyles';
import DiscardWizardModal from '../../../../Modals/DiscardWizardModal';
import useDeleteDepartment from '../../../../hooks/Locations/edit/useDeleteDepartment';
import styles from './departmentStyle';
import useAuth from '../../../../hooks/context/useAuth';
import { appActions } from '../../../../settings/appConfig';
import useUI from '../../../../hooks/context/useUI';

export default function DepartmentDetails({
  departments,
  custodianId,
}: {
  departments: Department[];
  custodianId: number;
}) {
  const currentDept = useMemo(
    () => (departments.length > 0 ? departments[0] : undefined),
    [departments],
  );
  const { isAuthorized } = useAuth();
  const { showSnackbar } = useUI();

  const [selectedTabIndx, setSelectedTabIndx] = useState(1);
  const [selectedDept, setSelectedDept] = useState<Department | undefined>(
    currentDept,
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndx(newValue);
  };

  const handleChange = (event: any) => {
    const dept =
      departments.find((p) => p.id === event.target.value) ?? departments[0];
    setSelectedDept(dept);
  };

  const onDeleteSuccess = (id: number) => {
    const index = departments.findIndex((d) => d.id == id);
    if (index > -1) departments.splice(index, 1);
    if (departments.length > 0) setSelectedDept(departments[0]);
    else setSelectedDept(undefined);
  };
  const {
    isDeleteModalOpened,
    isSaving,
    onCloseModal,
    onConfirmDelete,
    onDeleteClicked,
  } = useDeleteDepartment(onDeleteSuccess);

  const handleDeleteDepartment = (deptId: number) => {
    if (departments.length === 1) {
      showSnackbar(
        ['Custodian should contain at least one department'],
        'warning',
      );
    } else {
      onDeleteClicked(deptId);
    }
  };

  return (
    <>
      <Grid container mt={2} spacing={3}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <TextField
              value={selectedDept?.id}
              onChange={handleChange}
              select
              label="Select department"
            >
              {departments.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          {isAuthorized(appActions.addNewDepartment ?? '') === true && (
            <Button variant="outlined" sx={styles.addDepartmentBtn}>
              <Link
                to={`/custodian/${custodianId}/departments/insert`}
                style={linkStyles}
              >
                <Grid container>
                  <Grid item>
                    <Add />
                  </Grid>
                  <Grid item>
                    <Typography>Add Department</Typography>
                  </Grid>
                </Grid>
              </Link>
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container mt={2} spacing={3}>
        <Grid item xs={7}>
          <Tabs value={selectedTabIndx} onChange={handleTabChange}>
            <Tab
              value={1}
              label="Department Information"
              data-style={'outlined'}
            />
            <Tab value={2} label="Serve Information" data-style={'outlined'} />
            <Tab value={3} label="Serve Preferences" data-style={'outlined'} />
          </Tabs>
        </Grid>
        <Grid item>
          {selectedDept &&
            isAuthorized(appActions.editDepartments ?? '') === true && (
              <Link
                to={`/departments/edit/${selectedDept?.id}`}
                style={linkStyles}
              >
                <Button variant="outlined">
                  <Edit />
                  <Typography>Edit Department</Typography>
                </Button>
              </Link>
            )}
        </Grid>

        <Grid item>
          <DiscardWizardModal
            isModalOpened={isDeleteModalOpened}
            handleClose={onCloseModal}
            handleAgree={onConfirmDelete}
            title="Delete Department"
            message="Are you sure you want to delete this department?"
          />
          {selectedDept &&
            isAuthorized(appActions.editDepartments ?? '') === true &&
            (isSaving ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                color="error"
                onClick={() => handleDeleteDepartment(selectedDept.id)}
              >
                <Delete sx={textWhite} />
                <Typography>Delete Department</Typography>
              </Button>
            ))}
        </Grid>
      </Grid>

      <TabPanel value={selectedTabIndx} index={1}>
        <DepartmentInfo department={selectedDept} />
      </TabPanel>
      <TabPanel value={selectedTabIndx} index={2}>
        <ServeInformation
          departmentDaysAvailable={selectedDept?.departmentDaysAvailable ?? []}
          departmentServeMethods={selectedDept?.departmentServeMethods ?? []}
          portalNote={selectedDept?.portalNotes}
        />
      </TabPanel>
      <TabPanel value={selectedTabIndx} index={3}>
        <ServePreferencesInfo
          servePreferences={selectedDept?.servePreferences}
          files={selectedDept?.files ?? []}
        />
      </TabPanel>
    </>
  );
}
