import { Box, Grid } from '@mui/material';
import GenericInput from '../UI/GenericInput';
import usePhonesHelperMethods from '../../../../hooks/UI/usePhonesHelperMethods';

export default function DepartmentContacts({
  form,
  formKeyName,
  departmentIndex,
}: {
  form: any;
  formKeyName: string;
  departmentIndex: number;
}) {
  const arrayFieldHasError = (
    arrayName: string,
    itemIndex: number,
    fieldName: string,
  ) => {
    return form.errors &&
      form.errors[formKeyName] &&
      form.errors[formKeyName][departmentIndex] &&
      form.errors[formKeyName][departmentIndex][arrayName] &&
      form.errors[formKeyName][departmentIndex][arrayName][itemIndex] &&
      form.errors[formKeyName][departmentIndex][arrayName][itemIndex][fieldName]
      ? true
      : false;
  };
  const arrayFieldErrorMessage = (
    arrayName: string,
    itemIndex: number,
    fieldName: string,
  ) => {
    return form.errors &&
      form.errors[formKeyName] &&
      form.errors[formKeyName][departmentIndex] &&
      form.errors[formKeyName][departmentIndex][arrayName] &&
      form.errors[formKeyName][departmentIndex][arrayName][itemIndex] &&
      form.errors[formKeyName][departmentIndex][arrayName][itemIndex][fieldName]
      ? form.errors[formKeyName][departmentIndex][arrayName][itemIndex][
          fieldName
        ]
      : '';
  };
  const { formatPhoneNumberOnChange } = usePhonesHelperMethods();

  return (
    <Box pt={2}>
      {form.values[formKeyName][departmentIndex].contacts &&
        form.values[formKeyName][departmentIndex].contacts.length > 0 &&
        form.values[formKeyName][departmentIndex].contacts.map(
          (p: any, i: number) => (
            <Grid spacing={2} mx={1} px={1} container key={i}>
              <Grid item xs={4} px={2}>
                <GenericInput
                  name={`${formKeyName}.${departmentIndex}.contacts.${i}.name`}
                  maxLength={120}
                  label="Contact"
                  placeholder="Contact"
                  value={p.name}
                  onChange={(e: any) => {
                    form.setFieldValue(
                      `${formKeyName}.${departmentIndex}.contacts.${i}.name`,
                      e.target.value,
                    );
                  }}
                  errorMsg={arrayFieldErrorMessage(
                    'contacts',
                    i,
                    'name',
                  )}
                  hasError={arrayFieldHasError('contacts', i, 'name')}
                  onBlur={() => {
                    form.setFieldTouched(
                      `${formKeyName}[${departmentIndex}].contacts[${i}].name`,
                      true,
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4} px={2}>
                <GenericInput
                  name={`${formKeyName}.${departmentIndex}.contacts.${i}.phoneNumber`}
                  label="Phone"
                  maxLength={12}
                  placeholder="xxx-xxx-xxxx"
                  inputMode="tel"
                  value={p.phoneNumber}
                  onChange={(e: any) => {
                    formatPhoneNumberOnChange(e);
                    form.setFieldValue(
                      `${formKeyName}.${departmentIndex}.contacts.${i}.phoneNumber`,
                      e.target.value,
                    );
                  }}
                  errorMsg={arrayFieldErrorMessage(
                    'contacts',
                    i,
                    'phoneNumber',
                  )}
                  hasError={arrayFieldHasError(
                    'contacts',
                    i,
                    'phoneNumber',
                  )}
                  onBlur={() => {
                    form.setFieldTouched(
                      `${formKeyName}[${departmentIndex}].contacts[${i}].phoneNumber`,
                      true,
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4} px={2}>
                <GenericInput
                  name={`${formKeyName}.${departmentIndex}.contacts.${i}.title`}
                  maxLength={120}
                  label="Title"
                  placeholder="Contact Title"
                  value={p.title}
                  onChange={(e: any) => {
                    form.setFieldValue(
                      `${formKeyName}.${departmentIndex}.contacts.${i}.title`,
                      e.target.value,
                    );
                  }}
                  errorMsg={arrayFieldErrorMessage(
                    'contacts',
                    i,
                    'title',
                  )}
                  hasError={arrayFieldHasError('contacts', i, 'title')}
                  onBlur={() => {
                    form.setFieldTouched(
                      `${formKeyName}[${departmentIndex}].contacts[${i}].title`,
                      true,
                    );
                  }}
                />
              </Grid>

              <Grid item xs={4} px={2}>
                <GenericInput
                  name={`${formKeyName}.${departmentIndex}.contacts.${i}.email`}
                  label="Contact Email"
                  maxLength={80}
                  placeholder="Contact Email"
                  value={p.email}
                  onChange={(e: any) => {
                    form.setFieldValue(
                      `${formKeyName}.${departmentIndex}.contacts.${i}.email`,
                      e.target.value,
                    );
                  }}
                  errorMsg={arrayFieldErrorMessage(
                    'contacts',
                    i,
                    'email',
                  )}
                  hasError={arrayFieldHasError('contacts', i, 'email')}
                  onBlur={() => {
                    form.setFieldTouched(
                      `${formKeyName}[${departmentIndex}].contacts[${i}].email`,
                      true,
                    );
                  }}
                />
              </Grid>
            </Grid>
          ),
        )}
    </Box>
  );
}
