import { useFormik } from 'formik';
import * as Yup from 'yup';
import useValidation from '../../validation/useValidation';
export default function useAssociationForm() {
  const { validateDateFrom, validateDateTo } = useValidation();
  const validationSchema = Yup.object().shape({
    associations: Yup.array().of(
      Yup.object().shape({
        recordTypes: Yup.array().of(
          Yup.object().shape({
            recordTypeId: Yup.string().required('record type is required'),
            from: validateDateFrom(),
            to: validateDateTo(),
          }),
        ),
      }),
    ),
  });
  const associationsForm: any = useFormik({
    initialValues: {
      associations: [],
    },
    validateOnChange: true,
    validationSchema,
    onSubmit: (values) => {},
  });
  return { associationsForm };
}
