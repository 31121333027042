import { Grid } from '@mui/material'
import uploadFilesStyles from '../../../../assets/styles/uploadFilesStyles'
import { FilePond, registerPlugin, FileStatus } from 'react-filepond';

import 'filepond/dist/filepond.min.css';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { useEffect, useRef, useState } from 'react';
import useUploadFiles from '../../../../hooks/Locations/useUploadFiles';
import { FilePondFile } from 'filepond';
import axios from 'axios';

// Register the plugins
registerPlugin(
	FilePondPluginFileValidateSize,
	FilePondPluginFileRename,
	FilePondPluginFileValidateType);

export default function UploadFilesComponent(
	{
		keyName,
		acceptMultipleFiles,
		fileTypes,
		maxNumberOfFiles,
		maxFileSize,
		initialFiles,
		updateFormFiles,
		setIsBusy,
	}: {
		keyName: string,
		acceptMultipleFiles: boolean;
		fileTypes?: string[];
		maxNumberOfFiles: number;
		maxFileSize: string;
		initialFiles: any[];
		updateFormFiles: (pondFiles: FilePondFile[]) => void;
		setIsBusy: React.Dispatch<React.SetStateAction<boolean>>;
	}) {

	const latestFilesIdsRef = useRef<string[]>([]);
	const filePondRef = useRef<any>(null);

	useEffect(() => {
		latestFilesIdsRef.current = initialFiles.map(f => f.id);
	}, [initialFiles]);

	const { uploadDepartmentTempFile, deleteTempFile } = useUploadFiles();

	const handleInit = () => {
		updateFormFiles(initialFiles);
		setIsBusy(false);
	};

	let processFile = async (fieldName: any, file: any, metadata: any, load: any, error: any, progress: any, abort: any) => {
		setIsBusy(true);
		progress();
		const cancelTokenSource = axios.CancelToken.source();
		const abortController = new AbortController();
		if (!file.url) {
			await uploadDepartmentTempFile(file, abortController, cancelTokenSource)
				.then((result) => {
					file.url = result.uri;
					load(result.uri);
					if (filePondRef.current) {
						setFilesUpdated(filePondRef.current.getFiles(), true);
					  }
				}).catch((err) => {
					console.error('Encountered an error while uploading the file', err);
					error();
				})
		}
		else {
			load(file.url);
		}

		return {
			abort: () => {
				cancelTokenSource.cancel();
				abortController.abort();
				abort();
			},
		};
	}

	let revertFile = async (uniqueFileId: any, load: any, error: any) => {
		try {
			setIsBusy(true);
			const deletedFile = initialFiles.find(f => f.file.url === uniqueFileId);

			if (!deletedFile) {
				return;
			}

			latestFilesIdsRef.current = latestFilesIdsRef.current.filter(id => id !== deletedFile.id);

			const url = deletedFile.file.url?.substring(0, deletedFile.file.url.indexOf('?'));
			await deleteTempFile(url);

			let files = initialFiles.filter((file) => file.id !== deletedFile.id && latestFilesIdsRef.current.some(id => id === file.id));
			updateFormFiles(files);
			setIsBusy(false);
		}
		catch (err) {
			console.error("Encountered an error while deleting the file", err);
			setIsBusy(false);
			error();
		}
	}

	let onAddFile = () => {
		setIsBusy(true);
	}

	let setFilesUpdated = (pondFiles: FilePondFile[], forceUpdate?: boolean) => {
		//ignore updating files if we are rerendering by checking that all files are already uploaded
		if (pondFiles.every(f => (f.file as any).url) &&
			latestFilesIdsRef.current.length === pondFiles.length
			&& !forceUpdate) {
			return
		}
		else {
			latestFilesIdsRef.current = pondFiles.map(f => f.id);
			updateFormFiles(pondFiles);
			updateLoadingStatus(pondFiles);
		}
	}

	let updateLoadingStatus = (pondFiles: FilePondFile[]) => {
		let allFilesWithURLs = pondFiles.every(f => (f.file as any).url);
		let allFilesProcessed = pondFiles.every(f => f.status === FileStatus.PROCESSING_COMPLETE);

		if (allFilesWithURLs || allFilesProcessed) {
			setIsBusy(false);
			return;
		}
	}

	let onProcessFile = () => {
		updateLoadingStatus(initialFiles);
	}

	return (
		<>
			<Grid container sx={uploadFilesStyles.gridStyles}
				data-testid={keyName}>
				<Grid item xs={12} sx={uploadFilesStyles.uploadSectionStyles}>
					<FilePond
					    ref={filePondRef}
						name="files"
						id={'file-uploader'}
						files={initialFiles}
						allowDrop
						dropOnElement
						dropOnPage
						allowMultiple={acceptMultipleFiles}
						server={{
							process: processFile,
							revert: revertFile
						}}
						oninit={() => handleInit()}
						onupdatefiles={setFilesUpdated}
						onaddfile={onAddFile}
						onprocessfile={onProcessFile}
						allowFileTypeValidation
						acceptedFileTypes={fileTypes}
						maxFiles={maxNumberOfFiles}
						labelFileTypeNotAllowed="Invalid File Format!"
						labelIdle={`Drag & Drop your files or <span class="filepond--label-action"> Browse </span> <br /> 
						Please note the maximum number of individual or zipped files is ${maxNumberOfFiles}. <br/>`}
						allowFileSizeValidation
						maxFileSize={maxFileSize}
					/>
				</Grid>
			</Grid>
		</>
	)
}
