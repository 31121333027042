import { FormControl, TextField, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { requiredStar } from '../../../../assets/styles/appStyles';

export default function GenericInput({
  name,
  label,
  placeholder,
  maxLength,
  inputMode,
  disabled,
  onChange,
  onBlur,
  value,
  hasError,
  errorMsg,
  required,
}: {
  name: string;
  label: string;
  placeholder: string;
  maxLength: number;
  inputMode?:
    | 'search'
    | 'text'
    | 'email'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal'
    | undefined;
  disabled?: boolean;
  onChange: (e: any) => void;
  onBlur: (e?: any) => void;
  value: string;
  hasError?: boolean;
  errorMsg?: ReactNode | string;
  required?: boolean;
}) {
  return (
    <FormControl fullWidth>
      <TextField
        id={name}
        disabled={disabled}
        name={name}
        label={
          <>
            {label}
            {required && (
              <Typography component={'span'} sx={requiredStar}>
                *
              </Typography>
            )}
          </>
        }
        placeholder={placeholder}
        variant="outlined"
        inputMode={inputMode ?? 'text'}
        inputProps={{ maxLength: maxLength }}
        onChange={(e: any) => onChange(e)}
        onBlur={(e?: any) => onBlur(e)}
        value={value}
        error={hasError}
        helperText={errorMsg}
      />
    </FormControl>
  );
}
