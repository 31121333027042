import { useEffect, useCallback } from 'react';
import useHttp from '../http/useHttp';
export default function useFetchProviders() {
  const { getDataResponse, getData } = useHttp();
  const init = useCallback(async () => {
    await getData('lookup/getproviders');
  }, []);
  let providers: any[] = getDataResponse?.data ?? [];
  useEffect(() => {
    init();
  }, [init]);
  return { providers };
}