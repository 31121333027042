import { DepartmentFile } from '../../../../Models/Departments/Details/file';
import FilesInfo from '../../../Details/Components/Department/Components/ServePreference/Components/FilesInfo';

export default function DepartmentEditOldFiles({
  form,
  departmentIndex,
  formKeyName,
}: {
  form: any;
  formKeyName: string;
  departmentIndex: number;
}) {
  const onRemoveFile = (id: any) => {
    const index = form.values[formKeyName][
      departmentIndex
    ].servePreferences.oldFiles.findIndex(
      (f: DepartmentFile) => f.id === id,
    );
    if (index > -1) {
      const oldFilesToBeDeleted = [
        ...form.values[formKeyName][departmentIndex].servePreferences
          .oldFilesToBeDeleted,
      ];
      oldFilesToBeDeleted.push(
        form.values[formKeyName][departmentIndex].servePreferences.oldFiles[
          index
        ],
      );
      form.setFieldValue(
        `${formKeyName}.${departmentIndex}.servePreferences.oldFilesToBeDeleted`,
        oldFilesToBeDeleted,
      );
      const oldFiles = [
        ...form.values[formKeyName][departmentIndex].servePreferences.oldFiles,
      ];
      oldFiles.splice(index, 1);
      form.setFieldValue(
        `${formKeyName}.${departmentIndex}.servePreferences.oldFiles`,
        oldFiles,
      );
    }
  };
  return form &&
    form.values &&
    form.values[formKeyName] &&
    form.values[formKeyName][departmentIndex] &&
    form.values[formKeyName][departmentIndex].servePreferences &&
    form.values[formKeyName][departmentIndex].servePreferences.oldFiles &&
    form.values[formKeyName][departmentIndex].servePreferences.oldFiles.length >
      0 ? (
    <FilesInfo
      files={
        form.values[formKeyName][departmentIndex].servePreferences.oldFiles
      }
      onRemoveFile={onRemoveFile}
    />
  ) : null;
}
