import {
  Collapse,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { CustodianDepartmentSearchResult } from '../../../Models/custodian/custodianSearchResult';
import Styles from '../Styles/ListCustodiansStyles';

export default function CustodianDepartmentTableRow({
  departments,
  custodianId,
  isRowExpanded,
  unmountCollapsedOnExit,
}: {
  departments: CustodianDepartmentSearchResult[];
  custodianId: number;
  isRowExpanded: (id: number) => boolean;
  unmountCollapsedOnExit: boolean;
}) {
  return (
    <TableRow>
      <TableCell sx={Styles.expandedDepartmentCellStyles} colSpan={6}>
        <Collapse
          in={isRowExpanded(custodianId)}
          timeout="auto"
          unmountOnExit={unmountCollapsedOnExit}
        >
          <Typography padding={'5px'} color={'black'} fontWeight={'bold'}>
            Departments:
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={Styles.tableSecondaryHeaderStyles} width={'20%'}>
                  Name
                </TableCell>
                <TableCell sx={Styles.tableSecondaryHeaderStyles} width={'30%'}>
                  Address
                </TableCell>
                <TableCell sx={Styles.tableSecondaryHeaderStyles} width={'15%'}>
                  City
                </TableCell>
                <TableCell sx={Styles.tableSecondaryHeaderStyles} width={'20%'}>
                  State
                </TableCell>
                <TableCell width={'10%'}></TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            {departments && departments.length > 0 ? (
              <TableBody>
                {departments.map((departmentRow: any, index) => (
                  <TableRow key={index}>
                    <TableCell sx={Styles.departmentTableCellStyles}>
                      {departmentRow.name}
                    </TableCell>
                    <TableCell sx={Styles.departmentTableCellStyles}>
                      {departmentRow.address}
                    </TableCell>
                    <TableCell sx={Styles.departmentTableCellStyles}>
                      {departmentRow.city}
                    </TableCell>
                    <TableCell sx={Styles.departmentTableCellStyles}>
                      {departmentRow.stateName}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <Grid sx={Styles.loaderStyles}>
                <Typography id="modal-modal-title" variant="h6">
                  No departments found
                </Typography>
              </Grid>
            )}
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
