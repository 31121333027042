import { useFormik } from 'formik';
import * as Yup from 'yup';
import useValidation from '../../validation/useValidation';

export default function useBillingInfoForm({ states }: { states: any[] }) {
  const { validateName, validatePhoneNumber, validatePostalCode, validateInt } =
    useValidation();

  const validationSchema = Yup.object().shape({
    payeeName: validateName(true),
    payeeCity: Yup.string()
      .required('City is required')
      .max(40, "City can't exceed 40 characters"),
    payeeStateId: Yup.string()
      .required('State is required')
      .oneOf(
        states.map((o) => o.id.toString()),
        'Invalid State',
      ),
    payeeAddress: Yup.string()
      .required('Address is required')
      .max(120, "Address can't exceed 120 characters"),
    payeeContact: validatePhoneNumber(true),
    payeePostCode: validatePostalCode(true),
    processingFeesSubpoena: validateInt(false, 'Processing Fees Subpoena'),
    processingFeesAuthorization: validateInt(
      false,
      'Processing Fees Authorization',
    ),
    initialProcessingFeeNotes: Yup.string().max(
      150,
      "Notes can't exceed 150 characters",
    ),
  });
  const billingInfoForm: any = useFormik({
    initialValues: {
      payeeName: '',
      payeeAddress: '',
      payeeContact: '',
      payeeCity: '',
      payeePostCode: '',
      payeeStateId: '',
      invoiceBeforeRecords: false,
      invoicePaymentMethods: [],
      processingFeesMethods: [],
      initialProcessingFeeNotes: '',
    },
    validationSchema,
    onSubmit: (values) => {},
  });

  const checkboxClickHandler = (id: string, keyName: string) => {
    const newItems = [...billingInfoForm.values[keyName]];
    const itemIndex = billingInfoForm.values[keyName].findIndex(
      (i: string) => i === id,
    );
    if (itemIndex > -1) {
      newItems.splice(itemIndex, 1);
    } else {
      newItems.push(id);
    }
    billingInfoForm.setFieldValue(keyName, newItems);
  };

  return {
    billingInfoForm,
    checkboxClickHandler,
  };
}
