export const azureConfiguration = {
  clientId: window._env_.REACT_APP_AZURE_CLIENT_ID as string,
  resourceId: window._env_.REACT_APP_AZURE_RESOURCE_ID as string,
  authority: window._env_.REACT_APP_AZURE_AUTHORITY as string,
  redirectId: window._env_.REACT_APP_AZURE_REDIRECT_URI as string,
  resourceScope: window._env_.REACT_APP_AZURE_RESOURCE_SCOPE as string,

};
export const msalConfig = {
  auth: {
    clientId: azureConfiguration.clientId,
    resourceId: azureConfiguration.resourceId,
    authority: azureConfiguration.authority,
    redirectUri: azureConfiguration.redirectId,
    scopes: ['openid', 'profile', 'User.Read'],
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ['user.read'],
};

// To get Access token for locations Backend
//`${window._env_.MSAL_AUTH_RESOURCE_ID}/access.locations`
export const accessTokenScopes = [
  `${azureConfiguration.resourceId}/${azureConfiguration.resourceScope}`,
  // if you try to request a premission that is not granted in the azure AD, you will get 400 bad request error
  // `${azureConfiguration.resourceId}/locations_write`,
];
