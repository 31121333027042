import { useState, MouseEvent } from 'react';
import AssociationDetails from '../../../../Models/Association/custodian-association-details';
import AssociationsListItemDetails from './AssociationsListItemDetails';
import Associations from '../../../AddLocation/Components/Association/Associations';
import useEditAssociations from '../../../../hooks/Locations/edit/useEditAssociations';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { textPrimary } from '../../../../assets/styles/appStyles';
import usePostAssociations from '../../../../hooks/Locations/edit/usePostAssociations';
import { useParams } from 'react-router';
import { CancelOutlined, Edit, SaveOutlined } from '@mui/icons-material';
import {
  cancelIcon,
  cancelIconText,
  iconContainer,
  saveIcon,
  saveIconText,
} from '../../Styles/MainInformationTabStyles';

export default function AssociationsDetails({
  associations,
  setAssociationHasUpdate,
}: {
  associations: AssociationDetails[];
  setAssociationHasUpdate?: (_: boolean) => void;
}) {
  const { id } = useParams();

  const [expandedPanel, setExpandedPanel] = useState<number | undefined>(
    associations.length > 0 ? associations.map((a) => a.id)[0] : undefined,
  );

  const togglePanel = (id: number) => (_: any, isExpanding: boolean) => {
    if (isExpanding) setExpandedPanel(id);
    else setExpandedPanel(undefined);
  };

  const [editMode, setEditMode] = useState<boolean>(false);

  const editClickHandler = (e: MouseEvent<any>) => {
    e.stopPropagation();
    setEditMode(true);
  };

  const cancelClickHandler = () => {
    setEditMode(false);
  };

  const saveClicked = async () => {
    associationsForm.submitForm();
    setAssociationHasUpdate!(false);
    let errors = await associationsForm.validateForm(associationsForm.values);
    if (Object.keys(errors).length === 0) {
      const result = await edit(associationsForm.values.associations);
      if (result) {
        setAssociationHasUpdate!(true);
        setEditMode(false);
      }
    }
  };

  const { associationsForm, orderTypes, providers, recordTypes } =
    useEditAssociations({ associations, editMode });

  const { edit, isSaving } = usePostAssociations(id);

  return (
    <>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        p={1}
      >
        <Grid item>
          <Typography sx={textPrimary}>
            Total number of associations (
            {!editMode
              ? associations.length
              : associationsForm.values?.associations?.length ?? 0}
            )
          </Typography>
        </Grid>
        {!editMode && (
          <Grid item>
            <Button variant="outlined" onClick={editClickHandler}>
              <Edit />
              <Typography>Edit Associations</Typography>
            </Button>
          </Grid>
        )}
        {editMode && (
          <Grid item>
            <Grid container spacing={1} mb={1}>
              <Grid item>
                {!isSaving && (
                  <Box
                    data-testid="cancel"
                    sx={iconContainer}
                    onClick={cancelClickHandler}
                  >
                    <CancelOutlined sx={cancelIcon} />
                    <Typography sx={cancelIconText}>Cancel</Typography>
                  </Box>
                )}
                {isSaving && <CircularProgress />}
              </Grid>
              <Grid item>
                {!isSaving && (
                  <Box
                    data-testid="save"
                    sx={iconContainer}
                    onClick={saveClicked}
                  >
                    <SaveOutlined sx={saveIcon} />
                    <Typography sx={saveIconText}>Save</Typography>
                  </Box>
                )}
                {isSaving && <CircularProgress />}
              </Grid>
            </Grid>
          </Grid>
        )}
        {editMode &&
        associationsForm &&
        associationsForm.values.associations ? (
          <Grid container my={2}>
            <Grid item xs={12}>
              <form
                name={'associations'}
                onSubmit={associationsForm.handleSubmit}
              >
                <Associations
                  form={associationsForm}
                  orderTypes={orderTypes}
                  supportedRecordTypes={recordTypes}
                  providers={providers}
                />
              </form>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {!editMode &&
        associations?.map((association: AssociationDetails) => (
          <AssociationsListItemDetails
            key={association.id}
            association={association}
            expanded={expandedPanel === association.id}
            togglePanel={togglePanel}
          />
        ))}
    </>
  );
}
