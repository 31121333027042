import useHttp from '../http/useHttp';

export default function useFetchFileSasUrl() {
  const { postData } = useHttp();

  const getFileSasUrl = async (url: string) => {
    const response = await postData(`Blob/getfilesasurl`, url);
    return response?.data ?? '';
  };

  return { getFileSasUrl };
}
