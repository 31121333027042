import { useCallback, useState } from 'react';
import useValidation from '../../validation/useValidation';
import useUI from '../../context/useUI';
import useHttp from '../../http/useHttp';

export default function usePostAssociations(custodianId: any) {
  const { showSnackbar } = useUI();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { postDataResponse, postData } = useHttp();
  const { isAssociationRecordTypeValid } = useValidation();

  const edit = useCallback(async (model: any) => {
    const associations = model;

    associations.forEach((element: any) => {
      element.recTypes = [
        ...element.recordTypes
          .filter((e: any) => isAssociationRecordTypeValid(e))
          .map((record: any) => ({
            ...record,
          })),
      ];
    });

    const data = {
      cusId: custodianId,
      associations: associations.map((a: any) => {
        const recordTypes = a.recTypes;
        delete a.recTypes;
        return {
          ...a,
          recordTypes,
        };
      }),
    };
    try {
      setIsSaving(true);
      const result = await postData('custodian/edit-associations', data);
      setIsSaving(false);
      if (result && result.data && result.data == true) {
        showSnackbar(['custodian saved successfully!'], 'success');
        return true;
      }
    } catch (error) {
      setIsSaving(false);
    }
  }, []);

  return { isSaving, data: postDataResponse?.data ?? null, edit };
}
