import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  maxContactsAllowed,
  maxPhonesAllowed,
  maxEmailsAllowed,
} from '../../../settings/appConfig';
import useValidation from '../../validation/useValidation';

export default function useMainInfoForm({
  phoneTypes,
  custodianTypes,
  states,
}: {
  phoneTypes: any[];
  custodianTypes: any[];
  states: any[];
}) {
  const {
    validateName,
    validateURL,
    validatePhoneNumber,
    validateEmail,
    validatePostalCode,
  } = useValidation();

  const validationSchema = Yup.object().shape({
    name: validateName(true, 'Name', 120, 3),
    typeId: Yup.string().oneOf(
      custodianTypes.map((o) => o.id.toString()),
      'Invalid custodian type',
    ),
    portalUrl: validateURL(),
    phones: Yup.array()
      .of(
        Yup.object().shape({
          phoneNumber: validatePhoneNumber(true),
          phoneTypeId: Yup.string()
            .oneOf(
              phoneTypes.map((o) => o.id.toString()),
              'Invalid Phone type',
            )
            .required('Phone type is required'),
          isMainPhone: Yup.boolean(),
          hasDuplicateError: Yup.boolean(),
        }),
      )
      .min(1, 'Phones must have at least 1 entry')
      .max(
        maxPhonesAllowed,
        `Phones can have at most ${maxPhonesAllowed} entries`,
      ).test('unique', 'Cannot insert duplicate phones', function (value) {
        const { createError, path } = this;
        if (value && Array.isArray(value) && value.length > 1) {
          const phones = value?.map((v) => v.phoneNumber);
          if (new Set(phones).size !== phones.length)
            return createError({
              path,
              message: 'Cannot insert duplicate phones',
            });
        }
        return true;
      }),
    emails: Yup.array()
      .of(
        Yup.object().shape({
          email: validateEmail(),
          hasDuplicateError: Yup.boolean(),
        }),
      )
      .max(
        maxEmailsAllowed,
        `Email can have at most ${maxEmailsAllowed} entries`,
      )
      .test('unique', 'Cannot insert duplicate emails', function (value) {
        const { createError, path } = this;
        if (value && Array.isArray(value) && value.length > 1) {
          const emails = value?.map((v) => v.email);
          if (new Set(emails).size !== emails.length)
            return createError({
              path,
              message: 'Cannot insert duplicate emails',
            });
        }
        return true;
      }),

    addressInfo: Yup.object().shape({
      addressDetails: Yup.string()
        .max(120, "Address can't exceed 120 characters")
        .required('Address is required'),
      city: Yup.string()
        .max(40, "City can't exceed 40 characters")
        .required('City is required'),
      stateId: Yup.string()
        .required('State is required')
        .oneOf(
          states.map((o) => o.id.toString()),
          'Invalid State',
        ),
      postCode: validatePostalCode(true),
    }),
    contacts: Yup.array()
      .of(
        Yup.object().shape({
          name: validateName(),
          email: validateEmail(),
          phoneNumber: validatePhoneNumber(),
          title: validateName(false, 'Title'),
          isDirectContact: Yup.boolean(),
        }),
      )
      .max(
        maxContactsAllowed,
        `Contacts can have at most ${maxContactsAllowed} entries`,
      ),
  });

  const mainInfoForm: any = useFormik({
    initialValues: {
      name: '',
      typeId: null,
      addressInfo: {
        addressDetails: '',
        city: '',
        stateId: null,
        postCode: '',
        timeZoneId: null,
      },
      emails: [{ email: '', hasDuplicateError: false  }],
      portalUrl: '',
      phones: [{ isMainPhone: false, phoneNumber: '', phoneTypeId: '', hasDuplicateError: false  }],
      contacts: [
        {
          isDirectContact: false,
          email: '',
          phoneNumber: '',
          name: '',
          title: '',
        },
      ],
    },
    validationSchema,
    onSubmit: (values) => {},
  });
  return {
    mainInfoForm,
  };
}
