import { useCallback, useEffect, useState } from 'react';
import useHttp from '../http/useHttp';
import Role from '../../Models/Roles/roles';

export default function useFetchRole(id: number) {
  const { getDataResponse, getData } = useHttp();
  const [isLoading, setIsLoading] = useState(false);

  const init = useCallback(async (id: number) => {
    try {
      setIsLoading(true);
      await getData(`role/${id}`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  let role: Role = getDataResponse?.data ?? null;

  useEffect(() => {
    if (id > 0) init(id);
  }, [init, id]);

  return { role, isLoading };
}
