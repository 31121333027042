import { useFormik } from 'formik';
import { maxDepartmentsAllowed } from '../../../settings/appConfig';
import * as Yup from 'yup';
import InsertDepartmentModel from '../../../Models/Departments/InsertDepartmentModel';
import { useMemo, useState } from 'react';
import useUI from '../../context/useUI';
import useModal from '../../UI/useModal';
import useValidation from '../../validation/useValidation';

export default function useDepartmentForm({
  orderTypes,
  phoneTypes,
  states,
  recordTypes,
  departmentFileTypes,
  shouldFillInitialDepartement,
}: {
  orderTypes: any[];
  phoneTypes: any[];
  states: any[];
  recordTypes: any[];
  departmentFileTypes: any[];
  shouldFillInitialDepartement?: boolean;
}) {
  const {
    validateTimeFrom,
    validateTimeTo,
    validatePhoneNumber,
    validateEmail,
    validatePostalCode,
    validateName,
    validateNotes,
  } = useValidation();

  const validationSchema = Yup.object().shape({
    departments: Yup.array()
      .of(
        Yup.object().shape({
          name: validateName(true),
          email: validateEmail(),
          orderTypeId: Yup.string()
            .required('Order type is required')
            .oneOf(
              orderTypes.map((o) => o.id.toString()),
              'Invalid order type',
            ),

          recordTypes: Yup.array()
            .required('Record Types are required')
            .min(1, 'Record Types must have at least 1 selection')
            .max(
              recordTypes.length,
              `Record Types can have at most ${recordTypes.length} selection`,
            ),
          addressInfo: Yup.object().shape({
            addressDetails: Yup.string()
              .required('Address is required')
              .max(120, "Address can't exceed 120 characters"),
            city: Yup.string()
              .required('City is required')
              .max(40, "City can't exceed 40 characters"),
            postCode: validatePostalCode(true),
            stateId: Yup.string()
              .required('State is required')
              .oneOf(
                states.map((o) => o.id.toString()),
                'Invalid State',
              ),
          }),
          servePreferences: Yup.object().shape({
            facilitySpecificAuthorizationNotes: validateNotes(false, 250),
            copyServiceNotes: validateNotes(false, 250),
            additionalFacilitySpecificDocumentationNotes: validateNotes(
              false,
              250,
            ),
            acceptLORNotes: validateNotes(false, 250),
            files: Yup.array().of(
              Yup.object().shape({
                typeId: Yup.string()
                  .required('File Type is required')
                  .oneOf(
                    departmentFileTypes.map((o) => o.id.toString()),
                    'Invalid file type',
                  ),
                tempBlobUrl: Yup.string().required('Blob URL is required'),
              }),
            ),
          }),
          contacts: Yup.array().of(
            Yup.object().shape({
              name: validateName(),
              title: validateName(false,'Title'),
              phoneNumber: validatePhoneNumber(),
              email: validateEmail(),
            }),
          ),
          phones: Yup.array().of(
            Yup.object().shape({
              phoneNumber: validatePhoneNumber(),
              phoneTypeId: Yup.string().oneOf(
                phoneTypes.map((o) => o.id.toString()),
                'Invalid Phone type',
              ),
              phoneNotes: validateNotes(),
            }),
          ),
          portalNotes: validateNotes(),
          daysAvailable: Yup.array().of(
            Yup.object().shape({
              serveHours: Yup.object().shape({
                from: validateTimeFrom(),
                to: validateTimeTo(),
              }),

              pickupAndCopyHours: Yup.object().shape({
                from: validateTimeFrom(),
                to: validateTimeTo(),
              }),
              bestTimeToCall: Yup.object().shape({
                from: validateTimeFrom(),
                to: validateTimeTo(),
              }),
            }),
          ),
          serveMethods: Yup.array().of(
            Yup.object().shape({
              serveMethodId: Yup.string(),
            }),
          ),
        }),
      )
      .min(1, 'departments must have at least 1 entry')
      .max(
        maxDepartmentsAllowed,
        `departments can have at most ${maxDepartmentsAllowed} entries`,
      ),
  });
  const newDepartment: InsertDepartmentModel = useMemo(
    () => ({
      name: '',
      email: '',
      id: 0,
      active: true,
      orderTypeId: null,
      daysAvailable: [],
      serveMethods: [],
      addressInfo: {
        stateId: null,
        city: '',
        postCode: '',
        addressDetails: '',
        timeZoneId: null,
      },
      servePreferences: {
        isPatientAddress: false,
        isPatientAccountNumber: false,
        isPatientFullSSNNumber: false,
        isPatientInitialSensitiveInformation: false,
        isElectronicSignatureAccepted: false,
        isRequireDateOfLoss: false,
        isClaimNumber: false,
        isAuthorizationWithSubpoenaRequired: false,
        isPOAForSubpoena: false,
        isHIPPAAuthorizationOriginal: false,
        isFacilitySpecificAuthorization: false,
        facilitySpecificAuthorizationNotes: '',
        isAcceptLOR: false,
        acceptLORNotes: '',
        isSOAForSubpoena: false,
        isCopyService: false,
        copyServiceNotes: '',
        isAdditionalFacilitySpecificDocumentation: false,
        additionalFacilitySpecificDocumentationNotes: '',
        files: [],
      },
      recordTypes: [],
      phones: [
        {
          phoneNumber: '',
          phoneTypeId: '',
          phoneNotes: '',
        },
      ],
      contacts: [
        {
          email: '',
          name: '',
          phoneNumber: '',
          title: '',
        },
      ],
    }),
    [],
  );
  const { showSnackbar } = useUI();
  const departmentsForm: any = useFormik({
    initialValues: {
      departments: shouldFillInitialDepartement ? [newDepartment] : [] ,
    },
    validationSchema,
    onSubmit: (values) => {},
  });

  const addDepartment = async () => {
    await departmentsForm.submitForm();
    let errors = await departmentsForm.validateForm(departmentsForm.values);
    if (
      Object.keys(errors).length > 0 &&
      departmentsForm.values.departments.length > 0
    ) {
      showSnackbar(['please fix validation errors first'], 'error');
    } else {
      const department: InsertDepartmentModel = {
        ...newDepartment,
        id: departmentsForm.values.departments.length,
      };
      departmentsForm.values.departments.forEach((element: any) => {
        element.active = false;
      });
      const newItems = [...departmentsForm.values.departments, department];
      departmentsForm.setFieldValue('departments', newItems);
    }
  };
  const { handleCloseModal, handleOpenModal, isModalOpened } = useModal();
  const [deletedDepartmentId, setDeletedDepartmentId] = useState<
    number | undefined
  >();
  const deleteDepartment = () => {
    const newItems = [...departmentsForm.values.departments];
    const index = newItems.findIndex((d) => d.id === deletedDepartmentId);
    if (index > -1) {
      newItems.splice(index, 1);
      departmentsForm.setFieldValue('departments', [...newItems]);
    }
    closeConfirmation();
  };

  const closeConfirmation = () => {
    setDeletedDepartmentId(undefined);
    handleCloseModal();
  };
  const removeDepartment = (id: number) => {
    //don't remove last entry
    if (departmentsForm.values.departments.length === 1) return;
    setDeletedDepartmentId(id);
    handleOpenModal();
  };

  const handleChangeAccordion =
    (id: number) => (_: any, newExpanded: boolean) => {
      const newItems = [...departmentsForm.values.departments];
      newItems.forEach((element) => {
        element.active = false;
      });
      const index = newItems.findIndex((d) => d.id === id);
      if (index > -1) {
        newItems[index].active = newExpanded;
        departmentsForm.setFieldValue('departments', [...newItems]);
      }
    };

  return {
    departmentsForm,
    handleChangeAccordion,
    removeDepartment,
    addDepartment,
    onCloseConfirmationClicked: closeConfirmation,
    isConfirmationOpened: isModalOpened,
    discard: deleteDepartment,
  };
}
