import DepartmentContainer from './DepartmentContainer';
import { maxDepartmentsAllowed } from '../../../../settings/appConfig';
import { Box, Button, FormHelperText, Grid } from '@mui/material';
import { textError, textWhite } from '../../../../assets/styles/appStyles';
import DiscardModal from '../../../../Modals/DiscardWizardModal';

export default function Departments({
  orderTypes,
  phoneTypes,
  states,
  recordTypes,
  days,
  addDepartment,
  departmentsForm,
  handleChangeAccordion,
  removeDepartment,
  isConfirmationOpened,
  onCloseConfirmationClicked,
  discardDelete,
  timeZones,
  departmentFileTypes,
  serveMethods,
  copyCusodianAddress,
}: {
  orderTypes: any[];
  phoneTypes: any[];
  states: any[];
  recordTypes: any[];
  timeZones: any[];
  departmentFileTypes: any[];
  days: any[];
  serveMethods: any[];
  addDepartment: () => void;
  departmentsForm: any;
  handleChangeAccordion: (
    id: number,
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  removeDepartment: (id: number) => void;
  isConfirmationOpened: boolean;
  onCloseConfirmationClicked: () => void;
  discardDelete: () => void;
  copyCusodianAddress?: (key: string) => void;
}) {
  return (
    <Grid container>
      <Grid item xs={12} px={2}>
        {departmentsForm.values.departments.length < maxDepartmentsAllowed && (
          <Button
            data-testid="add-department"
            variant="contained"
            sx={textWhite}
            onClick={addDepartment}
          >
            Add Department
          </Button>
        )}
        {departmentsForm &&
        departmentsForm.errors &&
        departmentsForm.errors['departments'] &&
        typeof departmentsForm.errors['departments'] === 'string' ? (
          <FormHelperText sx={textError}>
            {departmentsForm.errors['departments']}
          </FormHelperText>
        ) : null}
        <DiscardModal
          isModalOpened={isConfirmationOpened}
          handleClose={onCloseConfirmationClicked}
          handleAgree={discardDelete}
          message="Are you sure you want to remove this department?"
          title="Remove Department"
        />
      </Grid>
      <Grid item xs={12} my={2}>
        {departmentsForm.values.departments.length > 0 &&
          departmentsForm.values.departments.map((d: any, i: number) => (
            <DepartmentContainer
              formKeyName={'departments'}
              form={departmentsForm}
              department={d}
              key={`${d.id} ${i.toString()}`}
              departmentIndex={i}
              togglePanel={handleChangeAccordion}
              removeDepartment={removeDepartment}
              orderTypes={orderTypes}
              phoneTypes={phoneTypes}
              states={states}
              recordTypes={recordTypes}
              timeZones={timeZones}
              departmentFileTypes={departmentFileTypes}
              serveMethods={serveMethods}
              days={days}
              canRemove={departmentsForm.values.departments.length > 1}
              copyCusodianAddress={copyCusodianAddress}
            />
          ))}
      </Grid>
    </Grid>
  );
}
