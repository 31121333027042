import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import AdapterDateFns from '@date-io/date-fns';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { textError } from '../../../assets/styles/appStyles';

const AppDatePicker = ({
  keyName,
  value,
  onChange,
  errorMsg,
  label,
}: {
  onChange: (value: any) => void;
  value: any;
  keyName: string;
  errorMsg: string;
  label: string;
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl fullWidth>
        <DatePicker
          label={label}
          InputProps={{
            name: keyName,
            id: keyName,
          }}
          renderInput={(params: any) => (
            <TextField
              id={keyName}
              name={keyName}
              variant="outlined"
              error={errorMsg ? true : false}
              {...params}
            />
          )}
          onChange={(e) => onChange(e)}
          value={value}
        />
        {errorMsg ? (
          <FormHelperText sx={textError}>{errorMsg}</FormHelperText>
        ) : null}
      </FormControl>
    </LocalizationProvider>
  );
};
export default AppDatePicker;
