import { useEffect, useCallback } from 'react';
import TimeZone from '../../Models/Locations/TimeZone';
import useHttp from '../http/useHttp';

export default function useFetchTimeZones() {
  const { getDataResponse, getData } = useHttp();

  const init = useCallback(async () => {
    await getData('lookup/gettimezones');
  }, []);

  let timeZones: TimeZone[] = getDataResponse?.data ?? [];

  useEffect(() => {
    init();
  }, [init]);

  return { timeZones };
}
