import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import RecordType from '../../../../Models/Locations/recordType';
import GenericSelectInput from '../UI/GenericSelectInput';
import AppDatePicker from '../AppDatePicker';
import { DeleteOutline } from '@mui/icons-material';
import useValidation from '../../../../hooks/validation/useValidation';
import useUI from '../../../../hooks/context/useUI';
import moment from 'moment';
import { error, textPrimary } from '../../../../assets/styles/appStyles';

export default function AssociationForm({
  recordTypes,
  orderTypes,
  providers,
  associationIndex,
  formKeyName,
  form,
}: {
  recordTypes: RecordType[];
  orderTypes: any[];
  providers: any[];
  associationIndex: number;
  formKeyName: string;
  form: any;
}) {
  const { isAssociationRecordTypesValid } = useValidation();
  const { showSnackbar } = useUI();
  const onAddRecordType = () => {
    const valid = isAssociationRecordTypesValid(
      form.values[formKeyName][associationIndex].recordTypes,
    );
    if (!valid) {
      form.values[formKeyName][associationIndex]?.recordTypes?.forEach(
        (element: any, index: number) => {
          form.setFieldTouched(
            `${formKeyName}.${associationIndex}.recordTypes.${index}.recordTypeId`,
            true,
          );
        },
      );

      showSnackbar(['Please fix the validation errors first'], 'error');
      return;
    }
    let oldRecordTypes = form.values[formKeyName][associationIndex].recordTypes;

    form.setFieldValue(`${formKeyName}.${associationIndex}.recordTypes`, [
      ...oldRecordTypes,
      {
        recordTypeId: null,
        orderTypeId: null,
        from: null,
        to: null,
        associationProviderId: null,
      },
    ]);
  };
  return (
    <Grid item xs={10}>
      {form.values[formKeyName][associationIndex]?.recordTypes?.length > 0 &&
        form.values[formKeyName][associationIndex]?.recordTypes?.map(
          (item: any, index: number) => (
            <Box key={index}>
              <Grid container pb={2}>
                <Grid item xs={3} px={1}>
                  <GenericSelectInput
                    name={`${formKeyName}.${associationIndex}.recordTypes.${index}.recordTypeId`}
                    label="Record Type"
                    value={item.recordTypeId}
                    options={recordTypes}
                    onChange={(value: string) => {
                      form.setFieldValue(
                        `${formKeyName}.${associationIndex}.recordTypes.${index}.recordTypeId`,
                        value,
                      );
                    }}
                    errorMsg={
                      form.touched[formKeyName] &&
                      form.touched[formKeyName][associationIndex] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ][index] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ][index]['recordTypeId'] &&
                      form.errors[formKeyName] &&
                      form.errors[formKeyName][associationIndex] &&
                      form.errors[formKeyName][associationIndex][
                        'recordTypes'
                      ] &&
                      form.errors[formKeyName][associationIndex]['recordTypes'][
                        index
                      ] &&
                      form.errors[formKeyName][associationIndex]['recordTypes'][
                        index
                      ]['recordTypeId']
                        ? form.errors[formKeyName][associationIndex][
                            'recordTypes'
                          ][index]['recordTypeId']
                        : ''
                    }
                    hasError={
                      form.touched[formKeyName] &&
                      form.touched[formKeyName][associationIndex] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ][index] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ][index]['recordTypeId'] &&
                      form.errors[formKeyName] &&
                      form.errors[formKeyName][associationIndex] &&
                      form.errors[formKeyName][associationIndex][
                        'recordTypes'
                      ] &&
                      form.errors[formKeyName][associationIndex]['recordTypes'][
                        index
                      ] &&
                      form.errors[formKeyName][associationIndex]['recordTypes'][
                        index
                      ]['recordTypeId']
                        ? true
                        : false
                    }
                    onBlur={() => {
                      form.setFieldTouched(
                        `${formKeyName}.${associationIndex}.recordTypes.${index}.recordTypeId`,
                        true,
                      );
                    }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={2} px={1}>
                  <GenericSelectInput
                    name={`${formKeyName}.${associationIndex}.recordTypes.${index}.orderTypeId`}
                    label="Order Type"
                    value={item.orderTypeId}
                    options={orderTypes}
                    onChange={(value: string) => {
                      form.setFieldValue(
                        `${formKeyName}.${associationIndex}.recordTypes.${index}.orderTypeId`,
                        value,
                      );
                    }}
                    errorMsg={
                      form.touched[formKeyName] &&
                      form.touched[formKeyName][associationIndex] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ][index] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ][index]['orderTypeId'] &&
                      form.errors[formKeyName] &&
                      form.errors[formKeyName][associationIndex] &&
                      form.errors[formKeyName][associationIndex][
                        'recordTypes'
                      ] &&
                      form.errors[formKeyName][associationIndex]['recordTypes'][
                        index
                      ] &&
                      form.errors[formKeyName][associationIndex]['recordTypes'][
                        index
                      ]['orderTypeId']
                        ? form.errors[formKeyName][associationIndex][
                            'recordTypes'
                          ][index]['orderTypeId']
                        : ''
                    }
                    hasError={
                      form.touched[formKeyName] &&
                      form.touched[formKeyName][associationIndex] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ][index] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ][index]['orderTypeId'] &&
                      form.errors[formKeyName] &&
                      form.errors[formKeyName][associationIndex] &&
                      form.errors[formKeyName][associationIndex][
                        'recordTypes'
                      ] &&
                      form.errors[formKeyName][associationIndex]['recordTypes'][
                        index
                      ] &&
                      form.errors[formKeyName][associationIndex]['recordTypes'][
                        index
                      ]['orderTypeId']
                        ? true
                        : false
                    }
                    onBlur={() => {
                      form.setFieldTouched(
                        `${formKeyName}.${associationIndex}.recordTypes.${index}.orderTypeId`,
                        true,
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={2} px={1}>
                  <GenericSelectInput
                    name={`${formKeyName}.${associationIndex}.recordTypes.${index}.associationProviderId`}
                    label="Provider"
                    value={item.associationProviderId}
                    options={[{ id: null, name: 'None' }, ...providers]}
                    onChange={(value: string) => {
                      form.setFieldValue(
                        `${formKeyName}.${associationIndex}.recordTypes.${index}.associationProviderId`,
                        value,
                      );
                    }}
                    errorMsg={
                      form.touched[formKeyName] &&
                      form.touched[formKeyName][associationIndex] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ][index] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ][index]['associationProviderId'] &&
                      form.errors[formKeyName] &&
                      form.errors[formKeyName][associationIndex] &&
                      form.errors[formKeyName][associationIndex][
                        'recordTypes'
                      ] &&
                      form.errors[formKeyName][associationIndex]['recordTypes'][
                        index
                      ] &&
                      form.errors[formKeyName][associationIndex]['recordTypes'][
                        index
                      ]['associationProviderId']
                        ? form.errors[formKeyName][associationIndex][
                            'recordTypes'
                          ][index]['associationProviderId']
                        : ''
                    }
                    hasError={
                      form.touched[formKeyName] &&
                      form.touched[formKeyName][associationIndex] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ][index] &&
                      form.touched[formKeyName][associationIndex][
                        'recordTypes'
                      ][index]['associationProviderId'] &&
                      form.errors[formKeyName] &&
                      form.errors[formKeyName][associationIndex] &&
                      form.errors[formKeyName][associationIndex][
                        'recordTypes'
                      ] &&
                      form.errors[formKeyName][associationIndex]['recordTypes'][
                        index
                      ] &&
                      form.errors[formKeyName][associationIndex]['recordTypes'][
                        index
                      ]['associationProviderId']
                        ? true
                        : false
                    }
                    onBlur={() => {
                      form.setFieldTouched(
                        `${formKeyName}.${associationIndex}.recordTypes.${index}.associationProviderId`,
                        true,
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={6}>
                      <AppDatePicker
                        keyName={`${formKeyName}.${associationIndex}.recordTypes.${index}.from`}
                        label="From"
                        value={item.from}
                        onChange={(value: any) => {
                          form.setFieldValue(
                            `${formKeyName}.${associationIndex}.recordTypes.${index}.from`,
                            moment(value).format('YYYY-MM-DD'),
                          );
                        }}
                        errorMsg={''}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AppDatePicker
                        keyName={`${formKeyName}.${associationIndex}.recordTypes.${index}.to`}
                        label="To"
                        value={item.to}
                        onChange={(value: string) => {
                          form.setFieldValue(
                            `${formKeyName}.${associationIndex}.recordTypes.${index}.to`,
                            moment(value).format('YYYY-MM-DD'),
                          );
                        }}
                        errorMsg={''}
                      />
                    </Grid>
                    {form.errors[formKeyName] &&
                      form.errors[formKeyName][associationIndex] &&
                      form.errors[formKeyName][associationIndex][
                        'recordTypes'
                      ] &&
                      form.errors[formKeyName][associationIndex]['recordTypes'][
                        index
                      ] &&
                      form.errors[formKeyName][associationIndex]['recordTypes'][
                        index
                      ]['from'] && (
                        <Grid item xs={12} pl={1}>
                          <FormHelperText sx={error}>
                            {
                              form.errors[formKeyName][associationIndex][
                                'recordTypes'
                              ][index]['from']
                            }
                          </FormHelperText>
                        </Grid>
                      )}
                  </Grid>
                </Grid>
                <Grid item xs={1} px={1}>
                  {form.values[formKeyName][associationIndex].recordTypes
                    .length > 1 && (
                    <IconButton
                      name="remove"
                      id="remove"
                      data-testid="remove"
                      color="error"
                      type="button"
                      onClick={() => {
                        let oldRecordTypes =
                          form.values[formKeyName][associationIndex]
                            .recordTypes;
                        oldRecordTypes.splice(index, 1);
                        form.setFieldValue(
                          `${formKeyName}.${associationIndex}.recordTypes`,
                          [...oldRecordTypes],
                        );
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Box>
          ),
        )}
      <Grid container>
        {form.values[formKeyName][associationIndex].recordTypes &&
          form.values[formKeyName][associationIndex].recordTypes.length > 0 && (
            <Grid item xs={3}>
              <Button onClick={onAddRecordType}>Add Record Type</Button>
            </Grid>
          )}
      </Grid>
      <Grid container justifyContent={'flex-end'}>
        <Grid item xs={2}>
          <Typography sx={textPrimary}>
            Total Records:{' '}
            {form.values[formKeyName][associationIndex].recordTypes?.length ??
              0}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
