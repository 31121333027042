import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@mui/material';
import { ServePreferences } from '../../../../../../../Models/Departments/Details/ServePreferences';
import CheckboxInput from '../../../../../../AddLocation/Components/UI/CheckboxInput';
import { ExpandMore } from '@mui/icons-material';
import { accordionSummaryText } from '../../../../../Styles/MainInformationTabStyles';

export default function PatientInfo({
  servePreferences,
  isExpanded,
  handleAccordionChange,
}: {
  servePreferences: ServePreferences | undefined;
  isExpanded: (panel: string) => boolean;
  handleAccordionChange: (panel: string) => (event: React.SyntheticEvent, isExpanding: boolean) => void
}) {
  return (
    <Accordion
    expanded={isExpanded('Patient')}
    onChange={handleAccordionChange('Patient')}>
      <AccordionSummary
        sx={accordionSummaryText}
        aria-controls="Patient-content"
        id="Patient-header"
        expandIcon={<ExpandMore />}
      >
        Patient
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={6}>
            <CheckboxInput
              label="Patient Address"
              keyName={'servePreferences.isPatientAddress'}
              checked={servePreferences?.isPatientAddress}
              onChange={() => {}}
              onBlur={() => {}}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} pl={3}>
            <CheckboxInput
              label="Patient Account Number"
              keyName={`servePreferences.isPatientAccountNumber`}
              checked={servePreferences?.isPatientAccountNumber}
              onChange={(e: any) => {}}
              onBlur={() => {}}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <CheckboxInput
              label="Patient Full SSN Number"
              keyName={`servePreferences.isPatientFullSSNNumber`}
              checked={servePreferences?.isPatientFullSSNNumber}
              onChange={(e: any) => {}}
              onBlur={() => {}}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6} pl={3}>
            <CheckboxInput
              label="Patient Initial Sensitive Information"
              keyName={`servePreferences.isPatientInitialSensitiveInformation`}
              checked={servePreferences?.isPatientInitialSensitiveInformation}
              onChange={(e: any) => {}}
              onBlur={() => {}}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <CheckboxInput
              label="Electronic Signature Accepted"
              keyName={`servePreferences.isElectronicSignatureAccepted`}
              checked={servePreferences?.isElectronicSignatureAccepted}
              onChange={(e: any) => {}}
              onBlur={() => {}}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
