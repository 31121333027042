import useFetchPaymentMethods from '../useFetchPaymentMethods';
import useFetchPhoneTypes from '../useFetchPhoneTypes';
import useFetchRecordTypes from '../useFetchRecordTypes';
import useFetchServeMethods from '../useFetchServeMethods';
import useFetchCustodianTypes from '../useFetchCustodianTypes';
import useFetchStates from '../useFetchStates';
import useFetchTimeZones from '../useFetchTimeZones';
import useFetchOrderTypes from '../useFetchOrderTypes';
import useFetchDays from '../useFetchDays';
import useFetchDepartmentFileTypes from '../useFetchDepartmentFileTypes';
import useFetchProviders from '../useFetchProviders';

export default function useAddCustodianLookups() {
  const { custodianTypes } = useFetchCustodianTypes();
  const { states } = useFetchStates();
  const { phoneTypes } = useFetchPhoneTypes();
  const { paymentMethods } = useFetchPaymentMethods();
  const { timeZones } = useFetchTimeZones();
  const { departmentFileTypes } = useFetchDepartmentFileTypes();
  

  const { orderTypes } = useFetchOrderTypes();

  const { recordTypes } = useFetchRecordTypes();
  const { serveMethods } = useFetchServeMethods();
  const { days } = useFetchDays();
  const { providers } = useFetchProviders();

  return {
    recordTypes,
    serveMethods,
    phoneTypes,
    supportedOrderTypes: orderTypes,
    states,
    custodianTypes,
    paymentMethods,
    timeZones,
    days,
    departmentFileTypes,
    providers,
  };
}
