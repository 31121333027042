import { Grid } from '@mui/material';
import State from '../../../Models/Locations/state';
import Panel from '../../../components/UI/Panel';
import TimeZone from '../../../Models/Locations/TimeZone';
import GenericInput from './UI/GenericInput';
import GenericSelectInput from './UI/GenericSelectInput';
import CustodianFieldSearchInput from './CustodianFieldSearchInput';

export default function CustodianAddress({
  states,
  timeZones,
  formik,
  showPanel,
}: {
  states: State[];
  timeZones: TimeZone[];
  formik: any;
  showPanel: boolean;
}) {
  const content = (
    <Grid spacing={2} mx={1} px={1} container>
      <Grid item xs={4} px={2}>
        <CustodianFieldSearchInput
          duplicateErrorMessage="The address you have entered is slightly similar to the addresses of existing custodians. This could indicate that the custodian you are trying to create may already exist."
          label="Address"
          isExactSearch={false}
          name="addressInfo.addressDetails"
          placeholder="Address"
          maxLength={120}
          value={formik.values.addressInfo.addressDetails}
          formErrorMessage={
            formik.touched.addressInfo &&
            formik.touched.addressInfo.addressDetails &&
            formik.errors.addressInfo &&
            formik.errors.addressInfo.addressDetails
              ? formik.errors.addressInfo.addressDetails
              : ''
          }
          hasFormError={
            formik.touched.addressInfo &&
            formik.touched.addressInfo.addressDetails &&
            formik.errors.addressInfo &&
            formik.errors.addressInfo.addressDetails
              ? true
              : false
          }
          onChange={(e: any) => {
            formik.setFieldValue(`addressInfo.addressDetails`, e.target.value);
          }}
          onBlur={() => {
            formik.setFieldTouched(`addressInfo.addressDetails`, true);
          }}
          searchPropertyName="address"
          required={true}
        />
      </Grid>
      <Grid item xs={4} px={2}>
        <GenericInput
          name="city"
          label="City"
          placeholder="City"
          maxLength={40}
          value={formik.values.addressInfo.city}
          onChange={(e: any) => {
            formik.setFieldValue(`addressInfo.city`, e.target.value);
          }}
          errorMsg={
            formik.touched.addressInfo &&
            formik.touched.addressInfo.city &&
            formik.errors.addressInfo &&
            formik.errors.addressInfo.city
              ? formik.errors.addressInfo.city
              : ''
          }
          hasError={
            formik.touched.addressInfo &&
            formik.touched.addressInfo.city &&
            formik.errors.addressInfo &&
            formik.errors.addressInfo.city
              ? true
              : false
          }
          onBlur={() => {
            formik.setFieldTouched(`addressInfo.city`, true);
          }}
          required={true}
        />
      </Grid>
      <Grid item xs={4} px={2}>
        <GenericSelectInput
          name={`stateId`}
          label="State"
          value={formik.values.addressInfo.stateId}
          onChange={(e: any) => {
            formik.setFieldValue(`addressInfo.stateId`, e);
          }}
          errorMsg={
            formik.touched.addressInfo &&
            formik.touched.addressInfo.stateId &&
            formik.errors.addressInfo &&
            formik.errors.addressInfo.stateId
              ? formik.errors.addressInfo.stateId
              : ''
          }
          hasError={
            formik.touched.addressInfo &&
            formik.touched.addressInfo.stateId &&
            formik.errors.addressInfo &&
            formik.errors.addressInfo.stateId
              ? true
              : false
          }
          onBlur={() => {
            formik.setFieldTouched(`addressInfo.stateId`, true);
          }}
          options={states}
          required={true}
        />
      </Grid>
      <Grid item xs={4} px={2}>
        <GenericInput
          name="postCode"
          label="Zip / Postal Code"
          placeholder="Zip / Postal Code"
          maxLength={5}
          value={formik.values.addressInfo.postCode}
          onChange={(e: any) => {
            formik.setFieldValue(`addressInfo.postCode`, e.target.value);
          }}
          errorMsg={
            formik.touched.addressInfo &&
            formik.touched.addressInfo.postCode &&
            formik.errors.addressInfo &&
            formik.errors.addressInfo.postCode
              ? formik.errors.addressInfo.postCode
              : ''
          }
          hasError={
            formik.touched.addressInfo &&
            formik.touched.addressInfo.postCode &&
            formik.errors.addressInfo &&
            formik.errors.addressInfo.postCode
              ? true
              : false
          }
          onBlur={() => {
            formik.setFieldTouched(`addressInfo.postCode`, true);
          }}
          required={true}
        />
      </Grid>
      <Grid item xs={4} px={2}>
        <GenericSelectInput
          name={`timeZoneId`}
          label="TimeZone"
          value={formik.values.addressInfo.timeZoneId}
          onChange={(e: any) => {
            formik.setFieldValue(`addressInfo.timeZoneId`, e);
          }}
          errorMsg={
            formik.touched.addressInfo &&
            formik.touched.addressInfo.timeZoneId &&
            formik.errors.addressInfo &&
            formik.errors.addressInfo.timeZoneId
              ? formik.errors.addressInfo.timeZoneId
              : ''
          }
          hasError={
            formik.touched.addressInfo &&
            formik.touched.addressInfo.timeZoneId &&
            formik.errors.addressInfo &&
            formik.errors.addressInfo.addressInfo &&
            formik.errors.addressInfo.timeZoneId
              ? true
              : false
          }
          onBlur={() => {
            formik.setFieldTouched(`addressInfo.timeZoneId`, true);
          }}
          options={timeZones}
        />
      </Grid>
    </Grid>
  );
  return (
    <>
      {showPanel === true && (
        <Panel headerText="Address Details">{content}</Panel>
      )}
      {!showPanel && content}
    </>
  );
}

CustodianAddress.defaultProps = {
  showPanel: true,
};
