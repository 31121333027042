import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TablePagination,
} from '@mui/material';
import ResearchCustodiansListTableHeader from './ResearchCustodiansListTableHeader';
import ResearchCustodiansListTableBody from './ResearchCustodiansListTableBody';
import ResearchCustodianListColumn from '../../../Models/custodian/research-custodian-list-column';
import Styles from '../Styles/ListCustodiansStyles';
import { debounceDelay, rowsPerPage } from '../../../settings/appConfig';
import { tableContainer, filterBoxStyles } from './ResearchStyles';
import useLocalStorage from '../../../hooks/storage/useLocalStorage';
import ResearchCustodiansListTableHeaderFilter from './ResearchCustodiansListTableHeaderFilter';
import useModal from '../../../hooks/UI/useModal';
import { useEffect, useRef, useState } from 'react';

export default function ResearchCustodiansList({
  data,
  handlePageChange,
  pageNo,
  count,
  isLoading,
  availableColumns,
  defaultColumns,
  onSearchChanged,
  isFilterCleared,
}: {
  data: any[];
  pageNo: number;
  count: number;
  handlePageChange: (_: number) => void;
  isLoading: boolean;
  availableColumns: ResearchCustodianListColumn[];
  defaultColumns: ResearchCustodianListColumn[];
  onSearchChanged: (search: any) => void;
  isFilterCleared: boolean;
}) {
  const initState = {
    locationName: '',
    phoneNumber: '',
    sourceLocation: '',
    address: '',
    city: '',
    state: '',
    postCode: '',
    altPhoneNumber: '',
    faxNumber: '',
    warnings: '',
    verifiedLocations: '',
    expeditors: '',
    attention: '',
    paymentForRecords: '',
    paymentTypeAccepted: '',
    receiveAuthByFax: '',
    rating: '',
    lastFulfillment: '',
    lastKnownCommunication: '',
    sourceId: '',
    sortProperties: [],
  };
  useEffect(() => {
    if (isFilterCleared) {
      initState.sortProperties = Array.from(searchValues.sortProperties);
      setSearchValues(initState);
    }
  }, [isFilterCleared]);
  const [searchValues, setSearchValues] = useState<any>(initState);

  const searchTimeoutId = useRef<any>(null);

  const updateSearchValue = (key: string, value: string) => {
    const stateToBeUpdated = { ...searchValues, [key]: value };
    setSearchValues(stateToBeUpdated);

    //apply debouncing
    if (searchTimeoutId.current) {
      clearTimeout(searchTimeoutId.current);
    }
    searchTimeoutId.current = setTimeout(() => {
      onSearchChanged(stateToBeUpdated);
    }, debounceDelay);
  };

  const updateSortValue = (sortPropertyName: string, id: number) => {
    const column = searchValues.sortProperties.find(
      (p: any) =>
        p.propertyName.toLowerCase() === sortPropertyName.toLowerCase(),
    );
    if (column) {
      if (column.sortDirection === 'asc') column.sortDirection = 'desc';
      else column.sortDirection = 'asc';
    } else {
      searchValues.sortProperties = [
        {
          propertyName: sortPropertyName,
          sortDirection: 'asc',
        },
      ];
    }
    const stateToBeUpdated = { ...searchValues };
    setSearchValues(stateToBeUpdated);
    onSearchChanged(stateToBeUpdated);
  };

  const [columns, setColumns] = useLocalStorage<number[]>(
    'selectedResearchColumns',
    [...defaultColumns.map((d) => d.id)],
  );

  const addColumnHandler = (column: ResearchCustodianListColumn) => {
    setColumns([...columns, column.id]);
  };

  const removeColumnHandler = (column: ResearchCustodianListColumn) => {
    if (columns.length <= 1) {
      return;
    }
    const oldCols = [...columns];
    const index = oldCols.findIndex((col) => col === column.id);
    if (index > -1) {
      oldCols.splice(index, 1);
    }

    if (column.hasSort) {
      const updatedSearchValues = searchValues.sortProperties.filter(
        (p: any) =>
          p.propertyName.toLowerCase() !==
          column.sortPropertyName?.toLowerCase(),
      );

      searchValues.sortProperties = updatedSearchValues;
      setSearchValues({ ...searchValues });
      onSearchChanged(searchValues);
    }
    setColumns([...oldCols]);
  };

  const { handleCloseModal, handleOpenModal, isModalOpened } = useModal();

  const exclusionFilters = ['locationName', 'phoneNumber'];
  return (
    <>
      <Box sx={filterBoxStyles}>
        <ResearchCustodiansListTableHeaderFilter
          isOpened={isModalOpened}
          onFilterClicked={handleOpenModal}
          onFilterClosed={handleCloseModal}
          availableColumns={availableColumns}
          selectedColumns={availableColumns.filter((c) =>
            columns.includes(c.id),
          )}
          onAddColumn={addColumnHandler}
          onRemoveColumn={removeColumnHandler}
        />
      </Box>
      <Box sx={tableContainer}>
        <Table>
          <ResearchCustodiansListTableHeader
            columns={availableColumns.filter((c) => columns.includes(c.id))}
            searchValues={searchValues}
            updateSearchValue={updateSearchValue}
            exclusionFilters={exclusionFilters}
            updateSortValue={updateSortValue}
          />
          <ResearchCustodiansListTableBody
            data={data}
            columns={availableColumns.filter((c) => columns.includes(c.id))}
          />
        </Table>
        {(!data || data.length === 0) && isLoading ? (
          <Grid container sx={Styles.loaderStyles}>
            {isLoading ? <CircularProgress /> : ''}
          </Grid>
        ) : (
          ''
        )}
      </Box>
      <TablePagination
        component="div"
        sx={Styles.paginationStyles}
        labelRowsPerPage={'Items per page:'}
        showFirstButton
        showLastButton
        count={count}
        page={pageNo}
        onPageChange={(_, newPage) => handlePageChange(newPage)}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
    </>
  );
}
