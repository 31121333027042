import { useState } from 'react';
import WizardStepTitle from '../../../Models/Layout/wizard/WizardStepTitle';
import { appRoutesPaths, custodianSteps } from '../../../settings/appConfig';
import useMainInfoForm from './useMainInfoForm';
import { useNavigate } from 'react-router';
import useModal from '../../UI/useModal';
import useBillingInfoForm from './useBillingInfoForm';
import useDepartmentForm from './useDepartmentForm';
import useValidation from '../../validation/useValidation';
import useAssociationForm from './useAssociationForm';
import useUI from '../../context/useUI';
import usePostCustodian from '../usePostCustodian';
import moment from 'moment';
import useCheckDuplicatePhonesAndEmails from '../useCheckDuplicatePhonesAndEmails';

export default function useAddCustodianWizard({
  states,
  custodianTypes,
  phoneTypes,
  recordTypes,
  supportedOrderTypes,
  departmentFileTypes,
}: {
  states: any[];
  custodianTypes: any[];
  phoneTypes: any[];
  recordTypes: any[];
  supportedOrderTypes: any[];
  departmentFileTypes: any[];
}) {
  const { save } = usePostCustodian();
  const { hasDuplicatePhoneOrEmailError } = useCheckDuplicatePhonesAndEmails();

  const { mainInfoForm } = useMainInfoForm({
    custodianTypes: custodianTypes,
    phoneTypes: phoneTypes,
    states: states,
  });

  const {
    addDepartment,
    departmentsForm,
    handleChangeAccordion,
    removeDepartment,
    isConfirmationOpened,
    onCloseConfirmationClicked,
    discard: discardDelete,
  } = useDepartmentForm({
    orderTypes: supportedOrderTypes,
    phoneTypes,
    states,
    recordTypes,
    departmentFileTypes,
  });

  const { billingInfoForm } = useBillingInfoForm({
    states: states,
  });

  const { associationsForm } = useAssociationForm();

  const [steps, setSteps] = useState<WizardStepTitle[]>([
    {
      label: custodianSteps.mainInfo,
      isCurrentStep: true,
    },
    {
      label: custodianSteps.departmentInfo,
    },
    {
      label: custodianSteps.billingInfo,
    },
    {
      label: custodianSteps.associatedCustodians,
    },
  ]);

  const [saving, setSaving] = useState<boolean>(false);

  const validate = async (step: WizardStepTitle): Promise<boolean> => {
    switch (step.label) {
      case custodianSteps.mainInfo:
        mainInfoForm.submitForm();
        let mainFormErrors = await mainInfoForm.validateForm(
          mainInfoForm.values,
        );
        return (
          Object.keys(mainFormErrors).length === 0 &&
          !hasDuplicatePhoneOrEmailError(mainInfoForm)
        );
      case custodianSteps.departmentInfo:
        departmentsForm.submitForm();
        let departmentErrors = await departmentsForm.validateForm(
          departmentsForm.values,
        );
        return Object.keys(departmentErrors).length === 0;
      case custodianSteps.billingInfo:
        billingInfoForm.submitForm();
        let billingInfoErrors = await billingInfoForm.validateForm(
          billingInfoForm.values,
        );
        return Object.keys(billingInfoErrors).length === 0;
      case custodianSteps.associatedCustodians:
        associationsForm.submitForm();
        let associationErrors = await associationsForm.validateForm(
          associationsForm.values,
        );
        return Object.keys(associationErrors).length === 0;

      default:
        return false;
    }
  };

  const stepClickHandler = async (step: WizardStepTitle) => {
    let newSteps = [...steps];
    const currentStepIndex = newSteps.findIndex((s) => s.isCurrentStep);
    if (currentStepIndex > -1) {
      const stepIndex = newSteps.findIndex((s) => s.label === step.label);
      if (stepIndex > 0 && stepIndex > currentStepIndex) {
        let previousStepsValid = true;
        for (let index = 0; index < stepIndex; index++) {
          const element = newSteps[index];
          const isValid = await validate(element);
          if (!isValid) {
            previousStepsValid = false;
            break;
          }
        }
        if (!previousStepsValid) return;
      }
      newSteps[currentStepIndex].isCurrentStep = false;
    }

    let currentStep = newSteps.find((s) => s.label === step.label);
    if (currentStep) currentStep.isCurrentStep = true;
    setSteps(newSteps);
  };

  const nextClickHandler = async () => {
    const currentStepIndex = steps.findIndex((s) => s.isCurrentStep);
    if (currentStepIndex > -1 && currentStepIndex < steps.length) {
      //validate current step
      const isValid = await validate(steps[currentStepIndex]);
      let newSteps = [...steps];
      newSteps[currentStepIndex].isValid = isValid;
      //if current step is valid, can go to the next step
      if (isValid) {
        newSteps[currentStepIndex].isCurrentStep = false;
        newSteps[currentStepIndex + 1].isCurrentStep = true;
        window.scrollTo(0, 0);
      } else {
        showSnackbar(['Please fix the validation errors first'], 'error');
      }
      setSteps(newSteps);
    }
  };

  const previousClickHandler = () => {
    //go directly to the previous step
    const currentStepIndex = steps.findIndex((s) => s.isCurrentStep);
    if (currentStepIndex > 0) {
      let newSteps = [...steps];
      newSteps[currentStepIndex].isCurrentStep = false;
      newSteps[currentStepIndex - 1].isCurrentStep = true;
      setSteps(newSteps);
      window.scrollTo(0, 0);
    }
  };

  const {
    isPhoneValid,
    isContactValid,
    isEmailValid,
    isAssociationRecordTypeValid,
  } = useValidation();
  const { showSnackbar } = useUI();
  const saveClickHandler = async () => {
    const currentStepIndex = steps.findIndex((s) => s.isCurrentStep);
    if (currentStepIndex > -1 && currentStepIndex < steps.length) {
      //validate last step
      const isValid = await validate(steps[currentStepIndex]);
      let newSteps = [...steps];
      newSteps[currentStepIndex].isValid = isValid;
      setSteps(newSteps);
      if (
        isValid &&
        mainInfoForm.isValid &&
        billingInfoForm.isValid &&
        associationsForm.isValid &&
        departmentsForm.isValid
      ) {
        let phones = mainInfoForm.values.phones?.filter((e: any) =>
          isPhoneValid(e),
        );
        phones = phones?.map((p: any) => {
          return {
            isMainPhone: p.isMainPhone,
            phoneNumber: p.phoneNumber,
            phoneTypeId: p.phoneTypeId,
          };
        });
        let emails = mainInfoForm.values.emails?.filter((e: any) =>
          isEmailValid(e),
        );
        emails = emails?.map((e: any) => {
          return {
            email: e.email,
          };
        });
        const contacts = mainInfoForm.values.contacts?.filter((e: any) =>
          isContactValid(e),
        );

        const departments = departmentsForm.values?.departments?.slice(
          0,
          departmentsForm.values?.departments?.length,
        );
        const associations = associationsForm.values?.associations;

        associations.forEach((element: any) => {
          element.recTypes = [
            ...element.recordTypes
              .filter((e: any) => isAssociationRecordTypeValid(e))
              .map((record: any) => ({
                ...record,
              })),
          ];
        });

        departments.forEach((element: any) => {
          element.departmentPhones = [
            ...element.phones.filter((e: any) => isPhoneValid(e)),
          ];
          element.departmentContacts = [
            ...element.contacts?.filter((e: any) => isContactValid(e)),
          ];
          element.departmentDays = element.daysAvailable?.map((day: any) => ({
            ...day,
            serveHours: {
              from: day?.serveHours?.from
                ? moment(day?.serveHours?.from).format('HH:mm')
                : null,
              to: day?.serveHours?.to
                ? moment(day?.serveHours?.to).format('HH:mm')
                : null,
            },
            pickupAndCopyHours: {
              from: day?.pickupAndCopyHours?.from
                ? moment(day?.pickupAndCopyHours?.from).format('HH:mm')
                : null,
              to: day?.pickupAndCopyHours?.to
                ? moment(day?.pickupAndCopyHours?.to).format('HH:mm')
                : null,
            },
            bestTimeToCall: {
              from: day?.bestTimeToCall?.from
                ? moment(day?.bestTimeToCall?.from).format('HH:mm')
                : null,
              to: day?.bestTimeToCall?.to
                ? moment(day?.bestTimeToCall?.to).format('HH:mm')
                : null,
            },
          }));
        });
        var model = {
          ...mainInfoForm.values,
          phones,
          emails,
          contacts,
          associations: associations.map((a: any) => {
            const recordTypes = a.recTypes;
            delete a.recTypes;
            return {
              ...a,
              recordTypes,
            };
          }),
          departments: departments.map((d: any) => {
            const phones = d.departmentPhones;
            const contacts = d.departmentContacts;
            const daysAvailable = d.departmentDays;
            const servePreferences = {
              ...d.servePreferences,
              files: d.servePreferences.files
                ?.filter((f: any) => f.tempBlobUrl)
                ?.map((f: any) => ({
                  fileSize: f.fileSize,
                  fileName: f.name,
                  tempBlobUrl: f.tempBlobUrl,
                  fileTypeId: f.typeId,
                })),
            };
            delete d.departmentPhones;
            delete d.departmentContacts;
            delete d.departmentDays;
            return {
              ...d,
              phones,
              contacts,
              daysAvailable,
              servePreferences,
            };
          }),
          custodianBillingInformation: billingInfoForm.values,
        };
        try {
          setSaving(true);
          await save(model);
        } catch {
          showSnackbar(['Saving failed, Please try again'], 'error');
        } finally {
          setSaving(false);
        }
        return true;
      } else {
        showSnackbar(['Please fix the validation errors first'], 'error');
      }
    }
    return false;
  };

  const nav = useNavigate();
  const { handleCloseModal, handleOpenModal, isModalOpened } = useModal();
  const discard = () => {
    nav(appRoutesPaths.locations);
    handleCloseModal();
  };
  const cancelClickHandler = () => {
    //show discard confirmation
    handleOpenModal();
  };

  const copyCustodianAddress = (formKeyName: string) => {
    departmentsForm.setFieldValue(
      `${formKeyName}.addressInfo.addressDetails`,
      mainInfoForm.values.addressInfo?.addressDetails,
    );

    departmentsForm.setFieldValue(
      `${formKeyName}.addressInfo.city`,
      mainInfoForm.values.addressInfo?.city,
    );

    departmentsForm.setFieldValue(
      `${formKeyName}.addressInfo.postCode`,
      mainInfoForm.values.addressInfo?.postCode,
    );

    departmentsForm.setFieldValue(
      `${formKeyName}.addressInfo.stateId`,
      mainInfoForm.values.addressInfo?.stateId,
    );

    departmentsForm.setFieldValue(
      `${formKeyName}.addressInfo.timeZoneId`,
      mainInfoForm.values.addressInfo?.timeZoneId,
    );
  };

  return {
    cancelClickHandler,
    saveClickHandler,
    previousClickHandler,
    nextClickHandler,
    stepClickHandler,
    steps,
    mainInfoForm,
    billingInfoForm,
    isDiscardModalOpened: isModalOpened,
    discard,
    cancelDiscard: handleCloseModal,
    addDepartment,
    departmentsForm,
    associationsForm,
    handleChangeAccordion,
    removeDepartment,
    isConfirmationOpened,
    onCloseConfirmationClicked,
    discardDelete,
    isSaving: saving,
    copyCustodianAddress,
  };
}
