// Styles.ts
import { SxProps } from '@mui/material';

const menuItemContainer: SxProps = {
  backgroundColor: (theme: any) => theme.palette.background.paper,
};

const accordionSummary: SxProps = {
  cursor: 'default',
  borderBottom: '2px solid',
  borderColor: (theme: any) => theme.palette?.neutral['200'],
};

const sideBarButton: SxProps = {
  paddingLeft: '10px',
  borderColor: (theme: any) => theme.palette?.neutral['200'],
};

const activeSideBarButton: SxProps = {
  backgroundColor: (theme: any) => theme.palette?.primary['100'],
  borderRadius: '8px',
};

const noMargin: SxProps = { margin: 'auto', padding: 0 };

const queueGroupAccordionSummary: SxProps = {
  cursor: 'default',
  borderBottom: '2px solid',
  paddingLeft: '25px',
  borderColor: (theme: any) => theme.palette?.neutral['200'],
};

const accordionTitle = {
  marginTop: '24px',
  cursor: 'default',
  color: (theme: any) => theme.palette?.neutral['400'],
  fontWeight: '700',
};

const drawerContainer = (drawerWidth: number) => {
  return {
    width: drawerWidth,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: drawerWidth,
      boxSizing: 'border-box',
    },
  };
};

const sideBarListContainer = {
  overflow: 'auto',
  marginTop: '70px',
  padding: '6px',
};

const sideBarOpenDrawerIcon = {
  height: '30px',
  width: '30px',
  mt: 10,
  ml: 3,
};

const sideBarHiddenDrawerIcon = { display: 'none' };
const sideBarShownDrawerIcon = { mr: 2 };

export default {
  menuItemContainer,
  accordionSummary,
  noMargin,
  sideBarButton,
  activeSideBarButton,
  accordionTitle,
  queueGroupAccordionSummary,
  drawerContainer,
  sideBarListContainer,
  sideBarOpenDrawerIcon,
  sideBarHiddenDrawerIcon,
  sideBarShownDrawerIcon
};
