export default interface CustodiansFieldedAzureSearchParams {
  name?: string;
  address?: string;
  stateId?: number;
  skip?: number;
  take?: number;
  phone?: number;
  email?: string;
  isExact: boolean;
}

export const CustodianSearchPropertiesMapping = {
  name: 'Name',
  address: 'address',
  email: 'email',
  phoneNumber: 'phone',
};
