import { useEffect, useCallback, useState } from 'react';
import useHttp from '../http/useHttp';
import { useIsAuthenticated } from '@azure/msal-react';
import useAuth from '../context/useAuth';
import {
  routesWithActions,
  appRoutesPaths,
  appActions,
} from '../../settings/appConfig';
import CustodiansList from '../../Pages/Locations/CustodiansList';
import CustodianWizard from '../../Pages/AddLocation/CustodianWizard';
import CustodianDetails from '../../Pages/Details/CustodianDetails';
import ManageUsers from '../../Pages/ManageUsers/ManageUsers';
import AssignUserRole from '../../Pages/ManageUsers/Components/AssignUserRole/AssignUserRole';
import ManageRoles from '../../Pages/ManageRoles/manageRoles';
import AddRole from '../../Pages/ManageRoles/Components/AddRole/addRole';
import EditRole from '../../Pages/ManageRoles/Components/EditRole/editRole';
import Unauthorized from '../../Pages/Common/Unauthorized';
import { Navigate } from 'react-router';
import NotFound from '../../Pages/Common/NotFound';
import EditDepartment from '../../Pages/Custodians/Departments/EditDepartment';
import AddDepartment from '../../Pages/Custodians/Departments/AddDepartment';
import ResearchCustodians from '../../Pages/Locations/ResearchCustodians/ResearchCustodians';

export default function useAuthRoutes() {
  const anonymousRoutes = [
    {
      path: appRoutesPaths.researchCustodians,
      element: <ResearchCustodians />,
    },
    {
      path: appRoutesPaths.notFound,
      element: <NotFound />,
    },
    {
      path: appRoutesPaths.unauthorized,
      element: <Unauthorized />,
    },
  ];
  const routeConf = [
    {
      path: appRoutesPaths.locations,
      element: <CustodiansList />,
    },
    {
      path: appRoutesPaths.addLocation,
      element: <CustodianWizard />,
    },
    {
      path: appRoutesPaths.custodianDetails,
      element: <CustodianDetails />,
    },
    {
      path: appRoutesPaths.editDepartment,
      element: <EditDepartment />,
    },
    {
      path: appRoutesPaths.addDepartment,
      element: <AddDepartment />,
    },
    {
      path: appRoutesPaths.users,
      element: <ManageUsers />,
    },
    {
      path: appRoutesPaths.addUser,
      element: <AssignUserRole />,
    },
    {
      path: appRoutesPaths.editUser,
      element: <AssignUserRole />,
    },
    {
      path: appRoutesPaths.roles,
      element: <ManageRoles />,
    },
    {
      path: appRoutesPaths.addRole,
      element: <AddRole />,
    },
    {
      path: appRoutesPaths.editRole,
      element: <EditRole />,
    },
  ];
  const [authRoutes, setAuthRoutes] = useState<any[]>(anonymousRoutes);
  const { isAuthorized, auth, setAuth } = useAuth();

  useEffect(() => {
    if (auth && auth.actions) {
      if (auth.actions.includes(appActions.viewCustodians)) {
        routeConf.push({
          path: '/',
          element: <Navigate replace to={appRoutesPaths.locations} />,
        });
      }
      routeConf.push({
        path: '/',
        element: <ResearchCustodians />,
      });
      routeConf.push({
        path: '*',
        element: <NotFound />,
      });
      setAuthRoutes([
        ...anonymousRoutes,
        ...routeConf.filter((route) => {
          const lnk = routesWithActions.find(
            (e: any) => e.navigateTo === route.path,
          );
          if (lnk && lnk.actionName) {
            return isAuthorized(lnk.actionName ?? '') === true;
          }
          return true;
        }),
      ]);
    }
  }, [auth]);

  const isAuthenticated = useIsAuthenticated();

  const { getDataResponse, getData } = useHttp();
  const init = useCallback(async () => {
    await getData('user/getuseractions');
  }, []);

  let actions: string[] = getDataResponse?.data ?? undefined;

  useEffect(() => {
    if (actions && isAuthenticated) {
      setAuth(actions);
    }
  }, [actions, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) init();
  }, [isAuthenticated, init]);

  return { authRoutes };
}
