import { Grid, Typography } from '@mui/material';
import GenericInput from '../../AddLocation/Components/UI/GenericInput';
import usePhonesHelperMethods from '../../../hooks/UI/usePhonesHelperMethods';
import Styles from '../Styles/SearchCustodiansStyles';
import { genericTitle } from '../../../assets/styles/appStyles';
import { useEffect, useRef, useState } from 'react';
import { debounceDelay } from '../../../settings/appConfig';
export default function ResearchCustodiansFilters({
  onSearchChanged,
  isFilterCleared,
}: {
  onSearchChanged: (name: string, phoneNumber: string) => void;
  isFilterCleared: boolean;
}) {
  useEffect(() => {
    if (isFilterCleared) {
      setName('');
      setPhoneNumber('');
    }
  }, [isFilterCleared]);
  const { formatPhoneNumberOnChange } = usePhonesHelperMethods();
  const [searchName, setName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const phoneTimeoutId = useRef<any>(null);
  const searchNameTimeoutId = useRef<any>(null);
  const handleChangeName = (value: string) => {
    setName(value);
    //apply debouncing
    if (searchNameTimeoutId.current) {
      clearTimeout(searchNameTimeoutId.current);
    }
    searchNameTimeoutId.current = setTimeout(() => {
      onSearchChanged(value, phoneNumber);
    }, debounceDelay);
  };
  const handleChangePhone = (value: string) => {
    setPhoneNumber(value);
    //apply debouncing
    if (phoneTimeoutId.current) {
      clearTimeout(phoneTimeoutId.current);
    }
    phoneTimeoutId.current = setTimeout(() => {
      onSearchChanged(searchName, value);
    }, debounceDelay);
  };
  return (
    <Grid container mt={2} sx={Styles.greyCard}>
      <Grid
        px={2}
        container
        justifyContent={'space-between'}
        alignItems="center"
      >
        <Typography align="left" sx={genericTitle}>
          Search Custodians
        </Typography>
      </Grid>
      <Grid
        px={2}
        mb={2}
        container
        justifyContent={'space-between'}
        alignItems="center"
      >
        <Grid item xs={12} md={6} lg={5}>
          <GenericInput
            name={`name`}
            label="Name"
            maxLength={120}
            placeholder=""
            value={searchName}
            onChange={(e: any) => {
              handleChangeName(e.target.value);
            }}
            errorMsg=""
            hasError={false}
            onBlur={() => {}}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <GenericInput
            name={'phoneNumber'}
            label="Phone"
            maxLength={12}
            placeholder="xxx-xxx-xxxx"
            inputMode="tel"
            value={phoneNumber}
            onChange={(e: any) => {
              formatPhoneNumberOnChange(e);
              handleChangePhone(e.target.value);
            }}
            errorMsg=""
            hasError={false}
            onBlur={() => {}}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
