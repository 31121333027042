import {
  Dialog,
  Button,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
} from '@mui/material';
export default function DiscardWizardModal({
  isModalOpened,
  handleClose,
  handleAgree,
  message,
  title,
}: {
  isModalOpened: boolean;
  handleClose: any;
  handleAgree: any;
  message?: string;
  title?: string;
}) {
  return (
    <div>
      <Dialog
        open={isModalOpened}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle>{title || 'Cancel Custodian'}</DialogTitle>
        <DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {message ||
                'Are you sure you want to cancel adding this Custodian?'}
            </DialogContentText>
          </DialogContent>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            No
          </Button>
          <Button onClick={handleAgree}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
