import { BlockBlobClient } from "@azure/storage-blob";
import useHttp from "../http/useHttp";
import { CancelTokenSource } from "axios";


export default function useUploadFiles() {
	const { postData } = useHttp();

	const uploadDepartmentTempFile = async (file: File, controller: AbortController, canceltoken: CancelTokenSource ) => {
		const fileName = file.name.replace(/\s/g, "")

		const url: string = await getUploadUrl({
			fileName: fileName,
		},canceltoken);

		await uploadFileToBlob(file, url, controller);

		return { uri: url };
	};

	const getUploadUrl = async (fileinfo:
		{ fileName: string }, canceltoken: CancelTokenSource) => {
		var result = await postData("blob/upload", fileinfo,canceltoken);
		return result?.data;
	};

	const uploadFileToBlob = async (file: File, url: string, controller: AbortController) => {
		try {
			const blockBlobClient = new BlockBlobClient(url);
			var response = await blockBlobClient.uploadData(file, { abortSignal: controller.signal });
		}
		catch (error: any) {
			console.error(error.message);
			console.error(error);
			return Promise.reject();
		}
	};

	const deleteTempFile = async (url: string) => {
		await postData("blob/delete", { fileURL: url});
	}

	return { uploadDepartmentTempFile, deleteTempFile };
}