import { Button, FormControl, FormHelperText, Grid } from '@mui/material';
import { textWhite, error } from '../../../../assets/styles/appStyles';
export default function MultiSelectInput({
  togglePress,
  values,
  errorMsg,
  options,
  showOnlySelected,
}: {
  togglePress: (e: string) => void;
  values: string[];
  errorMsg: string;
  options: any[];
  showOnlySelected?: boolean;
}) {
  return (
    <FormControl fullWidth>
      <Grid container>
        {options
          ?.filter((o) => (showOnlySelected ? values.includes(o.id) : true))
          .map((o) => {
            const selected = values.includes(o.id);
            return (
              <Grid item pr={1} pt={1} key={o.id}>
                <Button
                  onClick={() => {
                    togglePress(o.id);
                  }}
                  sx={selected ? textWhite : {}}
                  variant={selected ? 'contained' : 'outlined'}
                >
                  {o.name}
                </Button>
              </Grid>
            );
          })}
      </Grid>
      {errorMsg ? (
        <FormHelperText sx={error}>{errorMsg}</FormHelperText>
      ) : null}
    </FormControl>
  );
}
