import { SxProps } from '@mui/material';

const nav: SxProps = {
  zIndex: (theme: any) => theme.zIndex.drawer + 1,
  bgcolor: 'white',
};

const logo: SxProps = { width: '85.04px', cursor: 'pointer' };

const menu: SxProps = { mt: '5px', ml: '38px' };

export default { nav, logo, menu };
