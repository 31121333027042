import DepartmentServePreferencesCaseInfo from './DepartmentServePreferencesCaseInfo';
import DepartmentServePreferencesPatientInfo from './DepartmentServePreferencesPatientInfo';
import DepartmentServePreferencesDocumentsInfo from './DepartmentServePreferencesDocumentsInfo';
import InsertDepartmentModel from '../../../../Models/Departments/InsertDepartmentModel';

export default function DepartmentServePreferences({
	form,
	departmentFileTypes,
	department,
	formKeyName,
	departmentIndex,
}: {
	form: any;
	departmentFileTypes: any[];
	department: InsertDepartmentModel;
	formKeyName: string;
	departmentIndex: number;
}) {
	return (
		<>
			<DepartmentServePreferencesPatientInfo
				form={form}
				departmentIndex={departmentIndex}
				department={department}
				formKeyName={formKeyName} />

			<DepartmentServePreferencesCaseInfo
				form={form}
				departmentIndex={departmentIndex}
				department={department}
				formKeyName={formKeyName} />

			<DepartmentServePreferencesDocumentsInfo
				departmentFileTypes={departmentFileTypes}
				form={form}
				departmentIndex={departmentIndex}
				department={department}
				formKeyName={formKeyName} />
		</>
	)
}
