import { useCallback, useEffect, useState } from 'react';
import useHttp from '../http/useHttp';
import UpdateRole from '../../Models/Roles/updateRole';
import useUI from '../context/useUI';
import { useNavigate } from 'react-router';
import { appRoutesPaths } from '../../settings/appConfig';
import useAuth from '../context/useAuth';

export default function useUpdateRole() {
  const { putDataResponse, putData, getDataResponse, getData } = useHttp();
  const { showSnackbar } = useUI();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const nav = useNavigate();
  const { setAuth } = useAuth();

  const updateUserActions = useCallback(async () => {
    var result = await getData('user/getuseractions');
    if (result?.data) {
      setAuth(result?.data);
    }
  }, []);

  const updateRole = useCallback(async (role: UpdateRole) => {
    try {
      await putData('role', role);
      await updateUserActions();
      setIsSubmitting(false);
      showSnackbar(['added Successfully!'], 'success');
      nav(appRoutesPaths.roles);
    } catch (err) {
      setIsSubmitting(false);
    }
    return putDataResponse;
  }, []);

  return { updateRole, isSubmitting };
}
