import { useEffect, useCallback } from 'react';
import State from '../../Models/Locations/state';
import useHttp from '../http/useHttp';

export default function useFetchStates() {
  const { getDataResponse, getData } = useHttp();

  const init = useCallback(async () => {
    await getData('lookup/getstates');
  }, []);

  let states: State[] = getDataResponse?.data ?? [];

  useEffect(() => {
    init();
  }, [init]);

  return { states };
}
