import { useState } from 'react';
import AuthContext from '../context/AuthContext';
import { AuthData } from '../Models/Users/authData';
const AuthProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const [auth, setAuth] = useState<AuthData>();
  const setAuthInfo = (actions: string[]) => {
    const authData: AuthData = {
      actions: actions,
    };
    setAuth(authData);
  };
  const isAuthorized = (action: string) => {
    if (auth == null || auth.actions == null) return null;
    return auth?.actions.find((a) => a == action) ? true : false;
  };
  return (
    <AuthContext.Provider
      value={{
        auth: auth,
        setAuth: (actions: string[]) => setAuthInfo(actions),
        isAuthorized,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
