import {
  Button,
  Box,
  Grid,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import styles from '../roleStyles';
import Action from '../../../../Models/Roles/action';
import GenericInput from '../../../AddLocation/Components/UI/GenericInput';

const RoleForm = ({
  formik,
  changeActionIds,
  actions,
  isSubmitting,
}: {
  formik: any;
  changeActionIds: any;
  actions: Action[];
  isSubmitting: boolean;
}) => {
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container sx={styles.fromContainer}>
          <Grid item xs={5}>
            <GenericInput
              name="name"
              label="Role Name"
              placeholder="Role Name"
              maxLength={80}
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue(`name`, e.target.value);
              }}
              errorMsg={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : ''
              }
              hasError={
                formik.touched.name && formik.errors.name ? true : false
              }
              onBlur={() => {
                formik.setFieldTouched(`name`, true);
              }}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <GenericInput
              name="description"
              label="Role Description"
              placeholder="Role Description"
              maxLength={200}
              value={formik.values.description}
              onChange={(e: any) => {
                formik.setFieldValue(`description`, e.target.value);
              }}
              errorMsg={
                formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : ''
              }
              hasError={
                formik.touched.description && formik.errors.description
                  ? true
                  : false
              }
              onBlur={() => {
                formik.setFieldTouched(`description`, true);
              }}
            />
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={styles.errorMessage}
              style={{ marginLeft: '10px' }}
            >
              {formik.touched.actionIds && formik.errors.actionIds ? (
                <div>{formik.errors.actionIds}</div>
              ) : null}
            </Grid>
            {actions.map((action, index) => (
              <Grid xs={3} item key={action.id + '' + index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={`actions.${index}`}
                      data-testid={`actions.${index}`}
                      name={`actions.${index}`}
                      onChange={(event) => changeActionIds(event, action.id)}
                      onBlur={formik.handleBlur}
                      value={action.id}
                      color="primary"
                      checked={formik.values.actionIds.includes(action.id)}
                    />
                  }
                  label={action.name}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Box sx={styles.submitButtonContainer}>
          <Button type="submit" variant="contained" sx={styles.submitButton}>
            {isSubmitting ? <CircularProgress size={28} /> : 'Save'}
          </Button>
        </Box>
      </form>
    </>
  );
};
export default RoleForm;
