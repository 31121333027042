import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import style from '../../../departmentStyle';
import DepartmentFilePreview from './DepartmentFilePreview';
import { DepartmentFile } from '../../../../../../../Models/Departments/Details/file';
import { useMemo, useState } from 'react';
import useGetFile from '../../../../../../../hooks/Locations/useFetchFileSasUrl';
import { accordionSummaryText } from '../../../../../Styles/MainInformationTabStyles';
import useUI from '../../../../../../../hooks/context/useUI';

export default function FilesInfo({
  files,
  onRemoveFile,
  isExpanded,
  handleAccordionChange,
}: {
  files: DepartmentFile[];
  onRemoveFile?: (id: number) => void;
  isExpanded?: (panel: string) => boolean;
  handleAccordionChange?: (panel: string) => (event: React.SyntheticEvent, isExpanding: boolean) => void
}) {
  const { getFileSasUrl } = useGetFile();

  const [filePreview, setFilePreview] = useState<DepartmentFile>();
  const [filePreviewUrl, setFilePreviewURl] = useState<string>();
  const [downloading, setDownloading] = useState<string[]>([]);
  const [previewing, setPreviewing] = useState<string>('');
  const [openDialog, setOpenDialog] = useState(true);

  const showPreview = false;

  const { showSnackbar } = useUI();
  const groupFiles: { id: string; data: DepartmentFile[] }[] = useMemo(() => {
    const groupedData = files.reduce((acc: any, obj) => {
      const key = obj.fileTypeName;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});

    return Object.entries(groupedData).map(([id, data]) => ({
      id,
      data: data as DepartmentFile[],
    }));
  }, [files]);

  const downloadFile = async (file: DepartmentFile) => {
    if (!file.permanentBlobUrl) {
      showSnackbar(["the resource of the file isn't available"], 'error');
      return;
    }
    try {
      setDownloading([...downloading, file.id]);
      const result = await getFileSasUrl(file.permanentBlobUrl);
      setDownloading(downloading.filter((p) => p === file.id));
      window.open(result);
    } catch {
      setDownloading(downloading.filter((p) => p === file.id));
    }
  };

  const preview = async (file: DepartmentFile) => {
    if (!file.permanentBlobUrl) {
      showSnackbar(["the resource of the file isn't available"], 'error');
      return;
    }
    try {
      setOpenDialog(false);
      setPreviewing(file.id);
      const result = await getFileSasUrl(file.permanentBlobUrl);
      setFilePreviewURl(result);
      setFilePreview(file);
      setPreviewing('');
      setOpenDialog(true);
    } catch {
      setPreviewing('');
      setOpenDialog(false);
    }
  };

  function formatFileSize(bytes: number) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const decimals = 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const fileSize = parseFloat((bytes / Math.pow(k, i)).toFixed(decimals));

    return `${fileSize} ${sizes[i]}`;
  }
  return (
    <>
      <Accordion 
      expanded={onRemoveFile || (isExpanded ? isExpanded('FilesInfo'): false) ? true : undefined}
      onChange={handleAccordionChange ? handleAccordionChange('FilesInfo') : undefined}>
        <AccordionSummary
          sx={accordionSummaryText}
          aria-controls="Files-content"
          id="Files-header"
          expandIcon={onRemoveFile ? null : <ExpandMore />}
        >
          Files
        </AccordionSummary>
        <AccordionDetails>
          {groupFiles.map((p) => {
            return (
              <Grid container sx={{ marginTop: '20px' }} key={p.id}>
                <Grid item xs={12} sx={style.fileGroup}>
                  {p.id}
                </Grid>
                {p.data.map((file: DepartmentFile, index) => {
                  return (
                    <Grid
                      container
                      style={{ width: '48%' }}
                      key={file.id + '' + index}
                      sx={style.file}
                      alignItems={'center'}
                      mr={1}
                    >
                      <Grid item xs={1}>
                        <InsertDriveFileIcon sx={{ fontSize: '30px' }} />
                      </Grid>

                      <Grid item xs={7}>
                        <Typography sx={style.fileName}>
                          {file.fileName}
                        </Typography>
                        <Typography sx={style.fileSize}>
                          {formatFileSize(file.fileSize)}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          onClick={() => downloadFile(file)}
                          disabled={downloading.includes(file.id)}
                        >
                          {downloading.includes(file.id) ? (
                            <CircularProgress size={20} />
                          ) : (
                            'Download'
                          )}
                        </Button>
                      </Grid>
                      {showPreview && (
                        <Grid item xs={2}>
                          <Button
                            onClick={() => preview(file)}
                            disabled={previewing === file.id}
                          >
                            {previewing === file.id ? (
                              <CircularProgress size={20} />
                            ) : (
                              'Preview'
                            )}
                          </Button>
                        </Grid>
                      )}
                      {onRemoveFile && (
                        <Grid item xs={2}>
                          <Button
                            data-testid="remove-btn"
                            onClick={() => onRemoveFile(+file.id)}
                            disabled={previewing === file.id}
                          >
                            {previewing === file.id ? (
                              <CircularProgress size={20} />
                            ) : (
                              'Remove'
                            )}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
        </AccordionDetails>
      </Accordion>
      {filePreviewUrl && filePreview && (
        <DepartmentFilePreview
          file={filePreview}
          openDialog={openDialog}
          closeDialog={() => setOpenDialog(false)}
          fileUrl={filePreviewUrl}
        />
      )}
    </>
  );
}
