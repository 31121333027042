import useDepartmentForm from '../addLocation/useDepartmentForm';
import useFetchDays from '../useFetchDays';
import useFetchDepartmentFileTypes from '../useFetchDepartmentFileTypes';
import useFetchOrderTypes from '../useFetchOrderTypes';
import useFetchPhoneTypes from '../useFetchPhoneTypes';
import useFetchRecordTypes from '../useFetchRecordTypes';
import useFetchServeMethods from '../useFetchServeMethods';
import useFetchStates from '../useFetchStates';
import useFetchTimeZones from '../useFetchTimeZones';

export default function useAddDepartment() {
  const { days } = useFetchDays();
  const { serveMethods } = useFetchServeMethods();
  const { departmentFileTypes } = useFetchDepartmentFileTypes();
  const { timeZones } = useFetchTimeZones();
  const { recordTypes } = useFetchRecordTypes();
  const { states } = useFetchStates();
  const { phoneTypes } = useFetchPhoneTypes();
  const { orderTypes } = useFetchOrderTypes();

  const { departmentsForm } = useDepartmentForm({
    departmentFileTypes,
    orderTypes,
    phoneTypes,
    recordTypes,
    states,
	shouldFillInitialDepartement: true,
  });

  return {
    departmentsForm,
    orderTypes,
    phoneTypes,
    recordTypes,
    states,
    timeZones,
    departmentFileTypes,
    serveMethods,
    days,
  };
}
