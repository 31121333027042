import { useCallback } from 'react';
import useHttp from '../../http/useHttp';
import useUI from '../../context/useUI';
import CustodianBillingInfo from '../../../Models/custodian/custodian-billingInfo';

export default function useEditCustodianBillingInfo() {
  const { showSnackbar } = useUI();
  const { putDataResponse, putData } = useHttp();

  const edit = useCallback(async (model: CustodianBillingInfo) => {
    const result = await putData('custodian/editbilling', model);
    if (result && result.data && !isNaN(result.data)) {
      showSnackbar(['updated successfully!'], 'success');
    }
  }, []);

  return { data: putDataResponse?.data ?? null, edit };
}
