import MUIDrawer from '@mui/material/Drawer';
import { Box, Grid, IconButton } from '@mui/material';
import { drawerWidth } from '../../theme';
import { SidebarItemModel } from '../../../Models/Layout/sidebarItemModel';
import { useNavigate } from 'react-router';
import SideBarList from './SideBarList';
import Styles from './SideBarStyles';
import { Menu, ChevronLeft } from '@mui/icons-material';
import useUI from '../../../hooks/context/useUI';

export default function SideBar({ items }: { items: SidebarItemModel[] }) {
  const { isSideBarOpened, openSideBar } = useUI();
  const nav = useNavigate();

  const sideBarItemClicked = (item: SidebarItemModel) => {
    nav(item.navigateTo);
  };

  const handleDrawerOpen = () => {
    openSideBar(true);
  };

  const handleDrawerClose = () => {
    openSideBar(false);
  };

  return (
    <>
      <MUIDrawer
        variant="persistent"
        anchor="left"
        open={isSideBarOpened}
        sx={Styles.drawerContainer(isSideBarOpened ? drawerWidth : 0)}
      >
        <Box sx={Styles.sideBarListContainer}>
          <Grid container justifyContent={'end'}>
            <Grid item>
              <IconButton aria-label="close drawer" onClick={handleDrawerClose}>
                <ChevronLeft />
              </IconButton>
            </Grid>
          </Grid>
          <SideBarList sideBarItemClicked={sideBarItemClicked} items={items} />
        </Box>
      </MUIDrawer>
      <Box sx={Styles.sideBarOpenDrawerIcon}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={
            !isSideBarOpened
              ? Styles.sideBarShownDrawerIcon
              : Styles.sideBarHiddenDrawerIcon
          }
        >
          <Menu />
        </IconButton>
      </Box>
    </>
  );
}
