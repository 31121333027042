import { useNavigate } from 'react-router';
import { logoutUser } from './../../services/authService';
import { appRoutesPaths, baseURL } from './../../settings/appConfig';
import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { accessTokenScopes } from '../../settings/authConfig';
import useUI from '../context/useUI';

const useHttp = () => {
  const navigate = useNavigate();
  const [getDataResponse, setGetDataResponse] =
    useState<AxiosResponse<any, any>>();
  const [postDataResponse, setPostDataResponse] =
    useState<AxiosResponse<any, any>>();

  const [putDataResponse, setPutDataResponse] =
    useState<AxiosResponse<any, any>>();

  const [patchDataResponse, setPatchDataResponse] =
    useState<AxiosResponse<any, any>>();

  const [refreshAttempted, setRefreshAttempted] = useState(false);
  const { accounts, instance: msalInstance } = useMsal();
  const { showSnackbar } = useUI();
  const handleRefreshToken = async (error: any) => {
    const { config } = error;

    if (config && config.headers.Authorization) {
      if (!refreshAttempted) {
        try {
          // If the access token has expired and a refresh has not already been attempted, try to obtain a new access token using the refresh token
          setRefreshAttempted(true);
          const newAccessToken = await msalInstance.acquireTokenSilent({
            scopes: accessTokenScopes,
            account: accounts[0],
          });
          if (newAccessToken && newAccessToken.accessToken) {
            config.headers.Authorization = `Bearer ${newAccessToken.accessToken}`;
            return axios.request(config);
          } else logoutUser(msalInstance);
        } catch (error) {
          logoutUser(msalInstance);
        }
      }
    }
    throw error;
  };
  const handleValidationErrors = (error: any) => {
    var messages: string[] = [];
    if (error.response?.data?.errors) {
      Object.keys(error.response?.data?.errors).forEach((element) => {
        const value = error.response?.data?.errors[element];
        if (value && Array.isArray(value)) {
          value.forEach((e) => {
            if (typeof e === 'string') {
              messages.push(e);
            }
          });
        }
      });
    }
    if (messages && messages.length > 0) showSnackbar(messages, 'error');
    throw new Error(error);
  };

  const getData = async (url: string, cancelToken?: CancelTokenSource) => {
    try {
      setGetDataResponse(undefined);
      const accessToken = await msalInstance.acquireTokenSilent({
        scopes: accessTokenScopes,
        account: accounts[0],
      });

      const response: AxiosResponse<any, any> = await axios.get(baseURL + url, {
        cancelToken: cancelToken?.token,
        headers: {
          Authorization: `Bearer ${accessToken.accessToken}`,
        },
      });

      setGetDataResponse(response);
      return response;
    } catch (error: any) {
      if (error.name === 'InteractionRequiredAuthError') {
        msalInstance.acquireTokenPopup({
          scopes: accessTokenScopes,
        });
      } else if (error.response && error.response.status === 401) {
        handleRefreshToken(error)
          .then((response) => {
            setGetDataResponse(response);
            return response;
          })
          .catch((error) => {
            console.log('Failed to refresh access token', error);
          });
      } else if (error.response && error.response.status === 400) {
        //validation exception
        handleValidationErrors(error);
      } else if (error.response && error.response.status === 403) {
        //user is blocked
        navigate(appRoutesPaths.unauthorized);
      } else if (error.response && error.response.status === 404) {
        navigate(appRoutesPaths.notFound);
      } else {
        console.log('Failed to fetch data', error);
        throw new Error(error);
      }
    }
  };

  const postData = async (
    url: string,
    data: any,
    cancelToken?: CancelTokenSource,
  ) => {
    try {
      setPostDataResponse(undefined);
      const accessToken = await msalInstance.acquireTokenSilent({
        scopes: accessTokenScopes,
        account: accounts[0],
      });

      const response: AxiosResponse<any, any> = await axios.post(
        baseURL + url,
        data,
        {
          cancelToken: cancelToken?.token,
          headers: {
            Authorization: `Bearer ${accessToken.accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );
      setPostDataResponse(response);
      return response;
    } catch (error: any) {
      if (error.name === 'InteractionRequiredAuthError') {
        msalInstance.acquireTokenPopup({
          scopes: accessTokenScopes,
        });
      } else if (error.response && error.response.status === 401) {
        handleRefreshToken(error)
          .then((response) => {
            setPostDataResponse(response);
            return response;
          })
          .catch((error) => {
            console.log('Failed to refresh access token', error);
          });
      } else if (error.response && error.response.status === 403) {
        //user is blocked
        navigate(appRoutesPaths.unauthorized);
      } else if (error.response && error.response.status === 404) {
        navigate(appRoutesPaths.notFound);
      } else if (error.response && error.response.status === 400) {
        //validation exception
        handleValidationErrors(error);
      } else {
        throw new Error(error);
      }
    }
  };

  const putData = async (
    url: string,
    data: any,
    cancelToken?: CancelTokenSource,
  ) => {
    try {
      setPutDataResponse(undefined);
      const accessToken = await msalInstance.acquireTokenSilent({
        scopes: accessTokenScopes,
        account: accounts[0],
      });

      const response: AxiosResponse<any, any> = await axios.put(
        baseURL + url,
        data,
        {
          cancelToken: cancelToken?.token,
          headers: {
            Authorization: `Bearer ${accessToken.accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );

      setPutDataResponse(response);
      return response;
    } catch (error: any) {
      if (error.name === 'InteractionRequiredAuthError') {
        msalInstance.acquireTokenPopup({
          scopes: accessTokenScopes,
        });
      } else if (error.response && error.response.status === 401) {
        handleRefreshToken(error)
          .then((response) => {
            setPutDataResponse(response);
            return response;
          })
          .catch((error) => {
            console.log('Failed to refresh access token', error);
          });
      } else if (error.response && error.response.status === 403) {
        //user is blocked
        navigate(appRoutesPaths.unauthorized);
      } else if (error.response && error.response.status === 404) {
        navigate(appRoutesPaths.notFound);
      } else if (error.response && error.response.status === 400) {
        //validation exception
        handleValidationErrors(error);
      } else {
        console.log('Failed to post data', error);
        throw new Error(error);
      }
    }
  };

  const patchData = async (
    url: string,
    data: any,
    cancelToken?: CancelTokenSource,
  ) => {
    try {
      setPatchDataResponse(undefined);
      const accessToken = await msalInstance.acquireTokenSilent({
        scopes: accessTokenScopes,
        account: accounts[0],
      });

      const response: AxiosResponse<any, any> = await axios.patch(
        baseURL + url,
        data,
        {
          cancelToken: cancelToken?.token,
          headers: {
            Authorization: `Bearer ${accessToken.accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );

      setPatchDataResponse(response);
      return response;
    } catch (error: any) {
      if (error.name === 'InteractionRequiredAuthError') {
        msalInstance.acquireTokenPopup({
          scopes: accessTokenScopes,
        });
      } else if (error.response && error.response.status === 401) {
        handleRefreshToken(error)
          .then((response) => {
            setPutDataResponse(response);
            return response;
          })
          .catch((error) => {
            console.log('Failed to refresh access token', error);
          });
      } else if (error.response && error.response.status === 403) {
        //user is blocked
        navigate(appRoutesPaths.unauthorized);
      } else if (error.response && error.response.status === 404) {
        navigate(appRoutesPaths.notFound);
      } else if (error.response && error.response.status === 400) {
        //validation exception
        handleValidationErrors(error);
      } else {
        console.log('Failed to patch data', error);
        throw new Error(error);
      }
    }
  };

  return {
    getData,
    getDataResponse,
    postData,
    postDataResponse,
    putData,
    putDataResponse,
    patchData,
    patchDataResponse,
  };
};

export default useHttp;
