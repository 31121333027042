import { useCallback } from 'react';
import useHttp from '../http/useHttp';
import { useNavigate } from 'react-router';
import useUI from '../context/useUI';

export default function usePostCustodian() {
  const navigate = useNavigate();
  const { showSnackbar } = useUI();
  const { postDataResponse, postData } = useHttp();

  const save = useCallback(async (model: any) => {
    const result = await postData('custodian/save', model);
    if (result && result.data && !isNaN(result.data)) {
      showSnackbar(['custodian created successfully!'], 'success');
      navigate(`/custodians/details/${result.data}`);
    }
  }, []);

  return { data: postDataResponse?.data ?? null, save };
}
