import * as React from 'react';
import { Box, Divider, Tab, Tabs } from '@mui/material';
import useFetchCustodianDetails from '../../hooks/Locations/useFetchCustodianDetails';
import { useParams } from 'react-router';
import CustodianDetailsHeader from './Components/CustodianDetailsHeader';
import TabPanel from '../../components/UI/TabPanel';
import { horizontalLine } from '../../assets/styles/appStyles';
import { custodianDetailsContainer } from './CustodianDetailsStyles';
import DepartmentDetails from './Components/Department/DepartmentDetails';
import BillingInfoDetails from './Components/Billing/BillingInfoDetails';
import AssociationsDetails from './Components/Association/AssociationsDetails';
import ComponentLoader from '../../common/ComponentLoader/componentLoader';
import MainInformationTab from './Components/MainInformationTab';
import CustodianDetailsViewModel from '../../Models/custodian/custodian-details-viewmodel';
import { useState } from 'react';

export default function CustodianDetails() {
  const { id } = useParams();
  const { custodian, isLoading, setAssociationHasUpdate } =
    useFetchCustodianDetails(id ?? '');

  const [selectedTabIndex, setSelectedTabIndex] = React.useState(1);
  const [custodianData, setCustodianData] = useState(custodian);
  const phoneTypeId = 1;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  React.useEffect(() => {
    if (!isLoading) {
      setCustodianData(custodian);
    }
  }, [isLoading]);

  const handleCustodianUpdate = (
    updatedCustodian: CustodianDetailsViewModel,
  ) => {
    custodian.name = updatedCustodian.name;
    custodian.portalURL = updatedCustodian.portalURL;
    custodian.address = updatedCustodian.address;
    custodian.phoneNumbers = updatedCustodian.phoneNumbers;
  };

  return (
    <ComponentLoader isOpen={isLoading}>
      <Box sx={custodianDetailsContainer}>
        <Box>
          <CustodianDetailsHeader
            headerInfo={{
              address: custodian.address?.addressDetails ?? '-',
              creationDate: new Date(custodian.creationDate),
              portalURL: custodian.portalURL ?? '-',
              phone:
                custodian.phoneNumbers?.find((x) => x.isMainPhone === true)
                  ?.phoneNumber ??
                custodian.phoneNumbers?.find(
                  (x) => x.phoneTypeId === phoneTypeId,
                )?.phoneNumber ??
                '-',
              name: custodian.name,
            }}
          ></CustodianDetailsHeader>
        </Box>
        <Box>
          <Tabs
            value={selectedTabIndex}
            onChange={handleTabChange}
            data-style={'outlined'}
          >
            <Tab value={1} label="Main Information" data-style={'outlined'} />
            <Tab value={2} label="Departments" data-style={'outlined'} />
            <Tab value={3} label="Billing" data-style={'outlined'} />
            <Tab
              value={4}
              label="Custodian Relations"
              data-style={'outlined'}
            />
          </Tabs>
          <Divider sx={horizontalLine} />
          <MainInformationTab
            selectedTabIndex={selectedTabIndex}
            custodian={custodian}
            onCustodianUpdate={handleCustodianUpdate}
          ></MainInformationTab>
          <TabPanel value={selectedTabIndex} index={2}>
            <DepartmentDetails custodianId={custodian?.id} departments={custodian?.departments ?? []} />
          </TabPanel>
          <TabPanel value={selectedTabIndex} index={3}>
            <BillingInfoDetails
              custodian={custodianData}
              setCustodianData={setCustodianData}
            />
          </TabPanel>
          <TabPanel value={selectedTabIndex} index={4}>
            <AssociationsDetails
              associations={custodian?.associations ?? []}
              setAssociationHasUpdate={setAssociationHasUpdate}
            />
          </TabPanel>
        </Box>
      </Box>
    </ComponentLoader>
  );
}
