import { createContext } from 'react';
import { AuthData } from '../Models/Users/authData';
const defaultAuthData = {
  actions: [],
};
interface AuthContext {
  auth: AuthData | undefined;
  setAuth: (actions: string[]) => void;
  isAuthorized: (_: string) => boolean | null;
}
const AuthContext = createContext<AuthContext>({
  auth: defaultAuthData,
  setAuth: () => {},
  isAuthorized: (_: string) => {
    return null;
  },
});
export default AuthContext;