import CustodianAzureSearchResult from '../../Models/Locations/CustodianAzureSearchResult';
import CustodiansFieldedAzureSearchParams from '../../Models/Locations/CustodiansFieldedAzureSearchParams';
import useConstructAzureSearchParams from './useConstructAzureSearchParams';
import useFetchAzureSearchCustodians from './useFetchAzureSearchCustodians';
import { useEffect, useState, useCallback } from 'react';

export default function useSearchCustodians(
  isModalOpened: boolean,
  searchPropertyName: string,
  searchPropertyValue: string,
) {
  const { data, isLoading, fieldedSearchChanged } =
    useFetchAzureSearchCustodians();
  const [page, setPage] = useState(0);
  const { constructFieldedSearchParamsObject } =
    useConstructAzureSearchParams();

  const searchUsingFieldedSearch = useCallback(
    async (newPage: number) => {
      setPage(newPage);

      var searchParams = constructFieldedSearchParamsObject(
        newPage,
        searchPropertyName,
        searchPropertyValue,
      );
      await fieldedSearchChanged(searchParams);
    },
    [searchPropertyName, searchPropertyValue],
  );

  useEffect(() => {
    if (isModalOpened) {
      searchUsingFieldedSearch(0);
    }
  }, [isModalOpened, searchUsingFieldedSearch]);

  const searchExactProperty = async (value: string, propertyName: string) => {
    if (propertyName) {
      var searchParams = constructFieldedSearchParamsObject(
        0,
        propertyName,
        value,
        0,
        true,
      );
      if (searchParams !== ({} as CustodiansFieldedAzureSearchParams)) {
        var result: CustodianAzureSearchResult = await fieldedSearchChanged(
          searchParams,
        );
        return result;
      }

      return null;
    }
  };

  return {
    data,
    page,
    isLoading,
    searchUsingFieldedSearch,
    fieldedSearchChanged,
    searchExactProperty,
  };
}
