import { Box, FormControl, Grid, Button, Typography } from '@mui/material';
import { maxPhonesAllowed } from '../../../settings/appConfig';
import { error } from './../../../assets/styles/appStyles';
import CheckboxInput from './UI/CheckboxInput';
import plusIcon from '../../../assets/Icons/plusIcon.svg';
import usePhonesHelperMethods from '../../../hooks/UI/usePhonesHelperMethods';
import PhoneType from '../../../Models/Locations/phoneType';
import GenericSelectInput from './UI/GenericSelectInput';
import useValidation from '../../../hooks/validation/useValidation';
import useUI from '../../../hooks/context/useUI';
import CustodianFieldSearchInput from './CustodianFieldSearchInput';
export default function Phones({
  formik,
  phoneTypes,
}: {
  formik: any;
  phoneTypes: PhoneType[];
}) {
  const { formatPhoneNumberOnChange, isMainPhoneDisabled } =
    usePhonesHelperMethods();
  const { isPhonesValid, isPhoneNumberValid } = useValidation();
  const { showSnackbar } = useUI();

  const onAddNewPhone = async () => {
    if (isPhonesValid(formik.values.phones) || formik?.values?.phones?.length === 0) {
      const newItems = [
        ...formik.values.phones,
        { isMainPhone: false, phoneNumber: '', phoneTypeId: null },
      ];
      formik.setFieldValue('phones', newItems);
    } else {
      showSnackbar(['Please enter a valid phone'], 'error');
    }
  };

  return (
    <>
      {formik.values.phones.length > 0 &&
        formik.values.phones.map((item: any, index: number) => (
          <Grid container key={index} my={1}>
            <Grid item xs={4} px={2}>
              <CustodianFieldSearchInput
              setHasDuplicateError={(duplicateFound) => {formik.setFieldValue(
                `phones.${index}.hasDuplicateError`,
                duplicateFound,
              );}}
                searchPropertyName="phoneNumber"
                name={`phones.${index}.phoneNumber`}
                shouldViewModal={false}
                label="Fax/Phone number"
                maxLength={12}
                placeholder="xxx-xxx-xxxx"
                inputMode="tel"
                isExactSearch={true}
                value={item.phoneNumber}
                isValid={isPhoneNumberValid}
                formErrorMessage={
                  formik.touched.phones &&
                  formik.touched.phones[index] &&
                  formik.touched.phones[index]['phoneNumber'] &&
                  formik.errors.phones &&
                  formik.errors.phones[index] &&
                  formik.errors.phones[index]['phoneNumber']
                    ? formik.errors.phones[index]['phoneNumber']
                    : ''
                }
                hasFormError={
                  formik.touched.phones &&
                  formik.touched.phones[index] &&
                  formik.touched.phones[index]['phoneNumber'] &&
                  formik.errors.phones &&
                  formik.errors.phones[index] &&
                  formik.errors.phones[index]['phoneNumber']
                    ? true
                    : false
                }
                onChange={(e: any) => {
                  formik.setFieldTouched(`phones.${index}.phoneNumber`, true);

                  formik.setFieldError(`phones.${index}.phoneNumber`, '');
                  formatPhoneNumberOnChange(e);
                  formik.setFieldValue(
                    `phones.${index}.phoneNumber`,
                    e.target.value,
                  );
                }}
                onBlur={() => {
                  formik.setFieldTouched(`phones.${index}.phoneNumber`, true);
                }}
                duplicateErrorMessage={
                  'A custodian was found having the same phone number'
                }
                required={true}
              />
            </Grid>
            <Grid item xs={4} px={2}>
              <GenericSelectInput
                name={`phones.${index}.phoneTypeId`}
                label="Type"
                value={item.phoneTypeId}
                onChange={(value: string) => {
                  formik.setFieldValue(`phones.${index}.phoneTypeId`, value);
                }}
                errorMsg={
                  formik.touched['phones'] &&
                  formik.touched['phones'][index] &&
                  formik.touched['phones'][index]['phoneTypeId'] &&
                  formik.errors['phones'] &&
                  formik.errors['phones'][index] &&
                  formik.errors['phones'][index]['phoneTypeId']
                    ? formik.errors['phones'][index]['phoneTypeId']
                    : ''
                }
                hasError={
                  formik.touched['phones'] &&
                  formik.touched['phones'][index] &&
                  formik.touched['phones'][index]['phoneTypeId'] &&
                  formik.errors['phones'] &&
                  formik.errors['phones'][index] &&
                  formik.errors['phones'][index]['phoneTypeId']
                    ? true
                    : false
                }
                onBlur={() => {
                  formik.setFieldTouched(`phones[${index}].phoneTypeId`, true);
                }}
                options={phoneTypes}
                required={true}
              />
            </Grid>
            <Grid item md={4} px={2}>
              <FormControl fullWidth>
                <Box px={2}>
                  <CheckboxInput
                    checked={item.isMainPhone}
                    keyName={`phones[${index}].isMainPhone`}
                    label="Is Main Phone"
                    disabled={isMainPhoneDisabled(
                      item,
                      index,
                      phoneTypes,
                      formik,
                    )}
                    onBlur={() => {
                      formik.setFieldTouched(
                        `phones[${index}].isMainPhone`,
                        true,
                      );
                    }}
                    onChange={(e: any) => {
                      formik.setFieldValue(
                        `phones.${index}.isMainPhone`,
                        e.target.checked,
                      );
                    }}
                  />
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={2} px={1}>
              {formik.values.phones.length > 1 && (
                <Button
                  type="button"
                  onClick={() => {
                    //don't remove last entry
                    if (formik.values.phones.length === 1) return;
                    const newItems = [...formik.values.phones];
                    newItems.splice(index, 1);
                    formik.setFieldValue('phones', [...newItems]);
                  }}
                >
                  Remove
                </Button>
              )}
            </Grid>
          </Grid>
        ))}
      <Grid container alignItems={'center'} my={1}>
        <Grid item md={4} px={2}>
          {formik.errors &&
          formik.errors.phones &&
          typeof formik.errors.phones === 'string' ? (
            <Typography align="left" sx={error}>
              {formik.errors.phones}
            </Typography>
          ) : null}
          {formik.values.phones.length < maxPhonesAllowed && (
            <Button onClick={onAddNewPhone}>
              <Box data-testid="plusIcon" height={'20.75px'} width={'16.75px'}>
                <img src={plusIcon} width={'16.75px'} height={'16.75px'} />
              </Box>
              Add New Phone
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
}
