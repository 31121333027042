import { Box, Grid, Typography } from '@mui/material';
import { emptyValue } from '../../../../settings/appConfig';
import { detailsTitle, detailsValue } from '../../../../assets/styles/appStyles';

export default function BillingInfoPayeeDetails({
  payeeName,
  payeeCity,
  payeeAddress,
  payeeContact,
  payeePostCode,
  payeeStateName,
}: {
  payeeName: string;
  payeeCity: string;
  payeeAddress: string;
  payeeContact: string;
  payeePostCode: string;
  payeeStateName: string;
}) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Typography sx={detailsTitle}>Payee name</Typography>
        <Box sx={detailsValue}>{!!payeeName ? payeeName : emptyValue}</Box>
      </Grid>

      <Grid item xs={4}>
        <Typography sx={detailsTitle}>Payee city</Typography>
        <Box sx={detailsValue}>{!!payeeCity ? payeeCity : emptyValue}</Box>
      </Grid>

      <Grid item xs={4}>
        <Typography sx={detailsTitle}>Payee address</Typography>
        <Box sx={detailsValue}>
          {!!payeeAddress ? payeeAddress : emptyValue}
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Typography sx={detailsTitle}>Payee phone</Typography>
        <Box sx={detailsValue}>
          {!!payeeContact ? payeeContact : emptyValue}
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Typography sx={detailsTitle}>Payee state</Typography>
        <Box sx={detailsValue}>
          {!!payeeStateName ? payeeStateName : emptyValue}
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Typography sx={detailsTitle}>Payee Zip/Postal Code</Typography>
        <Box sx={detailsValue}>
          {!!payeePostCode ? payeePostCode : emptyValue}
        </Box>
      </Grid>
    </Grid>
  );
}
