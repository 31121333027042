import { Box, Button, Grid, Typography } from '@mui/material';
import WizardStepTitle from '../../Models/Layout/wizard/WizardStepTitle';
import checkedIcon from '../../assets/Icons/checkedIcon.svg';

import {
  wizardTitle,
  wizardTitleIndex,
  wizardTitleItem,
} from '../../assets/styles/wizardStyles';

export default function WizardHeaderItem({
  step,
  stepClicked,
  index,
  isFirstStep,
  isLastStep,
}: {
  step: WizardStepTitle;
  stepClicked: (step: WizardStepTitle) => void;
  index: number;
  isFirstStep?: boolean;
  isLastStep?: boolean;
}) {
  return (
    <Grid
      component={Button}
      item
      sx={wizardTitleItem(
        step.isCurrentStep,
        step.isValid,
        isFirstStep,
        isLastStep,
      )}
      py={'10px'}
      onClick={() => stepClicked(step)}
      disabled={!step.isValid && !step.isCurrentStep}
      key={step.label}
      data-testid="parent"
    >
      <Grid container px={1} alignItems={'center'}>
        <Grid item pr={'8px'}>
          {step.isValid && !step.isCurrentStep ? (
            <Box data-testid="checkedIcon" height={'16.75px'} width={'16.75px'}>
              <img src={checkedIcon} />
            </Box>
          ) : (
            <Box data-testid="index" sx={wizardTitleIndex(step.isCurrentStep)}>
              <Typography variant="subtitle2">{index + 1}</Typography>
            </Box>
          )}
        </Grid>
        <Grid item>
          <Typography sx={wizardTitle(step.isCurrentStep, step.isValid)}>
            {step.label}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
