import { Menu, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import NavBarStyles from './NavBarStyles';
import logoutIcon from '../../../assets/images/logout.svg';

export default function NavBarMenu({
  open,
  anchorEl,
  handleDropClose,
  handleLogout,
}: {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleDropClose: () => void;
  handleLogout: () => void;
}) {
  const theme = useTheme();
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleDropClose}
      sx={NavBarStyles.menu}
    >
      <MenuItem
        onClick={() => handleLogout()}
        style={{ backgroundColor: 'white' }}
      >
        <img src={logoutIcon} alt="logout icon" />
        <Typography color={theme.palette.neutral['400']} sx={{ ml: '10px' }}>
          Logout
        </Typography>
      </MenuItem>
    </Menu>
  );
}
