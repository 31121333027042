import { Button, Grid } from '@mui/material';
import WizardStepTitle from '../../Models/Layout/wizard/WizardStepTitle';
import WizardHeaderItem from './WizardHeaderItem';

export default function WizardHeader({
  steps,
  stepClicked,
}: {
  steps: WizardStepTitle[];
  stepClicked: (step: WizardStepTitle) => void;
}) {
  return (
    <Grid container mx={2} p={3} justifyContent={"center"}>
      <Grid item >
        <Grid container>
          {steps.map((step, i) => (
            <WizardHeaderItem
              index={i}
              key={step.label}
              stepClicked={stepClicked}
              step={step}
              isFirstStep={i == 0}
              isLastStep={i == steps.length - 1}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
