import { useCallback, useEffect, useState } from 'react';
import useHttp from '../http/useHttp';

export default function useFetchUsers() {
  const { getDataResponse, getData } = useHttp();
  const [isLoading, setIsLoading] = useState(false);

  const getAll = useCallback(async () => {
    try {
      setIsLoading(true);
      await getData(`user/getall`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  let users: any = getDataResponse?.data ?? [];

  useEffect(() => {
    getAll();
  }, [getAll]);

  return { users, isLoading, getAll };
}
