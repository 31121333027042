import { Box, FormHelperText, Grid, Typography } from '@mui/material';
import { error, selectedButton } from '../../../../assets/styles/appStyles';
import AppTimePicker from '../AppTimePicker';
import InsertDepartmentModel from '../../../../Models/Departments/InsertDepartmentModel';
import GenericSelectInput from '../UI/GenericSelectInput';
import { useEffect, useState } from 'react';

export default function DepartmentServeInfoDay({
  form,
  departmentIndex,
  department,
  formKeyName,
  timeZones,
  days,
  day,
  dayIndex,
  handServeId,
}: {
  form: any;
  departmentIndex: number;
  department: InsertDepartmentModel;
  formKeyName: string;
  timeZones: any[];
  days: any[];
  day: any;
  dayIndex: number;
  handServeId: string;
}) {
  const dayItemHourErrorMessage = (
    hourKeyName: string,
    hourLevelKeyName: string,
    index: number,
  ) => {
    return form.errors[formKeyName] &&
      form.errors[formKeyName][departmentIndex] &&
      form.errors[formKeyName][departmentIndex] &&
      form.errors[formKeyName][departmentIndex]['daysAvailable'] &&
      form.errors[formKeyName][departmentIndex]['daysAvailable'][index] &&
      form.errors[formKeyName][departmentIndex]['daysAvailable'][index][
        hourKeyName
      ] &&
      form.errors[formKeyName][departmentIndex]['daysAvailable'][index][
        hourKeyName
      ][hourLevelKeyName]
      ? form.errors[formKeyName][departmentIndex]['daysAvailable'][index][
          hourKeyName
        ][hourLevelKeyName]
      : '';
  };

  const [openPickerId, setOpenPickerId] = useState<String>('');

  useEffect(() => {
    if (
      department.serveMethods.findIndex(
        (s) => s.serveMethodId == handServeId,
      ) === -1
    ) {
      form.setFieldValue(
        `${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.serveHours.from`,
        null,
      );
      form.setFieldValue(
        `${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.serveHours.to`,
        null,
      );
      form.setFieldValue(
        `${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.pickupAndCopyHours.from`,
        null,
      );
      form.setFieldValue(
        `${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.pickupAndCopyHours.to`,
        null,
      );
    }
  }, [department.serveMethods]);
  return (
    <Grid container key={day.dayOfWeek} spacing={2} mt={4}>
      <Grid item xs={1} mt={4}>
        <Typography sx={selectedButton}>
          {days.find((d) => d.id.toString() == day.dayOfWeek)?.name}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Grid container>
          <Grid item xs={12}>
            <Typography align="left">Serve Hours</Typography>
          </Grid>
          <Grid item xs={12} pt={1}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <AppTimePicker
                  close={openPickerId !== 'serveHoursFrom'}
                  onOpenHandler={() => setOpenPickerId('serveHoursFrom')}
                  disabled={
                    department.serveMethods.findIndex(
                      (s) => s.serveMethodId == handServeId,
                    ) === -1
                  }
                  keyName={`${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.serveHours.from`}
                  label="Serve Hours"
                  fieldLabel="From"
                  errorMsg={''}
                  onChange={(value) => {
                    form.setFieldValue(
                      `${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.serveHours.from`,
                      value,
                    );
                  }}
                  value={department.daysAvailable[dayIndex].serveHours.from}
                />
              </Grid>
              <Grid item xs={6}>
                <AppTimePicker
                  close={openPickerId !== 'serveHoursTo'}
                  onOpenHandler={() => setOpenPickerId('serveHoursTo')}
                  disabled={
                    department.serveMethods.findIndex(
                      (s) => s.serveMethodId == handServeId,
                    ) === -1
                  }
                  keyName={`${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.serveHours.to`}
                  label="Serve Hours"
                  fieldLabel="To"
                  errorMsg={''}
                  onChange={(value) => {
                    form.setFieldValue(
                      `${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.serveHours.to`,
                      value,
                    );
                  }}
                  value={department.daysAvailable[dayIndex].serveHours.to}
                />
              </Grid>
              {dayItemHourErrorMessage('serveHours', 'from', dayIndex) && (
                <Grid item xs={12} pl={1}>
                  <FormHelperText sx={error}>
                    {dayItemHourErrorMessage('serveHours', 'from', dayIndex)}
                  </FormHelperText>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={3}>
        <Grid container>
          <Grid item xs={12}>
            <Typography align="left">Pick up and copy hours</Typography>
          </Grid>
          <Grid item xs={12} pt={1}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <AppTimePicker
                  close={openPickerId !== 'pickupAndCopyHoursFrom'}
                  onOpenHandler={() =>
                    setOpenPickerId('pickupAndCopyHoursFrom')
                  }
                  disabled={
                    department.serveMethods.findIndex(
                      (s) => s.serveMethodId == handServeId,
                    ) === -1
                  }
                  keyName={`${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.pickupAndCopyHours.from`}
                  label="Pick up and copy hours"
                  fieldLabel="From"
                  errorMsg={''}
                  onChange={(value) => {
                    form.setFieldValue(
                      `${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.pickupAndCopyHours.from`,
                      value,
                    );
                  }}
                  value={
                    department.daysAvailable[dayIndex].pickupAndCopyHours.from
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <AppTimePicker
                  close={openPickerId !== 'pickupAndCopyHoursTo'}
                  onOpenHandler={() => setOpenPickerId('pickupAndCopyHoursTo')}
                  disabled={
                    department.serveMethods.findIndex(
                      (s) => s.serveMethodId == handServeId,
                    ) === -1
                  }
                  keyName={`${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.pickupAndCopyHours.to`}
                  label="Pick up and copy hours"
                  fieldLabel="To"
                  errorMsg={''}
                  onChange={(value) => {
                    form.setFieldValue(
                      `${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.pickupAndCopyHours.to`,
                      value,
                    );
                  }}
                  value={
                    department.daysAvailable[dayIndex].pickupAndCopyHours.to
                  }
                />
              </Grid>
              {dayItemHourErrorMessage(
                'pickupAndCopyHours',
                'from',
                dayIndex,
              ) && (
                <Grid item xs={12} pl={1}>
                  <FormHelperText sx={error}>
                    {dayItemHourErrorMessage(
                      'pickupAndCopyHours',
                      'from',
                      dayIndex,
                    )}
                  </FormHelperText>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={3}>
        <Grid container>
          <Grid item xs={12}>
            <Typography align="left">Best time to call</Typography>
          </Grid>
          <Grid item xs={12} pt={1}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <AppTimePicker
                  close={openPickerId !== 'bestTimeToCallFrom'}
                  onOpenHandler={() => setOpenPickerId('bestTimeToCallFrom')}
                  keyName={`${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.bestTimeToCall.from`}
                  label="Best time to call"
                  fieldLabel="From"
                  errorMsg={''}
                  onChange={(value) => {
                    form.setFieldValue(
                      `${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.bestTimeToCall.from`,
                      value,
                    );
                  }}
                  value={department.daysAvailable[dayIndex].bestTimeToCall.from}
                />
              </Grid>
              <Grid item xs={6}>
                <AppTimePicker
                  close={openPickerId !== 'bestTimeToCallTo'}
                  onOpenHandler={() => setOpenPickerId('bestTimeToCallTo')}
                  keyName={`${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.bestTimeToCall.to`}
                  label="Best time to call"
                  fieldLabel="To"
                  errorMsg={''}
                  onChange={(value) => {
                    form.setFieldValue(
                      `${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.bestTimeToCall.to`,
                      value,
                    );
                  }}
                  value={department.daysAvailable[dayIndex].bestTimeToCall.to}
                />
              </Grid>
              {dayItemHourErrorMessage('bestTimeToCall', 'from', dayIndex) && (
                <Grid item xs={12} pl={1}>
                  <FormHelperText sx={error}>
                    {dayItemHourErrorMessage(
                      'bestTimeToCall',
                      'from',
                      dayIndex,
                    )}
                  </FormHelperText>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid item xs={12}>
          <Typography align="left">Timezone</Typography>
        </Grid>
        <Box pt={1}>
          <GenericSelectInput
            name={`${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.timeZoneId`}
            label="Timezone"
            value={department.daysAvailable[dayIndex].timeZoneId ?? ''}
            onChange={(e: string) => {
              form.setFieldValue(
                `${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.timeZoneId`,
                e,
              );
            }}
            errorMsg={''}
            hasError={false}
            onBlur={() => {
              form.setFieldTouched(
                `${formKeyName}.${departmentIndex}.daysAvailable.${dayIndex}.timeZoneId`,
                true,
              );
            }}
            options={timeZones}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
