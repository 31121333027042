import WizardStepTitle from '../../Models/Layout/wizard/WizardStepTitle';
import { Box } from '@mui/material';
import { custodianSteps } from '../../settings/appConfig';
import BillingInfo from './Components/BillingInfo';
import CustodianMainInfo from './Components/CustodianMainInfo';
import Departments from './Components/Department/Departments';
import Associations from './Components/Association/Associations';

export default function CustodiansWizardContent({
  activeStep,
  mainInfoForm,
  associationsForm,
  custodianTypes,
  billingInfoForm,
  paymentMethods,
  states,
  phoneTypes,
  recordTypes,
  serveMethods,
  supportedOrderTypes,
  timeZones,
  departmentFileTypes,
  days,
  providers,
  addDepartment,
  departmentsForm,
  handleChangeAccordion,
  removeDepartment,
  isConfirmationOpened,
  onCloseConfirmationClicked,
  discardDelete,
  copyCusodianAddress,
}: {
  activeStep?: WizardStepTitle;
  mainInfoForm: any;
  custodianTypes: any[];
  billingInfoForm: any;
  associationsForm: any;
  paymentMethods: any[];
  states: any[];
  phoneTypes: any[];
  recordTypes: any[];
  serveMethods: any[];
  supportedOrderTypes: any[];
  timeZones: any[];
  departmentFileTypes: any[];
  providers: any[];
  days: any[];
  addDepartment: () => void;
  departmentsForm: any;
  handleChangeAccordion: (
    id: number,
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  removeDepartment: (id: number) => void;
  isConfirmationOpened: boolean;
  onCloseConfirmationClicked: () => void;
  discardDelete: () => void;
  copyCusodianAddress?: (key: string) => void;
}) {
  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        {activeStep?.label === custodianSteps.mainInfo && (
          <form
            name={custodianSteps.mainInfo}
            onSubmit={mainInfoForm.handleSubmit}
          >
            <CustodianMainInfo
              formik={mainInfoForm}
              custodianTypes={custodianTypes}
              timeZones={timeZones}
              phoneTypes={phoneTypes}
              states={states}
            />
          </form>
        )}
        {activeStep?.label === custodianSteps.departmentInfo && (
          <form
            name={custodianSteps.departmentInfo}
            onSubmit={departmentsForm.handleSubmit}
          >
            <Departments
              orderTypes={supportedOrderTypes}
              phoneTypes={phoneTypes}
              recordTypes={recordTypes}
              states={states}
              timeZones={timeZones}
              departmentFileTypes={departmentFileTypes}
              days={days}
              addDepartment={addDepartment}
              departmentsForm={departmentsForm}
              handleChangeAccordion={handleChangeAccordion}
              removeDepartment={removeDepartment}
              isConfirmationOpened={isConfirmationOpened}
              onCloseConfirmationClicked={onCloseConfirmationClicked}
              discardDelete={discardDelete}
              serveMethods={serveMethods}
              copyCusodianAddress={copyCusodianAddress}
            />
          </form>
        )}

        {activeStep?.label === custodianSteps.billingInfo && (
          <form
            name={custodianSteps.billingInfo}
            onSubmit={billingInfoForm.handleSubmit}
          >
            <BillingInfo
              form={billingInfoForm}
              states={states}
              paymentMethods={paymentMethods}
            />
          </form>
        )}

        {activeStep?.label === custodianSteps.associatedCustodians && (
          <form
            name={custodianSteps.associatedCustodians}
            onSubmit={associationsForm.handleSubmit}
          >
            <Associations
              form={associationsForm}
              orderTypes={supportedOrderTypes}
              supportedRecordTypes={recordTypes}
              providers={providers}
            />
          </form>
        )}
      </Box>
    </Box>
  );
}
