import { useCallback, useState } from 'react';
import useHttp from '../http/useHttp';
import { useNavigate } from 'react-router';
import useUI from '../context/useUI';
import moment from 'moment';
import useValidation from '../validation/useValidation';

export default function usePostDepartment(id: any) {
  const navigate = useNavigate();
  const { showSnackbar } = useUI();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { postDataResponse, postData } = useHttp();
  const { isPhoneValid, isContactValid } = useValidation();

  const edit = useCallback(async (model: any) => {
    const data = buildCustodianModel(model,true)
    try {
      setIsSaving(true);
      const result = await postData('department/edit', data);
      setIsSaving(false);
      if (result && result.data && result.data == true) {
        showSnackbar(['department saved successfully!'], 'success');
        navigate(`/custodians/details/${model.cId}`);
      }
    } catch (error) {
      setIsSaving(false);
    }
  }, []);

  const insert = useCallback(async (model: any, custodianId?: string) => {
    var data = buildCustodianModel(model, false, custodianId)
    try {
      setIsSaving(true);
      const result = await postData(`department/insert`, data);
      setIsSaving(false);
      if (result && result.data && result.data > 0) {
        showSnackbar(['department inserted successfully!'], 'success');
        navigate(`/custodians/details/${custodianId}`);
      }
    } catch (error) {
      setIsSaving(false);
    }
    
  },[])

  const buildCustodianModel = function(model: any, isEditMode: boolean, custodianId?: string)
  {
    const departmentPhones = [
      ...model.phones.filter((e: any) => isPhoneValid(e)),
    ];
    const departmentContacts = [
      ...model.contacts?.filter((e: any) => isContactValid(e)),
    ];
    const departmentDays = model.daysAvailable?.map((day: any) => ({
      ...day,
      serveHours: {
        from: day?.serveHours?.from
          ? moment(day?.serveHours?.from).format('HH:mm')
          : null,
        to: day?.serveHours?.to
          ? moment(day?.serveHours?.to).format('HH:mm')
          : null,
      },
      pickupAndCopyHours: {
        from: day?.pickupAndCopyHours?.from
          ? moment(day?.pickupAndCopyHours?.from).format('HH:mm')
          : null,
        to: day?.pickupAndCopyHours?.to
          ? moment(day?.pickupAndCopyHours?.to).format('HH:mm')
          : null,
      },
      bestTimeToCall: {
        from: day?.bestTimeToCall?.from
          ? moment(day?.bestTimeToCall?.from).format('HH:mm')
          : null,
        to: day?.bestTimeToCall?.to
          ? moment(day?.bestTimeToCall?.to).format('HH:mm')
          : null,
      },
    }));

    if(isEditMode)
    {
      const servePreferences = {
        ...model.servePreferences,
        files: [...model.servePreferences.files
          ?.filter((f: any) => f.tempBlobUrl)
          ?.map((f: any) => ({
            fileSize: f.fileSize,
            fileName: f.name,
            tempBlobUrl: f.tempBlobUrl,
            fileTypeId: f.typeId,
          })),
          ...model.servePreferences.oldFiles.map((f: any) => ({
            ...f,
            tempBlobUrl: f.fileName,
          }))
        ],
      };
      const data : any = {
        id,
        department: {
          ...model,
          custodianId: model.cId,
          phones: departmentPhones,
          contacts: departmentContacts,
          daysAvailable: departmentDays,
          servePreferences: servePreferences,
        },
        oldFilesToBeDeleted : model.servePreferences.oldFilesToBeDeleted,
      };
      return data;
    }
    else
    {
      const servePreferences = {
        ...model.servePreferences,
        files: [...model.servePreferences.files
          ?.filter((f: any) => f.tempBlobUrl)
          ?.map((f: any) => ({
            fileSize: f.fileSize,
            fileName: f.name,
            tempBlobUrl: f.tempBlobUrl,
            fileTypeId: f.typeId,
          })),
        ],
      };
      const data : any = {
          ...model,
          custodianId: custodianId,
          phones: departmentPhones,
          contacts: departmentContacts,
          daysAvailable: departmentDays,
          servePreferences: servePreferences,
      };
      return data;
    }
  }

  return { isSaving, data: postDataResponse?.data ?? null, edit, insert };
}
