import React from 'react';
import Box from '@mui/material/Box';
import NavBar from './components/NavBar/NavBar';
import { createTheme, Grid, ThemeProvider } from '@mui/material';
import { lightTheme } from './theme';
import SideBar from './components/SideBar/SideBar';
import useAuthorizedMenuItems from '../hooks/UI/useAuthorizedMenuItems';

export const PageLayout = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const theme = createTheme(lightTheme);
  const authorizedItems = useAuthorizedMenuItems();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <SideBar items={authorizedItems} />
        <NavBar />
        <Box component="main" sx={{ flexGrow: 1, padding: '80px 30px' }}>
          <Grid container justifyContent={'center'}>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
