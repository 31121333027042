import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { genericTitle } from '../../../assets/styles/appStyles';

const YesNoInput = ({
  formik,
  keyname,
  label,
}: {
  label: string;
  keyname: string;
  formik: any;
}) => {
  return (
    <Box>
      <Typography align="left" sx={genericTitle}>
        {label}
      </Typography>
      <RadioGroup
        row
        aria-labelledby={`${keyname}-radio-buttons-group-label`}
        name={keyname}
        value={formik.values[keyname] === true ? '1' : '0'}
        onChange={(value) => {
          let newValue = value.target.value === '1' ? true : false;
          formik.setFieldValue(keyname, newValue);
        }}
      >
        <Grid container >
          <Grid item mr={1}>
            <FormControlLabel
              sx={{ width: 'auto' }}
              value="1"
              control={<Radio />}
              label={
                <Typography mr={1} pr={2}>
                  Yes
                </Typography>
              }
            />
          </Grid>
          <Grid item mr={1}>
            <FormControlLabel
              sx={{ width: 'auto' }}
              value={0}
              control={<Radio />}
              label={
                <Typography mr={1} pr={2}>
                  No
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </RadioGroup>
    </Box>
  );
};

export default YesNoInput;
