import { Box, Grid, Typography } from '@mui/material';
import CustodianContactViewModel from '../../../Models/custodian/custodian-contact-viewmodel';

import { gridContainer, infoGridItem } from '../Styles/CustodianContactDetailsStyles';
import { labelText, labelTitle } from '../../../assets/styles/appStyles';

export default function CustodianContactsDetails({
  contacts,
}: {
  contacts: CustodianContactViewModel[];
}) {
  return (
    <>
      <Grid rowSpacing={3} container sx={gridContainer}>
        {contacts?.length > 0 &&
          contacts.map((item, index: number) => (
            <Grid key={index} xs={12} item container sx={infoGridItem}>
              <Grid item xs={2} sx={infoGridItem}>
                <Typography align="left" sx={labelTitle}>
                  Contact
                </Typography>
                <Box>
                  <Typography sx={labelText}>{item.name}</Typography>
                </Box>
              </Grid>
              <Grid item xs={3} sx={infoGridItem}>
                <Typography align="left" sx={labelTitle}>
                  Email Address
                </Typography>
                <Box>
                  <Typography sx={labelText}>{item.email}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} sx={infoGridItem}>
                <Typography align="left" sx={labelTitle}>
                  Title
                </Typography>
                <Box>
                  <Typography sx={labelText}>{item.title}</Typography>
                </Box>
              </Grid>
              <Grid item xs={3} sx={infoGridItem}>
                <Typography align="left" sx={labelTitle}>
                  Phone
                </Typography>
                <Box>
                  <Typography sx={labelText}>{item.phoneNumber}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} sx={infoGridItem}>
                <Typography align="left" sx={labelTitle}>
                  Type
                </Typography>
                <Box>
                  <Typography sx={labelText}>
                    {item.isDirectContact === true ? 'Direct Contact' : 'None'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </>
  );
}
