import { SxProps } from '@mui/material';

const LoginPage: SxProps = {
  textAlign: 'center',
  width: 1,
  fontFamily: 'sans-serif',
  m: 'auto',
  minWidth: '1024px',
  height: '100vh',
};

const image: SxProps = {
  textAlign: 'right',
  mt: '0',
};

const infoContent: SxProps = {
  fontStyle: 'normal',
  textAlign: 'left',
  mt: '35px',
  width: '500px',
  height: '150px',
};

const infoContainer: SxProps = {
  zIndex: 10,
};

const title: SxProps = {
  color: (theme: any) => theme.palette?.common?.white,
  fontSize: 31,
  lineHeight: '30px',
  fontWeight: 700,
  mb: 2,
};

const desc: SxProps = {
  color: (theme: any) => theme.palette?.neutral['200'],
  fontSize: '18px',
  lineHeight: '22px',
  fontWeight: 400,
  fontStyle: 'normal',
};

const logo: SxProps = {
  mb: '20px',
  ml: 'auto',
  mr: 'auto',
  width: '142px',
  height: '80px',
};

const form: SxProps = {
  p: '30px',
  backgroundColor: (theme: any) => theme.palette?.background?.paper,
  borderRadius: '8px',
  width: '400px',
  margin: '30px',
  border: '2px solid',
  borderColor: (theme: any) => theme.palette?.neutral['200'],
};

const formTitle: SxProps = {
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '28px',
  textAlign: 'center',
  color: (theme: any) => theme.palette?.common?.black,
  mb: '8%',
};

const leftPart: SxProps = {
  backgroundColor: (theme: any) => theme.palette?.primary['500'],
  height: '100%',
};

const rightPart: SxProps = {
  backgroundColor: (theme: any) => theme.palette?.background?.paper,
  height: '100%',
};

const loginBtn: SxProps = {
  backgroundColor: (theme: any) => theme.palette?.primary['500'],
  borderRadius: '6px',
  width: '98%',
  boxShadow: 'none',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  padding: '12px 20px',
  textTransform: 'capitalize',
  color: (theme: any) => theme.palette.common.white,
  mb: '3%',
  '&:hover': {
    backgroundColor: (theme: any) => theme.palette?.primary['500'],
  },
};

const styles = {
  LoginPage,
  image,
  infoContent,
  title,
  desc,
  logo,
  form,
  formTitle,
  leftPart,
  rightPart,
  loginBtn,
  infoContainer,
};

export default styles;
