import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import useModal from '../../../hooks/UI/useModal';
import FieldSearchInput from './UI/FieldSearchInput';
import styles from '../../Locations/Styles/DuplicateCustodiansTableStyles';
import { textError, warningText } from '../../../assets/styles/appStyles';
import CustodianAzureSearchResult from '../../../Models/Locations/CustodianAzureSearchResult';
import DuplicateCustodiansModal from '../../../Modals/DuplicateCustodiansModal';

export default function CustodianFieldSearchInput({
  searchPropertyName,
  name,
  value,
  label,
  placeholder,
  duplicateErrorMessage,
  maxLength,
  formErrorMessage,
  hasFormError,
  onChange,
  onBlur,
  setHasDuplicateError,
  isExactSearch = false,
  inputMode,
  shouldViewModal = true,
  isValid,
  required,
}: {
  searchPropertyName: string;
  name: string;
  value: string;
  label: string;
  placeholder: string;
  duplicateErrorMessage: string;
  maxLength: number;
  formErrorMessage: string;
  hasFormError: boolean;
  onChange: (e: any) => void;
  onBlur: () => void;
  setHasDuplicateError?: (duplicateFound: boolean) => void;
  isExactSearch?: boolean;
  inputMode?:
    | 'search'
    | 'text'
    | 'email'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal'
    | undefined;
  shouldViewModal?: boolean;
  isValid?: (value: any) => boolean;
  required?: boolean;
}) {
  const { isModalOpened, handleOpenModal, handleCloseModal } = useModal();
  const [showDuplicateRecordsMessage, setShowDuplicateRecordsMessage] =
    useState<boolean>(false);

  const [azureSearchResult, setAzureSearchResult] =
    useState<CustodianAzureSearchResult | null>(null);

  const constructErrorMessage = () => {
    let errorMessage: React.ReactNode = '';
    let showDuplicateMessage = showDuplicateRecordsMessage;
    let custodianId = azureSearchResult?.custodians[0].id;
    if (formErrorMessage.includes('duplicate') && isExactSearch) {
      const splitResult = formErrorMessage.split(',');
      if (splitResult && splitResult.length > 0) {
        showDuplicateMessage = true;
        formErrorMessage = '';
        custodianId = +splitResult[1];
      }
    }
    if (showDuplicateMessage) {
      errorMessage = (
        <>
          {hasFormError ? (
            <>
              {formErrorMessage}
              <br></br>
            </>
          ) : (
            ''
          )}
          <Typography
            component={'span'}
            sx={!isExactSearch ? warningText : textError}
          >
            {duplicateErrorMessage}
          </Typography>
          <Button
            variant="text"
            sx={styles.viewBtnStyles}
            onClick={() => {
              if (shouldViewModal) {
                handleOpenModal();
              } else {
                window.open(`/custodians/details/${custodianId}`, '_blank');
              }
            }}
          >
            View
          </Button>
        </>
      );
    } else if (hasFormError) {
      errorMessage = formErrorMessage;
    }
    return errorMessage;
  };
  return (
    <>
      <FieldSearchInput
        setHasDuplicateError = {setHasDuplicateError}
        required={required}
        name={name}
        label={label}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        inputMode={inputMode}
        searchPropertyName={searchPropertyName}
        isExactSearch={isExactSearch}
        isValid={isValid}
        onChange={(e: any) => {
          onChange(e);
        }}
        formErrorMessage={constructErrorMessage()}
        hasFormError={
          hasFormError || showDuplicateRecordsMessage ? true : false
        }
        onBlur={function (): void {
          onBlur();
        }}
        setShowDuplicateRecordsMessage={setShowDuplicateRecordsMessage}
        setAzureSearchResult={setAzureSearchResult}
      />

      <DuplicateCustodiansModal
        isModalOpened={isModalOpened}
        searchPropertyName={searchPropertyName}
        searchPropertyValue={value}
        handleClose={handleCloseModal}
      />
    </>
  );
}
