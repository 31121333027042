import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  SxProps,
  Typography,
} from '@mui/material';
import { title } from '../../../../assets/styles/appStyles';

export default function MultipleCheckBoxes({
  label,
  keyName,
  options,
  selectedValues,
  toggleChange,
  disable = false,
  labelStyle,
}: {
  label: string;
  keyName: string;
  options: any[];
  selectedValues: string[];
  toggleChange: (e: string) => void;
  disable?: boolean;
  labelStyle?: SxProps;
}) {
  return (
    <>
      <FormControl fullWidth>
        <Typography sx={labelStyle ? labelStyle : title}>{label}</Typography>
        <Grid container>
          {options?.map((p) => (
            <Grid item key={p.name}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedValues?.includes(p.id)}
                    name={keyName}
                    onChange={(e: any) => toggleChange(p.id)}
                    disabled={disable}
                  />
                }
                label={p.name}
              />
            </Grid>
          ))}
        </Grid>
      </FormControl>
    </>
  );
}
