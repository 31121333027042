import { Button, Grid, Typography } from '@mui/material';
import InsertDepartmentModel from '../../../../Models/Departments/InsertDepartmentModel';
import MultipleCheckBoxes from '../UI/MultipleCheckBoxes';
import MultiSelectInput from '../UI/MultiSelectInput';
import GenericInput from '../UI/GenericInput';
import DepartmentServeInfoDay from './DepartmentServeInfoDay';
import { title } from '../../../../assets/styles/appStyles';

export default function DepartmentServeInfo({
  form,
  departmentIndex,
  department,
  formKeyName,
  serveMethods,
  timeZones,
  days,
}: {
  form: any;
  departmentIndex: number;
  department: InsertDepartmentModel;
  formKeyName: string;
  timeZones: any[];
  serveMethods: any[];
  days: any[];
}) {
  const onChangeServeMethods = (id: string) => {
    const newItems = [
      ...form.values[formKeyName][departmentIndex]['serveMethods'],
    ];
    const itemIndex = newItems.findIndex((i: any) => i.serveMethodId === id);
    if (itemIndex > -1) {
      newItems.splice(itemIndex, 1);
    } else {
      newItems.push({ serveMethodId: id });
    }
    form.setFieldValue(
      `${formKeyName}.${departmentIndex}.serveMethods`,
      newItems,
    );
  };

  const onChangeDays = (d: string) => {
    let daysAvailable = [
      ...form.values[formKeyName][departmentIndex].daysAvailable,
    ];
    const recordIndex = daysAvailable.findIndex(
      (record) => record.dayOfWeek === d,
    );
    if (recordIndex > -1) {
      daysAvailable = [
        ...daysAvailable.filter((record) => record.dayOfWeek !== d),
      ];
    } else
      daysAvailable = [
        ...daysAvailable,
        {
          dayOfWeek: d,
          timeZoneId: null,
          serveHours: { from: '', to: '' },
          pickupAndCopyHours: { from: '', to: '' },
          bestTimeToCall: { from: '', to: '' },
        },
      ];
    form.setFieldValue(
      `${formKeyName}.${departmentIndex}.daysAvailable`,
      daysAvailable,
    );
  };

  const onSelectAllDays = () => {
    const datesSelectedBefore =
      form.values[formKeyName][departmentIndex].daysAvailable;
    form.setFieldValue(`${formKeyName}.${departmentIndex}.daysAvailable`, [
      ...days.map((d) => {
        const daySelectedBefore = datesSelectedBefore.find(
          (b: any) => b.dayOfWeek === d.id,
        );
        return {
          dayOfWeek: d.id,
          timeZoneId: daySelectedBefore?.timeZoneId,
          serveHours: daySelectedBefore?.serveHours || { from: '', to: '' },
          bestTimeToCall: daySelectedBefore?.serveHours || {
            from: '',
            to: '',
          },
          pickupAndCopyHours: daySelectedBefore?.pickupAndCopyHours || {
            from: '',
            to: '',
          },
        };
      }),
    ]);
  };

  const portalId: string = serveMethods.find(
    (s) => s.name?.toLowerCase() === 'portal',
  )?.id;

  const portalSelected =
    department.serveMethods.findIndex((s) => s.serveMethodId == portalId) > -1;

  return (
    <>
      <Grid container spacing={2} pt={2}>
        <Grid xs={12} item>
          <MultipleCheckBoxes
            options={serveMethods}
            keyName={`${formKeyName}.${departmentIndex}.serveMethods`}
            label="Serve Method"
            selectedValues={department?.serveMethods?.map(
              (o: any) => o.serveMethodId,
            )}
            toggleChange={onChangeServeMethods}
          />
        </Grid>
        {portalSelected && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4} px={2} py={2}>
                <GenericInput
                  name={`${formKeyName}.${departmentIndex}.portalNotes`}
                  label="Portal Notes"
                  maxLength={150}
                  placeholder="Notes"
                  value={department.portalNotes ?? ''}
                  onChange={(e: any) => {
                    form.setFieldValue(
                      `${formKeyName}.${departmentIndex}.portalNotes`,
                      e.target.value,
                    );
                  }}
                  errorMsg={
                    form.errors[formKeyName] &&
                    form.errors[formKeyName][departmentIndex] &&
                    form.errors[formKeyName][departmentIndex] &&
                    form.errors[formKeyName][departmentIndex]['portalNotes']
                      ? form.errors[formKeyName][departmentIndex]['portalNotes']
                      : ''
                  }
                  hasError={
                    form.errors[formKeyName] &&
                    form.errors[formKeyName][departmentIndex] &&
                    form.errors[formKeyName][departmentIndex] &&
                    form.errors[formKeyName][departmentIndex]['portalNotes']
                      ? true
                      : false
                  }
                  onBlur={() => {
                    form.setFieldTouched(
                      `${formKeyName}.${departmentIndex}.portalNotes`,
                      true,
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid xs={12} item>
          <Typography sx={title}>Select days available for pickup</Typography>
          <Grid container alignItems={'center'}>
            <Grid item>
              <MultiSelectInput
                errorMsg=""
                options={days}
                togglePress={onChangeDays}
                values={department?.daysAvailable.map((o) => o.dayOfWeek)}
              />
            </Grid>
            {department?.daysAvailable.length !== days.length && (
              <Grid item pr={1} pt={1}>
                <Button
                  name="select-all"
                  variant="outlined"
                  onClick={onSelectAllDays}
                >
                  Select All
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid xs={12} item>
          {department?.daysAvailable
            .sort((d1, d2) => +d1.dayOfWeek - +d2.dayOfWeek)
            .map((d, index) => (
              <DepartmentServeInfoDay
                key={d.dayOfWeek}
                day={d}
                dayIndex={index}
                days={days}
                department={department}
                departmentIndex={departmentIndex}
                form={form}
                formKeyName={formKeyName}
                handServeId={
                  serveMethods.find(
                    (s) => s.name?.toLowerCase() === 'hand serve',
                  )?.id
                }
                timeZones={timeZones}
              />
            ))}
        </Grid>
      </Grid>
    </>
  );
}
