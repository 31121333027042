import * as Yup from 'yup';
import { useFormik } from 'formik';
import InsertRole from '../../../Models/Roles/insertRole';
import useValidation from '../../validation/useValidation';

export default function useRoleForm({
  saveRole,
}: {
  saveRole: (role: any) => void;
}) {
  const onFormSubmitted = async (role: any) => {
    saveRole(role);
  };

  const { validateName } = useValidation();

  const validationSchema = Yup.object().shape({
    name: validateName(true),
    description: Yup.string().max(
      200,
      'Description can not exceed 200 characters',
    ),
    actionIds: Yup.array().min(1, 'Please select at least one action'),
  });

  const initialValues: InsertRole = {
    name: '',
    description: '',
    actionIds: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (formik.isValid) {
        onFormSubmitted(values);
      }
    },
  });

  const changeActionIds = (event: any, actionId: number) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      formik.setFieldValue('actionIds', [...formik.values.actionIds, actionId]);
    } else {
      formik.setFieldValue('actionIds', [
        ...formik.values.actionIds.filter((id) => id !== actionId),
      ]);
    }
  };

  return { formik, changeActionIds };
}
