import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import AssociationRecordTypeDetails from '../../../../Models/Association/custodian-association-recordtype-details';
import AssociationsRecordTypesListItemDetails from './AssociationsRecordTypesListItemDetails';
import { tableHeaderStyles } from '../../../../assets/styles/appStyles';

export default function AssociationsRecordTypesListDetails({
  records,
}: {
  records: AssociationRecordTypeDetails[];
}) {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={tableHeaderStyles} width={'20%'}>Record type</TableCell>
              <TableCell sx={tableHeaderStyles} width={'20%'}>Order type</TableCell>
              <TableCell sx={tableHeaderStyles} width={'20%'}>Provider</TableCell>
              <TableCell sx={tableHeaderStyles} width={'20%'}>From</TableCell>
              <TableCell sx={tableHeaderStyles} width={'20%'}>To</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records?.map((record: AssociationRecordTypeDetails) => (
              <AssociationsRecordTypesListItemDetails
                key={record.id}
                recordTypeName={record.recordTypeName}
                custodianProviderName={record.custodianProviderName}
                dateFrom={record.dateFrom}
                dateTo={record.dateTo}
                orderTypeName={record.orderTypeName}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
