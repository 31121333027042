import { useEffect, useCallback } from 'react';
import useHttp from '../http/useHttp';
import DepartmentFileType from '../../Models/Locations/DepartmentFileType';

export default function useFetchDepartmentFileTypes() {
  const { getDataResponse, getData } = useHttp();

  const init = useCallback(async () => {
    await getData('lookup/departmentfiletypes');
  }, []);

  let departmentFileTypes: DepartmentFileType[] = getDataResponse?.data ?? [];

  useEffect(() => {
    init();
  }, [init]);

  return { departmentFileTypes };
}
