import { TextField, FormControl, Grid, Typography, Box } from '@mui/material';
import Styles from '../Styles/SearchCustodiansStyles';
import { useRef, useState } from 'react';
import { debounceDelay } from '../../../settings/appConfig';
import useFetchStates from '../../../hooks/Locations/useFetchStates';
import CustodianSearchStates from './CustodianSearchStates';
import { genericTitle } from '../../../assets/styles/appStyles';

export default function CustodiansSearch({
  onSearchChanged,
  showStatesFilteration
}: {
  onSearchChanged: (searchText?: string, stateId?: number) => void;
  showStatesFilteration?: boolean;
}) {
  const [searchText, setSearchText] = useState<string>('');
  const [state, setState] = useState<number | undefined>();
  const timeoutId = useRef<any>(null);

  const handleChangeSearchText = (value: string) => {
    setSearchText(value);

    //apply debouncing
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      onSearchChanged(value, state);
    }, debounceDelay);
  };

  const { states } = useFetchStates();

  return (
    <Grid container mt={2} sx={Styles.greyCard}>
      <Grid
        px={2}
        container
        justifyContent={'space-between'}
        alignItems="center"
      >
        <Typography align="left" sx={genericTitle}>
          Search Custodians
        </Typography>
      </Grid>
      <Grid
        px={2}
        mb={2}
        container
        justifyContent={'space-between'}
        alignItems="center"
      >
        <Grid item xs={12} md={6} lg={5}>
          <Box>
            <FormControl fullWidth>
              <TextField
                id="search-Text"
                value={searchText}
                label={'Search Text'}
                variant="outlined"
                onChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                ) => handleChangeSearchText(e.target.value)}
              ></TextField>
            </FormControl>
          </Box>
        </Grid>
        {showStatesFilteration ?
          <Grid item xs={12} md={6} lg={5}>
            <CustodianSearchStates
              searchText={searchText}
              onSearchChanged={onSearchChanged}
              setState={setState}
              state={state}
              states={states}
              useNative={false}
            />
          </Grid>
          : ''}

      </Grid>
    </Grid>
  );
}
