import style from '../Pages/Locations/Styles/DuplicateCustodiansTableStyles';
import DuplicateLocationsTable from '../Pages/AddLocation/Components/DuplicateCustodiansTable';
import { Typography, Modal, Grid, Button } from '@mui/material';

export default function DuplicateCustodiansModal(
	{
		isModalOpened,
		handleClose,
		searchPropertyName,
		searchPropertyValue,
	}:
		{
			isModalOpened: boolean;
			handleClose: any;
			searchPropertyName: string;
			searchPropertyValue: string;
		}) {
	return (
		<div>
			<Modal
				open={isModalOpened}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Grid container sx={style.duplicateCustodiansTableContainer}>
					<Grid item xs={12} sx={style.modalHeaderStyles}>
						<Grid item>
							<Typography id="modal-modal-title" variant="h4" >
								Duplicate Custodians
							</Typography>
						</Grid>
						<Grid item sx={style.closeModalBtnStyles}>
							<Button onClick={handleClose}>X Close</Button>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<DuplicateLocationsTable searchPropertyName={searchPropertyName} searchPropertyValue={searchPropertyValue} isModalOpened={isModalOpened} />
					</Grid>
				</Grid>
			</Modal>
		</div>
	);
}