import { SxProps } from '@mui/material';

const tableRow: SxProps = {
  '&:last-child td, &:last-child th': { border: 0 },
};

const tableHeader: SxProps = {
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '20px',
  color: (theme: any) => theme.palette.neutral['600'],
};

const tableContainer: SxProps = {
  border: '1px solid',
  borderRadius: '8px',
  borderColor: (theme: any) => theme.palette.neutral['200'],
};

const tableCell: SxProps = {
  color: (theme: any) => theme.palette.neutral['500'],
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
};

const header: SxProps = {
  background: (theme: any) => theme.palette.neutral[100],
};

export default { tableRow, tableHeader, tableContainer, tableCell, header };
