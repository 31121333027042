import { useParams } from 'react-router';
import DepartmentForm from '../../AddLocation/Components/Department/DepartmentForm';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import useEditDepartment from '../../../hooks/Locations/edit/useEditDepartment';
import { textWhite } from '../../../assets/styles/appStyles';
import usePostDepartment from '../../../hooks/Locations/usePostDepartment';
import useUI from '../../../hooks/context/useUI';
import { Link } from 'react-router-dom';

export default function EditDepartment() {
  const { id } = useParams();
  const {
    departmentsForm,
    department,
    isLoading,
    orderTypes,
    phoneTypes,
    recordTypes,
    states,
    timeZones,
    departmentFileTypes,
    serveMethods,
    days,
  } = useEditDepartment(id ?? '');

  const { edit, isSaving } = usePostDepartment(id);
  const { showSnackbar } = useUI();
  const saveClicked = async () => {
    departmentsForm.submitForm();
    const errors = await departmentsForm.validateForm(departmentsForm.values);
    if (Object.keys(errors).length === 0) {
      edit(departmentsForm.values.departments[0]);
    } else {
      showSnackbar(['please fix validation errors first'], 'error');
    }
  };

  return (
    <>
      {departmentsForm &&
      departmentsForm.values &&
      departmentsForm.values.departments[0] ? (
        <>
          <form>
            <DepartmentForm
              form={departmentsForm}
              department={departmentsForm.values.departments[0]}
              formKeyName={'departments'}
              departmentIndex={0}
              orderTypes={orderTypes}
              phoneTypes={phoneTypes}
              states={states}
              recordTypes={recordTypes}
              timeZones={timeZones}
              departmentFileTypes={departmentFileTypes}
              serveMethods={serveMethods}
              days={days}
            />
            <Grid container justifyContent={'end'} spacing={2}>
              <Grid item>
                {!isSaving && (
                  <Link to={`/custodians/details/${department?.custodianId}`}>
                    <Box component={Button} variant="outlined">
                      Cancel
                    </Box>
                  </Link>
                )}
                {isSaving && <CircularProgress />}
              </Grid>
              <Grid item>
                {!isSaving && (
                  <Box
                    component={Button}
                    variant="contained"
                    sx={textWhite}
                    onClick={saveClicked}
                  >
                    Save
                  </Box>
                )}
                {isSaving && <CircularProgress />}
              </Grid>
            </Grid>
          </form>
        </>
      ) : isLoading ? (
        <CircularProgress />
      ) : null}
    </>
  );
}
