import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Styles from '../Styles/ListCustodiansStyles';
import CustodianSearchResult from '../../../Models/custodian/custodianSearchResult';

export default function CustodianTableRow({
  custodianRow,
  handleRowToggle,
  isRowExpanded,
  showSelectBtn,
  selectBtnClicked,
}: {
  custodianRow: CustodianSearchResult;
  handleRowToggle: (id: number) => void;
  isRowExpanded: (id: number) => boolean;
  showSelectBtn?: boolean;
  selectBtnClicked?: (item: any) => void;
}) {
  return (
    <TableRow
      onClick={() => handleRowToggle(custodianRow.id)}
      sx={Styles.tableRowStyles}
    >
      <TableCell>
        <Typography>
          <Link
            to={`/custodians/details/${custodianRow.id}`}
            target="_blank"
            style={Styles.viewCustodianLinkStyles}
          >
            {custodianRow.name}
          </Link>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography sx={Styles.custodianTableCellStyles}>
          {custodianRow.address}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography sx={Styles.custodianTableCellStyles}>
          {custodianRow.city}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography sx={Styles.custodianTableCellStyles}>
          {custodianRow.stateName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography sx={Styles.custodianTableCellStyles}>
          {custodianRow.createdAt}
        </Typography>
      </TableCell>
      {showSelectBtn ? (
        <TableCell>
          <Typography>
            <Button
              size="small"
              id="select"
              name="select"
              onClick={() => {
                if (selectBtnClicked) selectBtnClicked(custodianRow);
              }}
            >
              Select
            </Button>
          </Typography>
        </TableCell>
      ) : (
        ''
      )}
      <TableCell>
        <IconButton aria-label="expand row" size="small" color={'warning'}>
          {isRowExpanded(custodianRow.id) ? (
            <KeyboardArrowUp />
          ) : (
            <KeyboardArrowDown />
          )}
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
