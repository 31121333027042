import Panel from '../../../../components/UI/Panel';
import { Grid } from '@mui/material';
import CheckboxInput from '../UI/CheckboxInput';
import InsertDepartmentModel from '../../../../Models/Departments/InsertDepartmentModel';

export default function DepartmentServePreferencesPatientInfo({
	form,
	department,
	formKeyName,
	departmentIndex,
}: {
	form: any;
	department: InsertDepartmentModel;
	formKeyName: string;
	departmentIndex: number;
}) {
	return (
		<>
			<Panel headerText='Patient'>
				<Grid container>
					<Grid item xs={6}>
						<CheckboxInput
							label='Patient Address'
							keyName={`${formKeyName}.${departmentIndex}.servePreferences.isPatientAddress`}
							checked={department.servePreferences.isPatientAddress}
							onChange={(e: any) => {
								form.setFieldValue(
									`${formKeyName}.${departmentIndex}.servePreferences.isPatientAddress`,
									e.target.checked,
								);
							}}
							onBlur={() => {
								form.setFieldTouched(
									`${formKeyName}.${departmentIndex}.servePreferences.isPatientAddress`,
									true,
								);
							}}
						/>
					</Grid>
					<Grid item xs={6} pl={3}>
						<CheckboxInput
							label='Patient Account Number'
							keyName={`${formKeyName}.${departmentIndex}.servePreferences.isPatientAccountNumber`}
							checked={department.servePreferences.isPatientAccountNumber}
							onChange={(e: any) => {
								form.setFieldValue(
									`${formKeyName}.${departmentIndex}.servePreferences.isPatientAccountNumber`,
									e.target.checked,
								);
							}}
							onBlur={() => {
								form.setFieldTouched(
									`${formKeyName}.${departmentIndex}.servePreferences.isPatientAccountNumber`,
									true,
								);
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<CheckboxInput
							label='Patient Full SSN Number'
							keyName={`${formKeyName}.${departmentIndex}.servePreferences.isPatientFullSSNNumber`}
							checked={department.servePreferences.isPatientFullSSNNumber}
							onChange={(e: any) => {
								form.setFieldValue(
									`${formKeyName}.${departmentIndex}.servePreferences.isPatientFullSSNNumber`,
									e.target.checked,
								);
							}}
							onBlur={() => {
								form.setFieldTouched(
									`${formKeyName}.${departmentIndex}.servePreferences.isPatientFullSSNNumber`,
									true,
								);
							}}
						/>
					</Grid>
					<Grid item xs={6} pl={3}>
						<CheckboxInput
							label='Patient Initial Sensitive Information'
							keyName={`${formKeyName}.${departmentIndex}.servePreferences.isPatientInitialSensitiveInformation`}
							checked={department.servePreferences.isPatientInitialSensitiveInformation}
							onChange={(e: any) => {
								form.setFieldValue(
									`${formKeyName}.${departmentIndex}.servePreferences.isPatientInitialSensitiveInformation`,
									e.target.checked,
								);
							}}
							onBlur={() => {
								form.setFieldTouched(
									`${formKeyName}.${departmentIndex}.servePreferences.isPatientInitialSensitiveInformation`,
									true,
								);
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<CheckboxInput
							label='Electronic Signature Accepted'
							keyName={`${formKeyName}.${departmentIndex}.servePreferences.isElectronicSignatureAccepted`}
							checked={department.servePreferences.isElectronicSignatureAccepted}
							onChange={(e: any) => {
								form.setFieldValue(
									`${formKeyName}.${departmentIndex}.servePreferences.isElectronicSignatureAccepted`,
									e.target.checked,
								);
							}}
							onBlur={() => {
								form.setFieldTouched(
									`${formKeyName}.${departmentIndex}.servePreferences.isElectronicSignatureAccepted`,
									true,
								);
							}}
						/>
					</Grid>
					<Grid item xs={6}></Grid>
				</Grid>
			</Panel>
		</>
	)
}
