import ResearchCustodianListColumn from '../Models/custodian/research-custodian-list-column';
export const defaultColumns: ResearchCustodianListColumn[] = [
  {
    id: 0,
    name: 'Source ID',
    width: 130,
    fieldName: 'sourceId',
  },
  {
    id: 1,
    name: 'Name',
    width: 250,
    fieldName: 'locationName',
    hasSort: true,
    sortPropertyName: 'SortingLocationName',
  },
  {
    id: 2,
    name: 'Phone number',
    width: 200,
    fieldName: 'phoneNumber',
  },
  {
    id: 3,
    name: 'Address',
    width: 400,
    fieldName: 'address',
  },
  {
    id: 4,
    name: 'City',
    width: 200,
    fieldName: 'city',
    hasSort: true,
    sortPropertyName: 'SortingCity',
  },
  {
    id: 5,
    name: 'State',
    width: 150,
    fieldName: 'state',
    hasSort: true,
    sortPropertyName: 'SortingState',
  },
  {
    id: 6,
    name: 'Postal code',
    width: 150,
    fieldName: 'postCode',
  },
];

export const availableColumns: ResearchCustodianListColumn[] = [
  ...defaultColumns,
  {
    id: 7,
    name: 'Source location',
    width: 150,
    fieldName: 'sourceLocation',
  },
  {
    id: 8,
    name: 'Fax number',
    width: 150,
    fieldName: 'faxNumber',
  },
  {
    id: 9,
    name: 'Warnings',
    width: 300,
    fieldName: 'warnings',
  },
  {
    id: 10,
    name: 'Verified location',
    width: 150,
    fieldName: 'verifiedLocations',
    hasSort: true,
    sortPropertyName: 'VerifiedLocations',
  },
  {
    id: 11,
    name: 'Expeditor',
    width: 150,
    fieldName: 'expeditors',
    hasSort: true,
    sortPropertyName: 'SortingExpeditors',
  },
  {
    id: 12,
    name: 'Alt phone number',
    width: 150,
    fieldName: 'altPhoneNumber',
  },
  {
    id: 13,
    name: 'Attention',
    width: 150,
    fieldName: 'attention',
    hasSort: true,
    sortPropertyName: 'SortingAttention',
  },
  {
    id: 14,
    name: 'Payment Type Accepted',
    width: 250,
    fieldName: 'paymentTypeAccepted',
  },
  {
    id: 15,
    name: 'Payment for records',
    width: 330,
    fieldName: 'paymentForRecords',
  },
  {
    id: 16,
    name: 'Receive Auth by fax',
    width: 150,
    fieldName: 'receiveAuthByFax',
  },
  {
    id: 17,
    name: 'Rating',
    width: 130,
    fieldName: 'rating',
    hasSort: true,
    sortPropertyName: 'Rating',
  },
  {
    id: 18,
    name: 'Last Fulfillment',
    width: 180,
    fieldName: 'lastFulfillment',
    hasSort: true,
    sortPropertyName: 'SortingLastFulfillment',
  },
  {
    id: 19,
    name: 'Last Known Communication',
    width: 300,
    fieldName: 'lastKnownCommunication',
    maxDisplayLength: 50,
  },
];
