import { TableCell, TableRow, Typography } from '@mui/material';
import moment from 'moment';

export default function AssociationsRecordTypesListItemDetails({
  dateFrom,
  dateTo,
  custodianProviderName,
  orderTypeName,
  recordTypeName,
}: {
  dateFrom?: Date;
  dateTo?: Date;
  custodianProviderName?: string;
  orderTypeName?: string;
  recordTypeName: string;
}) {
  return (
    <TableRow>
      <TableCell>
        <Typography>{recordTypeName}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{orderTypeName}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{custodianProviderName}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {dateFrom && moment(dateFrom).format('MMM D, YYYY')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {dateTo && moment(dateTo).format('MMM D, YYYY')}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
