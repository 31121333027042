import { SxProps } from '@mui/material';

const defaultStyles: SxProps = {
  justifyContent: 'space-between',
  alignItems: 'center',
};

const revertedStyles: SxProps = {
  justifyContent: 'start',
  flexDirection: 'row-reverse',
  alignItems: 'center',
};

const checkBoxStyles = {
  padding: '10px 5px 10px 0',
};

const styleClasses = {
  defaultStyles,
  revertedStyles,
  checkBoxStyles,
};

export default styleClasses;
