import { Grid, Box, Button, CircularProgress } from '@mui/material'
import { textWhite } from '../../../assets/styles/appStyles'
import DepartmentForm from '../../AddLocation/Components/Department/DepartmentForm'
import usePostDepartment from '../../../hooks/Locations/usePostDepartment';
import useUI from '../../../hooks/context/useUI';
import { useNavigate, useParams } from 'react-router';
import useAddDepartment from '../../../hooks/Locations/edit/useAddDepartment';

export default function AddDepartment() {
	const { custodianId } = useParams();
	const nav = useNavigate();
	const {
		departmentsForm,
		orderTypes,
		phoneTypes,
		recordTypes,
		states,
		timeZones,
		departmentFileTypes,
		serveMethods,
		days,
	} = useAddDepartment();
	const { insert, isSaving } = usePostDepartment(undefined);
	const { showSnackbar } = useUI();
	const saveClicked = async () => {
		departmentsForm.submitForm();
		const errors = await departmentsForm.validateForm(departmentsForm.values);
		if (Object.keys(errors).length === 0) {
			insert(departmentsForm.values.departments[0], custodianId);
		} else {
			showSnackbar(['please fix validation errors first'], 'error');
		}
	};
	return (
		<>
			<DepartmentForm
				form={departmentsForm}
				department={departmentsForm.values.departments[0]}
				formKeyName={'departments'}
				departmentIndex={0}
				orderTypes={orderTypes}
				phoneTypes={phoneTypes}
				states={states}
				recordTypes={recordTypes}
				timeZones={timeZones}
				departmentFileTypes={departmentFileTypes}
				serveMethods={serveMethods}
				days={days}
			/>
			<Grid container justifyContent={'end'} spacing={2}>
				<Grid item >
					<Button
						color='error'
						onClick={() => nav(`/custodians/details/${custodianId}`)}
						variant='outlined'
						disabled={isSaving}>
						Cancel
					</Button>
				</Grid>

				<Grid item>
					<Box
						component={Button}
						variant="contained"
						sx={textWhite}
						onClick={saveClicked}
						disabled={isSaving}
					>
						Save
					</Box>
				</Grid>

				<Grid item>
					{isSaving && <CircularProgress />}
				</Grid>
			</Grid>
		</>
	)
}
