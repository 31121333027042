import { TableBody } from '@mui/material';
import ResearchCustodiansListTableRow from './ResearchCustodiansListTableRow';
import ResearchCustodianListColumn from '../../../Models/custodian/research-custodian-list-column';

export default function ResearchCustodiansListTableBody({
  data,
  columns,
}: {
  data: any[];
  columns: ResearchCustodianListColumn[];
}) {
  return (
    <TableBody>
      {data.map((row, index) => (
        <ResearchCustodiansListTableRow
          key={`${index}`}
          row={row}
          columns={columns}
        />
      ))}
    </TableBody>
  );
}
