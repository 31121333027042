import { useEffect, useCallback } from 'react';
import RecordType from '../../Models/Locations/recordType';
import useHttp from '../http/useHttp';

export default function useFetchRecordTypes() {
  const { getDataResponse, getData } = useHttp();

  const init = useCallback(async () => {
    await getData('lookup/getrecordtypes');
  }, []);

  let recordTypes: RecordType[] = getDataResponse?.data ?? [];

  useEffect(() => {
    init();
  }, [init]);

  return { recordTypes };
}
