import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import useFetchRoles from '../../../../hooks/Roles/useFetchRoles';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ComponentLoader from '../../../../common/ComponentLoader/componentLoader';
import TableStyles from '../../../../assets/styles/tableStyles';
import { Link } from 'react-router-dom';

export default function RolesTable() {
  const { roles, isLoading } = useFetchRoles();

  return (
    <ComponentLoader isOpen={isLoading}>
      <TableContainer sx={TableStyles.tableContainer} component={Paper}>
        <Table aria-label="simple table">
          <TableHead sx={TableStyles.header}>
            <TableRow>
              <TableCell sx={TableStyles.tableHeader}>Name</TableCell>
              <TableCell sx={TableStyles.tableHeader}>Description</TableCell>
              <TableCell sx={TableStyles.tableHeader}>Access</TableCell>
              <TableCell sx={TableStyles.tableHeader}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((row) => (
              <TableRow key={row.id} sx={TableStyles.tableRow}>
                <TableCell>
                  <Typography sx={TableStyles.tableCell}>{row.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={TableStyles.tableCell}>
                    {row.description}
                  </Typography>
                </TableCell>
                <TableCell>
                  {row.actions.map((action) => (
                    <Typography key={action.id} sx={TableStyles.tableCell}>
                      {action.name}
                    </Typography>
                  ))}
                </TableCell>

                <TableCell>
                  <Typography sx={TableStyles.tableCell}>
                    <Link to={`/roles/edit/${row.id}`}>
                      <ModeEditIcon data-testid={row.id}></ModeEditIcon>
                    </Link>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ComponentLoader>
  );
}
