import { Box } from '@mui/material';
import ComponentLoader from '../../../../common/ComponentLoader/componentLoader';
import useFetchActions from '../../../../hooks/Roles/useFetchActions';
import useInsertRole from '../../../../hooks/Roles/useInsertRole';
import useRoleForm from '../../../../hooks/Roles/UseRoleForm/useRoleForm';
import RoleForm from '../RoleForm/roleForm';
import { cardContainer } from '../../../../assets/styles/appStyles';

const AddRole = () => {
  const { actions, isLoading } = useFetchActions();
  const { insertRole, isSubmitting } = useInsertRole();

  const addRole = async (role: any) => {
    await insertRole(role);
  };

  const { formik, changeActionIds } = useRoleForm({ saveRole: addRole });

  return (
    <Box sx={cardContainer} p={2}>
      <ComponentLoader isOpen={isLoading}>
        <RoleForm
          actions={actions}
          changeActionIds={changeActionIds}
          formik={formik}
          isSubmitting={isSubmitting}
        />
      </ComponentLoader>
    </Box>
  );
};
export default AddRole;
