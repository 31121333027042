// Styles.ts
import { SxProps } from '@mui/material';

const greyCard: SxProps = {
  backgroundColor: (theme: any) => theme.palette.neutral['100'],
  marginTop: '25px',
  marginBottom: '5px',
  padding: '6px 6px',
  borderRadius: '7px',
};

const alignCenter: SxProps = {
  display: 'flex',
  alignItems: 'center',
};

const classes = {
  greyCard,
  alignCenter,
};

export default classes;
