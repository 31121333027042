import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import AssociationDetails from '../../../../Models/Association/custodian-association-details';
import AssociationsRecordTypesListDetails from './AssociationsRecordTypesListDetails';
import { ExpandMore, LocationOn } from '@mui/icons-material';
import { accordionSummaryText } from '../../Styles/MainInformationTabStyles';
import { Link } from 'react-router-dom';
import { linkStyles } from '../../../../assets/styles/appStyles';

export default function AssociationsListItemDetails({
  association,
  togglePanel,
  expanded,
}: {
  association: AssociationDetails;
  expanded: boolean;
  togglePanel: (
    id: number,
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
}) {
  return (
    <>
      <Accordion
        data-testid={'association'}
        expanded={expanded}
        onChange={togglePanel(association.id)}
      >
        <AccordionSummary
          aria-controls={`${association.id}-content`}
          id={`${association.id}-header`}
          expandIcon={<ExpandMore />}
        >
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignItems={'center'} spacing={2}>
                <Grid item>
                  <Typography sx={accordionSummaryText}>
                    {association?.associatedCustodian?.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Link
                    style={linkStyles}
                    to={`/custodians/details/${association?.associatedCustodian.id}`}
                  >
                    view
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems={'center'}>
                <Grid item>
                  <LocationOn color="primary" />
                </Grid>
                <Grid item>
                  <Typography data-testid="custodian-address">
                    {association?.associatedCustodian?.addressDetails}
                    {association?.associatedCustodian?.addressDetails &&
                      association?.associatedCustodian?.city &&
                      ', '}
                    {association?.associatedCustodian?.city}
                    {association?.associatedCustodian?.city &&
                      association?.associatedCustodian?.stateName &&
                      ', '}
                    {association?.associatedCustodian?.stateName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <AssociationsRecordTypesListDetails
            records={association?.custodianAssociationRecordTypes ?? []}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
