// Styles.ts
import { SxProps } from '@mui/material';

const card: SxProps = {
  marginBottom: '10px',
  flexDirection: 'row',
  display: 'flex',
  color: (theme: any) => theme.palette.neutral['500'],
  gap: '7px',
};

const classes = {
  card,
};

export default classes;
