import { SxProps, Theme } from '@mui/material';

const wrapText: SxProps = {
  wordWrap: 'break-word !important',
  whiteSpace: 'pre-wrap !important',
  textOverflow: 'ellipsis !important',
};

const textWhite: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.common.white,
});

const selectedBox: SxProps = {
  color: (theme: any) => theme.palette.common.white,
  backgroundColor: (theme: any) => theme.palette.primary[400],
  borderRadius: '8px',
  padding: '14px 8px',
  fontWeight: 700,
  fontSize: '13px',
  lineHeight: '20px',
  textAlign: 'center',
};

const alertStyle = { m: 1, width: '100%' };

const box: SxProps = {
  borderRadius: '8px',
  padding: '14px 8px',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '20px',
  backgroundColor: (theme: any) => theme.palette.neutral['100'],
  Color: (theme: any) => theme.palette.neutral['800'],
  border: 'solid',
  borderColor: (theme: any) => theme.palette.neutral[200],
};

const unSelectedBox: SxProps = {
  ...box,
  borderColor: (theme: any) => theme.palette.primary[400],
  border: 'solid 1px',
  fontWeight: 600,
  color: (theme: any) => theme.palette.primary[400],
};

const textGrey: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.neutral['200'],
});
const bgGrey: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.neutral['200'],
});

const textError: SxProps = (theme: any) => ({
  color: theme.palette.error['500'],
  fontSize: '0.75rem',
});

const greyLightContainer: SxProps = (theme: any) => ({
  backgroundColor: theme.palette.neutral['100'],
  borderRadius: '8px 8px 0px 0px',
});

const bgWhite: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.common.white,
});

const textPrimary: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary['500'],
});

const buttonLink: SxProps<Theme> = (theme: Theme) => ({
  display: 'inline-block',
  padding: '10px 20px',
  borderRadius: '5px',
  fontWeight: '700',
  '&:hover': {
    backgroundColor: theme.palette.primary[700],
  },
  color: theme.palette.common.white,
  background: theme.palette.primary[400],
});

const cardContainer: SxProps = {
  borderRadius: '16px',
  border: '1px solid #E5E5E5',
  boxShadow: '-40px 40px 80px -8px rgba(145, 158, 171, 0.1)',
};

const horizontalLine: SxProps<Theme> = (theme: Theme) => ({
  width: '100%',
  margin: '10px 0px',
});

const labelTitle: SxProps<Theme> = (theme: Theme) => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: '600',
  fontSize: '16px',
  color: theme.palette.neutral[400],
});

const labelText: SxProps = {
  ...wrapText,
  fontFamily: (theme: any) => theme.typography.fontFamily,
  fontWeight: '600',
  fontSize: '20px',
  maxWidth: '95%',
};

const appTimePicker: SxProps = {
  input: {
    fontSize: '11px',
  },
};

const selectedButton: SxProps = {
  ...selectedBox,
  fontSize: '11px',
};

const title: SxProps = {
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '22px',
  color: (theme: any) => theme.palette.neutral['800'],
};

const detailsTitle: SxProps = {
  color: (theme: any) => theme.palette.neutral['500'],
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '19px',
  marginBottom: '5px',
};

const detailsValue: SxProps = {
  color: (theme: any) => theme.palette.neutral['800'],
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '22px',
  marginBottom: '5px',
};

const genericTitle: SxProps = {
  fontWeight: 'bold',
  marginBottom: '10px',
};

const error: SxProps = {
  color: (theme: any) => theme.palette.error['500'],
  marginBottom: '5px',
};

const fileTypeError: SxProps = {
  color: (theme: any) => theme.palette.error['500'],
  marginBottom: '5px',
  marginTop: '0px',
};

const menuItem: SxProps = {
  '&.Mui-selected': {
    color: (theme: any) => theme.palette.primary['500'],
    border: '1px solid',
    borderRadius: '8px',
    borderColor: (theme: any) => theme.palette.primary['100'],
    '& .MuiBadge-badge': {
      backgroundColor: (theme: any) => theme.palette.primary['100'],
      color: (theme: any) => theme.palette.primary['500'],
    },
  },
  '& .MuiBadge-badge': {
    backgroundColor: (theme: any) => theme.palette.neutral['200'],
  },
  borderRadius: '8px',
  margin: '6px',
  display: 'flex',
  alignItems: 'center',
};

const tableHeaderStyles: SxProps = {
  fontWeight: '700',
  fontSize: '18px',
  lineHeight: '20px',
  color: (theme: any) => theme.palette.common.black,
};

const linkStyles = {
  textDecoration: 'none',
  cursor: 'pointer',
  fontWeight: '600',
  fontSize: '15px',
  lineHeight: '20px',
  color: '#f97316',
};

const pointerStyle = {
  cursor: 'pointer',
}

const warningText: SxProps = {
  color: (theme: any) => theme.palette.neutral[400],
  fontSize: '0.75rem',
};

const requiredStar: SxProps<Theme> = {
  color: 'red',
  marginLeft: '2px',
};

export {
  greyLightContainer,
  textError,
  textWhite,
  textGrey,
  bgWhite,
  bgGrey,
  buttonLink,
  cardContainer,
  horizontalLine,
  labelTitle,
  labelText,
  textPrimary,
  selectedBox,
  unSelectedBox,
  appTimePicker,
  selectedButton,
  title,
  genericTitle,
  box,
  detailsTitle,
  detailsValue,
  error,
  menuItem,
  tableHeaderStyles,
  linkStyles,
  warningText,
  requiredStar,
  fileTypeError,
  alertStyle,
  wrapText,
  pointerStyle,
};
