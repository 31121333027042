import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import ResearchCustodianListColumn from '../../../Models/custodian/research-custodian-list-column';
import GenericMenuInput from '../../AddLocation/Components/UI/GenericMenuInput';
import { FilterList } from '@mui/icons-material';
import { menuItemContainer } from './ResearchStyles';

export default function ResearchCustodiansListTableHeaderFilter({
  availableColumns,
  onFilterClosed,
  onFilterClicked,
  isOpened,
  onAddColumn,
  onRemoveColumn,
  selectedColumns,
}: {
  availableColumns: ResearchCustodianListColumn[];
  selectedColumns: ResearchCustodianListColumn[];
  onAddColumn: (col: ResearchCustodianListColumn) => void;
  onRemoveColumn: (col: ResearchCustodianListColumn) => void;
  onFilterClosed: () => void;
  onFilterClicked: () => void;
  isOpened: boolean;
}) {
  const onChangeMenuItem = (id: string) => {
    let item = selectedColumns.find((s) => s.id.toString() == id);
    if (item) onRemoveColumn(item);
    else {
      item = availableColumns.find((s) => s.id.toString() == id);
      if (item) onAddColumn(item);
    }
  };
  return (
    <>
      <IconButton onClick={() => onFilterClicked()}>
        <FilterList />
      </IconButton>
      <Dialog
        open={isOpened}
        onClose={onFilterClosed}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle>Custodian Fields</DialogTitle>
        <DialogContent>
          <Box sx={menuItemContainer}>
            <GenericMenuInput
              errorMsg=""
              onChange={onChangeMenuItem}
              options={availableColumns}
              value={selectedColumns.map((i) => i.id.toString())}
              multiple={true}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
