import { Box, Grid } from '@mui/material';
import styleClasses from '../../assets/styles/panelStyles';

export default function Panel({
  headerText,
  children,
}: {
  headerText: any;
  children: any;
}) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={styleClasses.panelHeader}>{headerText}</Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={styleClasses.panelContent}>{children}</Box>
      </Grid>
      <Grid item xs={12} paddingBottom={2}></Grid>
    </Grid>
  );
}
