import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import InsertDepartmentModel from '../../../../Models/Departments/InsertDepartmentModel';
import DepartmentMainInfo from './DepartmentMainInfo';
import DepartmentServePreferences from './DepartmentServePreferences';
import DepartmentServeInfo from './DepartmentServeInfo';
import DepartmentNavigation from './DepartmentNavigation';
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function DepartmentForm({
  form,
  department,
  formKeyName,
  departmentIndex,
  orderTypes,
  phoneTypes,
  states,
  recordTypes,
  timeZones,
  departmentFileTypes,
  serveMethods,
  days,
  isWizard,
  copyCusodianAddress,
}: {
  department: InsertDepartmentModel;
  form: any;
  formKeyName: string;
  departmentIndex: number;
  orderTypes: any[];
  states: any[];
  phoneTypes: any[];
  recordTypes: any[];
  timeZones: any[];
  departmentFileTypes: any[];
  serveMethods: any[];
  days: any[];
  isWizard?: boolean;
  copyCusodianAddress?: (key: string) => void;
}) {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = (newValue: number) => {
    setSelectedTab(newValue);
    window.scrollTo(0, 0);
  };

  const navigationProps = {
    onClickNext: () => handleChangeTab(selectedTab + 1),
    onClickPrevious: () => handleChangeTab(selectedTab - 1),
    enableNext: selectedTab != 2,
    enablePrevious: selectedTab != 0,
  };
  return (
    <>
      <Box>
        <DepartmentNavigation {...navigationProps} />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => setSelectedTab(newValue)}
            aria-label="basic tabs example"
          >
            <Tab
              label="Department Information"
              id={`simple-tab-${0}`}
              aria-controls={`simple-tabpanel-${0}`}
            />
            <Tab
              label="Serve Info"
              id={`simple-tab-${1}`}
              aria-controls={`simple-tabpanel-${1}`}
            />
            <Tab
              label="Serve Preferences"
              id={`simple-tab-${2}`}
              aria-controls={`simple-tabpanel-${2}`}
            />
          </Tabs>
        </Box>
        <TabPanel value={selectedTab} index={0}>
          <DepartmentMainInfo
            form={form}
            departmentIndex={departmentIndex}
            department={department}
            formKeyName={formKeyName}
            orderTypes={orderTypes}
            phoneTypes={phoneTypes}
            states={states}
            recordTypes={recordTypes}
            timeZones={timeZones}
            isWizard={isWizard}
            copyCusodianAddress={copyCusodianAddress}
          />
        </TabPanel>

        <TabPanel value={selectedTab} index={1}>
          <DepartmentServeInfo
            form={form}
            departmentIndex={departmentIndex}
            department={department}
            formKeyName={formKeyName}
            timeZones={timeZones}
            serveMethods={serveMethods}
            days={days}
          />
        </TabPanel>

        <TabPanel value={selectedTab} index={2}>
          <DepartmentServePreferences
            departmentFileTypes={departmentFileTypes}
            departmentIndex={departmentIndex}
            department={department}
            formKeyName={formKeyName}
            form={form}
          ></DepartmentServePreferences>
        </TabPanel>
        <DepartmentNavigation {...navigationProps} />
      </Box>
    </>
  );
}
