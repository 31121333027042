import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TablePagination,
  Grid,
  CircularProgress,
} from '@mui/material';
import { useState } from 'react';
import { rowsPerPage } from '../../../settings/appConfig';
import Styles from '../Styles/ListCustodiansStyles';
import CustodianSearchResult from '../../../Models/custodian/custodianSearchResult';
import React from 'react';
import CustodianDepartmentTableRow from './CustodianDepartmentTableRow';
import CustodianTableRow from './CustodianTableRow';

export default function CustodiansListTable({
  custodians,
  count,
  onPageChanged,
  isLoading,
  unmountCollapsedOnExit,
  pageNo,
  setPageNo,
  usePagination,
  showSelectBtn,
  selectBtnClicked,
  showNoRecordsFoundMessage,
}: {
  count: number;
  custodians: CustodianSearchResult[];
  onPageChanged?: (_: number) => void;
  isLoading: boolean;
  unmountCollapsedOnExit: boolean;
  pageNo: number;
  setPageNo?: React.Dispatch<React.SetStateAction<number>>;
  usePagination: boolean;
  showSelectBtn?: boolean;
  selectBtnClicked?: (item: any) => void;
  showNoRecordsFoundMessage: boolean;
}) {
  const handlePageChange = (event: unknown, newPage: number) => {
    if (onPageChanged) onPageChanged(newPage);
    if (setPageNo) setPageNo(newPage);
  };
  const [expandedRow, setExpandedRow] = useState<number | undefined>(undefined);

  const handleRowToggle = (id: number) => {
    if (expandedRow === id) {
      setExpandedRow(undefined);
    } else {
      setExpandedRow(id);
    }
  };
  const isRowExpanded = (id: number) => expandedRow === id;

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={Styles.tableHeaderStyles} width={'20%'}>
                Name
              </TableCell>
              <TableCell sx={Styles.tableHeaderStyles} width={'35%'}>
                Address
              </TableCell>
              <TableCell sx={Styles.tableHeaderStyles} width={'15%'}>
                City
              </TableCell>
              <TableCell sx={Styles.tableHeaderStyles} width={'15%'}>
                State
              </TableCell>
              <TableCell sx={Styles.tableHeaderStyles} width={'15%'}>
                Created at
              </TableCell>
              {showSelectBtn ? (
                <TableCell sx={Styles.tableHeaderStyles}></TableCell>
              ) : (
                ''
              )}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {custodians?.map((custodianRow) => (
              <React.Fragment key={custodianRow.id}>
                <CustodianTableRow
                  custodianRow={custodianRow}
                  isRowExpanded={isRowExpanded}
                  handleRowToggle={handleRowToggle}
                  showSelectBtn={showSelectBtn}
                  selectBtnClicked={selectBtnClicked}
                />
                <CustodianDepartmentTableRow
                  custodianId={custodianRow.id}
                  departments={custodianRow.departments}
                  isRowExpanded={isRowExpanded}
                  unmountCollapsedOnExit={unmountCollapsedOnExit}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        {!custodians || custodians.length === 0 ? (
          <Grid container sx={Styles.loaderStyles}>
            {isLoading ? (
              <CircularProgress />
            ) : showNoRecordsFoundMessage ? (
              <Typography id="modal-modal-title" variant="h6">
                No records found
              </Typography>
            ) : (
              ''
            )}
          </Grid>
        ) : (
          ''
        )}
        {usePagination ? (
          <TablePagination
            component="div"
            sx={Styles.paginationStyles}
            labelRowsPerPage={'Items per page:'}
            showFirstButton
            showLastButton
            count={count}
            page={pageNo}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
          />
        ) : (
          ''
        )}
      </TableContainer>
    </>
  );
}
