import { Box, FormControl, Grid, Button, Typography } from '@mui/material';
import { maxContactsAllowed } from '../../../settings/appConfig';
import { error } from './../../../assets/styles/appStyles';
import CheckboxInput from './UI/CheckboxInput';
import Panel from '../../../components/UI/Panel';
import plusIcon from '../../../assets/Icons/plusIcon.svg';
import useFormatPhone from '../../../hooks/UI/usePhonesHelperMethods';
import GenericInput from './UI/GenericInput';
import useUI from '../../../hooks/context/useUI';
import useValidation from '../../../hooks/validation/useValidation';

export default function CustodianContactInfo({
  formik,
  showPanel,
}: {
  formik: any;
  showPanel: boolean;
}) {
  const { formatPhoneNumberOnChange } = useFormatPhone();

  const { isContactsValid } = useValidation();
  const { showSnackbar } = useUI();

  const onAddNewContact = async () => {
    if (isContactsValid(formik.values.contacts) || formik?.values?.contacts?.length === 0) {
      const newItems = [
        ...formik.values.contacts,
        {
          isDirectContact: false,
          email: '',
          phoneNumber: '',
          name: '',
        },
      ];
      formik.setFieldValue('contacts', newItems);
    } else {
      showSnackbar(['Please enter a valid contact'], 'error');
    }
  };

  const content = (
    <Grid container spacing={2} mx={1} px={1}>
      {formik.values.contacts.length > 0 &&
        formik.values.contacts.map((item: any, index: number) => (
          <Grid my={2} container key={index}>
            <Grid item xs={4} px={2}>
              <GenericInput
                name={`contacts.${index}.name`}
                label="Name"
                maxLength={120}
                placeholder="Name"
                value={item.name}
                onChange={(e: any) => {
                  formik.setFieldValue(
                    `contacts.${index}.name`,
                    e.target.value,
                  );
                }}
                errorMsg={
                  formik.touched.contacts &&
                  formik.touched.contacts[index] &&
                  formik.touched.contacts[index]['name'] &&
                  formik.errors.contacts &&
                  formik.errors.contacts[index] &&
                  formik.errors.contacts[index]['name']
                    ? formik.errors.contacts[index]['name']
                    : ''
                }
                hasError={
                  formik.touched.contacts &&
                  formik.touched.contacts[index] &&
                  formik.touched.contacts[index]['name'] &&
                  formik.errors.contacts &&
                  formik.errors.contacts[index] &&
                  formik.errors.contacts[index]['name']
                    ? true
                    : false
                }
                onBlur={() => {
                  formik.setFieldTouched(`contacts.${index}.name`, true);
                }}
              />
            </Grid>
            <Grid item xs={4} px={2}>
              <GenericInput
                name={`contacts.${index}.phoneNumber`}
                label="Phone"
                maxLength={12}
                placeholder="xxx-xxx-xxxx"
                inputMode="tel"
                value={item.phoneNumber}
                onChange={(e: any) => {
                  formatPhoneNumberOnChange(e);
                  formik.setFieldValue(
                    `contacts.${index}.phoneNumber`,
                    e.target.value,
                  );
                }}
                errorMsg={
                  formik.touched.contacts &&
                  formik.touched.contacts[index] &&
                  formik.touched.contacts[index]['phoneNumber'] &&
                  formik.errors.contacts &&
                  formik.errors.contacts[index] &&
                  formik.errors.contacts[index]['phoneNumber']
                    ? formik.errors.contacts[index]['phoneNumber']
                    : ''
                }
                hasError={
                  formik.touched.contacts &&
                  formik.touched.contacts[index] &&
                  formik.touched.contacts[index]['phoneNumber'] &&
                  formik.errors.contacts &&
                  formik.errors.contacts[index] &&
                  formik.errors.contacts[index]['phoneNumber']
                    ? true
                    : false
                }
                onBlur={() => {
                  formik.setFieldTouched(`contacts.${index}.phoneNumber`, true);
                }}
              />
            </Grid>
            <Grid item xs={4} px={2}>
              <GenericInput
                name={`contacts.${index}.title`}
                label="Title"
                maxLength={80}
                placeholder="Title"
                value={item.title}
                onChange={(e: any) => {
                  formik.setFieldValue(
                    `contacts.${index}.title`,
                    e.target.value,
                  );
                }}
                errorMsg={
                  formik.touched.contacts &&
                  formik.touched.contacts[index] &&
                  formik.touched.contacts[index]['title'] &&
                  formik.errors.contacts &&
                  formik.errors.contacts[index] &&
                  formik.errors.contacts[index]['title']
                    ? formik.errors.contacts[index]['title']
                    : ''
                }
                hasError={
                  formik.touched.contacts &&
                  formik.touched.contacts[index] &&
                  formik.touched.contacts[index]['title'] &&
                  formik.errors.contacts &&
                  formik.errors.contacts[index] &&
                  formik.errors.contacts[index]['title']
                    ? true
                    : false
                }
                onBlur={() => {
                  formik.setFieldTouched(`contacts.${index}.title`, true);
                }}
              />
            </Grid>
            <Grid item xs={12} my={1}></Grid>
            <Grid item xs={4} px={2}>
              <GenericInput
                name={`contacts.${index}.email`}
                label="Email"
                maxLength={80}
                placeholder="Email"
                value={item.email}
                onChange={(e: any) => {
                  formik.setFieldValue(
                    `contacts.${index}.email`,
                    e.target.value,
                  );
                }}
                errorMsg={
                  formik.touched.contacts &&
                  formik.touched.contacts[index] &&
                  formik.touched.contacts[index]['email'] &&
                  formik.errors.contacts &&
                  formik.errors.contacts[index] &&
                  formik.errors.contacts[index]['email']
                    ? formik.errors.contacts[index]['email']
                    : ''
                }
                hasError={
                  formik.touched.contacts &&
                  formik.touched.contacts[index] &&
                  formik.touched.contacts[index]['email'] &&
                  formik.errors.contacts &&
                  formik.errors.contacts[index] &&
                  formik.errors.contacts[index]['email']
                    ? true
                    : false
                }
                onBlur={() => {
                  formik.setFieldTouched(`contacts.${index}.email`, true);
                }}
              />
            </Grid>
            <Grid item xs={4} px={2}>
              <FormControl fullWidth>
                <Box px={2}>
                  <CheckboxInput
                    checked={item.isDirectContact}
                    keyName={`contacts[${index}].isDirectContact`}
                    label="Is Direct Contact"
                    onChange={(e: any) => {
                      formik.setFieldValue(
                        `contacts.${index}.isDirectContact`,
                        e.target.checked,
                      );
                    }}
                    onBlur={() => {
                      formik.setFieldTouched(
                        `contacts.${index}.isDirectContact`,
                        true,
                      );
                    }}
                  />
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={4} px={2}>
              {formik.values.contacts.length > 1 && (
                <Button
                  type="button"
                  onClick={() => {
                    if (formik.values.contacts.length === 1) return;
                    const newItems = [...formik.values.contacts];
                    newItems.splice(index, 1);
                    formik.setFieldValue('contacts', newItems);
                  }}
                >
                  Remove
                </Button>
              )}
            </Grid>
          </Grid>
        ))}
      <Grid container alignItems={'center'} my={1}>
        <Grid item xs={4} px={2}>
          {formik.errors &&
          formik.errors.contacts &&
          typeof formik.errors.contacts === 'string' ? (
            <Typography align="left" sx={error}>
              {formik.errors.contacts}
            </Typography>
          ) : null}
          {formik.values.contacts.length < maxContactsAllowed && (
            <Button onClick={onAddNewContact}>
              <Box data-testid="plusIcon" height={'20.75px'} width={'16.75px'}>
                <img src={plusIcon} width={'16.75px'} height={'16.75px'} />
              </Box>
              Add New Contact
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
  return (
    <>
      {showPanel === true && (
        <Panel headerText="Contacts Details">{content}</Panel>
      )}
      {!showPanel && content}
    </>
  );
}

CustodianContactInfo.defaultProps = {
  showPanel: true,
};
