import { SidebarItemModel } from '../Models/Layout/sidebarItemModel';
import { Settings, PostAdd, Person2, Article } from '@mui/icons-material';

export const maxDepartmentsAllowed = 5;
export const maxSideBarCharLength = 25;
export const maxPhonesAllowed = 5;
export const maxEmailsAllowed = 5;
export const maxContactsAllowed = 5;
export const rowsPerPage = 10;
export const baseURL = window._env_.REACT_APP_BASE_URL as string;
export const debounceDelay = Number(window._env_.REACT_APP_DEBOUNCEDELAY); //in ms
export const duplicateLocationRowsPerPage = 10;
export const acceptedFileUploadTypes = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
];
export const maximumUploadFileSize = '2000000000';

export const custodianSteps = {
  mainInfo: 'Main Information',
  departmentInfo: 'Department Info',
  billingInfo: 'Billing Information',
  associatedCustodians: 'Associated Custodians',
};
export const appActions = {
  researchCustodians: 'View research custodians',
  editBasicInfo: 'Edit basic info',
  editBillingInfo: 'Edit billing info',
  editAssociateCustodians: 'Edit Associate Custodians',
  addNewCustodian: 'Add new custodian',
  viewCustodians: 'View custodians',
  addNewDepartment: 'Add new department',
  viewDepartments: 'View departments',
  manageRoles: 'Manage roles',
  manageUsers: 'Manage users',
  editDepartments: 'Edit departments',
};

export const appRoutesPaths = {
  home: '/',
  notFound: '/notfound',
  unauthorized: '/unauthorized',
  locations: '/locations',
  researchCustodians: '/res-custodians',
  custodianDetails: '/custodians/details/:id',
  editDepartment: '/departments/edit/:id',
  addDepartment: 'custodian/:custodianId/departments/insert',
  addLocation: '/locations/add',
  users: '/users',
  addUser: '/users/assign',
  editUser: '/users/edit/:id',
  roles: '/roles',
  addRole: '/roles/add',
  editRole: '/roles/edit/:id',
};

export const routesWithActions = [
  {
    navigateTo: appRoutesPaths.locations,
    actionName: appActions.viewCustodians,
  },
  {
    navigateTo: appRoutesPaths.custodianDetails,
    actionName: appActions.viewCustodians,
  },
  {
    navigateTo: appRoutesPaths.addLocation,
    actionName: appActions.addNewCustodian,
  },
  { navigateTo: appRoutesPaths.editDepartment, actionName: appActions.editDepartments },
  { navigateTo: appRoutesPaths.addDepartment, actionName: appActions.addNewDepartment },
  { navigateTo: appRoutesPaths.roles, actionName: appActions.manageRoles },
  { navigateTo: appRoutesPaths.addRole, actionName: appActions.manageRoles },
  { navigateTo: appRoutesPaths.editRole, actionName: appActions.manageRoles },
  { navigateTo: appRoutesPaths.users, actionName: appActions.manageUsers },
  { navigateTo: appRoutesPaths.addUser, actionName: appActions.manageUsers },
  { navigateTo: appRoutesPaths.editUser, actionName: appActions.manageUsers },
];

export const sidebarItems: SidebarItemModel[] = [
  {
    title: 'Custodians',
    navigateTo: appRoutesPaths.locations,
    actionName: appActions.viewCustodians,
    icon: <Article />,
  },
  {
    title: 'Research Custodians',
    navigateTo: appRoutesPaths.researchCustodians,
    actionName: appActions.researchCustodians,
    icon: <Article />,
    isAnonymous: true,
  },
  {
    title: 'Add Custodian',
    navigateTo: appRoutesPaths.addLocation,
    actionName: appActions.addNewCustodian,
    icon: <PostAdd />,
  },
  {
    title: 'Manage Roles',
    navigateTo: appRoutesPaths.roles,
    actionName: appActions.manageRoles,
    icon: <Settings />,
  },
  {
    title: 'Manage Users',
    navigateTo: appRoutesPaths.users,
    actionName: appActions.manageUsers,
    icon: <Person2 />,
  },
];

export const emptyValue = '-';
