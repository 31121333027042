import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import AdapterDateFns from '@date-io/date-fns';
import {
  ClickAwayListener,
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material';
import { appTimePicker, error } from './../../../assets/styles/appStyles';
import { useEffect, useRef, useState } from 'react';

const AppTimePicker = ({
  keyName,
  value,
  onChange,
  errorMsg,
  label,
  fieldLabel,
  disabled,
  close,
  onOpenHandler,
}: {
  onChange?: (value: Date | null) => void;
  value: any;
  keyName: string;
  errorMsg?: string;
  label: string;
  fieldLabel: string;
  disabled?: boolean;
  close?: boolean;
  onOpenHandler?: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const timePickerRef = useRef(null);
  useEffect(() => {
    if (close != null && !disabled) setIsOpen(!close);

    return () => {
      setIsOpen(false);
    };
  }, [close]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl fullWidth sx={appTimePicker}>
        <ClickAwayListener
          onClickAway={() => {
            setIsOpen(false);
          }}
        >
          <div ref={timePickerRef}>
            <TimePicker
              label={fieldLabel}
              InputProps={{
                name: keyName,
                id: keyName,
                type: 'time',
              }}
              onChange={(e) => (onChange ? onChange(e) : {})}
              value={value}
              disabled={disabled}
              open={isOpen}
              onOpen={() => (onOpenHandler ? onOpenHandler() : {})}
              renderInput={(params: any) => (
                <TextField
                  id={keyName}
                  data-testid={keyName}
                  label={label}
                  name={keyName}
                  variant="outlined"
                  onKeyDown={(e) => {
                    e.preventDefault();
                    setIsOpen(!isOpen && !disabled);
                    if (onOpenHandler) onOpenHandler();
                  }}
                  onClick={() => {
                    setIsOpen(!isOpen && !disabled);
                    if (onOpenHandler) onOpenHandler();
                  }}
                  inputProps={{
                    maxLength: 150,
                  }}
                  error={errorMsg ? true : false}
                  {...params}
                />
              )}
            />
            {errorMsg ? (
              <FormHelperText sx={error}>{errorMsg}</FormHelperText>
            ) : null}
          </div>
        </ClickAwayListener>
      </FormControl>
    </LocalizationProvider>
  );
};

export default AppTimePicker;
