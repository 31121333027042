import { useEffect, useState } from 'react';
import { SidebarItemModel } from '../../Models/Layout/sidebarItemModel';
import { sidebarItems } from '../../settings/appConfig';
import useAuth from '../context/useAuth';

export default function useAuthorizedMenuItems() {
  const [menuItems, setMenuItems] = useState<SidebarItemModel[]>([]);
  const { isAuthorized, auth } = useAuth();
  useEffect(() => {
    if (auth && auth.actions) {
      let items: SidebarItemModel[] = [];
      sidebarItems.forEach((element) => {
        element.authorized =
          element.isAnonymous || isAuthorized(element.actionName ?? '');
      });
      items = [...sidebarItems];
      setMenuItems(items);
    }
  }, [auth]);

  return menuItems.filter((item) => item.authorized);
}
