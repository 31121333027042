import { DialogContent, Dialog, Box, Grid } from '@mui/material';
import useSearchAssociations from '../../../../hooks/associations/useSearchAssociations';
import { useEffect, useState } from 'react';
import CustodiansListTable from '../../../Locations/Custodians/CustodiansListTable';
import CustodiansSearch from '../../../Locations/Custodians/CustodiansSearch';
export default function AssignAssociationDialog({
  open,
  handleClose,
  associatedCustodians,
  onItemSelected,
}: {
  open: boolean;
  handleClose: () => void;
  associatedCustodians: any[];
  onItemSelected: (item: any) => void;
}) {
  const [hasUserStartedSearching, setHasUserStartedSearching] =
    useState<boolean>(false);
  const { data, isLoading, search } = useSearchAssociations();

  const onSearchChanged = async (value?: string, stateId?: number) => {
    if (value) {
      await search(value);
      setHasUserStartedSearching(true);
    } else setHasUserStartedSearching(false);
  };

  const onChooseItem = (item: any) => {
    onItemSelected(item);
    handleClose();
  };

  useEffect(() => {
    setHasUserStartedSearching(false);
  }, [open]);

  return (
    <Box>
      <Dialog
        maxWidth={'xl'}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Box width={1000}>
            <Grid container>
              <Grid item xs={12}>
                <Box>
                  <CustodiansSearch
                    onSearchChanged={onSearchChanged}
                    showStatesFilteration={false}
                  />
                  <CustodiansListTable
                    custodians={
                      hasUserStartedSearching
                        ? data?.custodians?.filter(
                            (o: any) =>
                              !associatedCustodians.some((a) => a.id === o.id),
                          )
                        : []
                    }
                    count={0}
                    isLoading={isLoading}
                    unmountCollapsedOnExit={true}
                    pageNo={0}
                    usePagination={false}
                    showSelectBtn={true}
                    selectBtnClicked={onChooseItem}
                    showNoRecordsFoundMessage={hasUserStartedSearching}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
