import { Box, Grid, Button, Typography } from '@mui/material';
import plusIcon from '../../../assets/Icons/plusIcon.svg';
import { error } from './../../../assets/styles/appStyles';
import { maxEmailsAllowed } from '../../../settings/appConfig';
import useValidation from '../../../hooks/validation/useValidation';
import useUI from '../../../hooks/context/useUI';
import CustodianFieldSearchInput from './CustodianFieldSearchInput';

export default function Emails({ formik }: { formik: any }) {
  const { isEmailsValid, isEmailAddressValid } = useValidation();
  const { showSnackbar } = useUI();

  const onAddNewEmail = async () => {
    if (isEmailsValid(formik.values.emails) || formik?.values?.emails?.length === 0) {
      const newItems = [...formik.values.emails, { email: '' }];
      formik.setFieldValue('emails', newItems);
    } else {
      showSnackbar(['Please enter a valid email'], 'error');
    }
  };

  return (
    <>
      {formik.values.emails.length > 0 &&
        formik.values.emails.map((item: any, index: number) => (
          <Grid container key={index} alignItems={'center'} my={1}>
            <Grid item xs={4} px={2}>
              <CustodianFieldSearchInput
              setHasDuplicateError={(duplicateFound) => {formik.setFieldValue(
                `emails.${index}.hasDuplicateError`,
                duplicateFound,
              );}}
                name={`emails.${index}.email`}
                searchPropertyName="email"
                isExactSearch={true}
                shouldViewModal={false}
                label="Email"
                maxLength={40}
                placeholder=""
                inputMode="email"
                value={item.email}
                isValid={isEmailAddressValid}
                onChange={(e: any) => {
                  formik.setFieldValue(`emails.${index}.email`, e.target.value);
                  if(!e.target.value)
                  {
                    formik.setFieldValue(`emails.${index}.hasDuplicateError`, false);
                  }
                }}
                formErrorMessage={
                  formik.touched.emails &&
                  formik.touched.emails[index] &&
                  formik.touched.emails[index]['email'] &&
                  formik.errors.emails &&
                  formik.errors.emails[index] &&
                  formik.errors.emails[index]['email']
                    ? formik.errors.emails[index]['email']
                    : ''
                }
                hasFormError={
                  formik.touched.emails &&
                  formik.touched.emails[index] &&
                  formik.touched.emails[index]['email'] &&
                  formik.errors.emails &&
                  formik.errors.emails[index] &&
                  formik.errors.emails[index]['email']
                    ? true
                    : false
                }
                onBlur={() => {
                  formik.setFieldTouched(`emails.${index}.email`, true);
                }}
                duplicateErrorMessage={
                  'A custodian was found having the same email'
                }
              />
            </Grid>

            <Grid item xs={1} px={1}>
              {formik.values.emails.length > 1 && (
                <Button
                  type="button"
                  onClick={() => {
                    //don't remove last entry
                    if (formik.values.emails.length === 1) return;
                    const newItems = [...formik.values.emails];
                    newItems.splice(index, 1);
                    formik.setFieldValue('emails', [...newItems]);
                  }}
                >
                  Remove
                </Button>
              )}
            </Grid>
          </Grid>
        ))}
      <Grid container alignItems={'center'} my={1}>
        <Grid item xs={4} px={2}>
          {formik.errors &&
          formik.errors.emails &&
          typeof formik.errors.emails === 'string' ? (
            <Typography align="left" sx={error}>
              {formik.errors.emails}
            </Typography>
          ) : null}
          {formik.values.emails.length < maxEmailsAllowed && (
            <Button onClick={onAddNewEmail}>
              <Box data-testid="plusIcon" height={'20.75px'} width={'16.75px'}>
                <img src={plusIcon} width={'16.75px'} height={'16.75px'} />
              </Box>
              Add New Email
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
}
