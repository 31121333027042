import Panel from '../../../../components/UI/Panel';
import { Box, FormControl, Grid, Typography } from '@mui/material';
import CheckboxInput from '../UI/CheckboxInput';
import GenericInput from '../UI/GenericInput';
import UploadFilesComponent from '../UI/UploadFilesComponent';
import {
  acceptedFileUploadTypes,
  maximumUploadFileSize,
} from '../../../../settings/appConfig';
import { useEffect, useRef, useState } from 'react';
import { FilePondFile } from 'filepond';
import uploadFilesStyles from '../../../../assets/styles/uploadFilesStyles';
import uploadIcon from '../../../../assets/Icons/uploadicon.svg';
import InsertDepartmentModel from '../../../../Models/Departments/InsertDepartmentModel';
import GenericSelectInput from '../UI/GenericSelectInput';
import DepartmentEditOldFiles from './DepartmentEditOldFiles';

export default function DepartmentServePreferencesDocumentsInfo({
  form,
  departmentFileTypes,
  department,
  formKeyName,
  departmentIndex,
}: {
  form: any;
  departmentFileTypes: any[];
  department: InsertDepartmentModel;
  formKeyName: string;
  departmentIndex: number;
}) {
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const previousformFilesRef = useRef(department.servePreferences.files);
  useEffect(() => {
    previousformFilesRef.current = department.servePreferences.files;
  }, [department.servePreferences.files]);

  const updateFormFiles = (pondFiles: FilePondFile[]) => {
    form.setFieldValue(
      `${formKeyName}.${departmentIndex}.servePreferences.files`,
      [
        ...pondFiles.map((f) => {
          let file = f.file as any;
          var oldFileIndex = previousformFilesRef.current?.findIndex(
            (formFile) => formFile.id === f.id,
          );
          if (oldFileIndex > -1) {
            return {
              ...previousformFilesRef.current[oldFileIndex],
              tempBlobUrl: file.url?.substring(0, file.url.indexOf('?')) ?? '',
            };
          } else {
            return {
              id: f.id,
              name: file.name,
              typeId: '',
              tempBlobUrl: file.url?.substring(0, file.url.indexOf('?')) ?? '',
              fileSize: file.size ?? 0,
              pondFile: f,
            };
          }
        }),
      ],
    );
  };

  const autoCheckServePreferencesFields = (typeId: number) => {
    let fileTypeName = departmentFileTypes
      .find((ft) => ft.id === typeId)
      ?.name?.toLowerCase();
    switch (fileTypeName) {
      case 'accept lor':
        form.setFieldValue(
          `${formKeyName}.${departmentIndex}.servePreferences.isAcceptLOR`,
          true,
        );
        break;
      case 'copy service':
        form.setFieldValue(
          `${formKeyName}.${departmentIndex}.servePreferences.isCopyService`,
          true,
        );
        break;
      case 'facility specific authorization':
        form.setFieldValue(
          `${formKeyName}.${departmentIndex}.servePreferences.isFacilitySpecificAuthorization`,
          true,
        );
        break;
      case 'additional facility specific documentation':
        form.setFieldValue(
          `${formKeyName}.${departmentIndex}.servePreferences.isAdditionalFacilitySpecificDocumentation`,
          true,
        );
        break;
      default:
        break;
    }
  };

  const getFileTypeDevHeight = (file: FilePondFile, index: number) => {
    if (file) {
      let itemHeight = Number(
        (
          window.document.getElementById(`filepond--item-${file.id}`)?.style
            .height ?? '50px'
        ).substring(0, 2),
      );
      return `${
        index === department.servePreferences.files.length - 1
          ? itemHeight + 7
          : itemHeight + 10
      }px`;
    }
  };

  const getFileTypeControleHeight = (file: FilePondFile) => {
    if (file) {
      let itemHeight = Number(
        (
          window.document.getElementById(`filepond--item-${file.id}`)?.style
            .height ?? '50px'
        ).substring(0, 2),
      );
      return `${itemHeight}px`;
    }
  };

  return (
    <Panel headerText="Documents">
      <Grid container>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <CheckboxInput
              label="Authorization with Subpoena Required"
              keyName={`${formKeyName}.${departmentIndex}.servePreferences.isAuthorizationWithSubpoenaRequired`}
              checked={
                department.servePreferences.isAuthorizationWithSubpoenaRequired
              }
              onChange={(e: any) => {
                form.setFieldValue(
                  `${formKeyName}.${departmentIndex}.servePreferences.isAuthorizationWithSubpoenaRequired`,
                  e.target.checked,
                );
              }}
              onBlur={() => {
                form.setFieldTouched(
                  `${formKeyName}.${departmentIndex}.servePreferences.isAuthorizationWithSubpoenaRequired`,
                  true,
                );
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <CheckboxInput
              label="POA for Subpoena"
              keyName={`${formKeyName}.${departmentIndex}.servePreferences.isPOAForSubpoena`}
              checked={department.servePreferences.isPOAForSubpoena}
              onChange={(e: any) => {
                form.setFieldValue(
                  `${formKeyName}.${departmentIndex}.servePreferences.isPOAForSubpoena`,
                  e.target.checked,
                );
              }}
              onBlur={() => {
                form.setFieldTouched(
                  `${formKeyName}.${departmentIndex}.servePreferences.isPOAForSubpoena`,
                  true,
                );
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <CheckboxInput
              label="HIPPA Authorization Original"
              keyName={`${formKeyName}.${departmentIndex}.servePreferences.isHIPPAAuthorizationOriginal`}
              checked={department.servePreferences.isHIPPAAuthorizationOriginal}
              onChange={(e: any) => {
                form.setFieldValue(
                  `${formKeyName}.${departmentIndex}.servePreferences.isHIPPAAuthorizationOriginal`,
                  e.target.checked,
                );
              }}
              onBlur={() => {
                form.setFieldTouched(
                  `${formKeyName}.${departmentIndex}.servePreferences.isHIPPAAuthorizationOriginal`,
                  true,
                );
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <CheckboxInput
              label="Facility Specific Authorization"
              keyName={`${formKeyName}.${departmentIndex}.servePreferences.isFacilitySpecificAuthorization`}
              checked={
                department.servePreferences.isFacilitySpecificAuthorization
              }
              onChange={(e: any) => {
                form.setFieldValue(
                  `${formKeyName}.${departmentIndex}.servePreferences.isFacilitySpecificAuthorization`,
                  e.target.checked,
                );
              }}
              onBlur={() => {
                form.setFieldTouched(
                  `${formKeyName}.${departmentIndex}.servePreferences.isFacilitySpecificAuthorization`,
                  true,
                );
              }}
            />
            {department.servePreferences.isFacilitySpecificAuthorization ? (
              <GenericInput
                name="facilitySpecificAuthorizationNotes"
                label="Facility Specific Authorization Notes"
                placeholder="Notes"
                key={`${formKeyName}.${departmentIndex}.servePreferences.facilitySpecificAuthorizationNotes`}
                maxLength={150}
                value={
                  department.servePreferences.facilitySpecificAuthorizationNotes
                }
                onChange={(e: any) => {
                  form.setFieldValue(
                    `${formKeyName}.${departmentIndex}.servePreferences.facilitySpecificAuthorizationNotes`,
                    e.target.value,
                  );
                }}
                onBlur={() => {
                  form.setFieldTouched(
                    `${formKeyName}.${departmentIndex}.servePreferences.facilitySpecificAuthorizationNotes`,
                    true,
                  );
                }}
                errorMsg={
                  form.touched[formKeyName] &&
                  form.touched[formKeyName][departmentIndex] &&
                  form.touched[formKeyName][departmentIndex][
                    'servePreferences'
                  ] &&
                  form.touched[formKeyName][departmentIndex][
                    'servePreferences'
                  ]['facilitySpecificAuthorizationNotes'] &&
                  form.errors[formKeyName] &&
                  form.errors[formKeyName][departmentIndex] &&
                  form.errors[formKeyName][departmentIndex][
                    'servePreferences'
                  ] &&
                  form.errors[formKeyName][departmentIndex]['servePreferences'][
                    'facilitySpecificAuthorizationNotes'
                  ]
                    ? form.errors[formKeyName][departmentIndex][
                        'servePreferences'
                      ]['facilitySpecificAuthorizationNotes']
                    : ''
                }
                hasError={
                  form.touched[formKeyName] &&
                  form.touched[formKeyName][departmentIndex] &&
                  form.touched[formKeyName][departmentIndex][
                    'servePreferences'
                  ] &&
                  form.touched[formKeyName][departmentIndex][
                    'servePreferences'
                  ]['facilitySpecificAuthorizationNotes'] &&
                  form.errors[formKeyName] &&
                  form.errors[formKeyName][departmentIndex] &&
                  form.errors[formKeyName][departmentIndex][
                    'servePreferences'
                  ] &&
                  form.errors[formKeyName][departmentIndex]['servePreferences'][
                    'facilitySpecificAuthorizationNotes'
                  ]
                    ? true
                    : false
                }
              />
            ) : (
              ''
            )}
          </Grid>
        </Grid>

        <Grid item xs={6} pl={3}>
          <Grid item xs={12}>
            <CheckboxInput
              label="Accept LOR"
              keyName={`${formKeyName}.${departmentIndex}.servePreferences.isAcceptLOR`}
              checked={department.servePreferences.isAcceptLOR}
              onChange={(e: any) => {
                form.setFieldValue(
                  `${formKeyName}.${departmentIndex}.servePreferences.isAcceptLOR`,
                  e.target.checked,
                );
              }}
              onBlur={() => {
                form.setFieldTouched(
                  `${formKeyName}.${departmentIndex}.servePreferences.isAcceptLOR`,
                  true,
                );
              }}
            />

            {department.servePreferences.isAcceptLOR ? (
              <GenericInput
                name="acceptLORNotes"
                label="Accept LOR Notes"
                placeholder="Notes"
                key={`${formKeyName}.${departmentIndex}.servePreferences.acceptLORNotes`}
                maxLength={150}
                value={department.servePreferences.acceptLORNotes}
                onChange={(e: any) => {
                  form.setFieldValue(
                    `${formKeyName}.${departmentIndex}.servePreferences.acceptLORNotes`,
                    e.target.value,
                  );
                }}
                onBlur={() => {
                  form.setFieldTouched(
                    `${formKeyName}.${departmentIndex}.servePreferences.acceptLORNotes`,
                    true,
                  );
                }}
                errorMsg={
                  form.touched[formKeyName] &&
                  form.touched[formKeyName][departmentIndex] &&
                  form.touched[formKeyName][departmentIndex][
                    'servePreferences'
                  ] &&
                  form.touched[formKeyName][departmentIndex][
                    'servePreferences'
                  ]['acceptLORNotes'] &&
                  form.errors[formKeyName] &&
                  form.errors[formKeyName][departmentIndex] &&
                  form.errors[formKeyName][departmentIndex][
                    'servePreferences'
                  ] &&
                  form.errors[formKeyName][departmentIndex]['servePreferences'][
                    'acceptLORNotes'
                  ]
                    ? form.errors[formKeyName][departmentIndex][
                        'servePreferences'
                      ]['acceptLORNotes']
                    : ''
                }
                hasError={
                  form.touched[formKeyName] &&
                  form.touched[formKeyName][departmentIndex] &&
                  form.touched[formKeyName][departmentIndex][
                    'servePreferences'
                  ] &&
                  form.touched[formKeyName][departmentIndex][
                    'servePreferences'
                  ]['acceptLORNotes'] &&
                  form.errors[formKeyName] &&
                  form.errors[formKeyName][departmentIndex] &&
                  form.errors[formKeyName][departmentIndex][
                    'servePreferences'
                  ] &&
                  form.errors[formKeyName][departmentIndex]['servePreferences'][
                    'acceptLORNotes'
                  ]
                    ? true
                    : false
                }
              />
            ) : (
              ''
            )}
          </Grid>

          <Grid item xs={12}>
            <CheckboxInput
              label="SOA for Subpoena"
              keyName={`${formKeyName}.${departmentIndex}.servePreferences.isSOAForSubpoena`}
              checked={department.servePreferences.isSOAForSubpoena}
              onChange={(e: any) => {
                form.setFieldValue(
                  `${formKeyName}.${departmentIndex}.servePreferences.isSOAForSubpoena`,
                  e.target.checked,
                );
              }}
              onBlur={() => {
                form.setFieldTouched(
                  `${formKeyName}.${departmentIndex}.servePreferences.isSOAForSubpoena`,
                  true,
                );
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <CheckboxInput
              label="Copy Service"
              keyName={`${formKeyName}.${departmentIndex}.servePreferences.isCopyService`}
              checked={department.servePreferences.isCopyService}
              onChange={(e: any) => {
                form.setFieldValue(
                  `${formKeyName}.${departmentIndex}.servePreferences.isCopyService`,
                  e.target.checked,
                );
              }}
              onBlur={() => {
                form.setFieldTouched(
                  `${formKeyName}.${departmentIndex}.servePreferences.isCopyService`,
                  true,
                );
              }}
            />
            {department.servePreferences.isCopyService ? (
              <GenericInput
                name="CopyServiceNotes"
                label="Copy Service Notes"
                placeholder="Copy Service Notes"
                maxLength={150}
                value={department.servePreferences.copyServiceNotes}
                onChange={(e: any) => {
                  form.setFieldValue(
                    `${formKeyName}.${departmentIndex}.servePreferences.copyServiceNotes`,
                    e.target.value,
                  );
                }}
                onBlur={() => {
                  form.setFieldTouched(
                    `${formKeyName}.${departmentIndex}.servePreferences.copyServiceNotes`,
                    true,
                  );
                }}
                errorMsg={
                  form.touched[formKeyName] &&
                  form.touched[formKeyName][departmentIndex] &&
                  form.touched[formKeyName][departmentIndex][
                    'servePreferences'
                  ] &&
                  form.touched[formKeyName][departmentIndex][
                    'servePreferences'
                  ]['copyServiceNotes'] &&
                  form.errors[formKeyName] &&
                  form.errors[formKeyName][departmentIndex] &&
                  form.errors[formKeyName][departmentIndex][
                    'servePreferences'
                  ] &&
                  form.errors[formKeyName][departmentIndex]['servePreferences'][
                    'copyServiceNotes'
                  ]
                    ? form.errors[formKeyName][departmentIndex][
                        'servePreferences'
                      ]['copyServiceNotes']
                    : ''
                }
                hasError={
                  form.touched[formKeyName] &&
                  form.touched[formKeyName][departmentIndex] &&
                  form.touched[formKeyName][departmentIndex][
                    'servePreferences'
                  ] &&
                  form.touched[formKeyName][departmentIndex][
                    'servePreferences'
                  ]['copyServiceNotes'] &&
                  form.errors[formKeyName] &&
                  form.errors[formKeyName][departmentIndex] &&
                  form.errors[formKeyName][departmentIndex][
                    'servePreferences'
                  ] &&
                  form.errors[formKeyName][departmentIndex]['servePreferences'][
                    'copyServiceNotes'
                  ]
                    ? true
                    : false
                }
              />
            ) : (
              ''
            )}
          </Grid>

          <Grid item xs={12}>
            <CheckboxInput
              label="Additional Facility Specific Documentation"
              keyName={`${formKeyName}.${departmentIndex}.servePreferences.isAdditionalFacilitySpecificDocumentation`}
              checked={
                department.servePreferences
                  .isAdditionalFacilitySpecificDocumentation
              }
              onChange={(e: any) => {
                form.setFieldValue(
                  `${formKeyName}.${departmentIndex}.servePreferences.isAdditionalFacilitySpecificDocumentation`,
                  e.target.checked,
                );
              }}
              onBlur={() => {
                form.setFieldTouched(
                  `${formKeyName}.${departmentIndex}.servePreferences.isAdditionalFacilitySpecificDocumentation`,
                  true,
                );
              }}
            />
            {department.servePreferences
              .isAdditionalFacilitySpecificDocumentation ? (
              <GenericInput
                name="additionalFacilitySpecificDocumentationNotes"
                label="Additional Facility Specific Documentation Notes"
                placeholder="Additional Facility Specific Documentation Notes"
                maxLength={150}
                value={
                  department.servePreferences
                    .additionalFacilitySpecificDocumentationNotes
                }
                onChange={(e: any) => {
                  form.setFieldValue(
                    `${formKeyName}.${departmentIndex}.servePreferences.additionalFacilitySpecificDocumentationNotes`,
                    e.target.value,
                  );
                }}
                onBlur={() => {
                  form.setFieldTouched(
                    `${formKeyName}.${departmentIndex}.servePreferences.additionalFacilitySpecificDocumentationNotes`,
                    true,
                  );
                }}
                errorMsg={
                  form.touched[formKeyName] &&
                  form.touched[formKeyName][departmentIndex] &&
                  form.touched[formKeyName][departmentIndex][
                    'servePreferences'
                  ] &&
                  form.touched[formKeyName][departmentIndex][
                    'servePreferences'
                  ]['additionalFacilitySpecificDocumentationNotes'] &&
                  form.errors[formKeyName] &&
                  form.errors[formKeyName][departmentIndex] &&
                  form.errors[formKeyName][departmentIndex][
                    'servePreferences'
                  ] &&
                  form.errors[formKeyName][departmentIndex]['servePreferences'][
                    'additionalFacilitySpecificDocumentationNotes'
                  ]
                    ? form.errors[formKeyName][departmentIndex][
                        'servePreferences'
                      ]['additionalFacilitySpecificDocumentationNotes']
                    : ''
                }
                hasError={
                  form.touched[formKeyName] &&
                  form.touched[formKeyName][departmentIndex] &&
                  form.touched[formKeyName][departmentIndex][
                    'servePreferences'
                  ] &&
                  form.touched[formKeyName][departmentIndex][
                    'servePreferences'
                  ]['additionalFacilitySpecificDocumentationNotes'] &&
                  form.errors[formKeyName] &&
                  form.errors[formKeyName][departmentIndex] &&
                  form.errors[formKeyName][departmentIndex][
                    'servePreferences'
                  ] &&
                  form.errors[formKeyName][departmentIndex]['servePreferences'][
                    'additionalFacilitySpecificDocumentationNotes'
                  ]
                    ? true
                    : false
                }
              />
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} pt={2}>
          <DepartmentEditOldFiles
            form={form}
            formKeyName={formKeyName}
            departmentIndex={departmentIndex}
          />
        </Grid>
        <Grid item xs={1.5}></Grid>

        <Grid item xs={9} sx={uploadFilesStyles.wrapperstyles}>
          <Grid item xs={3} sx={uploadFilesStyles.headerStyles}>
            <Box
              data-testid="uploadIcon"
              height={'25px'}
              width={'25px'}
              sx={uploadFilesStyles.uploadIconStyles}
            >
              <img src={uploadIcon} width={'25px'} height={'25px'} />
            </Box>
            <Typography sx={uploadFilesStyles.headerTextStyles}>
              Upload Files
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            className="file-panal"
            sx={uploadFilesStyles.filePanelStyles}
            alignItems="flex-end"
          >
            <Grid item xs={8}>
              <UploadFilesComponent
                keyName={`fileUploader.${departmentIndex}`}
                setIsBusy={setIsBusy}
                acceptMultipleFiles={true}
                maxNumberOfFiles={10}
                updateFormFiles={updateFormFiles}
                fileTypes={acceptedFileUploadTypes}
                initialFiles={department.servePreferences.files?.map(
                  (f) => f.pondFile,
                )}
                maxFileSize={maximumUploadFileSize}
              ></UploadFilesComponent>
            </Grid>
            <Grid item xs={4} className="file-type">
              {department.servePreferences.files.length > 0 && (
                <>
                  {department.servePreferences.files.map((file, i) => {
                    return (
                      <Grid
                        style={{
                          height: getFileTypeDevHeight(file.pondFile, i),
                        }}
                        item
                        key={i}
                      >
                        <FormControl
                          style={{
                            width: '100%',
                            height: getFileTypeControleHeight(file.pondFile),
                          }}
                          size="small"
                        >
                          <GenericSelectInput
                            name="typeId"
                            label="File Type"
                            disabled={isBusy}
                            options={departmentFileTypes}
                            value={department.servePreferences.files[i]?.typeId}
                            onChange={(e: any) => {
                              autoCheckServePreferencesFields(e);
                              form.setFieldValue(
                                `${formKeyName}.${departmentIndex}.servePreferences.files.${i}.typeId`,
                                e,
                              );
                            }}
                            onBlur={() => {
                              form.setFieldTouched(
                                `${formKeyName}.${departmentIndex}.servePreferences.files.${i}.typeId`,
                                true,
                              );
                            }}
                            errorMsg={
                              form.errors[formKeyName] &&
                              form.errors[formKeyName][departmentIndex] &&
                              form.errors[formKeyName][departmentIndex][
                                'servePreferences'
                              ] &&
                              form.errors[formKeyName][departmentIndex][
                                'servePreferences'
                              ]['files'] &&
                              form.errors[formKeyName][departmentIndex][
                                'servePreferences'
                              ]['files'][i] &&
                              form.errors[formKeyName][departmentIndex][
                                'servePreferences'
                              ]['files'][i]['typeId']
                                ? form.errors[formKeyName][departmentIndex][
                                    'servePreferences'
                                  ]['files'][i]['typeId']
                                : ''
                            }
                            hasError={
                              form.errors[formKeyName] &&
                              form.errors[formKeyName][departmentIndex] &&
                              form.errors[formKeyName][departmentIndex][
                                'servePreferences'
                              ] &&
                              form.errors[formKeyName][departmentIndex][
                                'servePreferences'
                              ]['files'] &&
                              form.errors[formKeyName][departmentIndex][
                                'servePreferences'
                              ]['files'][i] &&
                              form.errors[formKeyName][departmentIndex][
                                'servePreferences'
                              ]['files'][i]['typeId']
                                ? true
                                : false
                            }
                            required={true}
                            small={true}
                          />
                        </FormControl>
                      </Grid>
                    );
                  })}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1.5}></Grid>
      </Grid>
    </Panel>
  );
}
