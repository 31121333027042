import { SxProps } from '@mui/material';
const disabledContainer: SxProps = {
  pointerEvents: 'none',
  opacity: '0.4',
};
const loaderContainer: SxProps = {
  position: 'absolute',
  left: '50%',
  top: '50%',
};
export default {
  disabledContainer,
  loaderContainer,
};
