import { Box, Grid, Typography } from '@mui/material';
import MultipleCheckBoxes from '../../../AddLocation/Components/UI/MultipleCheckBoxes';
import CheckboxInput from '../../../AddLocation/Components/UI/CheckboxInput';
import {
  detailsTitle,
  detailsValue,
  title,
} from '../../../../assets/styles/appStyles';
import { emptyValue } from '../../../../settings/appConfig';

export default function BillingInfoFeesDetails({
  processingFeesSubpoena,
  processingFeesAuthorization,
  initialProcessingFee,
  initialProcessingFeeNotes,
  billingInfoProcessingFeesMethods,
  paymentMethods,
}: {
  paymentMethods: any[];
  billingInfoProcessingFeesMethods: string[];
  processingFeesSubpoena: number;
  processingFeesAuthorization: number;
  initialProcessingFee: boolean;
  initialProcessingFeeNotes: string;
}) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <MultipleCheckBoxes
          labelStyle={detailsTitle}
          options={paymentMethods}
          keyName={''}
          label="Processing fees method"
          selectedValues={billingInfoProcessingFeesMethods}
          toggleChange={() => {}}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography sx={detailsTitle}>
          Processing Fees (Authorization)
        </Typography>
        <Box sx={detailsValue}>
          {!!processingFeesAuthorization
            ? `${processingFeesAuthorization}$`
            : emptyValue}
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Box sx={detailsTitle}>
          <CheckboxInput
            keyName=""
            label="Initial processing fees"
            onBlur={() => {}}
            onChange={() => {}}
            checked={initialProcessingFee}
          />
        </Box>
        {initialProcessingFee && (
          <Box>
            <Typography sx={detailsTitle}>Notes</Typography>
            <Box sx={detailsValue}>
              {initialProcessingFee ? initialProcessingFeeNotes : emptyValue}
            </Box>
          </Box>
        )}
      </Grid>

      <Grid item xs={6}>
        <Typography sx={detailsTitle}>Processing Fees (Subpoena)</Typography>
        <Box sx={detailsValue}>
          {!!processingFeesSubpoena ? `${processingFeesSubpoena}$` : emptyValue}
        </Box>
      </Grid>
    </Grid>
  );
}
