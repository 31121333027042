import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
} from '@mui/material';
import SelectOption from '../../../../Models/Forms/selectOption';
import { fileTypeError, menuItem } from './../../../../assets/styles/appStyles';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';

export default function GenericMenuInput({
  options,
  onChange,
  value,
  errorMsg,
  multiple,
}: {
  options: SelectOption[] | any[];
  onChange: (e: string) => void;
  value: string | string[] | null;
  errorMsg: string;
  multiple?: boolean;
}) {
  return (
    <FormControl fullWidth>
      {options?.map((item, index) => (
        <MenuItem
          key={item.id + index}
          value={item.id}
          sx={menuItem}
          onClick={() => onChange(item.id)}
        >
          <Grid
            pl={1}
            container
            key={item.id + index}
            alignItems={'center'}
            spacing={1}
          >
            <Grid item m={0}>
              {(multiple && value?.includes(item.id.toString())) ||
              (!multiple && item?.id?.toString() == value) ? (
                <Box mt={1}>
                  <CheckCircle fontSize="small" />
                </Box>
              ) : (
                <Box mt={1}>
                  <RadioButtonUnchecked fontSize="small" />
                </Box>
              )}
            </Grid>
            <Grid item>{item.name}</Grid>
          </Grid>
        </MenuItem>
      ))}
      {errorMsg ? (
        <FormHelperText sx={fileTypeError}>{errorMsg}</FormHelperText>
      ) : null}
    </FormControl>
  );
}
