import { Grid } from '@mui/material';
import Panel from '../../../../components/UI/Panel';
import CheckboxInput from '../UI/CheckboxInput';
import InsertDepartmentModel from '../../../../Models/Departments/InsertDepartmentModel';

export default function DepartmentServePreferencesCaseInfo({
	form,
	department,
	formKeyName,
	departmentIndex,
}: {
	form: any;
	department: InsertDepartmentModel;
	formKeyName: string;
	departmentIndex: number;
}) {
	return (
		<Panel headerText='Case'>
			<Grid container>
				<Grid item xs={6}>
					<CheckboxInput
						label='Require Date Of Loss'
						checked={department.servePreferences.isRequireDateOfLoss}
						keyName={`${formKeyName}.${departmentIndex}.servePreferences.isRequireDateOfLoss`}
						onChange={(e: any) => {
							form.setFieldValue(
								`${formKeyName}.${departmentIndex}.servePreferences.isRequireDateOfLoss`,
								e.target.checked,
							);
						}}
						onBlur={() => {
							form.setFieldTouched(
								`${formKeyName}.${departmentIndex}.servePreferences.isRequireDateOfLoss`,
								true,
							);
						}}

					/>
				</Grid>
				<Grid item xs={6} pl={3}>
					<CheckboxInput
						label='Claim number'
						checked={department.servePreferences.isClaimNumber}
						keyName={`${formKeyName}.${departmentIndex}.servePreferences.isClaimNumber`}
						onChange={(e: any) => {
							form.setFieldValue(
								`${formKeyName}.${departmentIndex}.servePreferences.isClaimNumber`,
								e.target.checked,
							);
						}}
						onBlur={() => {
							form.setFieldTouched(
								`${formKeyName}.${departmentIndex}.servePreferences.isClaimNumber`,
								true,
							);
						}}
					/>
				</Grid>
			</Grid>
		</Panel>
	)
}
