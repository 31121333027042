import { Typography, Box } from '@mui/material';
import CustodiansListTable from './Custodians/CustodiansListTable';
import Styles from './Styles/ListCustodiansStyles';
import CustodiansSearch from './Custodians/CustodiansSearch';
import useFetchCustodiansSearchResult from '../../hooks/Locations/useFetchCustodiansSearchResult';
import { cardContainer } from '../../assets/styles/appStyles';
import { useState } from 'react';

export default function CustodiansList() {
  const { data, pageChanged, searchChanged, isLoading } = useFetchCustodiansSearchResult();
  const [pageNo, setPageNo] = useState(0);

  const onSearchChanged = (searchText?: string, stateId?: number) => {
    setPageNo(0);
    searchChanged(searchText, stateId);
  }

  return (
    <Box sx={cardContainer} p={2}>
      <Box sx={Styles.cardStyles}>
        <Typography sx={Styles.headerTitleStyles}>Custodians Management</Typography>
      </Box>
      <CustodiansSearch onSearchChanged={onSearchChanged}
       showStatesFilteration={true}/>
      <CustodiansListTable
        custodians={data.custodians}
        count={data.count}
        onPageChanged={pageChanged}
        isLoading={isLoading}
        unmountCollapsedOnExit={true}
        pageNo={pageNo}
        setPageNo={setPageNo}
        usePagination={true}
        showNoRecordsFoundMessage = {true}
      />
    </Box>
  );
}
