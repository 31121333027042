import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import TabPanel from '../../../components/UI/TabPanel';
import CustodianBasicDetailsEditForm from '../../AddLocation/Components/CustodianBasicDetails';
import CustodianAddressDetails from './CustodianAddressDetails';
import CustodianContactsDetails from './CustodianContactDetails';
import { useEffect, useState } from 'react';
import {
  ExpandMore,
  EditOutlined,
  CancelOutlined,
  SaveOutlined,
} from '@mui/icons-material';
import {
  accordionSummaryText,
  cancelIcon,
  cancelIconText,
  editFormContainer,
  editIcon,
  editIconText,
  iconContainer,
  saveIcon,
  saveIconText,
} from '../Styles/MainInformationTabStyles';
import CustodianDetailsViewModel from '../../../Models/custodian/custodian-details-viewmodel';
import { MouseEvent } from 'react';
import CustodianBasicDetails from './CustodianBasicDetails';
import useMainInfoForm from '../../../hooks/Locations/addLocation/useMainInfoForm';
import {
  FormikProps,
  FormikTouched,
  FormikValues,
  setNestedObjectValues,
} from 'formik';
import useAddCustodianLookups from '../../../hooks/Locations/addLocation/useAddCustodianLookups';
import CustodianAddress from '../../AddLocation/Components/CustodianAddress';
import CustodianContactInfo from '../../AddLocation/Components/CustodianContactInfo';
import usePatchCustodian from '../../../hooks/custodians/usePatchCustodian';
import ComponentLoader from '../../../common/ComponentLoader/componentLoader';
import useUI from '../../../hooks/context/useUI';
import useCheckDuplicatePhonesAndEmails from '../../../hooks/Locations/useCheckDuplicatePhonesAndEmails';

interface MainInformationTabProps {
  selectedTabIndex: number;
  custodian: CustodianDetailsViewModel;
  onCustodianUpdate: any;
}

export default function MainInformationTab(props: MainInformationTabProps) {
  const { selectedTabIndex, custodian, onCustodianUpdate } = props;
  const [custodianData, setCustodianData] = useState(custodian);
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useUI();
  const {hasDuplicatePhoneOrEmailError} = useCheckDuplicatePhonesAndEmails();
  const { states, custodianTypes, phoneTypes, timeZones } =
    useAddCustodianLookups();
  const patchCustodianBasicInfo = usePatchCustodian().patchCustodian;
  const mainInfoForm: FormikProps<any> = useMainInfoForm({
    phoneTypes: phoneTypes,
    custodianTypes: custodianTypes,
    states: states,
  }).mainInfoForm;

  const sections = {
    basicDetails: 'basicDetails',
    addressDetails: 'addressDetails',
    contactDetails: 'contactDetails',
  };
  const updateFormFromCustodian = () => {
    (mainInfoForm as FormikProps<any>).resetForm({
      values: createFormValues(custodian),
    });
    setCustodianData(custodian);
    return mainInfoForm;
  };

  const createFormValues = (data: CustodianDetailsViewModel) => {
    return {
      name: data?.name,
      typeId: data?.typeId,
      addressInfo: {
        addressDetails: data?.address?.addressDetails,
        city: data?.address?.city,
        stateId: data?.address?.stateId,
        postCode: data?.address?.postCode,
        timeZoneId: data?.address?.timeZoneId,
      },
      emails: data?.emailAddresses?.map((emailAddress) => {
        return { email: emailAddress };
      }),
      portalUrl: data?.portalURL,
      phones: data?.phoneNumbers,
      contacts: data?.contacts,
    };
  };

  useEffect(() => {
    updateFormFromCustodian();
  }, [custodian]);

  const patchCustodian = () => {
    const newCustodianDataVal = {
      ...custodianData,
      departments: [],
      name: mainInfoForm.values.name,
      typeId: mainInfoForm.values.typeId,
      type:
        custodianTypes.find((ct) => ct.id === mainInfoForm.values.typeId)
          ?.name || '',
      portalURL: mainInfoForm.values.portalUrl,
      emailAddresses: mainInfoForm.values.emails?.map(
        (emailObj: any) => emailObj.email,
      ),
      phoneNumbers: mainInfoForm.values.phones?.map(
        (p: any) => {
          return {
            isMainPhone: p.isMainPhone,
            phoneNumber: p.phoneNumber,
            phoneTypeId: p.phoneTypeId
          }
        }
      ),
      contacts: mainInfoForm.values.contacts,
      address: {
        addressDetails: mainInfoForm.values.addressInfo?.addressDetails,
        city: mainInfoForm.values.addressInfo?.city,
        postCode: mainInfoForm.values.addressInfo?.postCode,
        stateId: mainInfoForm.values.addressInfo?.stateId,
        timeZoneId: mainInfoForm.values.addressInfo?.timeZoneId,
      },
    };
    return patchCustodianBasicInfo(newCustodianDataVal);
  };

  const [expandedPanel, setExpandedPanel] = useState<string>(
    'Basic Details'
  );

  const [basicDetailsEditMode, setBasicDetailsEditMode] =
    useState<boolean>(false);

  const [addressDetailsEditMode, setAddressDetailsEditMode] =
    useState<boolean>(false);

  const [contactDetailsEditMode, setContactDetailsEditMode] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      basicDetailsEditMode ||
      addressDetailsEditMode ||
      contactDetailsEditMode
    ) {
      // marking all fields as touched
      mainInfoForm.validateForm().then((errors) => {
        if (Object.keys(errors).length !== 0) {
          mainInfoForm.setTouched(
            setNestedObjectValues<FormikTouched<FormikValues>>(errors, true),
          );
        }
      });
    }
  }, [basicDetailsEditMode, addressDetailsEditMode, contactDetailsEditMode]);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanding: boolean) => {
      if(isExpanding)
      setExpandedPanel(panel);
      else
      setExpandedPanel('');
    };

    const isExpanded = (panel: string) => 
    {
      return expandedPanel === panel;
    };

  const editClickHandler = (
    event: MouseEvent<HTMLDivElement>,
    accordionSection: string,
  ): void => {
    event.stopPropagation();
    mainInfoForm.resetForm({ values: createFormValues(custodianData) });
    switch (accordionSection) {
      case sections.basicDetails:
        setBasicDetailsEditMode(true);
        break;
      case sections.addressDetails:
        setAddressDetailsEditMode(true);
        break;
      case sections.contactDetails:
        setContactDetailsEditMode(true);
        break;
      default:
        break;
    }
  };

  const cancelClickHandler = (
    event: MouseEvent<HTMLDivElement>,
    accordionSection: string,
  ): void => {
    event.stopPropagation();
    exitEditMode(accordionSection);
    resetFormInitVal();
  };

  const resetFormInitVal = () => {
    mainInfoForm.resetForm();
  };

  const saveFormVal = async (shouldValidateDuplicatePhoneAndEmail? : boolean) => {
    const validationRes = await mainInfoForm.validateForm();
    if(shouldValidateDuplicatePhoneAndEmail)
    {
      return Object.keys(validationRes).length === 0 &&
      !hasDuplicatePhoneOrEmailError(mainInfoForm);
    }
    else
    {
      return Object.keys(validationRes).length === 0;
    }
  };

  const saveClickHandler = (
    event: MouseEvent<HTMLDivElement>,
    accordionSection: string,
    shouldValidateDuplicatePhoneAndEmail?: boolean 
  ): void => {
    event.stopPropagation();
    saveFormVal(shouldValidateDuplicatePhoneAndEmail).then((validationRes) => {
      if (validationRes === true) {
        setIsLoading(true);
        patchCustodian().then((res) => {
          if (res !== null) {
            populateLookupData(res);
            setCustodianData(res);
            onCustodianUpdate(res);
          }
          setIsLoading(false);
          exitEditMode(accordionSection);
        });
      }
      else
      {
        showSnackbar(['Please fix validation errors'], 'error');
      }
    });
  };

  const populateLookupData = (custodian: CustodianDetailsViewModel) => {
    custodian.type =
      custodianTypes.find((x) => x.id === custodian.typeId)?.name ?? '';
    if (custodian.address) {
      custodian.address.state =
        states.find((x) => x.id === custodian.address?.stateId)?.name ?? '';
      custodian.address.timeZone =
        timeZones.find((x) => x.id === custodian.address?.timeZoneId)?.name ??
        '';
    }
    if (custodian.phoneNumbers) {
      custodian.phoneNumbers.forEach(
        (phone) =>
          (phone.phoneType =
            phoneTypes.find((x) => x.id === phone.phoneTypeId)?.name ?? ''),
      );
    }
  };

  const exitEditMode = (accordionSection: string) => {
    switch (accordionSection) {
      case sections.basicDetails:
        setBasicDetailsEditMode(false);
        break;
      case sections.addressDetails:
        setAddressDetailsEditMode(false);
        break;
      case sections.contactDetails:
        setContactDetailsEditMode(false);
        break;
      default:
        break;
    }
  };

  return (
    <TabPanel value={selectedTabIndex} index={1}>
      <Box>
        <Accordion
          expanded={isExpanded('Basic Details')}
          onChange={handleAccordionChange('Basic Details')}
        >
          <AccordionSummary
            aria-controls="BasicDetails-content"
            id="BasicDetails-header"
            expandIcon={<ExpandMore />}
          >
            <Typography sx={accordionSummaryText}>Basic Details</Typography>
            {basicDetailsEditMode === false && (
              <Box
                sx={iconContainer}
                onClick={(e) => editClickHandler(e, sections.basicDetails)}
              >
                <EditOutlined sx={editIcon} />
                <Typography sx={editIconText}>Edit</Typography>
              </Box>
            )}
            {basicDetailsEditMode === true && (
              <>
                <Box
                  sx={iconContainer}
                  onClick={(e) => cancelClickHandler(e, sections.basicDetails)}
                >
                  <CancelOutlined sx={cancelIcon} />
                  <Typography sx={cancelIconText}>Cancel</Typography>
                </Box>
                <Box
                  sx={iconContainer}
                  onClick={(e) => saveClickHandler(e, sections.basicDetails, true)}
                >
                  <SaveOutlined sx={saveIcon} />
                  <Typography sx={saveIconText}>Save</Typography>
                </Box>
              </>
            )}
          </AccordionSummary>
          <AccordionDetails
            sx={basicDetailsEditMode === true ? editFormContainer : null}
          >
            {!basicDetailsEditMode && (
              <CustodianBasicDetails custodian={custodianData} />
            )}
            {basicDetailsEditMode && (
              <ComponentLoader isOpen={isLoading}>
                <CustodianBasicDetailsEditForm
                  formik={mainInfoForm}
                  custodianTypes={custodianTypes}
                  phoneTypes={phoneTypes}
                  showPanel={false}
                ></CustodianBasicDetailsEditForm>
              </ComponentLoader>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={isExpanded('Address Details')}
          onChange={handleAccordionChange('Address Details')}
        >
          <AccordionSummary
            aria-controls="Address-content"
            id="Address-header"
            expandIcon={<ExpandMore />}
          >
            <Typography sx={accordionSummaryText}>Address Details</Typography>
            {addressDetailsEditMode === false && (
              <Box
                sx={iconContainer}
                onClick={(e) => editClickHandler(e, sections.addressDetails)}
              >
                <EditOutlined sx={editIcon} />
                <Typography sx={editIconText}>Edit</Typography>
              </Box>
            )}
            {addressDetailsEditMode === true && (
              <>
                <Box
                  sx={iconContainer}
                  onClick={(e) =>
                    cancelClickHandler(e, sections.addressDetails)
                  }
                >
                  <CancelOutlined sx={cancelIcon} />
                  <Typography sx={cancelIconText}>Cancel</Typography>
                </Box>
                <Box
                  sx={iconContainer}
                  onClick={(e) => saveClickHandler(e, sections.addressDetails)}
                >
                  <SaveOutlined sx={saveIcon} />
                  <Typography sx={saveIconText}>Save</Typography>
                </Box>
              </>
            )}
          </AccordionSummary>
          <AccordionDetails
            sx={addressDetailsEditMode === true ? editFormContainer : null}
          >
            {!addressDetailsEditMode && (
              <CustodianAddressDetails
                address={custodianData.address}
              ></CustodianAddressDetails>
            )}
            {addressDetailsEditMode && (
              <ComponentLoader isOpen={isLoading}>
                <CustodianAddress
                  states={states}
                  timeZones={timeZones}
                  formik={mainInfoForm}
                  showPanel={false}
                />
              </ComponentLoader>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={isExpanded('Contact Details')}
          onChange={handleAccordionChange('Contact Details')}
        >
          <AccordionSummary
            aria-controls="ContactDetails-content"
            id="ContactDetails-header"
            expandIcon={<ExpandMore />}
          >
            <Typography sx={accordionSummaryText}>Contact Details</Typography>
            {contactDetailsEditMode === false && (
              <Box
                sx={iconContainer}
                onClick={(e) => editClickHandler(e, sections.contactDetails)}
              >
                <EditOutlined sx={editIcon} />
                <Typography sx={editIconText}>Edit</Typography>
              </Box>
            )}
            {contactDetailsEditMode === true && (
              <>
                <Box
                  sx={iconContainer}
                  onClick={(e) =>
                    cancelClickHandler(e, sections.contactDetails)
                  }
                >
                  <CancelOutlined sx={cancelIcon} />
                  <Typography sx={cancelIconText}>Cancel</Typography>
                </Box>
                <Box
                  sx={iconContainer}
                  onClick={(e) => saveClickHandler(e, sections.contactDetails)}
                >
                  <SaveOutlined sx={saveIcon} />
                  <Typography sx={saveIconText}>Save</Typography>
                </Box>
              </>
            )}
          </AccordionSummary>
          <AccordionDetails
            sx={contactDetailsEditMode === true ? editFormContainer : null}
          >
            {!contactDetailsEditMode && (
              <CustodianContactsDetails
                contacts={custodianData?.contacts ?? []}
              />
            )}
            {contactDetailsEditMode && (
              <ComponentLoader isOpen={isLoading}>
                <CustodianContactInfo formik={mainInfoForm} showPanel={false} />
              </ComponentLoader>
            )}
          </AccordionDetails>
        </Accordion>
        
      </Box>
    </TabPanel>
  );
}
