import { useNavigate } from 'react-router';
import style from '../../Locations/Styles/DuplicateCustodiansTableStyles';
import { duplicateLocationRowsPerPage } from '../../../settings/appConfig';
import {
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import useSearchCustodians from '../../../hooks/Locations/useSearchCustodians';

export default function DuplicateCustodiansTable({
  searchPropertyName,
  searchPropertyValue,
  isModalOpened,
}: {
  searchPropertyName: string;
  searchPropertyValue: string;
  isModalOpened: boolean;
}) {
  const nav = useNavigate();
  const { data, page, isLoading, searchUsingFieldedSearch } =
    useSearchCustodians(isModalOpened, searchPropertyName, searchPropertyValue);

  return data && data.custodians && data.custodians.length > 0 ? (
    <TableContainer>
      <Table sx={style.scrollableTable}>
        <TableHead>
          <TableRow>
            <TableCell width={'25%'}>Name</TableCell>
            <TableCell width={'40%'}>Address</TableCell>
            <TableCell width={'10%'}>City</TableCell>
            <TableCell width={'10%'}>State</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.custodians.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Typography>{row.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{row.address}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{row.city}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{row.stateName}</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <Button
                    size="small"
                    onClick={() =>
                      window.open(`/custodians/details/${row.id}`, '_blank')
                    }
                  >
                    View
                  </Button>
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        labelRowsPerPage={'Items per page:'}
        showFirstButton
        showLastButton
        count={data.totalCount}
        page={page}
        onPageChange={(e, newPage) => searchUsingFieldedSearch(newPage)}
        rowsPerPage={duplicateLocationRowsPerPage}
        rowsPerPageOptions={[]}
      />
    </TableContainer>
  ) : (
    <Grid>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Typography id="modal-modal-title" variant="h6">
          No duplicates found
        </Typography>
      )}
    </Grid>
  );
}
