import { useEffect, useCallback, useState } from 'react';
import useHttp from '../http/useHttp';
import Role from '../../Models/Roles/roles';

export default function useFetchRoles() {
  const { getDataResponse, getData } = useHttp();
  const [isLoading, setIsLoading] = useState(false);

  const init = useCallback(async () => {
    try {
      setIsLoading(true);
      await getData('role/getall');
    } finally {
      setIsLoading(false);
    }
  }, []);

  let roles: Role[] = getDataResponse?.data ?? [];

  useEffect(() => {
    init();
  }, [init]);

  return { roles, isLoading };
}
