import { SxProps } from '@mui/material';

const wrapperstyles: SxProps =
{
	border: '1px dashed grey',
	display: 'flex',
	pl: 1,
	pr: 1,
	mt:3,
	borderRadius: 2,
};

const gridStyles: SxProps = {
	justifyContent: 'center'
};

const headerStyles: SxProps = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}

const headerTextStyles: SxProps = {
	fontWeight:'bold',
	 display:'inline'
}

const uploadSectionStyles: SxProps = {
	mb:'-10px'
}

const uploadIconStyles: SxProps = {
	p:'2px',
	pb:'5px'
}

const filePanelStyles: SxProps = 
{
	display:'flex',
	marginRight : '-7px',
	backgroundColor: '#f1f0ef',
	borderRadius: '5px',
	borderBottom: 'inset',
	overflowX: 'hidden',
	overflowY: 'auto', 
	maxHeight: '600px',
	'&:hover': {
		opacity: '0.8'
	  },
}

const styleClasses = {
	wrapperstyles,
	gridStyles,
	headerStyles,
	uploadIconStyles,
	uploadSectionStyles,
	filePanelStyles,
	headerTextStyles
};


export default styleClasses;
