import { Grid, IconButton, Typography } from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../UI/AccordionHelper';
import DepartmentForm from './DepartmentForm';
import InsertDepartmentModel from '../../../../Models/Departments/InsertDepartmentModel';
import { Delete } from '@mui/icons-material';

export default function DepartmentContainer({
  form,
  formKeyName,
  department,
  departmentIndex,
  canRemove,
  togglePanel,
  removeDepartment,
  orderTypes,
  phoneTypes,
  states,
  recordTypes,
  timeZones,
  departmentFileTypes,
  serveMethods,
  days,
  copyCusodianAddress,
}: {
  department: InsertDepartmentModel;
  formKeyName: string;
  form: any;
  departmentIndex: number;
  canRemove: boolean;
  orderTypes: any[];
  phoneTypes: any[];
  states: any[];
  recordTypes: any[];
  timeZones: any[];
  serveMethods: any[];
  days: any[];
  departmentFileTypes: any[];
  togglePanel: (
    id: number,
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  removeDepartment: (id: number) => void;
  copyCusodianAddress?: (key: string) => void;
}) {
  return (
    <>
      <Accordion
        expanded={department.active}
        onChange={togglePanel(department.id)}
      >
        <AccordionSummary
          aria-controls={`${department.id}-content`}
          id={`${department.id}-header`}
        >
          <Grid
            container
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Grid item px={2}>
              <Typography>{department?.name}</Typography>
            </Grid>
            {canRemove && (
              <Grid item>
                <IconButton
                  data-testid="remove-department"
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeDepartment(department.id);
                  }}
                >
                  <Delete />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <DepartmentForm
            form={form}
            department={department}
            formKeyName={formKeyName}
            departmentIndex={departmentIndex}
            orderTypes={orderTypes}
            phoneTypes={phoneTypes}
            states={states}
            recordTypes={recordTypes}
            timeZones={timeZones}
            departmentFileTypes={departmentFileTypes}
            serveMethods={serveMethods}
            days={days}
            isWizard={true}
            copyCusodianAddress={copyCusodianAddress}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
