import { useCallback, useEffect, useState } from 'react';
import useHttp from '../http/useHttp';

export default function useFetchUserRole(id?: string) {
  const { getDataResponse, getData } = useHttp();
  const [isLoading, setIsLoading] = useState(false);

  const init = useCallback(async (id: string) => {
    try {
      setIsLoading(true);
      await getData(`user/getuserrole/${id}`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  let userRole: any = getDataResponse?.data ?? null;

  useEffect(() => {
    if (id && +id > 0) init(id);
  }, [init, id]);

  return { userRole, isLoading };
}
